import React from 'react';
import C18Input00 from './C18Input00'
import C18InputError00 from './C18InputError00'
import C18Button00 from './C18Button00'
import C18Anchor00 from './C18Anchor00'
import history from "../../history"
import {addToAdminLog, loadDevicesInfo, updateDeviceInfo, rnRest, handleRnMsg, loadZonesInfo, loadPrivsInfo, privs} from './C18utils';
import {dbVals} from '../../components/utils/http';
import{wsTrans, saveTokens,getUId,checkLoggedIn,getWindowResize} from '../utils/utils';
import {cl, globs, constant, initInfos, getTime,ufs,getLocalStorage,saveLocalStorage} from '../../components/utils/utils';

class C18Login00 extends React.Component{
  constructor(props) {
    super(props);
    document.title="Login";
//     cl(props)
//     cl(dbVals)
    this.doMobileLogin()

    this.state={
      email: "",//"LabSite2@grr.la",
      password: "",
      passwordVisible:false,
      passwordType: "password",
      rememberMe: false,
      loggedIn:false,
      tokenLink:"",
      loading:false,
    }
     this.alerts=[
      "Please Enter a Valid Email",//6
      "Checking . . .",//12
      "Please Enter Your Email Address"
     ];
     this.loadInfo()
  }
  
  testUfs=async()=>{
    let files=await ufs.list()
    cl(files)
  }
  
  loadInfo=async()=>{
//     cl("load info")
    await checkLoggedIn()
    let url="/usa/c18/sites"
    if(globs.userData?.loggedIn) {
      if(globs.requestedUrl){url=globs.requestedUrl}
      cl("pushing url")
//       history.push(url)
    } else {
      let ret=this.setState({loggedIn:globs?.userData?.loggedIn})
//       cl(ret)
    }
//     this.testUfs()

//     cl(globs.userData.loggedIn)
    
  }
  
  doLogin=async(force=false)=>{
//     globs.userData=null
//     cl(globs.requestedUrl)
    initInfos()
    let deviceId = getUId()// if the uId hasn't been loaded

//     cl("login");
//     cl(dbVals)
//     dbVals=dbVals||null
//     globs=globs||null
//     cl("login")
    this.setState({loading:true})
    wsTrans("usa", {cmd: "cRest", uri: "/o/users/login", method: "create", 
      body: {
        email: this.state.email,
        password: this.state.password,
        uId:deviceId,
        rememberMe: this.state.rememberMe,
        force: force,// if logged in elsewhere
      }}).then(async r=>{
//       if(r.result=="notFound"){
//         this.mySetState("login",{passwordError:"I can't find that email / password combination"})
//       }
      switch(r.result){
        case "notFound":
          this.mySetState("login",{passwordError:"I can't find that email / password combination"})
          this.setState({loading:false})
          break
        case "notActive":
          this.mySetState("login",{passwordError:"This user is not Active"})
          this.setState({loading:false})
          break
        case "ok":
        case "alreadyLoggedIn":
          // admin log with user info
          globs.sitesInfo.got=false
          globs.zonesInfo.got=false
          globs.usersInfo.got=false
          getWindowResize()
//           cl(r.session)
          saveTokens(r.data.accessToken, r.data.refreshToken, r.session)
          await loadDevicesInfo()
          await loadZonesInfo()
          await loadPrivsInfo()
//           cl("tokens saved")
          setTimeout(async()=>{
//             console.log("login to sites")
            let adds = [{
              userId:globs.userData?.session?.userId,
              time:Math.floor(getTime()),
              action:"login",
              deviceName: globs.device?.deviceName,
              oldVal:"",
              newVal:"",
            }]
            addToAdminLog(adds)
            let url="/usa/c18/sites"
            if(globs.zonesInfo.info.length == 1) {
              let z = globs.zonesInfo.info[0]
              // check if current user has permission to view zone
              await wsTrans("usa", {cmd: "cRest", uri: "/s/sessions", method: "update", 
                sessionId: globs.userData.session.sessionId,
                body: {siteId:z.siteId}})
              globs.userData.session.siteId=z.siteId
              if(privs("zone",z.zoneId,constant.AREA_PRIVS_READ)){
                url=`/usa/c18/sites/${z.siteId}/zones/${z.zoneId}`
              }
            }
            if(globs.requestedUrl){url=globs.requestedUrl}
            history.push(url)
            this.setState({loading:false})
//             cl(`Pushed: ${url}`)
//             history.push("/usa/c18/sites")
            // window.location="/usa/c18/sites" 
          },1000)

          // send msg to device to save remember me and whether bio login should be enabled
          if (window.ReactNativeWebView) {
            let device
            if (this.state.bioEnabled == 0) {
               // send credentials and tell device to ask user if they want biometric login to be enabled
               device = await rnRest("/authenticate", "update", {email: this.state.email, password: this.state.password})
               // update device
               await updateDeviceInfo(device)
            }
            // save remember me to device
            device = await rnRest("/device", "update", {rememberMe: this.state.rememberMe})
            await updateDeviceInfo(device)
          } 
//           switch(globs.userData.mode){
//             case "c18":
//               history.push("/usa/c18/sites")
//               break
//             default:
// //               let dash=await getHomeDashboard()
// //               if(dash){history.push(`/usa/dash/${dash}`)}
//               break
//           }
// // //           userSpecificHome()
          break
//         case "alreadyLoggedIn":
//           this.setState({alreadyLoggedInMessage:"You're already logged into this account somewhere else"})
//           break
        default:
          this.setState({loading:false})
          this.mySetState("login",{generalError:"Cannot connect. Please try again later"})
          break
      }
//       if(r.result=="ok"){
//         switch(globs.userData.mode){
//           case "c18":
//             history.push("")
//             break
//           default:
//             break
//         }
// //         history.push('/usa/dash/vz4sci_m-iHC9nQZ');
//       }

    })
    .catch((err)=> {
      this.setState({loading:false})
      this.mySetState("login",{generalError:"Cannot connect. Please try again later"})
    })
  }

  doMobileLogin=async(force=false)=>{
//     cl("doMobileLogin")
    var body
    if (window.ReactNativeWebView) {
      initInfos()
      let deviceId = getUId()
      let ret = await rnRest("/authenticate", "retrieve", {deviceId: deviceId})
      // set device id as uid if possible
      if (ret.device.deviceId) {
        deviceId = getUId(ret.device.deviceId)
      }
      this.setState({bioEnabled: ret.device.bioEnabled, rememberMe: ret.device.rememberMe || this.state.rememberMe})
      
      // check if email and password exist, bioLogin is enabled
      // do auto login
      if (ret.credentials?.email && ret.credentials?.password && ret.device.bioEnabled == 2) {
        this.setState({loading: true})
        wsTrans("usa", {cmd: "cRest", uri: "/o/users/login", method: "create", 
        body: {
          email: ret.credentials.email,
          password: ret.credentials.password,
          uId: deviceId,
          rememberMe: ret.device.rememberMe || this.state.rememberMe,
          force: force,// if logged in elsewhere
        }}).then(async r=>{
          cl(r);
          switch(r.result){
            case "notFound":
              this.mySetState("login",{passwordError:"I can't find that email / password combination"})
              this.setState({loading:false})
              break
            case "notActive":
              this.mySetState("login",{passwordError:"This user is not Active"})
              this.setState({loading:false})
              break
            case "ok":
              globs.sitesInfo.got=false
              globs.zonesInfo.got=false
              globs.usersInfo.got=false
              saveTokens(r.data.accessToken, r.data.refreshToken, r.session)
              // create device (if not already made)
              await loadDevicesInfo()
              await loadZonesInfo()
              await loadPrivsInfo()
              // update device on table with info
              await updateDeviceInfo(ret.device)
              setTimeout(async()=>{ 
                // push history to c18host so nav state changes
                let adds = [{
                  userId:globs.userData.session.userId,
                  time:Math.floor(getTime()),
                  action:"login",
                  deviceName: globs.device?.deviceName,
                  oldVal:"",
                  newVal:"",
                }]
                addToAdminLog(adds)
                let url = "/usa/c18/sites"
                if(globs.zonesInfo.info.length == 1) {
                  let z = globs.zonesInfo.info[0]
                  await wsTrans("usa", {cmd: "cRest", uri: "/s/sessions", method: "update", 
                    sessionId: globs.userData.session.sessionId,
                    body: {siteId:z.siteId}})
                  globs.userData.session.siteId=z.siteId
                  if(privs("zone",z.zoneId,constant.AREA_PRIVS_READ)){
                    url=`/usa/c18/sites/${z.siteId}/zones/${z.zoneId}`
                  }
                }
                history.push(url)
                this.setState({loading:false})
                // window.location="/usa/c18/sites"
              },1000)
              break
          case "alreadyLoggedIn":
            // this.setState({alreadyLoggedInMessage:"You're already logged into this account somewhere else"})
            this.setState({loading:false})
            break
          default:
            this.setState({loading:false})
            break
          }
        })
        .catch((err)=> {
          this.mySetState("login",{generalError:"Cannot connect. Please try again later"})
          this.setState({loading:false})
        })
      } 
      // get uid
      // let uid = await rnRest("/device", "retrieve", "")
      // if (!uid) {
      //   getUId()// if the uId is not available on device
      //   // send uId and base device info
      // }

      const handleMessage = async (e) => {
        // new version to accomodate rest transaction
        // window.ReactNativeWebView.postMessage(JSON.stringify(e.data))
        // handleRnMsg(e.data)
        return
        // make sure checking device
        if (e.data) {          
          let data = JSON.parse(e.data)
//           let body
          switch (data.cmd) {
            case ("fetch device id"):
              // fetch it
              // send another msg back to device
            case ("putDeviceInfo"):
                await loadDevicesInfo()
                // only take fields that are device-specific
                body = {
                  deviceName: data?.deviceName || globs.device.deviceName,
                  deviceToken: data?.deviceToken,
                  deviceInfo: data?.deviceInfo,
                  bioEnabled: data?.bioEnabled,
                  deviceTheme: data?.deviceTheme || globs.device.deviceTheme,
                }
                cl(body)
                await updateDeviceInfo(body)

                globs.devicesInfo={}
                await loadDevicesInfo()
                cl(globs.device)
                break
            case ("putDeviceId"):
              // load device uId
              getUId(data.uId)
              break
            case ("doLogin"):
              // check if info needs to be updated
              cl("mobile login")
              body = {
                  email: data.email,
                  password: data.password,
                  rememberMe: this.state.rememberMe,
                  force: force,// if logged in elsewhere
              }
              cl(body)
              // check if email and password exist, bioLogin is enabled
              if (body.email && body.password && data.bioEnabled == 2) {
                // do auto login
                wsTrans("usa", {cmd: "cRest", uri: "/o/users/login", method: "create", 
                body: body}).then(async r=>{
                  cl(r);
                  switch(r.result){
                    case "ok":
                    globs.sitesInfo.got=false
                    globs.zonesInfo.got=false
                    globs.usersInfo.got=false
                    saveTokens(r.data.accessToken, r.data.refreshToken, r.session)
                    await loadDevicesInfo()
                    setTimeout(()=>{ 
                      // push history to c18host so nav state changes
                      let adds = [{
                        userId:globs.userData.session.userId,
                        time:Math.floor(getTime()),
                        action:"login",
                        deviceName: globs.device?.deviceName,
                        oldVal:"",
                        newVal:"",
                      }]
                      addToAdminLog(adds)
                      history.push("/usa/c18/sites")
                      // window.location="/usa/c18/sites"
                    },1000)

                    // request info from device
                    data = {cmd: "getDeviceInfo"}
                    window.ReactNativeWebView.postMessage(JSON.stringify(data))
                    break
                  case "alreadyLoggedIn":
                    // this.setState({alreadyLoggedInMessage:"You're already logged into this account somewhere else"})
                    break
                  default:
                    break
                  }
                })
              }
              break
            }
          }
        }
      // window.addEventListener("message", async (e) => handleMessage(e))
      // document.addEventListener("message", async (e) => handleMessage(e))
      // request device id
      // let data = {cmd: "getDeviceId"}
      // window.ReactNativeWebView.postMessage(JSON.stringify(data))
      // request device info
      // let data = {cmd: "getLoginInfo"}
      // window.ReactNativeWebView.postMessage(JSON.stringify(data))
    }
  }
  
  validEmail=(email)=>{
    let atPos = email.indexOf("@");
    let perPos = email.lastIndexOf(".");
//     cl(atPos,perPos)
    return ((atPos > 0) && (perPos > atPos));
  }

  validate=(state, forgotPassword=false)=>{
    if(!this.validEmail(state.email)) {
      return (forgotPassword) ? this.setAlert(2) : this.setAlert(0);
    }
    return this.setAlert(1);
  }
  
  setAlert=(n)=>{
    let state=this.state
    var emailError
    switch(n){
      case 0:
        emailError=this.alerts[n]
        break
      case 2:
        emailError=this.alerts[n]
        break
    }
    this.setState({
      emailError: emailError,
    });
  }
  
  mySetState=(type,vals)=>{
    var state={}
    Object.assign(state,this.state,vals)
    this.validate(state)
    this.setState(vals)
  }
  
  forgotPassword=async()=>{
    cl("send forgot")
    let res=await wsTrans("usa", {cmd: "cRest", uri: "/o/forgot", method: "create", 
      body: {email:this.state.email}})
    let url=`${constant.feUrl}/usa/c18/resetPassword/${res.data.token}`
    this.setState({tokenLink:url})
    cl(res)
  }
  
  onChange=(type,vals)=>{
//     cl([type,vals])
    switch(type){
      case "input":
        vals.passwordError=""
        vals.generalError=""
        this.mySetState("input",vals)
        break
      case "visibility":
        let type=(this.state.passwordType=="text")?"password":"text"
        this.setState({passwordVisible: !this.state.passwordVisible})
        break
      case "forgotPassword":
        vals.e.preventDefault()
        this.validate(this.state, true)
        if(this.state.email && !this.state.emailError){
          let msg="If you have an account, we've sent you a message."
          this.forgotPassword()
          this.mySetState("forgot",{emailError:msg})
        }
        break
      case "keyPress":
        if(
          (vals.key=="Enter")&&
          (this.validEmail(this.state.email))&&
          (!this.state.loggedIn)
        ){
          this.doLogin(false)
        }
        break
      default:
        break
    }
  }
  
  render(){
//     cl(dbVals)
//     cl(this.state.loggedIn)
    let msg=(this.state.loggedIn)?"You're still logged in on another tab":this.state.emailError
//     cl(this.state.tokenLink)
    return(
    <div>
      <div className="login-register-new">
        <div className="login-left">
        </div>
        <div id="/login" className="login-right">

        <header>
          <div className="main-logo floatleft">
            <a href="https://link4controls.com/" className="main-logo"><img src="/img/link4_logo_blue_trans.png" alt="Link4 Controls"/></a>
          </div>
          <div className="floatright">
            Don't have an account? <C18Anchor00 id="back-to-login" to="/usa/c18/register">Register</C18Anchor00>
          </div>
        </header>

          <form>
            <h1>Welcome Back!</h1>

            <C18InputError00 parms={{
              type:'email',
              title:"Email",
              id:"if-email",
              required: true,
              value: this.state.email,
              valueId: "email",
              onChange: this.onChange,
              onKeyPress:e=>this.onChange("keyPress",{key:e.key}),
              message:msg,
              messageType: "error",
              disabled:this.state.loggedIn,
            }}/>
            <C18InputError00 parms={{
              type:'password',
              visible:this.state.passwordVisible,
              title:"Password",
              id:"if-password",
              required: true,
              value: this.state.password,
              valueId: "password",
              onChange: this.onChange,
              onKeyPress:e=>this.onChange("keyPress",{key:e.key}),
              message:this.state.passwordError,
              messages:this.state.generalError,
              messageType: "error",
              disabled:this.state.loggedIn,
            }}/>

            <div className="remember-container">
              {/* <div id="remember-me-container" className="floatleft">
                <C18Input00 id="remember-me" type="checkbox"
                disabled={this.state.loggedIn}
                value={this.state.rememberMe}
                onChange={e=>this.onChange("input",{rememberMe:e.currentTarget.checked})}
                />
                <label htmlFor="remember-me">Remember Me</label>
              </div> */}
              <div id="forgot-password" className="floatright"><C18Anchor00 to=""
              onClick={e=>this.onChange("forgotPassword",{e:e})}
                disabled={this.validEmail(this.state.email)}
              >Forgot Password?</C18Anchor00></div>
            </div>
            <div className="clearfloat"></div>

            <div className="button-container">
              {window.ReactNativeWebView && this.state.bioEnabled == 2 &&
              <C18Button00 type="button" id="login-button" className="filled"
                onClick={async()=>await this.doMobileLogin()}
              >Face/Touch ID Login</C18Button00>
              }
              <C18Button00 type="button" id="login-button" className="filled"
                onClick={()=>this.doLogin(false)}
                disabled={!this.validEmail(this.state.email) || this.state.loggedIn || this.state.loading}>
                 {this.state.loading ? 
                  <span>
                    <span className="spinnerGraph">
                      <img style={{height:15,width:15}} src="/img/spinner.gif" />
                    </span>
                    <span>Logging In...</span>
                  </span> : 
                  <span>Log In</span>}
              </C18Button00>
              {false&&this.state.tokenLink&&
                <>
                The Reset Password Link in the email is:<br/>
                <a href={this.state.tokenLink}>{this.state.tokenLink}</a>
                </>
              }
            </div>

          </form>
          <div id="footer" className="login">
            <p>Cloud-Based Environmental Controls</p>
            <p>Copyright &copy;2021 Link4 Corporation. All Rights Reserved.</p>
          </div>
        </div>
      </div>
    </div>
    )
  }
}

export default C18Login00;
