import React from "react";
import { cl, wsTrans } from "../utils/utils";
import {
  globs,
} from "../../components/utils/utils";
import C18SearchTabs00 from "./C18SearchTabs00";

class C18FWPortal00 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      selAccount: "",
      sortMode: 1, // site up
      planType: "",
      fwData:[],
      selectedfwData: [], // fw selected from list
      selectedSearchData: [], // fw selected from list
      sortColumn: "",
      sortOrder: "",
  };
    // this.loadInfo();
    this.setBreadCrumbs();
    this.dark =
      (globs.device?.deviceTheme || "").toLowerCase().indexOf("dark") >= 0
        ? 1
        : 0;
    this.bgColor1 = this.dark ? "#202020" : "#FFFFFF";
    this.bgColor2 = this.dark ? "#203030" : "#FFFFFF";
    this.selColor1 = this.dark ? "#606060" : "#DDDDDD";
  }

  setBreadCrumbs = () => {
    if (this.props.parms) {
      this.props.parms.onChange({
        cmd: "breadcrumbs",
        data: {
          breadcrumbs: [
            { t: "Sites", url: "/usa/c18/sites" },
            { t: "Admin", url: `/usa/c18/admin` },
            { t: "Firmware Portal", url: `/usa/c18/admin/fwPortal` },
          ],
        },
      });
    }
  };

  loadCurrentFirmware=async()=>{
    //     constant.urls.prod0="https://downloads.link4cloud.com"
    //     doGetPost("prod0","moduleFW/currentFW.json","GET")
      // let url="https://downloads.link4cloud.com/moduleFW/currentFW.json"
      // cl(config.server)
    
      let url="https://http0.link4cloud.com/usa/fw/currentFW.json"
      try {
        const resp = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        })
        this.curFW= await resp.json();
      } catch (error) {
        this.curFW={}
      }
      // cl("Current FW retrieved:")
  }

  loadInfo = async () => {
    // these are already loaded sites, accounts, zones, gateways,
    // fetch fw specific aggregation
    const { gateways } = this.props;
    // console.log("🚀 ~ loadInfo= ~ gateways:", gateways)
    // only consider pearls
    let pearlGateways = gateways.filter((g => g.gatewayType === 1900))
    let uniqueFW = this.getUniqueFW(pearlGateways)
    let activeCount = pearlGateways.filter((g => g.connected)).length

    // TODO load all historical fw as well
    await this.loadCurrentFirmware()

    // load fw json 
    // const accountWiseData = this.getAccountWiseFWData([...gateways]);
    this.setState({ pearlGateways: pearlGateways, activeCount: activeCount, fwData: uniqueFW, loaded: true });
  };

  componentDidMount() {
    // this.fetchFwVersionTable();
    this.loadInfo()
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.selectedId !== this.props.selectedId) {
      // refetch info
      let res =this.getSearchFw(this.props.selectedType, this.props.selectedId)
      this.setState({selectedSearchData: res, selectedRecordSelectedFw: null})
    }
  }

  getSearchFw = (type, id) => {
    const { gateways } = this.props;
    let pearlGateways = gateways.filter((g => g.gatewayType === 1900))
    // filter fw from state here
    // use type and id to determine what should be shown in selected
    let selectedGateways = pearlGateways.filter((a) => {
      switch (type) {
        case "account":
          return a.accountId == id
        case "site":
          return a.siteId == id
        case "zone":
          return a.zoneId == id
        case "gateway":
          return a.gatewayId == id
        default:
          break
      }
    })
    const res = this.getUniqueFW(selectedGateways)
    return res
  }

  getSearchGateways = (type, id) => {
    const { gateways } = this.props;
    let pearlGateways = gateways.filter((g => g.gatewayType === 1900))
    // filter fw from state here
    // use type and id to determine what should be shown in selected
    let selectedGateways = pearlGateways.filter((a) => {
      switch (type) {
        case "account":
          return a.accountId == id
        case "site":
          return a.siteId == id
        case "zone":
          return a.zoneId == id
        case "gateway":
          return a.gatewayId == id
        default:
          break
      }
    })
    return selectedGateways
  }

  getUniqueFW = (data) => {
    const fwData = {};

    data.forEach((record) => {
      let { fwVersion, connected } = record;
      if (!fwVersion) fwVersion = "Unknown"
      // create row for each unique fw
      // we've seen this
      if (fwData[fwVersion]) {
        if (connected) {
          fwData[fwVersion].active += 1
        } else {
          fwData[fwVersion].inactive += 1
          
        }
      } else {
        // new firmware
        fwData[fwVersion] = {version: fwVersion, active: (connected) ? 1 : 0, inactive: (connected) ? 0 : 1, releaseDate: "release date"}
      }
    });
    // map to array
    let arr = [];
    for (let key in fwData) {
      arr.push(fwData[key]);
    }
    return arr;

  }

  getSelectedFwData = (data) => {
    const fwData = {};
    data.forEach((record) => {
      let { fwVersion, connected, accountId } = record;
      if (!accountId) accountId = "Unknown"
      // create row for each unique account
      // we've seen this
      if (fwData[accountId]) {
        if (connected) {
          fwData[accountId].active += 1
        } else {
          fwData[accountId].inactive += 1
        }
      } else {
        // new firmware
        fwData[accountId] = {accountId, version: fwVersion, active: (connected) ? 1 : 0, inactive: (connected) ? 0 : 1, releaseDate: "release date"}
      }
    });
    // map to array
    let arr = [];
    for (let key in fwData) {
      arr.push(fwData[key]);
    }
    // console.log("🚀 ~ C18FWPortal ~ getAccountWiseFWData= ~ accountData:", arr);
    return arr;

  }

  getGatewayData = (data) => {
    const gatewayData = [];
    data.forEach((record) => {
      const { accountId, siteId, zoneId, connected, updateTime, created, name } = record;
      const site = this.props.sites.find((e) => e.siteId == siteId)?.name || siteId;
      const zone = this.props.zones.find((e) => e.zoneId == zoneId)?.zoneName || zoneId;
      if (!accountId) return;
      let createdOn =  `${new Date(created * 1000)}`
      let lastComm =  `${new Date(updateTime * 1000)}`
      gatewayData.push({ name, site, zone, created, connected, createdOn, updateTime, lastComm });
    });
    return gatewayData;
  }

  handleRowClickAllFw = (record) => {
    // search record.id in gateways, group result with account
    const { gateways } = this.props;
    // filter gateways by record.version and then group by account
    const selectedData = gateways.filter((gateway) => gateway.fwVersion === record.version);
    // console.log("🚀 ~ selectedData:", selectedData)
    const selectedFwData = this.getSelectedFwData(selectedData);
    this.setState({ selectedFwData: selectedFwData, selectedRecordAllFw: record });
  }

  handleRowClickSelectedFw = (record) => {
    // Show table of gateways belonging to that fw version
    let res = this.getSearchFw("account", record.accountId)
    this.setState({ selectedSearchData: res, selectedRecordSelectedFw: record });
  }

  handleRowClickSearchFw = (record) => {
    // Show table of gateways belonging to that fw version and selected prop
    let id = this.props.selectedId
    let type = this.props.selectedType
    if (this.state.selectedRecordSelectedFw) {
      id = this.state.selectedRecordSelectedFw.accountId
      type = "account"
    }
    let selectedGateways = this.getSearchGateways(type, id)
    selectedGateways = selectedGateways.filter((gw)=> gw.fwVersion == record.version)
    const res = this.getGatewayData(selectedGateways);
    this.setState({ selectedGateways: res, selectedRecordSearchFw: record });
  }

  getSelectedData = (data) => {
    // count active and inactive and return all the fields as is
    const selectedData = [];
    let activeCount = 0;
    let inactiveCount = 0;
    data.forEach((record,i) => {
      // console.log("🚀 ~ data.forEach ~ record:", record)
      const { connected } = record;
      // if (!accountId) return;
      if (connected === true) {
        activeCount += 1;
      } else {
        inactiveCount += 1;
      }
      selectedData.push({ ...data[i], fwName: data[i].name, activeCount, inactiveCount });
    });
    return selectedData;
  }

  getSortedData = (data, sortColumn, sortOrder) => {
    return [...data].sort((a, b) => {
      const aValue = a[sortColumn] || a[sortColumn];
      const bValue = b[sortColumn] || b[sortColumn];
      if (typeof aValue === "string" && typeof bValue === "string") {
        return sortOrder === "asc"
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      } else {
        return sortOrder === "asc" ? aValue - bValue : bValue - aValue;
      }
    })
  }

  onChangeSort = (oldColumn, oldOrder, newColumn, type) => {
    // console.log("🚀 ~ C18FWPortal ~ column:", column)
    let newOrder = "asc";
    if (oldColumn === newColumn) {
      newOrder = oldOrder === "asc" ? "desc" : "asc";
    }
    let sortState = {}
    sortState[`sortColumn${type}`] = newColumn
    sortState[`sortOrder${type}`] = newOrder
    cl(sortState)
    this.setState(sortState);
  };



  showStatus = () => {
    const { activeCount, pearlGateways} = this.state;
    let str=this.curFW["prl"]
    // cl(str)
    let parts=str.split("_")
    let da=parts[4]?.split(".")[0]
    let da2
    if (da) {
      let yr=da?.substring(0,4)
      let mo=da?.substring(4,6)
      let dy=da?.substring(6,8)
      da2=`${mo}-${dy}-${yr}`
    }
    return (
      <div style={{ display: 'flex', flexDirection: 'row', gap: '20px', width: '100%', marginTop: '20px' }}>
      {/* Active Tab  */}
      <div
        id="activeAlarmsTab"
        style={{
          border: "1px solid #ccc",
          borderRadius: "8px",
          width: "100%",
          height: "150px",
          textAlign: "center",
          padding: "20px",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          fontSize: "18px",
        }}
      >
        <h3 style={{ margin: 0, fontSize: "20px" }}>
          Total Active Pearls
        </h3>
        <p style={{ fontSize: "20px", marginTop: "20px" }}>{activeCount}</p>
      </div>
      {/* Inactive Tab  */}
      <div
        id="activeAlarmsTab"
        style={{
          border: "1px solid #ccc",
          borderRadius: "8px",
          width: "100%",
          height: "150px",
          textAlign: "center",
          padding: "20px",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          fontSize: "18px",
        }}
      >
        <h3 style={{ margin: 0, fontSize: "20px" }}>
          Total Inactive Pearls
        </h3>
        <p style={{ fontSize: "20px", marginTop: "20px" }}>{pearlGateways.length - activeCount}</p>
      </div>
      {/* Latest FW  */}
      <div
        id="activeAlarmsTab"
        style={{
          border: "1px solid #ccc",
          borderRadius: "8px",
          width: "100%",
          height: "150px",
          textAlign: "center",
          padding: "20px",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          fontSize: "18px",
        }}
      >
        <h3 style={{ margin: 0, fontSize: "20px" }}>
          Latest Digital Pearl FW
        </h3>
        <table style={{ fontSize: "20px", marginTop: "20px", width:"initial", display: "inline-block"}}><tbody>
        <tr><td>Version:</td><td>{parts[2]}</td></tr>
        <tr><td>Commit:</td><td>{parts[3]}</td></tr>
        <tr><td>Date:</td><td>{da2}</td></tr>
        </tbody></table>
      </div>

    </div>
    );
  };


  showAllFw = () => {
    const { fwData, sortColumnAllFw, sortOrderAllFw, selectedRecordAllFw}= this.state;
    const sortedData = this.getSortedData(fwData, sortColumnAllFw, sortOrderAllFw)
    const tableColumns = [
      { key: "version", label: "Version" },
      { key: "active", label: "Active" },
      { key: "inactive", label: "Inactive" },
      // { key: "releaseDate", label: "Release Date" },
    ]

    return (
      <>
      <div className="table-container" style={{ marginTop: "3%", overflowY: "auto", maxHeight: "380px" }}>
        <table id="fwTable" className="list" style={{ borderSpacing: "0 5px", width: "100%" }}>
          <thead style={{ backgroundColor: "#f2f2f2", position: "sticky", top: 0, zIndex: 1 }}>
            <tr>
            {tableColumns.map(({ key, label }) => (
                <th key={key} style={{ textAlign: "center", width: "15%" , padding: "10px"  }}>
                  <button
                    type="button"
                    onClick={() => this.onChangeSort(sortColumnAllFw, sortOrderAllFw, key, "AllFw")}
                    style={{
                      border: "none",
                      background: "none",
                      fontSize: "16px",
                      fontWeight: "bold",
                      cursor: "pointer",
                    }}
                  >
                    {label} {sortColumnAllFw === key ? (sortOrderAllFw === "asc" ? "▲" : "▼") : ""}
                  </button>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {
            // loaded ? (
            //   <tr>
            //     <td colSpan="5" style={{ textAlign: "center", padding: "10px" }}>Loading...</td>
            //   </tr>
            // ) : 
            (sortedData && sortedData.length) ? (
              sortedData.map((fw, index) => {
                // cl("🚀 ~ C18FWPortal ~ fwData.map ~ fw:", fw)
                return (
                <tr key={index} onClick={() => this.handleRowClickAllFw(fw)}
                  style={{
                    cursor: "pointer",
                    backgroundColor: selectedRecordAllFw === fw ? "#e0e0e0" : "transparent",
                    transition: "background 0.2s ease-in-out",
                  }}>
                  <td style={{ textAlign: "center", padding: "10px" }}>{fw.version}</td>
                  <td style={{ textAlign: "center", padding: "10px" }}>{fw.active}</td>
                  <td style={{ textAlign: "center", padding: "10px" }}>{fw.inactive}</td>
                  {/* <td style={{ textAlign: "center", padding: "10px" }}>{fw.releaseDate}</td> */}
                </tr>
              )})
            ) : (
              <tr>
                <td colSpan="5" style={{ textAlign: "center", padding: "10px" }}>No Data Available</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <br/>
      <div><i>{sortedData.length} results found...</i></div>
      </>
    );
  };

  showSelectedFw=()=>{
    const { selectedFwData, sortColumnSelectedFw, sortOrderSelectedFw, selectedRecordAllFw, selectedRecordSelectedFw} = this.state;
    // console.log("🚀 ~ specificfwData:", specificfwData)
    const tableColumns = [
      { key: "accountId", label: "Account" },
      { key: "version", label: "Version" },
      { key: "active", label: "Active" },
      { key: "inactive", label: "Inactive" },
      // { key: "releaseDate", label: "Release Date" },
    ]
    const sortedData = this.getSortedData(selectedFwData, sortColumnSelectedFw, sortOrderSelectedFw)
    return (
      <>
      <h2>Accounts on {selectedRecordAllFw.version}</h2>
      
      <div className="table-container" style={{ marginTop: "3%", overflowY: "auto", maxHeight: "380px" }}>
      <table id="spFWTable" className="list" style={{ borderSpacing: "0 5px",  width:"100%" }}>
          <thead style={{ backgroundColor: '#f2f2f2' , position: "sticky", top: 0, zIndex: 1 ,}}>
          <tr>
              {tableColumns.map(({ key, label }) => (
                <th key={key} style={{ textAlign: "center", width: "15%" , padding: "10px"  }}>
                  <button
                    type="button"
                    onClick={() => this.onChangeSort(sortColumnSelectedFw, sortOrderSelectedFw, key, "SelectedFw")}
                    style={{
                      border: "none",
                      background: "none",
                      fontSize: "16px",
                      fontWeight: "bold",
                      cursor: "pointer",
                    }}
                  >
                    {label} {sortColumnSelectedFw === key ? (sortOrderSelectedFw === "asc" ? "▲" : "▼") : ""}
                  </button>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
          {(sortedData && sortedData.length) ? (
            sortedData.map((row, index) => {
              let accountName = this.props.accounts.find((e) => e.accountId == row.accountId)?.name || row.accountId
              return (
                <tr key={index} onClick={() => this.handleRowClickSelectedFw(row)}
                style={{
                  cursor: "pointer",
                  backgroundColor: selectedRecordSelectedFw === row ? "#e0e0e0" : "transparent",
                  transition: "background 0.2s ease-in-out",
                }}>
                <td style={{ textAlign: "center", padding: "10px" }}>{accountName}</td>
                <td style={{ textAlign: "center", padding: "10px" }}>{row.version}</td>
                <td style={{ textAlign: "center", padding: "10px" }}>{row.active}</td>
                <td style={{ textAlign: "center", padding: "10px" }}>{row.inactive}</td>
                  {/* <td style={{ padding: '10px' }}>{`${new Date()}`}</td> */}
                </tr>
            )
            })
          ) : (
            <tr>
              <td colSpan="5" style={{ textAlign: "center", padding: "10px" }}>No Data Available</td>
            </tr>
          )}
          </tbody>
        </table>
      </div>
       <br/>
       <div><i>{sortedData.length} results found...</i></div>
       </>
    )
  }

  getSelectedName=()=>{
    switch (this.props.selectedType) {
      case "account":
        return this.props.relAccounts.find((e) => e.v == this.props.selectedId)?.t || this.props.selectedId
      case "site":
        return this.props.relSites.find((e) => e.v == this.props.selectedId)?.t || this.props.selectedId
      case "zone":
        return this.props.relZones.find((e) => e.v == this.props.selectedId)?.t || this.props.selectedId
      case "user":
        return this.props.relUsers.find((e) => e.v == this.props.selectedId)?.t || this.props.selectedId
      case "gateway":
        return this.props.relGateways.find((e) => e.v == this.props.selectedId)?.t || this.props.selectedId
      default:
      return ""
    }
  }


  showSearchFw=()=>{
    const { sortColumnSearchFw, sortOrderSearchFw, selectedSearchData, selectedRecordSearchFw } = this.state;
    // console.log("🚀 ~ selectedfwData:", selectedfwData)
    const tableColumns = [
      { key: "version", label: "Version" },
      { key: "active", label: "Active" },
      { key: "inactive", label: "Inactive" },
      // { key: "releaseDate", label: "Release Date" },
    ]
    const sortedData = this.getSortedData(selectedSearchData, sortColumnSearchFw, sortOrderSearchFw)
    let selectedName = this.getSelectedName()
    let type = this.props.selectedType
    if (this.state.selectedRecordSelectedFw) {
      type = "Account"
      selectedName = this.props.accounts.find((e) => e.accountId == this.state.selectedRecordSelectedFw.accountId)?.name || this.state.selectedRecordSelectedFw.accountId
    }
    return (
      <>
      <h2>Firmware Versions on {type}: <i>{selectedName}</i></h2>
      <div className="table-container" style={{ marginTop: "3%", overflowY: "auto", maxHeight: "380px" }}>
      <table id="selFWTable" className="list" style={{ borderSpacing: "0 5px",  width:"100%" }}>
        <thead><tr><th>{" "}</th></tr></thead>
          <thead style={{ backgroundColor: '#f2f2f2' , position: "sticky", top: 0, zIndex: 1 ,}}>
          <tr>
              {tableColumns.map(({ key, label }) => (
                <th key={key} style={{ textAlign: "center", width: "15%" , padding: "10px"  }}>
                  <button
                    type="button"
                    onClick={() => this.onChangeSort(sortColumnSearchFw, sortOrderSearchFw, key, "SearchFw")}
                    style={{
                      border: "none",
                      background: "none",
                      fontSize: "16px",
                      fontWeight: "bold",
                      cursor: "pointer",
                    }}
                  >
                    {label} {sortColumnSearchFw === key ? (sortOrderSearchFw === "asc" ? "▲" : "▼") : ""}
                  </button>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
          {/* {activeAlarmsData && activeAlarmsData.map((row, index) => ( */}
            {sortedData.map((row, index) => {
              return (
                <tr key={index}
                onClick={() => this.handleRowClickSearchFw(row)}
                style={{
                  cursor: "pointer",
                  backgroundColor: selectedRecordSearchFw === row ? "#e0e0e0" : "transparent",
                  transition: "background 0.2s ease-in-out",
                }}>
                <td style={{ textAlign: "center", padding: '10px' }}>{row.version}</td>
                <td style={{ textAlign: "center", padding: '10px' }}>{row.active}</td>
                  <td style={{ textAlign: "center", padding: '10px' }}>{row.inactive}</td>
                  {/* <td style={{ padding: '10px' }}>{`${new Date()}`}</td> */}
                </tr>
            )
            })
            }
          </tbody>
        </table>
      </div>
       <br/>
       <div><i>{sortedData.length} results found...</i></div>
       </>
    )
  }

  showGatewaysByFw = () => {
    const { sortColumnGateways, sortOrderGateways, selectedGateways } = this.state;
    const tableColumns = [
      { key: "site", label: "Site Name" },
      { key: "zone", label: "Zone Name" },
      { key: "name", label: "Gateway Name" },
      { key: "updateTime", label: "Last Comm" },
      { key: "created", label: "Created On" }
    ]
    const sortedData = this.getSortedData(selectedGateways, sortColumnGateways, sortOrderGateways)
    let selectedName = this.getSelectedName()
    let type = this.props.selectedType
    if (this.state.selectedRecordSelectedFw) {
      type = "Account"
      selectedName = this.props.accounts.find((e) => e.accountId == this.state.selectedRecordSelectedFw.accountId)?.name || this.state.selectedRecordSelectedFw.accountId
    }
    return(
      <>
      <h2>Gateways on <i>{this.state.selectedRecordSearchFw.version}</i> and {type}: <i>{selectedName}</i></h2>

      <div className="table-container" style={{ marginTop: "3%", overflowY: "auto", maxHeight: "380px" }}>
      <table id="gatewayTable" className="list" style={{ borderSpacing: "0 5px", width: "100%" }}>
        <thead style={{ backgroundColor: '#f2f2f2', position: "sticky", top: 0, zIndex: 1 }}>
          <tr>
          {tableColumns.map(({ key, label }) => (
                <th key={key} style={{ textAlign: "center", width: "15%" , padding: "10px"  }}>
                  <button
                    type="button"
                    onClick={() => this.onChangeSort(sortColumnGateways, sortOrderGateways, key, "Gateways")}
                    style={{
                      border: "none",
                      background: "none",
                      fontSize: "16px",
                      fontWeight: "bold",
                      cursor: "pointer",
                    }}
                  >
                    {label} {sortColumnGateways === key ? (sortOrderGateways === "asc" ? "▲" : "▼") : ""}
                  </button>
                </th>
              ))}
          </tr>
        </thead>
        <tbody>
            {sortedData.map((row, index) => {
              return (
                <tr key={index}>
                <td style={{ textAlign: "center", padding: '10px' }}>{row.site}</td>
                <td style={{ textAlign: "center", padding: '10px' }}>{row.zone}</td>
                <td style={{ textAlign: "center", padding: '10px' }}>{row.name}</td>
                <td style={{ textAlign: "center", padding: '10px' }}>{row.lastComm}</td>
                <td style={{ textAlign: "center", padding: '10px' }}>{row.createdOn}</td>
                </tr>
            )
            })
            }
          </tbody>
      </table>
    </div>
    <br/>
       <div><i>{sortedData.length} results found...</i></div>
      </>
    );
  }
  


 showSearchTabs=()=>{
  return <C18SearchTabs00 {...this.props} />;
 }

  render() {
    let st = this.state;
    if (st.loaded) {
      return (
        <div>    
         {this.showStatus()} 
         {this.showAllFw()} 
         {st.selectedRecordAllFw && this.showSelectedFw()} 
         {this.showSearchTabs()}
         {this.showSearchFw()}
         {st.selectedRecordSearchFw && this.showGatewaysByFw()}
        </div>
      );
    } else {
      return <div id="content-area">loading. . .</div>;
    }
  }
}
export default C18FWPortal00;
