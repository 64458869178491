import React from 'react';
import {wsTrans} from '../utils/utils'
import {previewSubscription, createPlan, updateSubscription, getRecurlyAccount, getBilling, verifyBilling, addToAdminLog,
onCheckout, getZonesCount, getZoneDiscount, getTotal, loadZonesInfo, loadSitesInfo, getZoneInfo, getBalance, checkOwner, 
checkAdmin, loadUsersInfo, loadAccountInfo, redirectPrivs, loadGatewaysInfo, getGatewayInfo, getPearlType, getPearlCount} from './C18utils'
import {cl, globs, getTime,allStates,allTimezones, allZones, allAddons, capitalize, recurlySubdomain, round, zoneTypes, dateToYrMoDa, dateToMoDaYrStr, zoneQtyDiscount} from '../../components/utils/utils';
import C18Button00 from './C18Button00'
import C18Input00 from './C18Input00'
import C18MuiInput from './C18MuiInput';
import C18SiteZoneSub00 from './C18SiteZoneSub00'
import history from "../../history"
import UsaIcon from './UsaIcon';

class C18ManageSubscription extends React.Component{
  constructor(props) {
    super(props);
    this.state={
      loaded:false,
      preview:false,
      displayCheckout:false,
      displayPricingTable: false,
      opacity:1,
      fading:false,
      showPopup:true,
      auto_renew:true,
      changeTimeout:true,
    }
    this.subscribe_savePageEvent=globs.events.subscribe("savePageEvent",this.saveZones)
    this.props.parms.onChange({cmd:"savePage", data:{savePage:true}})
    this.loadInfo()
    this.setBreadCrumbs()
  }
  
  setBreadCrumbs=()=>{
    if(this.props.parms){
      this.props.parms.onChange(
        {
          cmd: "breadcrumbs",
          data:
            {breadcrumbs: [
              {t:"Sites", url:"/usa/c18/sites"},
              {t:"Admin", url:`/usa/c18/admin`},
              {t:"Manage Subscription", url:`/usa/c18/admin/manageSubscription`},
            ]},
        },
      )
    }
  }

  loadInfo=async()=>{
    await loadUsersInfo()
    await loadAccountInfo()

    // redirect if not admin
    // redirectPrivs()

    await loadSitesInfo()
    await loadZonesInfo()
    await loadGatewaysInfo()
    let sub = globs.subscriptionInfo.info
    // init/load selected_qty
    cl(sub)
    // this.mySetState(sub)
    // set zone_type to highest num of zones on acct
    let zone_type = "zone"
    // load recurly account
    let account = await getRecurlyAccount()
    cl(account)
    let recurlyUrl =  `https://${recurlySubdomain}.recurly.com/account/${account?.hostedLoginToken}/`
    this.saveZoneInfo(false)// save the names to compare later
    let siteSel=globs.sitesInfo.info[0].siteId
    let zoneSel=this.getZoneSel(siteSel)
    // load pricing table
    // let imagePath = "./link4_logo_blue_trans.png"
    // const img = new Image()
    // img.src = imagePath
    // // await img.decode()
    // img.onload = (() => {
    //   cl("loaded image")
    // })
    // get whether user is owner or admin
    let owner = checkOwner()
    let admin = await checkAdmin()

    // calculate pearl protect count
    this.protect_count = getPearlCount("Protect")


    let vals = {zone_type: zone_type, loaded: true, oldPlan: sub, currPlan: JSON.parse(JSON.stringify(sub)),
      account: account, recurlyUrl: recurlyUrl, subChange: false, newConfig: [], zoneSel: zoneSel, owner: owner, admin: admin}
    // set period if not available
    this.mySetState(vals)
  }
  
  mySetState=(vals)=>{
    cl(vals)
    this.setState(vals)
  }

  saveZoneInfo=async(doSave)=>{// do Save, means save to db, not means copy current settings , newPlan means new plan will be created on recurly
    if(!doSave){
      this.zoneNames=[]
      this.zoneTiers=[]
    }
    let gzi=globs.zonesInfo.info
    let adminAdds=[]
    let recurlyUpdate = false
    for(let i=0;i<gzi.length;i++){
      if(doSave){
        let zoneNew = {zoneId: gzi[i].zoneId}
        let zoneOld = {}
        let zoneChange = false
        if (this.zoneTiers[i]!=gzi[i].zoneTier){
          cl(`update ${gzi[i].zoneTier}`)
          zoneNew.zoneTier = gzi[i].zoneTier
          zoneOld.zoneTier = this.zoneTiers[i]
          zoneChange = true
          recurlyUpdate = true
        }
        if (zoneChange) {
//           cl(zoneNew)
          this.saveZoneInfoToDb(zoneNew)
          this.saveToAdminLog(adminAdds, zoneOld, gzi[i])
        }
      }else{
        // identify zones as pearl protects here?
        this.zoneNames.push(gzi[i].zoneName)
        this.zoneTiers.push(gzi[i].zoneTier)
      }
//       if(gzi[i].siteId==siteId){
//         return gzi[i].zoneId
//       }
    }
//     cl(adminAdds)
    if(doSave){addToAdminLog(adminAdds)}
  }

  saveZoneInfoToDb=async(data)=>{
    await wsTrans("usa", {cmd: "cRest", uri: "/s/zones", method: "update", 
      sessionId: globs.userData.session.sessionId, body: data})
    globs.events.publish("saveOK",true)
  }

  saveZones=async(cmd)=>{
    if(cmd=="save"){
      globs.events.publish("saveOK",true)
      // this.saveZoneNames(true)// save to db
      // make sure subscription transaction is made before saving zone info
      if (this.state.subChange) {
        // make new plan
        this.state.currPlan.protect_count = this.protect_count
        let planInfo = await onCheckout(this.state.currPlan)
        cl(planInfo)
        if (planInfo.plan && planInfo.preview) {
          planInfo.displayCheckout = true
          // get balance (to show credit available)
          let balance = await getBalance()
          cl(balance)
          planInfo.balance = balance
          this.mySetState(planInfo)
          // saving happens upon successful checkout
        } else {
          // plan generation error
        }
      } else {
        // actually write zone tier
        this.state.newConfig.forEach((z)=>{
          this.saveZoneTier(z.zoneId, z.zoneTier)
        })
        this.saveZoneInfo(true)
      }
    }
//     history.goBack()
  }

  setZoneCount=(oldZoneTier, newZoneTier, revert=false)=>{// should not be changing zonesInfo!
      // do not decrement, and increment only if would be exceeding current license count
      let tiers = this.makeTiers()
      let oldZonesUnlocked = this.state.oldPlan.add_ons.zone_qty.zone[zoneTypes["unlocked"]] 
      let newZonesUnlocked = Object.keys(tiers).filter(k=>{return tiers[k]==zoneTypes["unlocked"]}).length
      cl([this.state.currPlan.add_ons.zone_qty.zone[newZoneTier]])
      // only subtract from licenses if switching to paid
      // if (newZoneTier == zoneTypes["unlocked"]) this.state.currPlan.add_ons.zone_qty.zone[oldZoneTier]--
      // always subtract from licenses even if locking
      // this.state.currPlan.add_ons.zone_qty.zone[oldZoneTier]--
      // only subtract from licenses if locking and license count is greater than old licenses
      if (newZoneTier == zoneTypes["locked"] && this.state.currPlan.add_ons.zone_qty.zone[zoneTypes["unlocked"]] > oldZonesUnlocked) {
        this.state.currPlan.add_ons.zone_qty.zone[oldZoneTier]--
      }
      // only add to unlocked zone count if the number of new zones is equal to new licenses
      // cl([this.state.currPlan.add_ons.zone_qty.zone[newZoneTier], newZonesUnlocked])
      if (newZoneTier != zoneTypes["unlocked"] ||  this.state.currPlan.add_ons.zone_qty.zone[newZoneTier] == (newZonesUnlocked - 1)) {
        this.state.currPlan.add_ons.zone_qty.zone[newZoneTier]++
      }
      cl([this.state.currPlan.add_ons.zone_qty.zone[zoneTypes["unlocked"]], newZonesUnlocked])
  }

  makeTiers=()=>{
    let tiers={}
    globs.zonesInfo.info.forEach(z=>{tiers[z.zoneId]=z.zoneTier})
    if(this.state.newConfig){
      this.state.newConfig.forEach(nc=>{tiers[nc.zoneId]=nc.zoneTier})
    }
    return tiers
  }

  // getZoneTier=(zoneId)=>{// should not be changing zonesInfo!
  //   let gzi=globs.zonesInfo.info
  //   for(let i=0;i<gzi.length;i++){
  //     if(gzi[i].zoneId==zoneId){
  //       return gzi[i].zoneTier
  //     }
  //   }
  // }

  getZoneSel=(siteId)=>{
    let gzi=globs.zonesInfo.info
    for(let i=0;i<gzi.length;i++){
      if(gzi[i].siteId==siteId){
        return gzi[i].zoneId
      }
    }
  }

  // only done iff plan is confirmed
  saveZoneTier=(zoneId,zoneTier)=>{// should not be changing zonesInfo!
    let gzi=globs.zonesInfo.info
    for(let i=0;i<gzi.length;i++){
      if(gzi[i].zoneId==zoneId){
        gzi[i].zoneTier=zoneTier
        return
      }
    }
  }

  onCancel = () => {
    // TODO un-disable outside controls
    globs.events.publish("savePageEnable",true)
    this.mySetState({displayCheckout: false})
  }

  onConfirm = async () => {
    cl(JSON.stringify(this.state.newConfig))
    this.state.newConfig.forEach((z)=>{
      this.saveZoneTier(z.zoneId, z.zoneTier)
    })
    this.saveZoneInfo(true)
    // if billing info
    if (this.state.account?.billingInfo) {
    // if (true) {
      let verification = await verifyBilling()
      cl(verification)
      if (verification && verification.status == "success") {
        cl("proceeding with updating subscription")
        let updated = await updateSubscription(this.state.plan, this.state.currPlan.add_ons)
        // have to terminate and begin new subscription unless on trial?
        // refresh page
        // refund open credit invoices (do on backend)
        this.mySetState({displayCheckout: false, plan: null, preview: null, newConfig: [], subChange: false, currPlan: this.state.oldPlan})
        window.location.reload()
      } else {
        cl("verification failed")
        // popup telling user that their billing info needs to be changed + link
        let title = `Error Processing Subscription Change`
        let text = `The transaction failed because the stored payment method is no longer valid. Update your payment method and try again.`
        let buttons = ["Cancel", "Update Payment Method"]
        let res= await this.getPopup(title, text, buttons)
        if (res == "Update Payment Method") {
          window.open(this.state.recurlyUrl)
        } else {

        }
      }
    } else {
      // takes to recurly hosted payment page
      // will downgrade if confirmation was not made
      window.location.href = `https://${recurlySubdomain}.recurly.com/subscribe/${this.state.plan.code}/${this.state.account?.code}/${this.state.account?.username}`
      this.mySetState({displayCheckout: false, plan: null, preview: null, newConfig: [], subChange: false, currPlan: this.state.oldPlan})
    }
  }

  // onCheckout = async () => {
  //   // adjust plan to account for locked and disabled controllers on account
  //   // number of disabled controller should not change on this screen
  //   // number of locked controllers is total zones - (unlocked zones + disabled zones)
  //   let all_zones = globs.zonesInfo.info.length
  //   let locked_zones = all_zones - (this.state.currPlan.add_ons.zone_qty.zone[zoneTypes["unlocked"]])
  //   cl(globs.zonesInfo.info)
  //   cl([all_zones, locked_zones])
  //   this.state.currPlan.add_ons.zone_qty.zone[zoneTypes["locked"]] = locked_zones
  //   let planInfo = await onCheckout(this.state.currPlan)
  //   cl(planInfo)
  //   if (planInfo.plan && planInfo.preview) {
  //     planInfo.displayCheckout = true
  //     planInfo.plan.plan_code = planInfo.plan.code
  //     cl(planInfo.plan)
  //     // get balance (to show credit available)
  //     let balance = await getBalance()
  //     cl(balance)
  //     planInfo.balance = balance
  //     this.mySetState(planInfo)
  //     // saving happens upon successful checkout
  //   } else {
  //     // plan generation error
  //   }
  // }

  onChange=async(type,vals)=>{
    globs.events.publish("savePageEnable",true)
    switch(type){
      case "siteSel":
        Object.assign(vals,{zoneSel:this.getZoneSel(vals.siteSel)})
      case "zoneSel":
        this.mySetState(vals)
        break
      case "siteZone":
//         cl(vals)
        this.mySetState({zoneSel:vals.zoneId})
        break
      case "zoneTier":
//         cl(type,vals)
        // don't downgrade if switching to locked or disabled!
        // but how to handle case where going to unlocked
        let zoneInfo=getZoneInfo(vals.zoneSel)//this.state.zoneSel
        let res = "Remove License"
        // only show popup if timer expired and not going below old count
        if (vals.zoneTier == zoneTypes["unlocked"]) {
          this.state.changeTimeout = false
          clearTimeout()
          setTimeout(()=> {
            cl("timeout")
            this.state.changeTimeout = true
          }, 1000)
        }
        cl("changeTimeout: " + this.state.changeTimeout)
        // old license count vs currently unlocked zones
        let oldCnt = this.state.oldPlan.add_ons.zone_qty.zone[zoneTypes["unlocked"]] 
        let allTiers = this.makeTiers()
        let newUnlockCnt = Object.keys(allTiers).filter(k=>{return allTiers[k]==zoneTypes["unlocked"]}).length
        cl([oldCnt, newUnlockCnt])
        if (vals.zoneTier == zoneTypes["locked"] && this.state.changeTimeout && newUnlockCnt == oldCnt) {
          let title = `Remove license from ${zoneInfo.zoneName}?`
          let text = `Removing a license from a zone will not reduce the number of licenses you have allotted. License count can be adjusted on this page.`
          let buttons = ["Cancel", "Remove License"]
          res= await this.getPopup(title, text, buttons)
        }
        if (res=="Remove License") {
          // switching to locked
          // replace zone tier if zone already in newConfig
          let oldTier = zoneInfo.zoneTier
          let skip = false
          this.state.newConfig.forEach((z)=> {
            if (zoneInfo.zoneId == z.zoneId) {
              zoneInfo = z
              oldTier = zoneInfo.zoneTier
              z.zoneTier = vals.zoneTier
              skip = true
              return
            }
          })
          if (!skip) this.state.newConfig.push({zoneId: zoneInfo.zoneId, zoneTier: vals.zoneTier})
          this.setZoneCount(oldTier, vals.zoneTier, skip)
        }
        break
      case "zone_qty":
        let copy_qty = this.state.currPlan.add_ons.zone_qty
        let tiers = this.makeTiers()
        let oldZonesUnlocked = this.state.oldPlan.add_ons.zone_qty.zone[zoneTypes["unlocked"]] 
        let newZonesUnlocked = Object.keys(tiers).filter(k=>{return tiers[k]==zoneTypes["unlocked"]}).length
        let newVal = parseInt(vals[2])
        if (newVal >= newZonesUnlocked) copy_qty[vals[0]][vals[1]] = newVal
        break
      case "custom_fields":
        let copy_custom = this.state.currPlan.custom_fields
        copy_custom[vals[0]] = vals[1]
        break
      case "api_access":
        cl(vals)
        this.state.currPlan.add_ons.api_access = vals[type]
      case "annual":
        cl(vals)
        this.state.currPlan.add_ons.annual = vals[type]
      default:
        this.mySetState(vals)
        break
    }
    // check if plan changed
    let planChanged = this.isPlanChanged()
    this.mySetState({subChange: planChanged})

    // calculate new plan?
    // if (planChanged) {
    //   let planInfo = await onCheckout(this.state.currPlan)
    //   cl(planInfo)
    //   if (planInfo.plan && planInfo.preview) {
    //     // get balance (to show credit available)
    //     // let balance = await getBalance()
    //     // cl(balance)
    //     // planInfo.balance = balance
    //     this.mySetState(planInfo)
    //     // saving happens upon successful checkout
    //   } else {
    //     // plan generation error
    //   }
    // }
  }

  isPlanChanged = () => {
    // check add-ons
    let oldPeriod = this.state.oldPlan.add_ons.annual 
    let newPeriod = this.state.currPlan.add_ons.annual
    if (oldPeriod != newPeriod) return true
    let oldApi = this.state.oldPlan.add_ons.api_access 
    let newApi = this.state.currPlan.add_ons.api_access
    if (oldApi != newApi) return true
    // check unlocked count
    let oldZonesUnlocked = this.state.oldPlan.add_ons.zone_qty.zone[zoneTypes["unlocked"]] 
    let newZonesUnlocked = this.state.currPlan.add_ons.zone_qty.zone[zoneTypes["unlocked"]]
    if (newZonesUnlocked!=oldZonesUnlocked) return true
    return false
  }

  showPricingTableButton = () => {
    return (
      <>
      <C18Button00 disabled={this.state.displayPricingTable} className="icon" onClick={e=>this.mySetState({displayPricingTable: !this.state.displayPricingTable})}>
        Pricing
      </C18Button00>
      <><div className="clearfloat"></div><br/></>
      </>
    )
  }

  showPricingTable = () => {
//       cl(this.state.imageDisplay)

    return(
      <>
        <div className="popup" style={{
          opacity: this.state.opacity,
          display:(this.state.displayPricingTable)?"block":"none"}}>
          <p>Pricing Table:</p>
          <div className="table-container">
            <table className="list pricing-table"><tbody>
              <tr key="pt1" >
                <td>Zone Count</td>
                <td>Monthly</td>
                <td>Monthly (*Billed Annually)</td>
              </tr>
              <tr key="pt2" >
                <td>1 to 10 Zones</td>
                <td>${allZones["zone"]["unlocked"]}</td>
                <td>$29.65*</td>
              </tr>
              <tr key="pt3" >
                <td>11 to 24 Zones</td>
                <td>{round(allZones["zone"]["unlocked"] - zoneQtyDiscount["standard"][0])}</td>
                <td>$23.35*</td>
              </tr>
              <tr key="pt4" >
                <td>25 or more Zones</td>
                <td>{round(allZones["zone"]["unlocked"] - zoneQtyDiscount["standard"][1])}</td>
                <td>$17.95*</td>
              </tr>
            </tbody></table>
          </div>
          <div className="clearfloat"></div><br/>
          <div className="alignright">
          <C18Button00 className="icon" onClick={e=>this.mySetState({displayPricingTable: !this.state.displayPricingTable})}>
            Close
          </C18Button00>
          </div>
        </div>
        <div className="popup-scrim"></div>
      </>
    )
//     if (this.state.displayPricingTable) {

// //         cl(idsp)
// //         let im=await this.loadImage(path)
// //         cl(im)
// //         let im=this.getImage(path)
// //         cl(im)
//       cl(this.state.img)
//       let w=this.state.img.width
//       let h=this.state.img.height
//       if(w>500){
//         h=h*500/w
//         w=500
//       }

//       return (
//         <div key={0} style={{width: w, height: h}} className="image-popup"
//           >
//           <img width={w} src={this.state.img.src}
//           style={{backgroundColor: "#C0FFC0", borderRadius: 10, 
//             boxShadow: "5px 10px 10px #888888"}}
//           />
//           <div className="image-close-button">
//             <UsaIcon icon={`im0-Cancel`} result={o=>this.mySetState({displayPricingTable: !this.state.displayPricingTable})}/>
//           </div>
//         </div>
//       )  
//     }
  }

  saveToAdminLog=(adds,o,n)=>{
//     cl(o)
//     cl(n)
    let addObj={
      userId:globs.userData.session.userId,
      siteId:o.siteId,
      zoneId:o.zoneId,
      time:Math.floor(getTime())
    }
    if(o.zoneTier!=n.zoneTier){
      adds.push(
        Object.assign({},addObj,
        {
        action:"zoneTier",
        oldVal:o.zoneTier,
        newVal:n.zoneTier,
      }))
    }
  }

  // show how much you will save/pay in comparison to old plan
  // use info from subscriptionChange preview (once it works)
  showDifference = () => {
    if (this.state.displayCheckout) {
      cl(this.state.preview.invoice_collection)
      let msg = []
      let chargeBalance = 0
      if (!this.state.preview.invoice_collection.charge_invoice || !this.state.account?.billingInfo) {
        // get account balance to show next to charge today?
        // let oldTotal = (this.state.oldPlan.plan_code == "cloud2p0trialsub") ? 0 : getTotal(this.state.oldPlan)
        // cl(oldTotal)
        let newTotal = getTotal(this.state.currPlan)
        msg.push(<div>You will be charged {round(newTotal)} today.</div>)
      } else {
        let chargeTotal = this.state.preview.invoice_collection.charge_invoice.total
        let creditTotal = this.state.preview.invoice_collection.credit_invoices[0]?.total || 0
        chargeBalance = this.state.preview.invoice_collection.charge_invoice.balance
        let creditBalance = this.state.preview.invoice_collection.credit_invoices[0]?.balance || 0
        let planTotal = this.state.plan.currencies[0]?.unitAmount || 0
        cl([chargeTotal, creditTotal, chargeBalance, creditBalance, planTotal])
        // if (planTotal > 0) {
        //   // msg += `The total prorated charge is ${round(chargeTotal)}. The ${this.state.currPlan.custom_fields.Period} charge upon renewal will be ${round(planTotal)}. `
        //   msg.push(<div>The {this.state.currPlan.custom_fields.Period} charge upon renewal will be {round(planTotal)}.</div>)
        // }
        // if (creditTotal < 0) {
        //   // msg += `The total prorated credit from your previous subscription is ${round(Math.abs(creditTotal))}. `
        //   msg.push(<div>The total prorated credit from your previous subscription is {round(Math.abs(creditTotal))}. </div>)
        // }
        // if (creditBalance < 0) {
        //   // msg += `Your account will be credited ${round(creditBalance)} today.`
        //   msg.push(<div>You will be credited {round(Math.abs(creditBalance))} today.</div>)
        // }
        if (chargeBalance > 0) {
          // msg += `You will be charged ${round(chargeBalance)} today.`
          msg.push(<div>You will be charged a prorated amount of {round(chargeBalance)} today.</div>)
        } else {
          msg.push(<div>You will not be charged today.</div>)
        }
      }
      cl(this.state.balance)
      if (this.state.account?.billingInfo && chargeBalance > 0 && this.state.balance?.balances[0]?.amount < 0 ) {
        msg.push(<div>Your account's credit balance of {round(Math.abs(this.state.balance?.balances[0]?.amount))} will be applied.</div>)
      }
      return (
        <div>
          {this.showPlanSummary(this.state.oldPlan, false, true)}
          {this.showPlanSummary(this.state.currPlan, true, true)}
          <div className="clearfloat"></div><br/>
          {msg}
        </div>
      )
    }
  }

  //   {title: ``,text:``,          
  // buttons:["Cancel","Lock zone"]}

  getPopup = (title, text, buttons) => {
    return new Promise((r, e)=> {
      let popup = {
        title: title,
        text: text,
        buttons: buttons,
        displayPopup: true,
        r: r
      }
      this.setState({popup:popup})
    })
  }

  resolvePopup = (b) => {
    cl(b)
    this.state.popup.r(b)
    let popup = this.state.popup
    popup.displayPopup = false
    this.setState({popup: popup})
  }

  showPopup = () => {
    // let diff=this.props.parms.opacity-this.state.opacity
    // if(diff){setTimeout(this.doFading,1)}
    if (this.state.popup) {
      return (
        <>
          <div className="popup" style={{
            display:(this.state.popup.displayPopup)?"block":"none"}}>
            <p><b>{this.state.popup.title}</b></p>
            <br />
            <p><i>*{this.state.popup.text}</i></p>
            <div className="alignright">
            <br/>
            {this.state.popup.buttons.map((b, i)=> {
              return (
                <>
                <C18Button00 className="icon" key={i} onClick={e=>this.resolvePopup(b)}>
                {b}
                </C18Button00>
                <div className="divider"/>
                </>
              )
            })}
            <div className="clearfloat"></div><br/>
            </div>
          </div>
          <div className="popup-scrim"></div>
        </>
      )
    }
  }

  // pop up comparison screen
  showCheckout = () => {
    // let diff=this.props.parms.opacity-this.state.opacity
    // if(diff){setTimeout(this.doFading,1)}
    return(
      <>
        <div className="popup" style={{
          opacity: this.state.opacity,
          display:(this.state.displayCheckout)?"block":"none"}}>
          <p>Order Summary:</p>
          <br />
          <div className="alignright">
          {this.showDifference()}
          <br/>
          <C18Button00 className="icon" onClick={e=>this.onCancel()}>
            Cancel
          </C18Button00>
          <div className="divider"/>
          <C18Button00 className="icon" onClick={e=>this.onConfirm()}>
            Place your order
          </C18Button00>
          <div className="clearfloat"></div><br/>
          </div>
        </div>
        <div className="popup-scrim"></div>
      </>
    )
  }


  showPlanSummary = (plan, newPlan = false, checkout = false) => {
    cl("show plan summary")
    // let total = 0
    let zone_total = 0
    let annual = (plan.add_ons.annual) ? 12 : 1
    let annual_discount = (plan.add_ons.annual) ? .9 : 1
    // let chargeTotal = null
    // let creditSub = null
    let chargeBalance = null
    // let creditBalance = null
    // let creditTotal = 0
    // let planTotal 
    // count protects

    plan.protect_count = this.protect_count
    let real_total = getTotal(plan)
    // cl(real_total)
    if (this.state.preview?.invoice_collection?.charge_invoice) {
    //   chargeTotal = this.state.preview.invoice_collection.charge_invoice.total
    //   creditSub = this.state.preview.invoice_collection.credit_invoices[0]?.total || 0
      chargeBalance = this.state.preview.invoice_collection.charge_invoice.balance
    //   creditBalance = this.state.preview.invoice_collection.credit_invoices[0]?.balance || 0
    //   planTotal = this.state.plan.currencies[0]?.unitAmount || 0
    //   this.state.preview.invoice_collection.charge_invoice.credit_payments.forEach((payment)=>{
    //     creditTotal += payment.amount
    //   })
    //   cl([chargeTotal, creditSub, chargeBalance, creditBalance, planTotal, creditTotal])
    // use getTotal to calculate final amount
    }
    return (
      <div className="table-container">
        <table className="list subscription">
        <tbody>
        {(!newPlan && plan.plan_code == "cloud2p0trialsub") ? 
          <tr key="trial" className="checkout-summary-row">
            <td>Trial</td>
            <td></td>
            <td>=</td>
            <td>{round(0)}</td>
          </tr>
        :
          Object.entries(allZones).map(([type, price])=>{
            // apply temp price reduction for pearl protects (up to the number active on account)
            let qtys = plan.add_ons.zone_qty[type]
            return Object.entries(qtys).map(([tier, val]) => {
              if (tier == zoneTypes["unlocked"]) {
                // reduce protect_count by 1 and use protect pricing
                if (plan.protect_count > 0) {
                  let protect_total = (price["unlocked_protect"] - getZoneDiscount(plan, "protect")) * plan.protect_count * annual * annual_discount
                  zone_total += protect_total
                  // cl([price["unlocked_protect"], getZoneDiscount(plan, "protect"), plan.protect_count, protect_total])
                }
                let t_total = (price[tier] - getZoneDiscount(plan)) * (val - plan.protect_count)  * annual * annual_discount
                // cl([price[tier], getZoneDiscount(plan), (val - plan.protect_count), t_total])
                zone_total += t_total
                if (val >= 0) {
                  return (
                    <tr key={type + tier} className="checkout-summary-row">
                      <td>{(checkout) ? 
                        (newPlan) ? `New Subscription` : `Current Subscription`
                        :
                        `${(plan.add_ons.annual) ? "Annual" : "Monthly"} ${capitalize(type)} License`
                        }
                      </td>
                      <td>{val - plan.protect_count} x {round((price[tier] - getZoneDiscount(plan)) * annual * annual_discount)} (Standard)</td>
                      {plan.protect_count && <td>+</td>}
                      {plan.protect_count && <td>{plan.protect_count} x {round((price["unlocked_protect"] - getZoneDiscount(plan, "protect")) * annual * annual_discount)} (Protect)</td>}
                      <td>=</td>
                      <td>{round(real_total)}</td>
                    </tr>
                  )
                }
              }
            })
          })
        }
        {Object.entries(allAddons).map(([type, price])=> {
          // if (type == "API Access" && state.add_ons.api_access) {
          //   total += price * annual
          //   return (
          //     <tr>
          //       <td>{capitalize(type)} </td>
          //       <td>1 x ${price * annual}</td>
          //       <td>=</td>
          //       <td>{round(price * annual)}</td>
          //     </tr>
          //   )
          // }
        })
        }
        </tbody>
        </table>
      </div>      
    )
  }

  // {newPlan && 
  // <tr>
  //   <td>Prorated Credit</td>
  //   <td></td>
  //   <td>=</td>
  //   <td>{(creditSub != null) ? `-${round(Math.abs(creditSub))}` : "*calculated at checkout*"}</td>
  // </tr>
  // }

  showRecurlyButton = () => {
    // check if current user is owner
    // let owner = globs.accountInfo.info.owner==globs?.userData?.session?.userId
    if (this.state.recurlyUrl) {
      return (
        <>
        <C18Button00 disabled={!this.state.owner} className="outlined">
          {
            (this.state.owner) ?
            <a target="_blank" rel="noopener noreferrer" href={this.state.recurlyUrl} className="colored-link-text">
              Billing & Invoices
            </a> 
            :
            <a style={{color: "#aaa"}}>
              Billing & Invoices
            </a>
          }
        </C18Button00>
        <><div className="clearfloat"></div><br/></>
        {!this.state.account?.billingInfo &&
          <div style={{display: "flex"}}>
            <div className="subscription-alarm-number">!</div>
            <div className="colored-link-text-red">   No billing information detected. {!this.state.owner ? "Please contact your owner to configure this setting." : ""}</div>
          </div>
        }
        <br/>
        {this.state.account?.hasPastDueInvoice &&
          <div style={{display: "flex"}}>
            <div className="subscription-alarm-number">!</div>
            <div className="colored-link-text-red">   This account is past due. {!this.state.owner ? "Please contact your owner to restore account status." : ""}</div>
          </div>
        } 
        <><div className="clearfloat"></div><br/></>
        </>
      )
    }
  }

  showPlanCheckout = () => {
    // let total_count_arr = Object.entries(allZones).map(([type, price]) => getZonesCount(type, this.state, true)) 
    // cl(total_count_arr)
    // let total_count = total_count_arr.reduce((val1, val2) => val1 + val2) 
    // disable checkout if plan config is the same,  show mouseover text
    return (
      <>
      <C18Button00 className="outlined" onClick={e=>this.onCheckout()}>
        Checkout
      </C18Button00>
      <><div className="clearfloat"></div><br/></>
      </>
    )
  }

  showCurrentPlan = () => {
    // show details of current plan
    // all accounts should be initialized to "free trial" - zones should still be counted but free trial discount will show
    // being on trial means that all zone and feature limits are bypassed
    let dateStr = (this.state.oldPlan.plan_code == "cloud2p0trialsub" || !this.state.oldPlan.auto_renew) ? "Ends on " : "Renews on "
    let dateFmt = dateToMoDaYrStr(new Date(this.state.oldPlan.end_date))
    if (this.state.oldPlan) {
      return (
        <div>
          <h2>Current Subscription ({dateStr} <span className="colored-link-text-red">{dateFmt}</span>)</h2>
          {this.showPlanSummary(this.state.oldPlan)}
        </div>
      )
    }
  }

  showPlanPreview = () => {
    // show "cart"-like summary of their choices
    // show "proceed to checkout"  button (will show preview of old -> new plan and cost total)
    if (this.state.subChange) {
      return (
        <div>
        <h2>Shopping Cart Preview</h2>
        {this.showPlanSummary(this.state.currPlan, true)}
        </div>
      )
    }
  }

  showZoneTypeSelect = () => {
    let arr = Object.entries(allZones);
    return (
      <div>
      <label htmlFor="zone-type">Zone Type</label>
        <span className="custom-select">
          <select id="zone-type"
            value={capitalize(this.state.zone_type)}
            onChange={(e)=>this.onChange("zone_type", {zone_type: e.currentTarget.value})}
          >
          {Object.entries(allZones).map(([type, val])=>{
            return(
              <option key={type} value={type}>{capitalize(type)}</option>
            )
          })}
          </select>
          <span className="material-icons down-arrow">
            keyboard_arrow_down
          </span>
        </span>
      </div>
    )
  }

  showPeriodSelect = () => {
    return (
      <div className="slide-switch">
        <label className="switch-label" htmlFor="period-monthly">Monthly</label>
        <label className="switch">
          <C18Input00
            type="checkbox"
            id="period-toggle"
            name="period-toggle"
            checked={this.state.currPlan.add_ons.annual}
            onChange={e=>this.onChange("annual", {annual: !this.state.currPlan.add_ons.annual})}
          />
          <span className="slider round"></span>
        </label>
        <label className="switch-label" htmlFor="period-annual">Annual</label>
        <><div className="clearfloat"></div><br/></>
      </div>
    )
  }

  showApiSelect = () => {
    return (
      <div className="slide-switch">
        <label className="switch">
          <C18Input00
            type="checkbox"
            id="api-toggle"
            name="api-toggle"
            checked={this.state.currPlan.add_ons.api_access}
            onChange={e=>this.onChange("api_access", {api_access: !this.state.currPlan.add_ons.api_access})}
          />
          <span className="slider round"></span>
        </label>
        <label className="switch-label" htmlFor="api-access">API Access</label>
        <><div className="clearfloat"></div><br/></>
      </div>
    )
  }

  // <C18Input00 type="radio" id="period-annual" name="periodselect" value="annual"
  //         checked={this.state.custom_fields.Period=="annual"}
  //         onChange={e=>this.onChange("period", {period: e.target.value})}
  //       ></C18Input00>

  //     <C18Input00 type="radio" id="period-monthly" name="periodselect" value="monthly"
  //       checked={this.state.custom_fields.Period=="monthly"}
  //       onChange={e=>this.onChange("period", {period: e.target.value})}
  //     ></C18Input00>

  showTiers = () => {
    // show each tier and how many zones they have selected for the selected zone type
    // for each tier (under selected zone type)
    let qtys = this.state.currPlan.add_ons.zone_qty[this.state.zone_type]
    return (
      <div>
      {
        // make tier header
        // make tier quantity select
        // show tier features can be adjusted based on the selected zone type
        Object.entries(qtys).map(([tier, val]) => {
          if (tier == zoneTypes["unlocked"]) {
            return (
              <div key={this.state.zone_type + tier}>
                <h2>{capitalize(tier)}</h2>
                <C18MuiInput
                  key={this.state.zone_type + tier}
                  value={val}
                  onChange={(e) => this.onChange("zone_qty", [this.state.zone_type, tier, e.currentTarget.value])}
                  type="number"
                  saveOK={true}
                  unit="zones"
                  inputProps={{
                      step: 1,
                      min: 0,
                      max: 999,
                      inputMode:"decimal",
                      type:"number",
                      style: {textAlign: 'center'}
                  }}
                />
              </div>
            )
          }
        })
      }
      </div>
    )
      

  }

  showPlanTable = () => {
    // show select controls for adjusting plan (plan should already prefill the current categories)
    // show select drop-down (default is zone they have most add-ons for)
    // selecting a different zone type adjusts the price (and features?) of the zones shown in the table
    return (
      <div>
      {this.showPeriodSelect()}
      </div>
    ) 
    // {this.showApiSelect()}
    // <h2>{capitalize(this.state.zone_type)} Select</h2>
    // {this.showZoneTypeSelect()}
  }


  showManageSubscription = () => {
    return (
      <div>
      {this.showCurrentPlan()}
      {this.showPlanPreview()}
      <><div className="clearfloat"></div><br/></>
      {this.showPlanTable()}
      {this.showCheckout()}
      {this.showPopup()}
      {this.showRecurlyButton()}
      {this.showPricingTableButton()}
      {this.showPricingTable()}
      <C18SiteZoneSub00 parms={{
        edit:false,
        height:200,
        value:this.state.zoneSel,
        onChange:this.onChange,
        newConfig:this.state.newConfig,
        zoneNames:this.zoneNames,
        totalLicenses:this.state.currPlan.add_ons.zone_qty.zone[zoneTypes["unlocked"]],
        disabled:false
      }}/>
      </div>
    )
  }
  

  render(){
    if(this.state.loaded){
      return this.showManageSubscription()
    } else{
      return <div>loading. . .</div>
    }
  }
}
      
export default C18ManageSubscription;
