import React from 'react';
import C18Select00 from './C18Select00'
import C18DateFormat00 from './C18DateFormat00'
import C18Anchor00 from './C18Anchor00'
import history from "../../history"
import {cl,globs,constant,show,dateToDisplayDate,getDaysRemaining, calcTimezoneOffset,getRandomString} from '../../components/utils/utils';
import {wsTrans,saveSession,saveBrowserAccess} from '../utils/utils'
import {loadSitesInfo,loadZonesInfo,loadAlarmsInfo,alLevel,getAlarmInfo,privs,loadPrivsInfo,
  getThirdPartyAccounts,loadMBInfo,loadGatewaysInfo,
  loadUsersInfo,getUserIndex,loadSubscriptionInfo,loadAddedSensorsInfo
} from './C18utils'
var config = require('../../components/utils/config');

class C18SitesListTiles00 extends React.Component{
  constructor(props) {
    super(props);
//     cl(props)
//     cl(globs)
//     cl(props)
    saveBrowserAccess("/browser/sitesList/construct/line18")
    
//     cl(sites)
    this.state={
      tileMode: "grid-select",
      loaded: false,
      accountSel:null,
      serverSel:config.server,
      draggingIndex: null,
      siteOrder:[],
//       sites:sites,
      sites:[
        {
          name:"San Juan Capistrano",
          greenhouses: 10,
          unfinishedTasks: 3,
          alarms: 0,
          siteId:"0sna8jVYIh0xw6oF",
        },
        {
          name:"Tatooine",
          greenhouses: 10,
          unfinishedTasks: 3,
          alarms: 1,
          siteId:"0sna8jVYIh0xw6oF",
        },
        {
          name:"Mos Elsley",
          greenhouses: 10,
          unfinishedTasks: 3,
          alarms: 0,
          siteId:"0sna8jVYIh0xw6oF",
        },
        {
          name:"Endor",
          greenhouses: 10,
          unfinishedTasks: 3,
          alarms: 0,
          siteId:"0sna8jVYIh0xw6oF",
        },
        {
          name:"Alderaan",
          greenhouses: 10,
          unfinishedTasks: 3,
          alarms: 0,
          siteId:"0sna8jVYIh0xw6oF",
        },
        {
          name:"Naboo",
          greenhouses: 10,
          unfinishedTasks: 3,
          alarms: 0,
          siteId:"0sna8jVYIh0xw6oF",
        },
      ],
    }
    this.props.parms.onChange(
      {
        cmd: "breadcrumbs",
        data:
          {breadcrumbs: [
            {t:"Sites", url:"/usa/c18/sites"},
          ]},
      },
    )
    this.makeSites()

  }
  
  countGreenhouses=(siteId)=>{
//     cl(siteId)
    let gzi=globs.zonesInfo.info
    let count=0
    gzi.forEach(z=>{
//       cl(z)
      if((z.siteId==siteId)&&(!z.deleted)){count+=1}
    })
    return count
  }
  
  countAlarms=(siteId)=>{
    let gai=globs.alarmsInfo.info
//     cl(gai)
    let alarmCount=0
    for(let i=0;i<gai.length;i++){
      let a=gai[i]
//       cl(a)
      if((a.s==siteId)&&(
        alLevel(a.a,a.s,a.z)<4)&&
        ((globs.zonesInfo.sz2z[a.s]||{})[a.z])&&
          (!(globs.zonesInfo.sz2z[a.s]||{})[a.z]?.virtual)){alarmCount++}
//       if((a.s==siteId)&&(alLevel(a.a,a.s,a.z)<4)&&
// (globs.zonesInfo.sz2z[a.s][a.z])&&(!globs.zonesInfo.sz2z[a.s][a.z].virtual)){alarmCount++}
//         let ai=getAlarmInfo(a.a)
//         if(ai.level<=3){alarmCount++}
//       }
//       cl(a)
    }
    return alarmCount
  }
  saveSiteOrder=async(so)=>{
    let data={siteOrder:so}
    // save locally
    globs.accountInfo.info.siteOrder = so
    await wsTrans("usa", {cmd: "cRest", uri: "/s/accounts", method: "update", 
      sessionId: globs.userData.session.sessionId, body: data})
  }
  
  saveUser=async(info)=>{
//     cl([userId,info])
    await wsTrans("usa", {cmd: "cRest", uri: "/s/users", method: "update2", 
      sessionId: globs.userData.session.sessionId,
      body: info})
  }
  
  showSubInfo=async()=>{
    // check if pop up should be shown
    await loadSubscriptionInfo()
    // let user=globs.usersInfo.info[getUserIndex(globs.userData.session.userId)]
    // if(!user){return}
    // if(!user.uiInfo){user.uiInfo={
    //   showHelpInfo:true,
    // }}
    // if(user.uiInfo.showHelpInfo){
    if(false){
      let days = getDaysRemaining(new Date(globs.subscriptionInfo.info.end_date))
      let text = `In ${days} days, your free trial will end. If you do not purchase licenses before trial ends, you will not be able to access unlicensed zones.` 
      let buttons = ["OK, Remind Me Later", "Manage Subscription"]
      let res=await this.props.parms.getPopup({text:text, buttons:buttons})
      // if(res=="Got It!"){
      //   user.uiInfo.showHelpInfo=false
      //   this.saveUser({userId:user.userId,uiInfo:user.uiInfo})
      // }
    }
  }

  showHelpInfo=async()=>{
    await loadUsersInfo()
    let user=globs.usersInfo.info[getUserIndex(globs.userData.session.userId)]
    if(!user){return}
    if(!user.uiInfo){user.uiInfo={
      showHelpInfo:true,
    }}
    if(user.uiInfo.showHelpInfo){
      let res=await this.props.parms.getPopup({text:"You can get information at any time by right-clicking on the area you want to know more about.",          
        buttons:["Dismiss","Got It!"]})
      if(res=="Got It!"){
        user.uiInfo.showHelpInfo=false
        this.saveUser({userId:user.userId,uiInfo:user.uiInfo})
      }
    }
  }
  
  makeSites=async()=>{
//     cl(globs.userData.session)
//     cl("make sites")
//     cl(show("main"))
//     cl("make sites")
//     Promise.all([
//       loadSitesInfo(),
//       loadZonesInfo(),
//       loadAlarmsInfo(),
//       loadGatewaysInfo(),
//       loadAddedSensorsInfo(),
//       loadPrivsInfo(),
//       loadMBInfo(),
//       loadUsersInfo(),
//     ])
//     cl("done")
    await loadSitesInfo()
    await loadZonesInfo()
    await loadAlarmsInfo()
    await loadGatewaysInfo()
    await loadAddedSensorsInfo()
    await loadPrivsInfo()
    await loadMBInfo()
    await loadUsersInfo()
//     cl("make sites")
//     cl("loaded888888888888888888888888888888888")
//     cl(globs.privsInfo)
//     let p3=await getThirdPartyAccounts(globs.userData.session.userId)
//     cl(p3)
//     cl(globs)
    await this.loadAccounts()// needs to check for privs!
//     cl(globs)
//     cl(globs.privsInfo.info)
    let sites=[]
//     cl(globs.sitesInfo)
    let gsi=globs.sitesInfo.info
//     cl(gsi)
//     cl(globs.zonesInfo)
//     cl("make sites")
    gsi.forEach(s=>{
//       cl(s)
      let count=this.countGreenhouses(s.siteId)
      sites.push(
        {
          name: s.name?.length > 35 ? s.name.substring(0, 32) + '...' : s.name,
          greenhouses:this.countGreenhouses(s.siteId),
          unfinishedTasks: "tbd",
          alarms: this.countAlarms(s.siteId),
          siteId:s.siteId,
          siteImage:s.siteImage
        }
      )
    })
    let siteOrder = this.getSiteOrder(sites);

    let idx = getUserIndex(globs.userData.session.userId)
    if (idx!=-1) {
      let u = globs.usersInfo.info[idx]
      this.timezone = (u?.timezone) ? u.timezone : "";
      if(!u.apiKey){
        // cl("new one")
        u.apiKey=getRandomString(32)
        let res= await wsTrans("usa", {cmd: "cRest", uri: "/s/users", method: "update", 
          sessionId: globs.userData.session.sessionId, body: {userId:u.userId,apiKey:u.apiKey}})
      }
      this.apiKey = u.apiKey
    }
//     cl("make sites")
    this.setState({loaded:true, sites:sites, siteOrder:siteOrder, accountSel:globs.userData.session.accountId})
//     return sites
//     cl("make sites")
    this.showHelpInfo()
//     cl("make sites")
    // cl("siteorder.", this.getSiteOrder())
    // this.showSubInfo()
  }
  
  showSiteTileAlarm=(s)=>{
    if(s.alarms){
      return(
        <tr className="alarm-on"><th>Alarms <button type="button" className="alarm-icon" 
          onClick={e=>{this.onChange("alarm",{siteId:s.siteId,event: e}); return false}}
          aria-label="alarm">!
          </button> level 1-3</th><td>{s.alarms}</td></tr>
      )
    }else{
      return(
        <tr><th>Alarms</th><td>--</td></tr>
      )
    }
  }
  
  onClickSite=async(e,s)=>{// need to set the siteId in the session on the server!
    e.preventDefault()
    e.stopPropagation()
    await wsTrans("usa", {cmd: "cRest", uri: "/s/sessions", method: "update", 
      sessionId: globs.userData.session.sessionId,
      body: {siteId:s.siteId}})
    globs.userData.session.siteId=s.siteId
    history.push(`/usa/c18/sites/${s.siteId}`)
//     cl(s)
  }

  handleDragStart = (index) => {
    // cl(index,'newSiteOrder drag start')
    this.setState({ draggingIndex: index });
  };

  handleDragEnter = (index) => {
    
    const { draggingIndex, siteOrder } = this.state;

    
    if (draggingIndex === index) return;
    const newSiteOrder = siteOrder.slice()
    // cl("old zone order")
    const [movedCard] = newSiteOrder.splice(draggingIndex, 1);
    newSiteOrder.splice(index, 0, movedCard);

    // cl("🚀 ~ C18ZonesListTiles00 ~ newZones:", newSiteOrder)
    // cl("new zone order: " + newSiteOrder)
    this.setState({
      // zones: newZones,
      draggingIndex: index,
      siteOrder: newSiteOrder,
    });
  };

  handleDragEnd = async (index) => {
    const { siteOrder } = this.state;
    // console.log("🚀 ~ handleDragEnd= ~ siteOrder:", siteOrder)
    await this.saveSiteOrder(siteOrder);
    this.setState({ draggingIndex: null });
    // cl("so after save", siteOrder)
    // cl(`saved site order: from ${index} ` + siteOrder)
  };


  getSiteOrder=(sites)=>{
        let so = globs.accountInfo.info.siteOrder || []
        let st=this.state
        so = so.filter((s)=>{return s !== null})
        // cl(so)
        // cl("st.sites", sites)
        if (!so.length || so.length != sites.length) {
          so = Array.from({length: sites.length}, (v, i) => i)
        }
        // cl(so)
        so.forEach((z,i)=>{so[i]=+z})
        // cl(so)
        return so
      }
    
  showSiteTiles=()=>{
    let sites=[]
//     cl(globs.privsInfo)
    this.state.sites.forEach((s,i)=>{
//       cl(s)
//       if(s.siteId=="d1fIue45CWvP@Nik"){
//       }
      s = this.state.sites[this.state.siteOrder[i]]

      let gws=globs.gatewaysInfo.info.filter(gw=>{return gw.siteId==s.siteId})
      let serverGood=true,gotServer=false
      if(!gws.length){
        let zones=globs.zonesInfo.info.filter(z=>{return z.siteId==s.siteId})
//         cl(zones)
        zones.forEach(z=>{
          if(z.server){
            gotServer=true
            if(z.server==config.server){serverGood=true}
          }
        })
      }else{
//         cl(gws)
        gws.forEach(gw=>{
//           cl(gw.server)
//           cl(config.server)
          if(["http0","nomq00"].includes(gw.server)){gw.server="prod0"}
          if(gw.server){gotServer=true}
          if(gw.server&&(gw.server!=config.server)){serverGood=false}
          if((gw.server=="prod0Test")&&(config.server=="prodX")){serverGood=true}
          if((gw.server=="http0Test")&&(["prod0Test","prodS"].includes(config.server))){
            serverGood=true}
        })
      }
//       let color="black"
//       var title
      var color,title
      if(!gotServer){color="#888888"}
      if(!serverGood){
        color="orange"
        title=`This site is connected to a different server!`
      }
      if(privs("site",s.siteId,constant.AREA_PRIVS_READ)){
        let path
        if (s.siteImage) {
          let si=s.siteImage.split('.')[0];
          let ext=s.siteImage.split('.')[1];
          path=`${constant.expressUrl}/usa/images/site/uploads/${si[0]}/${si[1]}/${si[2]}/${si.substr(3)}.${ext}`
        } else {
          path = "/gh1.png";
        }
        sites.push(
          <div
          draggable
          onDragStart={() => this.handleDragStart(i)}
          onDragEnter={() => this.handleDragEnter(i)}
          onDragEnd={() => this.handleDragEnd(i)}
          key={s.siteId} 
          id="siteTiles"
          data-context={true}
          className="location-box-container" 
          onClick={e=>{this.onClickSite(e,s)}}>
            <div id="siteTiles" data-context="siteTiles" className={`location-box${(s.alarms)?" alarm-on":""}`} aria-label={s.name}>
              <C18Anchor00 to="" onClick={e=>{this.onClickSite(e,s)}}>
                <h2 style={{color:color}} title={title}>{s.name}</h2>
              </C18Anchor00>
                
                <table><tbody>
                  <tr><th>Greenhouses</th><td>{s.greenhouses}</td></tr>
                  <tr>{false&&<><th>Unfinished Tasks</th><td>{s.unfinishedTasks}</td></>}</tr>
                  {this.showSiteTileAlarm(s)}
                </tbody></table>
                <img id="sites/siteImage" data-context="sites/siteImage" src={path} alt={s.name}/>
            </div>
          </div>
        )

      }
    })

    // const { siteOrder } = this.state;
    // const orderedSites = siteOrder.map(index => sites[index]);
    // cl(orderedSites)

    return (
      <div className="location-grid-area">
        {sites}
      </div>
    )
  }
  
  showSiteListAlarms=(s)=>{
    if(s.alarms){
      return(
        <>
        <td>{s.alarms}</td><td>level 1-3
        <button type="button" className="alarm-icon"
          onClick={e=>{this.onChange("alarm",{siteId:s.siteId,event: e}); return false}}>!</button></td>        
        </>
      )
    }else{
      return(
        <>
        <td>--</td><td>--</td>
        </>
      )
    }
    
  }
  
  showSiteList=()=>{
    let sites=[]
    this.state.sites.forEach((s,i)=>{

      s = this.state.sites[this.state.siteOrder[i]]
      
      if(privs("site",s.siteId,constant.AREA_PRIVS_READ)){
        let gws=globs.gatewaysInfo.info.filter(gw=>{return gw.siteId==s.siteId})
        let serverGood=true,gotServer=false
        if(!gws.length){
          let zones=globs.zonesInfo.info.filter(z=>{return z.siteId==s.siteId})
  //         cl(zones)
          zones.forEach(z=>{
            if(z.server){
              gotServer=true
              if(z.server==config.server){serverGood=true}
            }
          })
        }else{
  //         cl(gws)
          gws.forEach(gw=>{
  //           cl(gw.server)
  //           cl(config.server)
            if(["http0","nomq00"].includes(gw.server)){gw.server="prod0"}
            if(gw.server){gotServer=true}
            if(gw.server&&(gw.server!=config.server)){serverGood=false}
            if((gw.server=="prod0Test")&&(config.server=="prodX")){serverGood=true}
            if((gw.server=="http0Test")&&(["prod0Test","prodS"].includes(config.server))){
              serverGood=true}
          })
        }
  //       let color="black"
  //       var title
        var color,title
        if(!gotServer){color="#888888"}
        if(!serverGood){
          color="orange"
          title=`This site is connected to a different server!`
        }

  //       cl(s)
        let alarmClass=(s.alarms)?"alarm-on":""
        sites.push(
          <tr          
            draggable
            onDragStart={() => this.handleDragStart(i)}
            onDragEnter={() => this.handleDragEnter(i)}
            onDragEnd={() => this.handleDragEnd(i)} 
            onClick={e=>{this.onClickSite(e,s)}} key={i} className={alarmClass}
          >
            <td>
              <C18Anchor00 style={{color:color}} title={title} to="" className="name" onClick={e=>{this.onClickSite(e,s)}}>
                {s.name}
              </C18Anchor00>
            </td>
            <td>{s.greenhouses}</td>
              {false&&<td>{s.unfinishedTasks}</td>}
              {this.showSiteListAlarms(s)}
              {false&&
                <td><a href="" className="create-grow-journal-entry material-icons-outlined" aria-label="make grow journal entry">
                        note_alt
                      </a></td>
              }
          </tr>
        )
      }
    })
    return (
      <div className="location-list">
        <table className="list"><tbody>
          <tr>
            <th>Name</th><th>Zones</th>{false&&<th>Unfinished Tasks</th>}<th>Alarms</th><th>Alarm Level</th>
          </tr>
          {sites}
        </tbody></table>
      </div>
    )
  }
  
  onChange=async(type,vals)=>{
//     cl(type,vals)
    switch(type){
      case "format":
        this.setState(vals)
        // if(vals.forecast) {
        //   this.props.parms.onChange({ forecast: vals.forecast })
        // }
        break
      case "selectAccount":
        this.setState(vals)
        cl(vals)
        globs.userData.session.accountId=vals.accountSel
        saveSession(globs.userData.session)
        let endPoint=(
          (!privs("super","",constant.AREA_PRIVS_READ))&&
          globs.privsInfo.thirdPartyAccounts?.length)?
          "/tp/tpSession":"/su/suSession"
        let res=await wsTrans("usa", {cmd: "cRest", uri: endPoint, method: "update", 
          sessionId: globs.userData.session.sessionId,
          body: {accountId:vals.accountSel}})
        cl(res)
        cl("***********************reload")
        setTimeout(()=>{window.location.reload()},1000)
        break
      case "selectServer":
        let server=vals.serverSel
        cl(server)
        this.setState({serverSel:server})
        sessionStorage.setItem("serverOverride", server);
        setTimeout(()=>{window.location.reload()},1000)
        break
      case "alarm":
        let e=vals.event
        e.stopPropagation()
        history.push(`/usa/c18/sites/${vals.siteId}/alarmLog`)
        break
      case "restartServer":
        cl("restart")
        await wsTrans("usa", {cmd: "cRest", uri: "/s/server", method: "update",
          sessionId: globs.userData.session.sessionId,
          body: {cmd:"restart"}})
        break
    }
  }
  
  showSites=()=>{
    return (this.state.tileMode=="grid-select")?this.showSiteTiles():this.showSiteList()
  }
  
  loadAccounts=async()=>{
    var res
    if(privs("super","",constant.AREA_PRIVS_READ)){
      if(this.accounts){return}
      res=await wsTrans("usa", {cmd: "cRest", uri: "/su/suAccounts", method: "retrieve", 
        sessionId: globs.userData.session.sessionId,
        body: {}})
    }else{
      if(globs.privsInfo.thirdPartyAccounts?.length){
  //       cl("get third party")
        res=await wsTrans("usa", {cmd: "cRest", uri: "/tp/tpAccounts", method: "retrieve",
          sessionId: globs.userData.session.sessionId,
          body: {}})
      }
    }
    // cl(res)
    this.accounts=res?.data
//     cl(globs.privsInfo)
    // cl(this.accounts)
    if(this.accounts){
      this.accounts.sort((a,b)=>{
//         cl(a)
        let na=(a.name||"").toLowerCase()
        let nb=(b.name||"").toLowerCase()
        if(na<nb){return -1}
        if(na>nb){return 1}
        return 0
      })
    }
    // cl(this.accounts)
  }
  
  showSelectServer=()=>{
    let servers=[
      {t:"Prod",v:{s:"prod0",h:"prod0"}},
//       {t:"Prod Test",v:{s:"prod0Test",h:"prod0Test"}},
      {t:"Prod Exp",v:{s:"prodX",h:"prodX"}},
      {t:"Prod Stable",v:{s:"prodS",h:"prodS"}},
      {t:"Node MQTT",v:{s:"nomq00",h:"nomq00"}},
//       {t:"Dev",v:{s:"dev",h:"sg"}},
      {t:"Mongo",v:{s:"devMongo",h:"devMongo"}},
      {t:"Ryan ElK",v:{s:"ryan",h:"sg_ryan"}},
      {t:"Wayne",v:{s:"wayne",h:"wayne"}},
      {t:"Stage",v:{s:"stage",h:"sg_stage"}},
      {t:"Http1",v:{s:"http1",h:"http1"}},
//       {t:"Alpha",v:{s:"alpha",h:"sg_alpha"}},
//       {t:"Demo",v:{s:"demo",h:"demo"}},
//       {t:"Show Server",v:{s:"showServ",h:"showServ"}},
//       {t:"Show Server Remote",v:{s:"showServ2",h:"showServ2"}},
    ]
//     cl(this.state.serverSel)
    if((config.server=="prod0")&&(!privs("super","",constant.AREA_PRIVS_READ))){return null}
    return(
        <div className="custom-select">
          <label htmlFor="">Select Server</label>
          <C18Select00 id=""
            value={this.state.serverSel}
            onChange={e=>this.onChange("selectServer",{serverSel: e.currentTarget.value})}
          >
          {servers.map((s,i)=>{
//             cl(s.v.s)
            return(
              <option key={i} value={s.v.s}>{s.t}</option>
            )
          })}
          </C18Select00>
          <span className="material-icons down-arrow">
            keyboard_arrow_down
          </span>
        </div>
    )
  }

  showServerRestart=()=>{
//           <button type="button" style={{marginBottom:16}}
//           className="material-icons">refresh</button>
//     cl(config)
    if(config.host=="prod0"){return null}else{
      return(
        <div className="custom-select" style={{display:"inline-block",position:"relative",
          top:8,height:40,
          borderStyle:"solid",borderWidth:1,borderRadius:10,borderColor:"#a0a0a0",

        }}>
        <div></div>
            <button type="button" style={{verticalAlign:"baseline",margin:"0px 2px 0px 2px"}}
            className="material-icons" title="Restart Server"
            onClick={e=>this.onChange("restartServer")}
            >refresh</button>

        </div>
      )
    }
  }


  showSelectAccount=()=>{
//     this.loadAccounts()
    // cl(this.accounts)
    // cl(globs.privsInfo.info)
    let isSuper=privs("super","",constant.AREA_PRIVS_READ)!=0
//     cl(isSuper)
    // cl(globs.privsInfo.thirdPartyAccounts?.length>1)
    if((isSuper||(
      (globs.privsInfo.thirdPartyAccounts?.length>1)
    )) && this.accounts) {
//       cl("passed privs test")
//       cl(this.accounts)
      return(
        <section className="link4 customdrop-section custom-dropdown">
        <div className="custom-select">
          <label htmlFor="">Select Account</label>
          <C18Select00 id=""
            value={this.state.accountSel}
            onChange={e=>this.onChange("selectAccount",{accountSel: e.currentTarget.value})}
          >
          {this.accounts.map((a,i)=>{
            return(
              <option key={i} value={a.accountId}>{a.name}</option>
            )
          })}
          </C18Select00>
          <span className="material-icons down-arrow">
            keyboard_arrow_down
          </span>
        </div> 
        {isSuper&&this.showSelectServer()}
        {isSuper&&this.showServerRestart()}
        </section>
      )
    }else{return null}
  }

  render(){
//     cl(this.state)
//     cl(globs.privsInfo.info)
    if(this.state.loaded){
      return (
        <div>
          <h1>Sites</h1>
          <div id="content-area">
            <section className={"link4 "+(this.state.tileMode)?"no-section":""}>
              <C18DateFormat00 parms={{
                leftString: dateToDisplayDate(new Date(),"mm/dd/yyyy h:mm ap", calcTimezoneOffset(this.timezone)),
                leftClass: "date",
                type: "listTile",
                parent: "sites",
                value: this.state.tileMode,
                valueId: "tileMode",
                timeZone: this.timezone,
                weather: true,
                apiKey: this.apiKey,
                onChange: o=>this.onChange("format",o)
              }}/>
              {this.showSelectAccount()}
              {this.showSites()}
            </section>
          </div>
        </div>
      )
    }else{
      return <div id="content-area">loading sites list. . .</div>
    }
  }
}
      
export default C18SitesListTiles00;
