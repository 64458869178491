import {cl, constant, getTime, globs, start, stop, show, setRestTimeout} from './utils';
import {getConfigs, getPages, getFuiPages, getTemplates} from './httpauth';// , getSite
// import {pi} from './paramids'
import {p, pi,pInd} from './paramIds';
import {openWS, sendWS, sendSocket, checkNewSite} from './ws';
// import {net} from 'net'
// let net=require('net')
var config = require('./config');
// console.log(net)
// import {config_setpoints} from './paramIds';

/* zones is an array [0] -> zone 0, etc., then
it should have a setpoint field: zones[0].setpoints
*/
var dbVals = {
  zones: [],
  z: [],
  site: -1,
  user: 123,
  gotSite: false,
  initted: false,
  siteAuthorized: false,
};

// cl("here!")

// var z = [];// this is where the whole site is stored
/* z[0] has values for zone 0, z[255] has site-wide values
z[0] is organized by channels: 0-39 for unit 0, 192-199 for mixing tanks,
240-243 for the unit configuration and 255 for zone config
the units settings are in*/

// let httpHost = window.location.hostname
// const baseUrl = "http://" + httpHost + ":3375";

// var events = (function(){
//   var topics = {};
//   var hOP = topics.hasOwnProperty;
// 
//   return {
//     subscribe: function(topic, listener) {
//       // Create the topic's object if not yet created
//       if(!hOP.call(topics, topic)) topics[topic] = [];
// 
//       // Add the listener to queue
//       var index = topics[topic].push(listener) -1;
// 
//       // Provide handle back for removal of topic
//       return {
//         remove: function() {
//           delete topics[topic][index];
//         }
//       };
//     },
//     publish: function(topic, info) {
//       // If the topic doesn't exist, or there's no listeners in queue, just leave
//       if(!hOP.call(topics, topic)) return;
// 
//       // Cycle through topics queue, fire!
//       topics[topic].forEach(function(item) {
//       		item(info !== undefined ? info : {});
//       });
//     }
//   };
// })();



/*when the data gets read in, we need to translate
to some better format than just paramId
zones should have name, desc,
setpoints should have named fields*/

// const po = function (method, body){
//         var ret = {
//             cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
//             credentials: 'same-origin', // include, same-origin, *omit
//             headers: {
//                 'user-agent': 'Mozilla/4.0 MDN Example',
//                 'content-type': 'application/json'
//             },
//             method: method, // *GET, POST, PUT, DELETE, etc.
//             mode: 'cors', // no-cors, cors, *same-origin
//             redirect: 'follow', // *manual, follow, error
//             referrer: 'no-referrer', // *client, no-referrer
//         }
//         if (body !== "") ret.body = body ;
//         return ret ;
//     }

    // function putZoneInfo(zoneIds){
    //
    // }

    // function putParam(p){
    //   putZones()
    //   // cl(p);
    //
    // }

  // function inRange (val, base, size){
  //   // cl(val, base, size);
  //   return (val >= base && val < base + size)
  // }

  // function ifud(val){
  //   return typeof val === 'undefined';
  // }

    // function ifArrUd(arr, ind, val){
    //   if(ifud(arr[ind])){
    //   // if (typeof arr[ind] === 'undefined'){
    //     arr[ind] = val;
    //     // cl(test);
    //   }
    //   // return typeof val === 'undefined';
    // }

/*now that we're getting the total current state
from the server, the zone and channel information
is handled differently. All we really need
to do is to go and get what we need.
 */

// function putAZone(pa){
//   if(ifud(dbVals.zones[pa.z]))
//     dbVals.zones[pa.z] = {
//       name: "",
//       description: "",
//       version: "",
//       setpoints: [],
//       snapshots: [],
//       channels: [],
//     };
//   if (pa.i === p.PID_ZONE_NAME){
//     dbVals.zones[pa.z].name = pa.d;
//   }
//   if (pa.i === p.PID_ZONE_DESCRIPTION){
//     dbVals.zones[pa.z].description = pa.d;
//   }
// }

// function getVersions(zone){
//   var versions = [];
//   for(let i = 240 ; i < 244 ; i++){
//     try{
//       versions[i - 240] = zone[i][p.PID_CONTROLLER_VERSION]
//     } catch(err){}
//   }
//   return versions;
// }

function getSetPoints(zone, sids){
  let setpoints = [];
  for(let i = 0 ; i < 8 ; i++){
    setpoints[i] = [];
    for(let j = 0 ; j < 10 ; j++){
      let id = p.PID_BASE_CONFIG_SETPOINTS + 10 * i + j;
      setpoints[i][sids[j]] = zone[255][id]
      // let ind =
    }
  }
  return setpoints;
}

function getSnapshots(unit, snaps){
  let snapshots = [];
  snaps.forEach((s, i)=>{
    snapshots[s] =unit[i];
  })
  return snapshots;
  // cl(snapshots);
}

function getIrrigationChannelData(ret, chan){
  let cd = ["irrigation_mode", "on_duration", "off_duration", "start_time_1", "start_time_2", "start_time_3", "start_time_4", "start_time_5", "start_time_6", "week_a_sun", "week_a_mon", "week_a_tue", "week_a_wed", "week_a_thu", "week_a_fri", "week_a_sat", "week_b_sun", "week_b_mon", "week_b_tue", "week_b_wed", "week_b_thu", "week_b_fri", "week_b_sat", "start_astroadjust", "start", "end_astroadjust", "end", "setting_1_threshold_light", "setting_1_threshold_temp", "setting_2_threshold_light", "setting_2_threshold_temp", "soil_moisture_input", "soil_moisture_threshold", "vpdacc_threshold", "tankSensor"];
  cd.forEach(field=>{
    let id = pi[1800].config_channels_configuration[field];
//     cl(chan[id]);
    ret[field]=chan[id];
  });
//   cl(ret);
//   cl(chan);
}

function getChannelData(ret, chan){
  // cl(ret);
  let jumps = [0, 0, getIrrigationChannelData, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  if (ret.channelType >= jumps.length) {return}
  let j = jumps[ret.channelType];
  // cl(ret.channelType);
  if ((j !== 0) && (j !== undefined)) {
//     cl(j + " type " + ret.channelType)
    j(ret, chan);
  }
//   cl(chan);
//   cl(ret);
}

function getAChannel(chan, chans){
  // cl(chans);
  let ret = [];
  chans.forEach((ch, i)=>{
    // cl(i);
    ret[ch] = chan[i];
  })
  try{
    getChannelData(ret, chan);
  }catch(e){cl("get channel data"); cl(e.message)}
  // cl(ret);
  return ret;
}

function getChannels(zone, base, chans){
  // cl("get", zone, base)
  let channels = [];
  for(let i = 0 ; i < 40; i++){
    // cl(i);
    let cid = 40 * base + i;
    channels[i] = getAChannel(zone[cid], chans);
    // if ((base*1 === 0) &&(i === 0)){
    //   cl(zone[cid])
    // }
  }
  // cl(channels);
  return channels;
}

function getAEcph(oneEcph, ecphs){
    let ret = [];
//     cl(oneEcph);
    ecphs.forEach((e, i)=>{
//         cl(e);
        ret[e] = oneEcph[i];
    });
//     cl(ret);
    return ret;
}

function getEcphs(zone, unit, ecphs){
//     cl("get");
// pb.config_ecph
/* z is an array of 256 zones,
 each is an array of 256 channels
 ch 0-39, 40-79, etc. are the channels on the units
 channels 240-243 are the unit settings
 ch 255 is the zone settings
 ecph is special!!!
 each of the 4 units has a range of ecph channels:
 192-199 is unit 0
 200-207 is 1
 208-215 is 2
 216-223 is 3
 this is where the ecphIndex is put
 get the ecph configs for this unit*/
//     cl(zone[192 + 8 * unit]);
//     cl(z[zone]);
//     cl(unit);
//     cl(ecphs);
    let ecphRet = [];
    for (let i = 0 ; i < 8 ; i++){
        let ecphId = 192 + 8 * unit + i;
        ecphRet[i] = getAEcph(zone[ecphId], ecphs);
    }
//     cl(ecphs);
    return ecphRet;
}

function getUnits(zone, snaps, chans, ecphs){
  // cl("get units");
  let units = [];
  for(let i = 240 ; i < 244 ; i++){
    try{
//       cl(zone[i]);
      // let version = zone[i][p.PID_CONTROLLER_VERSION]
      // cl(version);
      // cl(p.PID_CONTROLLER_VERSION);
      var version, snapshots, channels, ecph ;
      // try{
        // cl(i);
        // cl(zone[i]);
        version = zone[i][p.PID_CONTROLLER_VERSION];
      // } catch(e){cl(e.message)}
      // try{
        snapshots = getSnapshots(zone[i], snaps); // getSnapshots(zo, snaps),
      // } catch{cl("snapshots")}
      // try{
        channels = getChannels(zone, i - 240, chans);
      // } catch{cl("channels")}
      // try{
        ecph = getEcphs(zone, i-240, ecphs);
      // } catch{cl("ecph")}

      units[i - 240] = {
        version: version, // zone[i][p.PID_CONTROLLER_VERSION],
        snapshots: snapshots, // getSnapshots(zone[i], snaps), // getSnapshots(zo, snaps),
        channels: channels, // getChannels(zone, i - 240, chans),
        ecph: ecph, // getEcphs(zone, i-240, ecphs),
      }
      // cl(units);
    } catch(err){}
  }
  // cl(units);
  return units;
}

// function updateHistoryTime(newTime){
//   if (dbVals.historyTime < newTime){
//     dbVals.historyTime = newTime;
//     // cl(dbVals.historyTime);
//   }
// }

// function setHistory(res){
//   res.forEach(r=>{
//     updateHistoryTime(r.t)
//     // cl(r);
//   })
//   putSite(res)
//   cl(res)
// }

// function getHistory(){
//   cl("get history: " + dbVals.historyTime)
//   return doGetPost("/ngrest/s/" + dbVals.site +
//     "/t/" + dbVals.historyTime,
//     "GET", setHistory, {});
// }

// function setHistoryTimer(){
//   getHistory()
//   setInterval(getHistory, 30000)
// }

function putHistoryTime(){
  // cl(z[255][255][p.PID_BASE_CONFIG_TIME])
//   dbVals.historyTime = dbVals.z[255][255][p.PID_BASE_CONFIG_TIME];
//   setHistoryTimer();
}

function getMeasures(zo, meas){
/*temp units is 11 in channel configs
pb.zone_configuration_settings = 4666
pi[1800].zone_configuration_settings = [];//4666
let id = getId(p.PID_BASE_CONFIG_SETPOINTS, 0,
  pi[1800].config_setpoints, "coolSetpoint")

OK, here's one of the first stupid mistakes
each zone_configuration_settings has *2* entries
0 is timestamp, 1 is content

*/
  let measRet = {};
  meas.forEach((m, i)=>{
    let id = p.PID_BASE_CONFIG_ZONE_SETTINGS + 2 * i + 1;
    measRet[m.n] = m.u[zo[255][id]]
  })
  return measRet;
}

function cleanZ(){
/* for some reason, dbVals.z is getting zone info only for a bunch of bogus zones.
 go through the object, removing references to zones that don't have data for channel 240: the zone master
 for that zone*/
  let z2 = [];
 cl(dbVals.z);
  dbVals.z.forEach((z, i)=>{
    if (z[240] !== undefined){// must have setting for unit 0
//       cl(z[240]);
      if(z[240][1] !== undefined){// must have first snapshot value
        z2[i] = z;
      }
    }
  });
//  cl(z2);
 dbVals.z = z2;
}

function getZoneInfo(){
/* the current state is loaded into z
fill in the dbVals.zones structure*/
//   cl(z);
//   cleanZ();
  let snaps = makeSnaps();
  let chans = makeChans();
  let sids = makeSids();// Setpoint IDs
  let meas = makeMeasures();
  let ecphs = makeEcphs();
  // cl(snaps);
//   cl (dbVals.z);
  dbVals.z.forEach((zo, i)=>{
//     cl(zo[0]);
    // let versions = getVersions(zo);
    // cl(versions);
    // cl(zo[240]);
    // cl(zo[255]);
    if ((i < 255) && (zo[0])){
      try{
        dbVals.zones[i] = {
          name: zo[255][p.PID_ZONE_NAME],
          description: zo[255][p.PID_ZONE_DESCRIPTION],
          setpoints: getSetPoints(zo, sids),
          units: getUnits(zo, snaps, chans, ecphs),
          measures: getMeasures(zo, meas)
          // units: [],
          // versions: getVersions(zo),
          // snapshots: getSnapshots(zo, snaps),
          // channels: [],
        }
      }catch{}
    }
    // cl(z[255]);
    // cl("zone" + i.toString());
    // cl(dbVals.zones);
  })
  // putHistoryTime();
  // cl(dbVals.zones[0].setpoints[0].coolSetpoint);
}

function makeSids(){
  return ["setpointIndex", "modified",
    "enabled", "startTimeOfDay", "astroAdjust",
    "rampMinutes", "heatSetpoint", "coolSetpoint",
    "humidifySetpoint", "dehumidifySetpoint"];
}

// function putASetpoint(pa){
//   var sids = ["setpointIndex", "modified",
//     "enabled", "startTimeOfDay", "astroAdjust",
//     "rampMinutes", "heatSetpoint", "coolSetpoint",
//     "humidifySetpoint", "dehumidifySetpoint"];
//   var rel = pa.i - p.PID_BASE_CONFIG_SETPOINTS;
//   var ind = Math.floor(rel / 10);
//   var pid = rel % 10;
//   if(ifud(dbVals.zones[pa.z].setpoints[ind]))
//     dbVals.zones[pa.z].setpoints[ind] = [];
//   dbVals.zones[pa.z].setpoints[ind][sids[pid]] = pa.d;
// }

// function putAController(pa){
//   // dbVals.zones[pa.z].versions
//   if(pa.i === p.PID_CONTROLLER_VERSION){
//     dbVals.zones[pa.z].version = pa.d;
//   }
// }

/*the structure that we're after is:
dbVals.zones[zoneid] = {
name:
description:
version:
setpoints: [0..7] {
setpointindex:
enabled:, ec.
}
}*/

function makeSnaps(){
  var snaps = [];
  snaps[6] = "temperatureStage";
  snaps[7] = "humidityStage";
  snaps[8] = "heatSetpoint";
  snaps[9] = "coolSetpoint";
  snaps[10] = "humidifySetpoint";
  snaps[11] = "dehumidifySetpoint";
  snaps[18] = "lowAlarm";
  snaps[19] = "highAlarm";
  snaps[20] = "lowInTemperatureAlarm";
  snaps[21] = "highInTemperatureAlarm";
  snaps[22] = "inTemperatureSensorAlarm";
  snaps[23] = "inTemperature";
  snaps[24] = "outTemperature";
  snaps[27] = "inHumidity";
  snaps[28] = "outHumidity";
  snaps[30] = "differentialPressure";
  snaps[31] = "co2";
  snaps[32] = "inLight";
  snaps[33] = "outLight";
  snaps[34] = "windSpeed";
  snaps[35] = "windDirection";
  snaps[36] = "rain";
  snaps[37] = "snow";
  snaps[81] = "barometricPressure";
  return snaps;
}

function putZones2(params){
  getZoneInfo();
  return
  // var snaps = makeSnaps();
  // params.forEach(pa=>{
  //   if(inRange(pa.i, p.PID_BASE_CONFIG_ZONES, 2)){
  //     putAZone(pa);
  //   }
  // });
  // params.forEach(pa=>{
  //   if(inRange(pa.i, p.PID_BASE_CONFIG_SETPOINTS, 80)){
  //     putASetpoint(pa);
  //   }
  //   if(inRange(pa.i, p.PID_BASE_CONFIG_CONTROLLERS, 4)){
  //     putAController(pa);
  //   }
  //   if(inRange(pa.i, p.PID_BASE_SNAPSHOTS, 100)){
  //     putASnapshot(snaps, pa);
  //   }
  // });
  // cl(dbVals);
}

// function putZones(params){
//   var snaps = makeSnaps();
//   params.forEach(pa=>{
//     if(inRange(pa.i, p.PID_BASE_CONFIG_ZONES, 2)){
//       putAZone(pa);
//     }
//   });
//   params.forEach(pa=>{
//     if(inRange(pa.i, p.PID_BASE_CONFIG_SETPOINTS, 80)){
//       putASetpoint(pa);
//     }
//     if(inRange(pa.i, p.PID_BASE_CONFIG_CONTROLLERS, 4)){
//       putAController(pa);
//     }
//     if(inRange(pa.i, p.PID_BASE_SNAPSHOTS, 100)){
//       putASnapshot(snaps, pa);
//     }
//   });
//   cl(dbVals);
// }

function makeChans(){
  var chans = [];
  chans[104] = "position";
  chans[105] = "relay";
  chans[106] = "channelOverride";
  chans[107] = "analogOutput";
  chans[505] = "used";
  chans[506] = "isAnalog";
  chans[507] = "channelName";
  chans[508] = "channelType";
  return chans;
}

function makeMeasures(){
  var meas = [];
  meas[11] = {n: 'tempUnits', u: ["degF", "degC"]};
  meas[12] = {n: 'windUnits', u: ["mph", "kph"]};
  meas[13] = {n: 'lightUnits', u: ["w/m2", "klux", "uMol"]};
  meas[143] = {n: 'volUnits', u: ["l/ml", "gal/oz", "gal/ml"]}
  meas[144] = {n: 'ecUnits', u: ["uS", "CF", "PPM"]};
  return meas;
}

function makeEcphs(){
    var ecphs = [];
    ecphs[p.PID_BASE_CONFIG_ECPH + 0] = "ecphIndex";
    ecphs[p.PID_BASE_CONFIG_ECPH + 2] = "name";
    ecphs[p.PID_BASE_CONFIG_ECPH + 4] = "alarmHoldTime";
    ecphs[p.PID_BASE_CONFIG_ECPH + 5] = "lowECThreshold";
    ecphs[p.PID_BASE_CONFIG_ECPH + 6] = "highECThreshold";
    ecphs[p.PID_BASE_CONFIG_ECPH + 7] = "highECDeviationThreshold";
    ecphs[p.PID_BASE_CONFIG_ECPH + 8] = "lowPHThreshold";
    ecphs[p.PID_BASE_CONFIG_ECPH + 9] = "highPHThreshold";
    ecphs[p.PID_BASE_CONFIG_ECPH + 10] = "highPHDeviationThreshold";
    return ecphs;
}


// function putAChannel(zone, chans, pa){
//   // cl(pa);
//   if(ifud(dbVals.zones[zone].channels[pa.c])){
//     dbVals.zones[zone].channels[pa.c] = [];
//   }
//   dbVals.zones[zone].channels[pa.c][chans[pa.i]] = pa.d;
//   // cl(pa);
// }
// gets = [104, 105, 107, 506, 507, 508]
// pi[1800].snapshot_channels["position"] = 104
// pi[1800].snapshot_channels["relay"] = 105
// pi[1800].snapshot_channels["channelOverride"] = 106
// pi[1800].snapshot_channels["analogOutput"] = 107
// pi[1800].channels_configuration["isAnalog"] =  506
// pi[1800].channels_configuration["channelName"] =  507
// pi[1800].channels_configuration["channelType"] =  508

// function putChannels(resp, params){
//   var chans = makeChans();
//   resp.forEach(pa=>{
//     putAChannel(params.zone, chans, pa);
//   });
//   // cl(params);
// }

// function doGetPost(purl, cmd, func, params){
//   return new Promise((res, rej)=>{
//     let url = baseUrl + purl;
//     // let cmd = (ifud(params.Post)) ? "GET" : "POST";
//     let pv = ((cmd === "POST") || (cmd === "PUT")) ?
//       JSON.stringify(params.Post) : "";
//     // cl(pv);
//     let httpObj = po (cmd, pv) ;
// //     cl(httpObj);
//     return fetch(url, httpObj).then(
//       response => response.json().then(
//       resp=>{
//         func(resp, params);
//         res(resp);
//       }), e=>{rej(e)});
//   })
// }

// function doPost(purl, func, params){
//   return new Promise((res, rej)=>{
//     let url = baseUrl + purl;
//     let httpObj = po ("POST", params.Post) ;
//     return fetch(url, httpObj).then(
//       response => response.json().then(
//       resp=>{
//         func(resp, params);
//         res(resp);
//       }), e=>{rej(e)});
//   })
// }

// function getZones(params){
//   return doGetPost("/ngrest/s/0/zones", putZones, params);
// }

// function putASnapshot(snaps, pa){
//   dbVals.zones[pa.z].snapshots[snaps[pa.i]] = pa.d;
// }

// function getChannels(params){
//   return doGet("/ngrest/s/0/z/" + params.zone + "/channeloverview0", putChannels, params);
// }

// function getTime(){
//   return (new Date()).getTime();
// }

// function nop(res){
//   cl(res);
// }

function addChans(){
  Object.keys(dbVals.z).forEach(k=>{
    let z=dbVals.z[k]
    for(let i=0;i<40;i++){
      if(!z[i]){z[i]={}}
    }
  })
}

function putValArray(vals){
//   cl(vals)
  vals.forEach(r=>{
    try{// all this is just to get the data put away in z
      dbVals.z[r.z][r.c][r.i] = r.d;
    } catch (err){
      try {
        dbVals.z[r.z][r.c] = [];
        dbVals.z[r.z][r.c][r.i] = r.d;
      } catch (err){
        try {
          dbVals.z[r.z] = [];
          dbVals.z[r.z][r.c] = [];
          dbVals.z[r.z][r.c][r.i] = r.d;
        } catch (err){
          cl(err)
        }
      }
    }
  })
  addChans()
}

function putSite(resp, params){
//   cl("putsite")
/*this processes *all* the current params
into an array of arrays, organized by
z, c, and i*/
  // 0: {c: 240, d: "0", i: 1359, z: 0}
  // let start = getTime();
  // cl(z)
//   cl(resp);
//   cl("length: " + resp.length);
//   for (let i = 0 ; i < resp.length ; i++){
//     if (resp[i].i == 5022) cl(resp[i]);
//   }
//   cl("putsite")
//   cl(dbVals)
//   cl(resp)
//   cl("putSite")
  dbVals.z=[]
  putValArray(resp)
  
//   resp.forEach(r=>{
//     try{// all this is just to get the data put away in z
//       dbVals.z[r.z][r.c][r.i] = r.d;
//     } catch (err){
//       try {
//         dbVals.z[r.z][r.c] = [];
//         dbVals.z[r.z][r.c][r.i] = r.d;
//       } catch (err){
//         try {
//           dbVals.z[r.z] = [];
//           dbVals.z[r.z][r.c] = [];
//           dbVals.z[r.z][r.c][r.i] = r.d;
//         } catch (err){
//           cl(err)
//         }
//       }
//     }
//   })
  
//   cl(dbVals.z)
  // cl("site put");
  putZones2();
//   cl("put")
  dbVals.gotSite = true;
//   cl(dbVals)
  // cl(dbVals);
  // cl(getTime() - start);
  // cl(z)
  // cl(resp);
//   cl(dbVals.z)
//   cl(dbVals.z[3])
//   cl("putsite done")
}

// function getSite(params){
//   cl("site " + globs.token);
//   dbVals.site = params.site;
//   return doGetPost("/auth/ngrest/s/" + params.site +
//     "/siteoverview1",
//     "GET", putSite, params);
// }

function postPacks(params){
//   let url = `/auth/ngrest/s/${}`
//   cl(url);
  cl(params);
//  return doGetPost("/auth/ngrest/stream", "POST", nop, params);
}

function putStream(params){
//   cl(params);
  if(dbVals.z !== undefined){
    let z = params.z;
    params.p.forEach(p=>{
      let val = dbVals.z[z][p.c][p.i];
      dbVals.z[z][p.c][p.i] = p.d;
      cl(`id: ${p.i} was ` + val + ", now " + p.d);
  //     cl(p);
    });
  }
}

function getId(base, ind, table, key){
  // cl(z);
  return base + table[key];
}

// function paramUpdate(r){
//   // cl("updated");
// }

var getDBVal=(dbv)=>{
//   cl(dbv)
  return(typeof(dbv)=="object")?dbv.val:dbv
}

var getZValue=(z, c, i)=>{
  let d = null;
  try{
    let zone = dbVals.z[z];
    try{
      let chan = zone[c];
      try{
//         if((z==0)&&(c==0)){cl(i,chan[i])}
        return getDBVal(chan[i])
//         if(typeof(chan[i])=="object"){
//           return chan[i].val
//         }else{
//           return chan[i];
//         }
      }catch{}
    }catch{}
  }catch{}
}

var putZValue=(z, c, i, d)=>{
//   if(i==781){
//     cl([z, c, i, d])
//   }
  /*returns true if this is a *changed* value */
  // cl("put: "+z+"-"+c+"-"+i+"-"+d)
  // return true;
//   cl(z)
//   cl([z, c, i, d])
// cl("putz")
//   cl(dbVals)
//   if(i==781){
//     cl([z,c,i,d])
//   }
  try{
    let zone = dbVals.z[z];
    if(!zone){dbVals.z[z]={};zone=dbVals.z[z]}
    try{
//       let chan = (zone||{})[c]||[];
      let chan=zone[c]
      if(!chan){zone[c]={};chan=zone[c]}
//       cl(chan)
      try{
//         cl("try1")
//         cl(chan[i])
//         cl(typeof(chan[i]))
        let ch=((chan[i]!=null)&&(typeof (chan[i])=="object"))?chan[i].val:chan[i]
//         cl("try1")
        ch=(ch==undefined)?ch:ch.toString()
//   cl(`putZ: ${z}-${c}-${i}-${d} was ${ch}`)
//         cl("try1")
        d=(d==undefined)?"0"/*d*/:d.toString()// undefined is changed to 0
// the sync process treats undefined as 0
//         cl(d)
//         cl(ch,d,z,c,i)
//         cl("try2")
//         cl(ch)
//         cl(d)
//         cl([ch,d])
//         cl([ch, d.toString()])
//         let res=
//         if ((typeof ch=="undefined")||(ch.toString() !== d.toString())){
//         if(i==508){
//           cl(ch,d)
//         }
//         cl(d)
//         cl(ch,d)
        if(ch!=d){
//           cl([i,ch,d])
          chan[i] = d;
//           cl(chan)
//             cl(dbVals.z[0][4][969])
//           cl("true",d)
          return true;
        }
      }catch{
        cl("Exception in putZValue")
      }
    }catch(er){cl("exception 2 " + er)}
  }catch(er){cl("exception 3 - " + er)}
  return false;
}

  var getZoneControllers=(zInd)=>{
//     cl(zInd)
//     console.trace()
    let tab="config_controllers"
    let col="isInNetwork"
    let base = pInd[1800][tab][0];
    let pid = base+pi[1800][tab][col];
    let conts=[]
    for (let i=0;i<4;i++){
      conts.push(+getZValue(zInd, 240 + i, pid)||0)
    }
//     cl(zInd,conts)
//     console.trace()
    conts[0]=1
    return conts
//     cl(conts)
  }

var checkSendArray=(parms,virtual,gatewayType,controllerId)=>{
//   cl(parms)
  let p2=[]
  parms.forEach(p=>{
    if(putZValue(p.z,p.c,p.i,p.d)){p2.push(p)}
  })
  cl(p2)
  return sendArray(p2,virtual,gatewayType,controllerId)// returns a Promise now
}

// var updateDbVirtual=(par)=>{
//   cl(par)
// }

// var lazy.array=[];
// var lazy.timer=null;

var lazy={array:[],timer:null,r:null,e:null}

var getLazyArray=()=>{
  let sndArray=lazy.array.slice(0);// copy lazy.array
//   cl(sndArray)
  lazy.array=[];
  return sndArray
}

var showChanTypes=(arr)=>{
  let arr2=[]
  arr.forEach(a=>{
    if(a.i==152){arr2.push(a)}
  })
//   cl(arr2)
}

var sendArrayNow=(sndArray,gwType,controllerId,virt,r,e)=>{
//   showChanTypes(sndArray)
//   cl(sndArray)
  let par = {
    cmd: "data01",
    s: globs.userData.session.siteId,
    gwType:gwType||1800,
    controllerId:controllerId||"",
    user: globs.userData.session.userId,
    sessionId: globs.userData.session.sessionId,
    virtual:virt||false,
    // zone: z,
//     token: globs.token,
    params: sndArray,
  };
//   cl(par);
//   if(lazy.v){updateDbVirtual(par)}
//   cl(sndArray)
//   cl(par)
  sendSocket(par).then(re=>{
//     cl("send success")
//     cl(re)
    if(par.virtual){
      putValArray(par.params)
    }
//     cl("response")
//     cl(re)
    r.forEach(r=>r(re))// array now
//     r(re)
  },
    er=>e(er)
  );
}

var sendArrayNowPromise=(sndArray,gwType,controllerId,virt)=>{
  return new Promise((r,e)=>{
//     cl(sndArray)
    sendArrayNow(sndArray,gwType,controllerId,virt,[r],e)
  })
}

var cleanSendArray=(arr)=>{
  let kv={}
  arr.forEach(a=>{
//     if(a.i==722){cl(a)}
//     kv[`${a.z}-${a.c}-${a.i}`]=a
    if(a.d||(a.d==0)){
      kv[`${a.z}-${a.c}-${a.i}`]=a
    }else{
//       cl(a)
    }
  })
  let keys=Object.keys(kv).sort((a,b)=>{
    if(a>b){return 1}
    if(a<b){return -1}
    return 0
  })
  return keys.map(k=>{return kv[k]})
}

var lazySendArray=()=>{
//   cl("lazy send")
  let sndArray=getLazyArray()
//   cl(sndArray)
  sendArrayNow(cleanSendArray(sndArray),lazy.gwType,lazy.controllerId,lazy.v,lazy.r,lazy.e)
//   let sndArray=lazy.array.slice(0);// copy lazy.array
//   lazy.array=[];
//   cl(lazy)
//   cl(globs.userData);
}

function virtualAddRTD(arr){
  let cal2rtd={"39":23, "40":27, "41":24, "42":28, "43":31, "44":33, "45":34, "47":38, "48":39, 
    "49":40, "50":41, "51":42, "52":48, "53":49, "54":50, "55":51, "56":52, "57":43, 
    "58":44, "59":45, "60":46, "61":47, "89":80, "91":81, "127":32, "128":30, "139":35, 
    "140":36, "141":37}
    let base = p.PID_BASE_CONFIG_CONTROLLER_SETTINGS
    let adds=[]
    arr.forEach(a=>{
      let pid= (a.i-base-1)/2
      let mapV=cal2rtd[pid]
//       cl([pid,mapV])
      if(mapV){
        adds.push({
          i:mapV,
          z:a.z,
          c:240,
          d:a.d,
          t:a.t,
        })
      }
    })
    return arr.concat(adds)
}

// function sendArray800(arr){
//   return new Promise((r,e)=>{
//     setTimeout(e=>{r(true)},500)
//     cl(arr)
// //     r(true)
//   })
// }

function sendArray(arr,virtual,gwType,controllerId,localOnly){
//   cl("sendArray")
//   cl(arr[5])
//   cl(arr)
//   cl(virtual)
//   cl(gwType)
//   cl(controllerId)
//   cl(localOnly)
//   cl(globs)
//   gwType=gwType||1800
//   if(!virtual&&(gwType==800)){return sendArray800(arr)}
// now, if we get an "expiredToken" response, login
// again, and send the message again
// now, this gets appended to the lazy.array, and is not actually sent until 2 secs later  
/*array elements:
5:
c: 0
d: 0
f: 1
i: 786
t: 1614353676
z: 0
*/
//   cl(virtual,localOnly)
  if(virtual){arr=virtualAddRTD(arr)}// add fake rtd data for calibrations
  return new Promise((r,e)=>{
    if (!arr.length){r()}
    if(!lazy.r){lazy.r=[]}
    lazy.r.push(r)
    lazy.e=e
    lazy.v=virtual
//     lazy.z=arr[0].z
    lazy.gwType=gwType
    lazy.controllerId=controllerId
//     cl(arr)
    if(!lazy.array.length){
      if(!localOnly){
//         cl("setting timer")
//         cl(lazy.array)
        lazy.timer=setTimeout(lazySendArray, /*200*/0);
      }
    }
    arr.forEach(e=>{
      lazy.array.push(e);
    });
//     cl(lazy.array)
    if(localOnly){r()}
  })
}

function setAVal(s, z, c, i, d){
/* packs is just an array of params
 now this will be sent back through the websocket
{command: command, site: site, user: user, zone: zone, token: JWT, params: [{c: channel, i: id, t: time, p: param}]}. the initial Command is "data00"
*/
  let pack = {
//     z: z,
    c: c,
    i: i,
    t: getTime(),
    d: d,
    u: globs.userData.session.userId,
  }
  let par = {
    command: "data01",
    site: globs.siteid,
    user: 0,
    zone: z,
    token: globs.token,
    params: [pack],
  };
//   let packs = [pack];
  cl(par)
  // let obj = {obj: packs};
//   let params = {Post: par};
  // sendWS(par);
  sendSocket(par).then(r=>{
    cl(r);
  });
//   return doGetPost("/ngrest/s/" + s +
//     "/params",
//     "POST", paramUpdate, params);
}

function getParam(z,c,id){
    let ret=dbVals.z[z][c][id]
    return (ret)?ret:0
  }
  
function putParam(z,c,id, t,parms,val){// tab refers to our local (channel, id) tables
    parms.push({
      c:c,// zone wide
      d:val,
      f:2,// 2 means from cloud
      i:id,
      t:t,
      z:z,
    })
  }
  
function openCurSite(){
  if (!dbVals.initted){
    let ws = openWS(constant.wsUrl);// this can't be called until we're logged in
    ws.then(r=>{cl("ws: "+show("main"))})
    dbVals.initted = true;
  }
}

// function initNet(){
//   let port=3106
//   let host="0.0.0.0"
//   console.log(ip_local())
// //   console.log("init net")
// //   net.createServer(sock=>{
// //     cl("got sock")
// //   }).listen(port,host)
// }
// 
// function ip_local()
// {
//  var ip = false;
//  window.RTCPeerConnection = window.RTCPeerConnection || window.mozRTCPeerConnection || window.webkitRTCPeerConnection || false;
// 
//  if (window.RTCPeerConnection)
//  {
//    console.log("got rtc")
//   ip = [];
//   var pc = new RTCPeerConnection({iceServers:[]}), noop = function(){};
//   pc.createDataChannel('');
//   pc.createOffer(pc.setLocalDescription.bind(pc), noop);
// 
//   pc.onicecandidate = function(event)
//   {
// //    console.log("got rtc event")
// //    console.log(event)
//    if (event && event.candidate && event.candidate.candidate)
//    {
//     var s = event.candidate.candidate.split('\n');
//     ip.push(s[0].split(' ')[4]);
//    }
//   }
//  }
// 
//  return ip;
// }
  
function init(){
//   console.trace()
/* this will guarantee that everything is loaded.
 returns a promise
 // assumes that we've already got a token from auth
 */
// cl("init");
//     cl(`Initting: ${dbVals.initted}`)
    if (!dbVals.initted){
//       cl("not initted");
      cl("Start Init: " + show("main"))//cl(`time: ${getTime()}`)
      let ws = openWS(constant.wsUrl);// this can't be called until we're logged in
//       cl(show("main"))//cl(`time: ${getTime()}`)
//       cl("initting");
//       start("ws");
//       ws.then(r=>{
//         stop("ws");
//       });
//       let gs = getSite({site: 0});
//       let gc = getConfigs();
//       let pa = getPages();
      let fp = getFuiPages();
      let gt = getTemplates();
      setRestTimeout("web socket", ws)
      setRestTimeout("Fui page", fp)
      setRestTimeout("template", gt)
//       cl(show("main"))//cl(`time: ${getTime()}`)
      
//       ws.then(r=>{cl(`ws time: ${getTime()}`)})
//       fp.then(r=>{cl(`fp time: ${getTime()}`)})
//       ws.then(r=>{cl("ws: "+show("main"))})
//       fp.then(r=>{cl("fp: "+show("main"))})
//       gt.then(r=>{cl("gt: "+show("main"))})
      
      dbVals.initted = true;
//       cl("promised")
//       cl(show("main"))//cl(`time: ${getTime()}`)
      return Promise.all([ws, fp, gt]);// , gs , gc, pa
    } else {
//       return Promise.resolve();
      return checkNewSite()
//       return new Promise((res, rej)=>{
//         res([{command: "gotcursite00"}]);
//       })
    }
    cl("init done");
//     Promise.all([gc, gs]).then(r=>{
//       // cl(dbVals)
//       this.setState({loaded: true});
//     })
}


export {init, postPacks, putStream, putHistoryTime, dbVals,
  getId, setAVal, /*events, */getZoneInfo, putSite, putValArray,
  openCurSite, getDBVal,
  getZValue, putZValue, sendArray, checkSendArray,getParam,putParam,
  getZoneControllers,getLazyArray,sendArrayNowPromise
}// getSite,
