import React from 'react';
import C18Anchor00 from './C18Anchor00'
import C18Select01 from './C18Select01'
import C18Button00 from './C18Button00'
import {loadZonesInfo,getZoneInfo,setSiteZoneTypes,loadSiteData,loadGatewaysInfo,
  saveTable} from './C18utils'
import {dbVals,sendArray} from '../../components/utils/http';
import {pInd} from '../../components/utils/paramIds'
import {wsTrans,getParamId2} from '../utils/utils'
import {cl,globs,getRandomString} from '../../components/utils/utils';

var c={
  MBTY_COIL:0x00,// bits 0,1
  MBTY_DISC:0x01,
  MBTY_HOLD:0x02,
  MBTY_INP:0x03,
  MBTY_MASK:0x03,
  STCO_STATUS:0x00,// bit 2
  STCO_CONFIG:0x04,
  STCO_MASK:0x04,
  ANDI_ANA:0x00,// bit3
  ANDI_DIG:0x08,
  ANDI_MASK:0x08,
  PRIO_0:0x00,// bits 4,5 - highest priority
  PRIO_1:0x10,
  PRIO_2:0x20,
  PRIO_3:0x30,
  MB_RD_COIL:1,
  MB_RD_DISC:2,
  MB_RD_HOLD:3,
  MB_RD_INP:4,
  MB_WR_COIL:5,
  MB_WR_HOLD:6,
  MB_WR_COILS:15,
  MB_WR_HOLDS:16,
}

class MbBuf{

  #crc16=(buffer,len)=>{
    var crc = 0xFFFF;
    var odd;
    for (var i = 0; i < len; i++) {
      crc = crc ^ buffer[i];
      for (var j = 0; j < 8; j++) {
        odd = crc & 0x0001;
        crc = crc >> 1;
        if (odd) {
            crc = crc ^ 0xA001;
        }
      }
    }
    return crc;
  }

  constructor(size){
    this.buf=new Uint8Array(size)
    this.ind=0
  }

  addByte(bVal){
    this.buf[this.ind++]=bVal
  }

  addReg(regVal){
    this.buf[this.ind++]=(regVal>>8)&0xFF
    this.buf[this.ind++]=regVal&0xFF
  }

  getReg(ofs){
    return (this.buf[ofs]<<8)+this.buf[ofs+1]
  }

  addCRC(){
    let crc=this.#crc16(this.buf,this.ind)
    cl(crc)
    this.buf[this.ind++]=crc&0xFF
    this.buf[this.ind++]=(crc>>8)&0xFF
  }

  getBuf(){
    return Array.from(this.buf)
  }

  setBuf(arr){
    this.buf=new Uint8Array(arr)
//     cl(this.buf)
  }

  showBuf(){
    let res=Array.from(this.buf).map(b=>{return b.toString(16)})
//     cl(res.join(" "))
  }
}

class C18ModbusGP01 extends React.Component{
  constructor(props) {
    super(props);
    // cl(props)
    this.state={
      loaded:false,
      devType:0,
      mbAddr:"",
      devType:"",
      regVals:[],
    }
//     this.loadInfo()
  }

  devTypes={
    "xy-md02":{
      name:"XY-MD02",
      regs:[
        {
          name:"Temperature",
          addr:0x0001,
          type:c.MBTY_INP|c.STCO_STATUS|c.ANDI_ANA|c.PRIO_0
        },
        {
          name:"Humidity",
          addr:0x0002,
          type:c.MBTY_INP|c.STCO_STATUS|c.ANDI_ANA|c.PRIO_0
        },
        {
          name:"Address",
          addr:0x0101,
          type:c.MBTY_HOLD|c.STCO_CONFIG
        },
        {
          name:"Baud Rate",
          addr:0x0102,
          type:c.MBTY_HOLD|c.STCO_CONFIG
        },
        {
          name:"Temp Cal",
          addr:0x0103,
          type:c.MBTY_HOLD|c.STCO_CONFIG
        },
        {
          name:"Hum Cal",
          addr:0x0104,
          type:c.MBTY_HOLD|c.STCO_CONFIG
        },
      ]

    }
  }

  componentDidMount(){
    this.loadInfo()
  }

  loadInfo=async()=>{
    let pa=this.props.parms
    let devType=Object.keys(this.devTypes)[0]
    await loadGatewaysInfo()
    cl(this.props)
    let z=globs.zonesInfo.info.filter(z=>{return z.zoneId==pa.zone})[0]
    cl(globs.gatewaysInfo)
    let gw=globs.gatewaysInfo.info.filter(gw=>{return gw.gatewayId==z.gatewayId})[0]
    cl(gw)
    this.setState({loaded:true,devType:devType,clientId:gw?.clientId})
  }

  setRegVal=(ind,val)=>{
    let regVals=this.state.regVals.slice(0)
    regVals[ind]=val
    this.setState({regVals:regVals})
  }

  readReg=async(vals)=>{
    let st=this.state
    this.setRegVal("")
    cl(vals)
    let reg=this.devTypes[st.devType].regs[vals.reg]

    let cmd=[c.MB_RD_COIL,c.MB_RD_DISC,c.MB_RD_HOLD,c.MB_RD_INP]
      [reg.type&c.MBTY_MASK]
//     cl(cmd)
    let mbBuf=new MbBuf(6)// size of the message
    mbBuf.addByte(st.mbAddr)
    mbBuf.addByte(cmd)
    mbBuf.addReg(reg.addr)
    mbBuf.addReg(1)// get 1 register
//     mbBuf.addCRC()
    mbBuf.showBuf()
//     let buf=mbBuf.getBuf()
//     cl(buf)
    let query={
      cmd:"mbRead",
      buf:mbBuf.getBuf(),
      clientId:st.clientId,
    }
//     cl(query)
    let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/modbusGp",
      method: "retrieve", sessionId: globs.userData.session.sessionId,
      body: query})
    cl(res)
    if(res.data){
      mbBuf.setBuf(res.data)
      this.setRegVal(vals.reg,mbBuf.getReg(3))
    }
//     cl(res)
//     cl(val)
  }

  writeReg=async(vals)=>{
    let st=this.state
    let reg=this.devTypes[st.devType].regs[vals.reg]
    let cmd=[c.MB_WR_COIL,c.MB_RD_DISC,c.MB_WR_HOLD,c.MB_RD_INP]
      [reg.type&c.MBTY_MASK]
    let mbBuf=new MbBuf(6)// size of the message
    mbBuf.addByte(st.mbAddr)
    mbBuf.addByte(cmd)
    mbBuf.addReg(reg.addr)
    mbBuf.addReg(st.regVals[vals.reg])// get 1 register
    mbBuf.showBuf()
    let query={
      cmd:"mbRead",
      buf:mbBuf.getBuf(),
      clientId:st.clientId,
    }
    cl(query)
//     return
    let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/modbusGp",
      method: "retrieve", sessionId: globs.userData.session.sessionId,
      body: query})
//     mbBuf.setBuf(res.data)
//     let regVals=st.regVals.slice(0)
//     regVals[vals.reg]=mbBuf.getReg(3)
//     this.setState({regVals:regVals})
  }

  regVal=(vals)=>{
    cl(vals)
    let st=this.state
    let regVals=st.regVals.slice(0)
    regVals[vals.ind]=vals.val
    this.setState({regVals:regVals})
  }

  onChange=(type,vals)=>{
    let cmds={regVal:this.regVal,readReg:this.readReg,
      writeReg:this.writeReg}
    if(cmds[type]){return cmds[type](vals)}
    switch(type){
      case "setState":
        this.setState(vals)
        break
//       case "readReg":
//         cl(vals)
//         break
//       case "writeReg":
//         cl(vals)
//         break
      case "regVal":
        cl(vals)
        break
    }
  }

  showDeviceTypeSelect=()=>{
    let st=this.state
    let opts=[
      {v:"xy-md02",t:"XY-MD02"},
    ]
    return(
      <C18Select01 parms={{
        label:"Select DeviceType",
        valueName:"devType",
        devType:(st.devType||0),
        opts:opts,
        onChange:(e,v)=>{this.onChange("setState",v)}//this.onChange,
      }}/>
    )
  }

  showMbAddr=()=>{
    let st=this.state
    return(
      <>
        <label htmlFor="mbAddr">Enter MB Addr</label>
        <input id="mbAddr" type="text"
          value={st.mbAddr}
          onChange={e=>{this.onChange("setState",{mbAddr:e.target.value})}}
        />
      </>
    )
  }

  showReg=(reg,i)=>{
//     cl(reg)
    let st=this.state
    let isWrite=((reg.type&c.STCO_MASK)==c.STCO_CONFIG)
    return(
      <React.Fragment key={i}>
        <br/>
        <div style={{width:40,display:"inline-block",
          fontSize:18,fontWeight:700,textAlign:"right",
          marginRight:10,
        }}>
          {reg.addr}
        </div>
        <div style={{width:150,display:"inline-block",
          fontSize:18,fontWeight:700}}>
          {reg.name}
        </div>
        <C18Button00 style={{marginRight:10}}
        type="button" className="filled"
          onClick={e=>{this.onChange("readReg",{reg:i})}}
        >Read</C18Button00>
        <input style={{display:"inline-block"}} id="mbAddr" type="text"
          value={(st.regVals[i]==null)?"":st.regVals[i]}
          onChange={e=>{this.onChange("regVal",
            {val:e.target.value,ind:i})}}
        />
        {isWrite&&
          <C18Button00 type="button" className="filled"
            onClick={e=>{this.onChange("writeReg",{reg:i})}}
          >Write</C18Button00>
        }<br/>
      </React.Fragment>

    )
  }

  showRegisters=()=>{
    let st=this.state
//     cl(this.devTypes)
//     cl(st.devType)
    let rows=this.devTypes[st.devType].regs.map((reg,i)=>{
      return this.showReg(reg,i)
    })
    return rows
  }

  showPage=()=>{
    return(
      <div>
      {this.showDeviceTypeSelect()}
      {this.showMbAddr()}
      {this.showRegisters()}
      </div>
    )
  }

  render(){
    if(this.state.loaded){
      return this.showPage()
//       return this.showTypes()
    }else{return<div>loading. . .</div>}
  }
}

export default C18ModbusGP01;
