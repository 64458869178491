import React from 'react';
import C18Anchor00 from './C18Anchor00'
import C18Button00 from './C18Button00'
import RepeatHours from './IDoser/RepeatHours'
import RepeatDayHours from './IDoser/RepeatDayHours'
import RepeatDays from './IDoser/RepeatDays'
import RepeatWeekDays from './IDoser/RepeatWeekDays'
import RepeatMonthDays from './IDoser/RepeatMonthDays'
import RepeatMonthWeek from './IDoser/RepeatMonthWeek'
import RepeatYearDay from './IDoser/RepeatYearDay'
import RepeatYearWeek from './IDoser/RepeatYearWeek'
import RepeatMinutes from './IDoser/RepeatMinutes'
import Select from 'react-select'
import {loadSitesInfo,loadZonesInfo,getZoneIndex,loadUsersInfo,getUserIndex,
  privs,notifyHandlers,acctFeature} from '../../usa/components/C18utils'
import {wsTrans} from '../utils/utils';
import {cl,globs,getTime,constant,ymdToDate,dateToDisplayDate,getRandomString,days,shortDays,weeks,months} from '../../components/utils/utils';

class C18SidebarTasks00 extends React.Component{
  constructor(props) {
    super(props);
//     cl(props)
    let nowStr= dateToDisplayDate(new Date(),"yyyy-mm-dd")
    this.state={
      mode:"sites",
      loaded:false,
      showNew:false,
      showComplete: true,
      dueDate:nowStr,
      handlers:[],
      tags:[],
    }
    this.tagOptions=[
      {value: "equipment", label: "equipment"},
      {value: "sensors", label: "sensors"},
      {value: "plants", label: "plants"},
      {value: "pests", label: "pests"},
      {value: "nutrients", label: "nutrients"},
      {value: "power", label: "power"},
      {value: "personnel", label: "personnel"},
      {value: "supplies", label: "supplies"},
      {value: "maintenance", label: "maintenance"},
      {value: "documentation", label: "documentation"},
      {value: "link4", label: "Link4"},
      {value: "other", label: "other"},
    ]
    this.repeatOpts={
      none: {t: "Does Not Repeat"},// minutes, from, to
      minutes: {t: "Every So Many Minutes"},// minutes, from, to
      hours: {t: "Every So Many Hours"},// hours
      dayhours: {t: "These Hours of the Day"},// days, hour flags
      days: {t: "Every So Many Days"},// days
      weekdays: {t: "These Days of the Week"},// weeks, day flags
      monthdays: {t: "These Days of the Month"},// months, month day flags
      monthweek: {t: "This Week and Day of the Month"},// months, day, week
      yearday: {t: "This Day of the Year"},// years, day of month, month
      yearweek: {t: "This Month, Week and Day of the Year"},// years, day, week, month
    }
    this.loadInfo()
    this.subscribe_refreshEvent=globs.events.subscribe("RefreshTasksEntries",this.loadInfo)
  }

  componentWillUnmount=()=>{
    this.subscribe_refreshEvent.remove();
  }

  loadInfo=async()=>{
    await loadUsersInfo()
    let users={}
    globs.usersInfo.info.forEach(u=>{
      users[u.userId]=u
    })
    this.userOptions=globs.usersInfo.info.map(ui=>{
      return{value:ui.userId,label:ui.name}
    })
    var query
    let pa=this.props.parms
//     cl(pa)
    var tag
    switch(pa.level){
      case "sites":
        query={}
        break
      case "site":
        query={site:pa.siteId}
        break
      case "zone":
        query={zone:pa.zoneId}
        break
      case "config":
        tag=`${pa.pageType}-${pa.zuci}`
        query={zone:pa.zoneId,tags:{$in:[tag]}}
        break
    }
    let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/tasks", method: "retrieve",
      sessionId: globs.userData.session.sessionId, body: query,})
    let tasks=this.sortTasks(res.data)
    cl(this.state.tags)
    this.setState({loaded:true,users:users,tasks:tasks})
  }

  sortTasks=(tasks)=>{
    return tasks.sort((a,b)=>{
      let af = a["status"]=="done"
      let bf = b["status"]=="done"
      if (af == bf) {
        af = a["dueDate"]
        bf = b["dueDate"]
      }
      if(af>bf){return 1}
      if(af<bf){return -1}
      return 0
    })
  }
  
  onChange=async(type,vals)=>{
//     cl(type,vals)
    let nowStr= dateToDisplayDate(new Date(),"yyyy-mm-dd")
    let st=this.state
    switch(type){
      case "addTask":
        vals.e.preventDefault()
        var newState
        if(st.showNew){
          newState={showNew:false}
        }else{
          newState={
            showNew:true,
            dueDate:nowStr,
            title:"",
            assignDate:nowStr,
            handlers:[],
            tags:[],
            taskId:getRandomString(16),
            editTaskId:-1,
            repeat:{
              period: 1,
              details: 0,
            },
            repeatPattern: "none",
          }
        }
        this.setState(newState)
        break
      case "handlers":
//         globs.events.publish("savePageEnable", true)
        let handlers=vals.map(ha=>{
          return ha.value
        })
        this.setState({handlers:handlers})
        break
      case "chartElements":
//         cl(this.state.tags)
        cl(this.setTags(vals))
        this.setState({tags:this.setTags(vals)})
        break
      case "updComplete":
//         vals.task.complete=vals.complete
        let tasks=this.state.tasks.slice(0)
        for(let i=0;i<tasks.length;i++){
          let ta=tasks[i]
          if(ta.taskId==vals.taskId){
            ta.status=(vals.complete)?"done":"readyToStart"
//             ta.complete=vals.complete
            cl(ta)
            this.setTaskComplete(ta)
          }
        }
        tasks=this.sortTasks(tasks)
        cl(tasks)
        this.setState({tasks:tasks})
        break
      case "editEntry":
        let ta=this.state.tasks.filter(ta=>{return ta.taskId==vals.editTaskId})[0]
        Object.assign(vals,{title:ta.title,dueDate:ta.dueDate,handlers:ta.handlers,
          taskId:ta.taskId,tags:ta.tags||[],repeat:ta.repeat||{period:1,details:0},
          repeatPattern:ta.repeatPattern||"none",showNew:false})
        // resort tasks if due date changed
      case "title":
      case "upd":
        cl(vals)
        this.setState(vals)
        break
      case "saveTask":
        this.saveTask()
        break
      case "cancelTask":
        this.setState({showNew:false,editTaskId:-1})
      break
      case "deleteEntry":
        let res=await this.props.parms.getPopup({text:"Are you sure you want to delete this Task?", buttons:["Cancel","Yes"]})
        if(res=="Yes"){
          this.deleteTask(vals.id)
        }
        break
      case "rept":
        this.handleRepts(vals)
        break
    }
  }

  handleRepts=(vals)=>{
    let repeat=this.state.repeat
    
//     cl(vals)
    switch(vals.type){
      case "minsFrom":
        Object.assign(repeat,{details:repeat.details&0xFF00|vals.val})
        break
      case "minsTo":
        Object.assign(repeat,{details:repeat.details&0xFF|(vals.val<<8)})
        break
      case "period":
        Object.assign(repeat,{period:vals.val})
        break
      case "dayHour":
        Object.assign(repeat,{details:repeat.details^(0x800000>>vals.hr)})
        break
      case "weekDay":
        Object.assign(repeat,{details:repeat.details^(0x40>>vals.hr)})
        break
      case "monthDay":
        Object.assign(repeat,{details:repeat.details^(0x40000000>>vals.hr)})
        break
      case "monthWeekDay":
        Object.assign(repeat,{details:repeat.details&0xFF00|vals.val})
        break
      case "monthWeekWeek":
        Object.assign(repeat,{details:repeat.details&0xFF|(vals.val<<8)})
        break
      case "yearDayDay":
        Object.assign(repeat,{details:repeat.details&0xFF00|vals.val})
        break
      case "yearDayMonth":
        Object.assign(repeat,{details:repeat.details&0xFF|(vals.val<<8)})
        break
      case "yearWeekDay":
        Object.assign(repeat,{details:repeat.details&0xFFE0|(vals.val&0x1F)})
        break
      case "yearWeekWeek":
        Object.assign(repeat,{details:repeat.details&0xFC1F|((vals.val&0x1F)<<5)})// 1F << 5=3E invert=C1
        break
      case "yearWeekMonth":
        Object.assign(repeat,{details:repeat.details&0x83FF|((vals.val&0x1F)<<10)})// C1<<5=183
        break
      default:
        break
    }
    this.setState({repeat:repeat})
  }

  makeOrd=(n)=>{
    if((n<10)||(n>20)){
      if((n%10==1)&&(n!=11)){return "st"}
      if(n%10==2){return "nd"}
      if(n%10==3){return "rd"}
    }
    return "th"
  }
  
  handleRepts=(vals)=>{
    let repeat=this.state.repeat
    
//     cl(vals)
    switch(vals.type){
      case "minsFrom":
        Object.assign(repeat,{details:repeat.details&0xFF00|vals.val})
        break
      case "minsTo":
        Object.assign(repeat,{details:repeat.details&0xFF|(vals.val<<8)})
        break
      case "period":
        Object.assign(repeat,{period:vals.val})
        break
      case "dayHour":
        Object.assign(repeat,{details:repeat.details^(0x800000>>vals.hr)})
        break
      case "weekDay":
        Object.assign(repeat,{details:repeat.details^(0x40>>vals.hr)})
        break
      case "monthDay":
        Object.assign(repeat,{details:repeat.details^(0x40000000>>vals.hr)})
        break
      case "monthWeekDay":
        Object.assign(repeat,{details:repeat.details&0xFF00|vals.val})
        break
      case "monthWeekWeek":
        Object.assign(repeat,{details:repeat.details&0xFF|(vals.val<<8)})
        break
      case "yearDayDay":
        Object.assign(repeat,{details:repeat.details&0xFF00|vals.val})
        break
      case "yearDayMonth":
        Object.assign(repeat,{details:repeat.details&0xFF|(vals.val<<8)})
        break
      case "yearWeekDay":
        Object.assign(repeat,{details:repeat.details&0xFFE0|(vals.val&0x1F)})
        break
      case "yearWeekWeek":
        Object.assign(repeat,{details:repeat.details&0xFC1F|((vals.val&0x1F)<<5)})// 1F << 5=3E invert=C1
        break
      case "yearWeekMonth":
        Object.assign(repeat,{details:repeat.details&0x83FF|((vals.val&0x1F)<<10)})// C1<<5=183
        break
      default:
        break
    }
    this.setState({repeat:repeat})
  }

  deleteTask=async(taskId)=>{
//     cl(`Delete ${taskId}`)
    await wsTrans("usa", {cmd: "cRest", uri: "/s/tasks", method: "delete",
      sessionId: globs.userData.session.sessionId, body: {taskId:taskId}})
    let tasks=this.state.tasks.slice(0)
    for(let i=0;i<tasks.length;i++){
      if(tasks[i].taskId==taskId){
        tasks.splice(i,1)
      }
    }
    this.setState({tasks:tasks})
  }

  setTaskComplete=async(ta)=>{
    await wsTrans("usa", {cmd: "cRest", uri: "/s/tasks", method: "update",
      sessionId: globs.userData.session.sessionId, body: {taskId:ta.taskId,status:ta.status},})
  }

  saveTask=async()=>{
// if there are multiple tasks, then add them all, and return to TaskList
//     cl(this.state)
//     cl(this.props)
    let pa=this.props.parms
//     let multi=this.state.tasks.filter(ta=>{return ta.select})
//     if(multi.length){return this.saveMultipleTasks(multi)}
    let st=this.state
    let tags=this.state.tags.slice(0)
    if(pa.level=="config"){
      let tag=`${pa.pageType}-${pa.zuci}`
      if(!tags.includes(tag)){tags.push(tag)}
    }
    let task={
      title:st.title,
//       body:st.body,
//       images:st.images,
      handlers:st.handlers,
      scope:pa.level,
      pageType:pa.pageType,
      zuci:pa.zuci,
//       scope:st.scope,
      site:pa.siteId,//st.site,
      zone:pa.zoneId,//st.zone,
      assignDate:st.assignDate,
      assignUserId:globs.userData.session.userId,
      dueDate:st.dueDate,
//       estimatedHours:+st.estimatedHours+8*st.estimatedDays,
//       priority:st.priority,
//       category:st.category,
      status:st.status,
      tags:tags,
      taskId:st.taskId,
      title:st.title,
      repeat:st.repeat,
      repeatPattern:st.repeatPattern,
    }
    notifyHandlers(task,this.props.parms)
    await wsTrans("usa", {cmd: "cRest", uri: "/s/tasks", method: "update",
      sessionId: globs.userData.session.sessionId, body: task,})
    let tasks=this.state.tasks.slice(0)
    let ta=tasks.filter(ta=>{return ta.taskId==task.taskId})[0]
    if(ta){
//       cl("updating task")
      Object.assign(ta,task)
      this.sortTasks(tasks)
    }else{
//       cl("making new task")
      tasks.push(task)
      this.sortTasks(tasks)
    }
    this.setState({tasks:tasks,showNew:false,editTaskId:-1})
  }

  showTags=(tags)=>{
    if(!tags){return null}
    let tags2=tags.slice(0)
//     cl(this.props.parms)
    let pa=this.props.parms
    let myTag=`${pa.pageType}-${pa.zuci}`
    if(!["zone","config"].includes(pa.level)/*this.props.parms.level!="zone"*/){
      let zInd=getZoneIndex(pa?.entry?.zoneId)
      if(zInd){
        tags2.push(globs.zonesInfo.info[zInd].zoneName)
      }
    }
    return tags2.map((t,i)=>{
      if(t!=myTag){
        return(
            <span id="/sidebar/tasks/tags" key={i} className={`grow-journal-tag ${t}`}>{t}</span>
        )
      }
    })
  }

  showDeleteEntry=(type,id)=>{
//     cl(globs.privsInfo.info)
//     cl(privs("account",0,constant.AREA_PRIVS_ADMIN))
    if(privs("account",0,constant.AREA_PRIVS_ADMIN)){
      return(
        <button id="/sidebar/growJournalEntry/delete" type="button" className="material-icons trash" aria-label="delete entry"
          onClick={o=>this.onChange("deleteEntry",{type:type,id:id})}>
          delete_outline
        </button>
      )
    }
  }

  showEditEntry=(ta)=>{
    return(
      <button id="/sidebar/growJournalEntry/edit" type="button" className="material-icons-outlined add" aria-label="add comment"
        onClick={o=>this.onChange("editEntry",{editTaskId:ta.taskId})} title="Edit, actually">
        edit
      </button>
    )
  }

  showTaskEdit=(ta,i)=>{
//     cl(ta)
    return this.showTaskNew(i,true)
  }

  showRepeating=(ta)=>{
    let repeating=ta.repeatPattern && ta.repeatPattern!="none"
    let repeatText = this.repeatOpts[ta.repeatPattern]?.t || ""
    return(
      <>
      {
        repeating &&
        <span title={repeatText} className="material-icons-outlined">repeat</span>
      }
      </>
    )
  }

  showTask=(ta,i)=>{
    // cl(ta)
    if(ta.taskId==this.state.editTaskId){return this.showTaskEdit(ta,i)}
//     cl(ta)
    let st=this.state
    let handlerId=(ta.handlers||[])[0]||"None"
    let user=st.users[handlerId]||{}
    let av=user.avatar
    var path
    if(av){
      path=`${constant.expressUrl}/usa/images/avatars/uploads/${av[0]}/${av[1]}/${av[2]}/${av.substr(3)}.jpeg`
    }else{
      path=`${constant.expressUrl}/usa/images/avatars/stockAvatar.jpg`
    }
    var da2="None"
    var overdue
    if(ta.dueDate){
      let tzo=(new Date).getTimezoneOffset()
//       let da=ymdToDate(ta.dueDate)
      let da=60*tzo+Date.parse(ta.dueDate)/1000//.getTime()
//       cl(da)
      
      da2=dateToDisplayDate(new Date(1000*da),"mm/dd/yyyy")
      let now=getTime()
//       cl(now,da)
      overdue=now>(da+86400)
    }

    let noteTaskId = this.props.parms.sideBarNoteId;
    // cl(getTaskId)
    
    let gjEntry={}
    if((st.showComplete)&&(ta.status=="done")){return}
//         {this.showTags(ta.tags)}
    return(
      <div key={i} className="info-section">
        <div className="floatleft" style={{verticalAlign:"top"}}>
          <span>
            <input type="checkbox" id=""
            checked={ta.status=="done"}
            onChange={e=>this.onChange("updComplete",{taskId:ta.taskId,
              complete:e.currentTarget.checked})}
            />
          </span>
        <span style={{color:ta.taskId==noteTaskId?"blue":""}}>{ta.title}</span>
        <br></br>
        <div style={{marginTop: "8px"}}>{this.showTags(ta.tags)}</div>
      </div>
        
        <div className="floatright time-date" style={{verticalAlign:"top",marginTop:0}}>
          {this.showRepeating(ta)}
          <span className="date" style={{color:(overdue)?"red":ta.taskId==noteTaskId?"blue":null}}>{da2}</span>
          {this.showDeleteEntry("main",ta.taskId)}

          {this.showEditEntry(ta)}
          <div>
            <img src={path} alt={user.name}/><span  style={{color:ta.taskId==noteTaskId?"blue":""}}>{user.name}</span>
          </div>
        </div>


        <div className="clearfloat"></div>
        <br /><hr /><br />
      </div>
    )
  }

  showTaskHead=()=>{
    return(
      <div className="info-section">
        <div className="right-checkbox floatleft top-margin-small">
          <label htmlFor="">Hide Completed</label><input type="checkbox" id=""
          checked={this.state.showComplete}
          onChange={e=>this.onChange("upd",{showComplete:e.currentTarget.checked})}/>
        </div>
        <C18Anchor00 to="" className="colored-link-text floatright material-icons-outlined add" aria-label="add task"
        onClick={e=>this.onChange("addTask",{e:e})}
        >add</C18Anchor00>
      </div>
    )
  }

  setTags=(vals)=>{
    let standards=this.tagOptions.map(to=>{return to.value})
//     cl(standards)
    let adds=this.state.tags.filter(ta=>{return !standards.includes(ta)})
//     cl(adds)
    let tags=[]
    vals.forEach(v=>{
      tags.push(v.value)
    })
//     cl(this.state.tags)
//     cl(tags.concat(adds))
    return tags.concat(adds)
  }

  getTagOption=(value)=>{
    for(let i=0;i<this.tagOptions.length;i++){
      let o=this.tagOptions[i]
//       cl([o.value,value])
      if(o.value==value)return o
    }
  }

  makeChartElements=()=>{
    let elements=[]
    cl(this.state.tags)
    this.state.tags.forEach(t=>{
      elements.push(this.getTagOption(t))
    })
//     cl(elements)
    return elements
  }

  makeHandlerList=()=>{
    return this.state.handlers.map(ha=>{
      let user=globs.usersInfo.info[getUserIndex(ha)]
      if (user) {
        return {value:user.userId,label:`${user.name}-${user.email}`}
      }
    })
  }

  showTitle=()=>{
    return (
      <textarea id="/sidebar/tasks/header/title"
        className="fullwidth"
        value={this.state.title}
        onChange={e=>this.onChange("title",{title:e.currentTarget.value})}
        >
      </textarea>
    )
  }

  showDueDate=()=>{
    return (
      <>
        <label htmlFor="dueDate">Due Date</label>
        <input id="dueDate" type="date"
          value={this.state.dueDate}
          onChange={e=>this.onChange("upd",{dueDate:e.currentTarget.value})}
        />
      </>
    )
  }

  showHandlers=()=>{
    return (
      <div className="fullwidth">
        <label htmlFor="handlers">Assignees</label>
        <Select
          id="handlers"
          value={this.makeHandlerList()}
          onChange={o=>{this.onChange("handlers",o)}}
          options={this.userOptions}
          isMulti={true}
        />
      </div>
    )
  }

  showTagsEdit=()=>{
    return (
      <>
        <label htmlFor="">Add Tags</label>
        <Select id="/sidebar/growJournal/header/tags"
          className="floatleft grow-journal-select-tags with-right-button"
          value={this.makeChartElements()}
          onChange={o=>{this.onChange("chartElements",o)}}
          options={this.tagOptions}
          isMulti={true}
        />
      </>
    )
  }

  repeatHours=()=>{
    return(
      <div>
        <label htmlFor="idoser-repeat-num">Repeat Every</label>
        <input id="idoser-repeat-num" type="number" step="1"
          value={this.state.repeat.period} 
          disabled={false}
          onChange={e=>this.onChange("rept",{type:"period",val:e.currentTarget.value})}
        /><span className="units">Hours</span>
      </div>
    )
  }
  
  repeatDays=()=>{
    return(
      <div>
        <label htmlFor="idoser-repeat-num">Repeat Every</label>
        <input id="idoser-repeat-num" type="number" step="1"
          value={this.state.repeat.period} 
          disabled={false}
          onChange={e=>this.onChange("rept",{type:"period",val:e.currentTarget.value})}
        /><span className="units">Days</span>
      </div>
    )
  }
  
  repeatMinutes=()=>{
    let da=new Date()
    return(
      <div>
        <label htmlFor="idoser-repeat-num">Repeat Every</label>
        <input id="idoser-repeat-num" type="number" step="1" 
        value={this.state.repeat.period} 
        disabled={false}
        onChange={e=>this.onChange("rept",{type:"period",val:e.currentTarget.value})}
        /><span className="units">Minutes</span>
              <div className="clearfloat"></div>
              <br />

              <span className="custom-select">
                <label htmlFor="idoser-between">Between</label>
                <select id="idoser-between"
                  value={this.state.repeat.details & 0x0FF}
                  disabled={false}
                  onChange={e=>this.onChange("rept",{type:"minsFrom",val:e.currentTarget.value})}
                >
                {[...Array(24).keys()].map(v=>{
                  da.setHours(v)
                  return<option key={v} value={v}>{dateToDisplayDate(da,"hh ap")}</option>
                })}
                </select>
                <span className="material-icons down-arrow">
                  keyboard_arrow_down
                </span>
              </span>

              <span className="custom-select">
                <label htmlFor="idoser-between">And</label>
                <select id="idoser-between"
                  value={this.state.repeat.details>>8}
                  disabled={false}
                  onChange={e=>this.onChange("rept",{type:"minsTo",val:e.currentTarget.value})}
                >
                {[...Array(24).keys()].map(v=>{
                  da.setHours(v)
                  return<option key={v} value={v}>{dateToDisplayDate(da,"hh ap")}</option>
                })}
                </select>
                <span className="material-icons down-arrow">
                  keyboard_arrow_down
                </span>
              </span>

      </div>
    )
    return null
  }
  
  repeatDayHours=()=>{
    let da=new Date()
    let details=this.state.repeat.details
//         <button type="button" className="select-all">Select All</button>
//         <button type="button" className="select-all">Unselect All</button> 
    return(
      <div>
        <div className="form-title">On These Hours:</div>
        <div className="toggle-field">
          {[...Array(24).keys()].map(v=>{
            da.setHours(v)
            let sel=details&0x800000
            details=details<<1
            return(
              <button key={v} type="button" className={`toggle${(sel)?" selected":""}`} 
                aria-pressed={(sel)?"true":"false"}
                disabled={false}
                onClick={e=>this.onChange("rept",{type:"dayHour",hr:v})}
                >{dateToDisplayDate(da,"hh ap")}</button> 
            )
          })}
        </div>
      </div>
    )
  }
  
  repeatWeekDays=()=>{
//     let days=["Sun","Mon","Tue","Wed","Thu","Fri","Sat"]
    let da=new Date()
    let details=this.state.repeat.details
//         <button type="button" className="select-all">Select All</button>
//         <button type="button" className="select-all">Unselect All</button> 
    return(
      <div>
        <div className="form-title">On These Days:</div>
        <div className="toggle-field days">
          {[...Array(7).keys()].map(v=>{
            da.setHours(v)
            let sel=details&0x40
            details=details<<1
            return(
              <button key={v} type="button" className={`toggle${(sel)?" selected":""}`} 
                aria-pressed={(sel)?"true":"false"}
                disabled={false}
                onClick={e=>this.onChange("rept",{type:"weekDay",hr:v})}
                >{shortDays[v]}</button> 
            )
          })}
        </div>
      </div>
    )
  }
  
  repeatMonthDays=()=>{
    let days=["Sun","Mon","Tue","Wed","Thu","Fri","Sat"]
    let da=new Date()
    let details=this.state.repeat.details
    return(
      <div>
        <div className="form-title">On These Days:</div>
        <div className="toggle-field days">
          {[...Array(31).keys()].map(v=>{
            da.setHours(v)
            let sel=details&0x40000000
            details=details<<1
            return(
              <button key={v} type="button" className={`toggle${(sel)?" selected":""}`} 
                aria-pressed={(sel)?"true":"false"}
                disabled={false}
                onClick={e=>this.onChange("rept",{type:"monthDay",hr:v})}
                >{v+1}</button> 
            )
          })}
        </div>
      </div>
    )
  }
  
  repeatMonthWeek=()=>{
//     let days=["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"]
//     let weeks=["First","Second","Third","Fourth","Last"]
    return(
      <div>
        <label htmlFor="idoser-repeat-num">Repeat Every</label>
        <input id="idoser-repeat-num" type="number" step="1"
          value={this.state.repeat.period} 
          onChange={e=>this.onChange("rept",{type:"period",val:e.currentTarget.value})}
        /><span className="units">Months</span>
              <div className="clearfloat"></div>
              <br />
              <span className="custom-select">
                <label htmlFor="idoser-day-of-week">On</label>
                <select id="idoser-day-of-week"
                  value={this.state.repeat.details&0xFF}
                  disabled={false}
                  onChange={e=>this.onChange("rept",{type:"monthWeekDay",val:e.currentTarget.value})}
                >
                {days.map((d,i)=>{
                  return(
                    <option key={i} value={i}>{d}</option>
                  )
                })}
                </select>
                <span className="material-icons down-arrow">
                  keyboard_arrow_down
                </span>
              </span>

              <span className="custom-select">
                <label htmlFor="idoser-ordinal-week">Of the</label>
                <select id="idoser-ordinal-week"
                  value={this.state.repeat.details>>8}
                  disabled={false}
                  onChange={e=>this.onChange("rept",{type:"monthWeekWeek",val:e.currentTarget.value})}
                >
                {weeks.map((w,i)=>{
                  return(
                    <option key={i} value={i}>{w}</option>
                  )
                })}
                </select>
                <span className="material-icons down-arrow">
                  keyboard_arrow_down
                </span>
              </span><span className="units">Week of the Month</span>
      </div>

      
    )
  }

  repeatYearDay=()=>{
    return(
      <div>
        <label htmlFor="idoser-repeat-num">Repeat Every</label>
        <input id="idoser-repeat-num" type="number" step="1"
          value={this.state.repeat.period} 
          disabled={false}
          onChange={e=>this.onChange("rept",{type:"period",val:e.currentTarget.value})}
        /><span className="units">Years</span>
              <div className="clearfloat"></div>
              <br />
              <span className="custom-select">
                <label htmlFor="idoser-ordinal">On the</label>
                <select id="idoser-ordinal"
                  value={this.state.repeat.details&0xFF}
                  disabled={false}
                  onChange={e=>this.onChange("rept",{type:"yearDayDay",val:e.currentTarget.value})}
                >
                {[...Array(31).keys()].map(v=>{
                  return(
                    <option key={v} value={v+1}>{`${v+1}${this.makeOrd(v+1)}`}</option>
                  )
                })}
                </select>
                <span className="material-icons down-arrow">
                  keyboard_arrow_down
                </span>
              </span><span className="units">Day of</span>

              <span className="custom-select">
                <label htmlFor="idoser-month">The Month of</label>
                <select id="idoser-month"
                  value={this.state.repeat.details>>8}
                  disabled={false}
                  onChange={e=>this.onChange("rept",{type:"yearDayMonth",val:e.currentTarget.value})}
                >
                {[...Array(12).keys()].map(v=>{
                  return(
                    <option key={v} value={v+1}>{months[v]}</option>
                  )
                })}
                </select>
                <span className="material-icons down-arrow">
                  keyboard_arrow_down
                </span>
              </span>
      </div>
    )
  }
  
  repeatYearWeek=()=>{
    return(
      <div>
        <label htmlFor="idoser-repeat-num">Repeat Every</label>
        <input id="idoser-repeat-num" type="number" step="1"
          value={this.state.repeat.period} 
          disabled={false}
          onChange={e=>this.onChange("rept",{type:"period",val:e.currentTarget.value})}
        /><span className="units">Years</span>
              <div className="clearfloat"></div>
              <br />
              <span className="custom-select">
                <label htmlFor="idoser-day-of-week">On</label>
                <select id="idoser-day-of-week"
                  value={this.state.repeat.details&0x1F}
                  disabled={false}
                  onChange={e=>this.onChange("rept",{type:"yearWeekDay",val:e.currentTarget.value})}
                >
                  {days.map((d,i)=>{
                    return(
                      <option key={i} value={i}>{d}</option>
                    )
                  })}
                </select>
                <span className="material-icons down-arrow">
                  keyboard_arrow_down
                </span>
              </span>

              <span className="custom-select">
                <label htmlFor="idoser-ordinal-week">Of the</label>
                <select id="idoser-ordinal-week"
                  value={(this.state.repeat.details>>5)&0x1F}
                  disabled={false}
                  onChange={e=>this.onChange("rept",{type:"yearWeekWeek",val:e.currentTarget.value})}
                >
                  {weeks.map((w,i)=>{
                    return(
                      <option key={i} value={i}>{w}</option>
                    )
                  })}
                </select>
                <span className="material-icons down-arrow">
                  keyboard_arrow_down
                </span>
              </span><span className="units">Week of</span>

              <span className="custom-select">
                <label htmlFor="idoser-month">The Month of</label>
                <select id="idoser-month"
                  value={(this.state.repeat.details>>10)&0x1F}
                  disabled={false}
                  onChange={e=>this.onChange("rept",{type:"yearWeekMonth",val:e.currentTarget.value})}
                >
                {[...Array(12).keys()].map(v=>{
                  return(
                    <option key={v} value={v+1}>{months[v]}</option>
                  )
                })}
                </select>
                <span className="material-icons down-arrow">
                  keyboard_arrow_down
                </span>
              </span>

      </div>
    )
  }

  repeatNone=()=>{
    return null
  }


  showRepeatSelect=()=>{
    let ro=this.repeatOpts
    return(
      <span className="custom-select">
        <label htmlFor="idoser-repeat">Repeat Pattern</label>
        <select id="idoser-repeat"
          value={this.state.repeatPattern}
          disabled={false}
          onChange={e=>this.onChange("upd",{repeatPattern:e.currentTarget.value})}
        >
        {Object.keys(ro).map((repeatId,i)=>{
          return(
            <option key={i} value={repeatId}>{ro[repeatId].t}</option>
          )
        })}
        </select>
        <span className="material-icons down-arrow">
          keyboard_arrow_down
        </span>
      </span>
      
    )
  }

  showRepeatEdit=()=>{
    let repeats={hours:this.repeatHours, minutes:this.repeatMinutes, dayhours:this.repeatDayHours, days:this.repeatDays,
      weekdays: this.repeatWeekDays, monthdays: this.repeatMonthDays, monthweek:this.repeatMonthWeek, yearday:this.repeatYearDay,
      yearweek: this.repeatYearWeek, none: this.repeatNone
    }
    if(repeats[this.state.repeatPattern]){return repeats[this.state.repeatPattern]()}
  }

  showSaveCancel=(editFlag)=>{
    let addDisable=false
    let addSave=(editFlag)?"Save":"Add"
    return (
      <>
        <C18Button00 type="button" className="filled" disabled={addDisable}
          style={{marginLeft:10}}
          onClick={()=>this.onChange("saveTask",{})}>{addSave}</C18Button00>
        <C18Button00 type="button" className="outlined"
        style={{marginLeft:10}}
        onClick={()=>this.onChange("cancelTask",{})}>Cancel</C18Button00>
      </>
    )
  }

  showTaskNew=(key,editFlag)=>{
    let p={}
    return(
      <div key={key} className="chart-elements-container">
          <div className="clearfloat"></div>
          <label htmlFor="">{(p.edit)?"Edit":"Add"} Task</label>
          {this.showTitle()}
          <br />
          {this.showDueDate()}
          <div className="clearfloat"></div><br/>
          {this.showHandlers()}
          <div className="clearfloat"></div><br/>
          {this.showTagsEdit()}
          <div className="clearfloat"></div><br/>
          {acctFeature("repeatingTasks") && this.showRepeatSelect()}
          <div className="clearfloat"></div><br/>
          {acctFeature("repeatingTasks") && this.showRepeatEdit()}
          {this.showSaveCancel(editFlag)}
          <div className="clearfloat"></div>

          <br /><hr /><br />
      </div>
    )
  }

  showNoTaskMessage=()=>{
    if(this.state.tasks.length === 0) {
      return (
        <div style={{textAlign: "center"}}>No Tasks</div>
      )
    }
  }  
  
  render(){
    let st=this.state
//     cl(st)
    if(this.state.loaded){
//         <div className="center">
//           <a href="" className="colored-link-text large-text">See All</a>
//         </div>
    return(
      <div id="sidebar-tasks">
      <div className="clearfloat"></div>
      <br /><hr /><br />
      {this.showTaskHead()}
      {st.showNew&&
        this.showTaskNew(0,false)
      }
      <div className="clearfloat"></div>
      <br /><hr /><br />
      {this.state.tasks.map((ta,i)=>{return this.showTask(ta,i)})}
      {this.showNoTaskMessage()}


      </div>

      )
    }else{
      return <div id="content-area">loading. . .</div>
    }
  }
}
      
export default C18SidebarTasks00;
