import React from 'react';
import { cl } from '../../components/utils/utils';
// simple class component to show the alarm tabs
class C18AlarmTabs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        selectedId: "a036uzDCxohZ7ovD",
        activeAlarms: 0,
        pastAlarms: 0,
        pastAlarmsEmails: 0,
        activeAlarmsSelected: 0,
        pastAlarmsSelected: 0,
        pastAlarmsEmailsSelected: 0,
        serverHealthCheck: '',
        host: 'https://api02.link4cloud.com',
        allAlarms: [],
        allAlarmEmails: [],
        };

    }


  refreshServerStatus = async () => {
    const { host } = this.state;
    const url =`${host}/status/75cgVDOLOXprBe8fk*M9OktUH5EJXd38/alarmServerStatus/`;
    try {
      const resp = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
      cl(resp)
      const data = await resp.json();
      cl(data)
      let curr = new Date(data.data)
      cl(data)
      this.setState({serverStatus: curr})
    } catch (error) {
      cl("Error fetching server health check", error);
      this.setState({
        serverHealthCheck: "Error", 
      });
    }
  }

  // Fetch method as a class method
  getAlarms = async () => {
    const { host } = this.state;
    const url = `${host}/status/75cgVDOLOXprBe8fk*M9OktUH5EJXd38/activeAlarms/`;
    try {
      const resp = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
      const data = await resp.json();
      // cl(data)
      return data
    } catch (error) {
      // cl("Error fetching total alarms:", error);
      this.setState({ 
        allAlarms: [], 
        error: error, 
        isLoading: false 
      });
    }
  }

  getAlarmEmails = async () => {
    const { host } = this.state;
    const url =`${host}/status/75cgVDOLOXprBe8fk*M9OktUH5EJXd38/alarmEmails/`;
    try {
      const resp = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
      const data = await resp.json();
      // cl(data)
      return data
    } catch (error) {
      cl("Error fetching 24-hour alarms emails", error);
      this.setState({ 
        allAlarmEmails: [], 
        error: error, 
        isLoading: false 
      });
    }
  };

  fetchTotalAlarmsSelected = async (accountId) => {
    const { host } = this.state;
    // let accountId = this.props.selValue.accounts;
    // let isAccountId = accountId ? `${accountId}` : '';
    // const { selectedId: accountId } = this.state;
    if(accountId) {
      try {
        const url = `${host}/status/75cgVDOLOXprBe8fk*M9OktUH5EJXd38/allActiveAlarms/${accountId}`;
        const res = await fetch(url, { method: "GET" });
        const data = await res.json();
        this.setState({ 
          activeAlarmsSelected: data[0]?.totalAlarms, 
          isLoading: false 
        });
      } catch (error) {
        // cl("Error fetching total alarms:", error);
        this.setState({ 
          activeAlarmsSelected: 0, 
          error: error, 
          isLoading: false 
        });
      }
    } else {
      this.setState({ 
        activeAlarmsSelected: 0, 
        error: 'No account selected', 
        isLoading: false 
      });
    }
  }

  fetchAlarms24HoursSelected = async (accountId) => {
    const { host } = this.state;
    // let accountId = this.props.selValue.accounts;
    // let isAccountId = accountId ? `${accountId}` : '';
    // const { selectedId: accountId } = this.state;
    if(accountId) {
      try {
        const url =`${host}/status/75cgVDOLOXprBe8fk*M9OktUH5EJXd38/allActiveAlarms24/${accountId}`;
        const res = await fetch(url, { method: "GET" });
        const data = await res.json();
        this.setState({ 
          pastAlarmsSelected: data[0]?.totalAlarms, 
          isLoading: false 
        });
  
      } catch (error) {
        cl("Error fetching 24-hour alarms", error);
        this.setState({ 
          pastAlarmsSelected: 0, 
          error: error, 
          isLoading: false 
        });
      }
      } else {
        this.setState({ 
          pastAlarmsSelected: 0, 
          error: 'No account selected', 
          isLoading: false 
        });
      }
  };

  fetchAlarmsEmails24HoursSelected = async (accountId) => {
    const { host } = this.state;
    // let accountId = this.props.selValue.accounts;
    // let isAccountId = accountId ? `${accountId}` : '';
    // const { selectedId: accountId } = this.state;
    if(accountId) {
      try {
        const url =`${host}/status/75cgVDOLOXprBe8fk*M9OktUH5EJXd38/allAlarmsEmails24/${accountId}`;
        const res = await fetch(url, { method: "GET" });
        const data = await res.json();
        this.setState({ 
          pastAlarmsEmailsSelected: data[0]?.totalAlarms, 
          isLoading: false 
        });

      } catch (error) {
        cl("Error fetching 24-hour alarms emails", error);
        this.setState({ 
          pastAlarmsEmailsSelected: 0, 
          error: error, 
          isLoading: false 
        });
      }
  } else {
    this.setState({ 
      pastAlarmsEmailsSelected: 0, 
      error: 'No account selected', 
      isLoading: false
    });
    }
  };

  componentDidMount = () =>{
    this.loadInfo()
  } 

  loadInfo= async () =>{
    this.refreshServerStatus()
    let allAlarms = await this.getAlarms();
    let allAlarmEmails = await this.getAlarmEmails();

    // use same function to get selected accounts
    // this.fetchTotalAlarmsSelected(this.props.selValue?.accounts);
    // this.fetchAlarms24HoursSelected(this.props.selValue?.accounts);
    // this.fetchAlarmsEmails24HoursSelected(this.props.selValue?.accounts);

    this.checkServerStatus=setInterval(this.refreshServerStatus, 1000 * 60)
    // cl(allAlarms)
    // cl(allAlarmEmails)
    this.setState({allAlarms: allAlarms, allAlarmEmails: allAlarmEmails})
  }

  componentWillUnmount=()=>{
      cl("host unmount")
      clearInterval(this.checkServerStatus)
    }

  componentDidUpdate(prevProps) {
    // if (prevProps.selValue?.accounts !== this.props.selValue?.accounts) {
      // this.fetchTotalAlarmsSelected(this.props.selValue?.accounts);
    // }
  }

  render() {
    const { allAlarms, allAlarmEmails, serverHealthCheck } = this.state;
    cl(this.props)
    let tabs = ""
    cl(tabs)
    let yesterdayTs = (Date.now() - (24 * 60 * 60 * 1000))/1000
    // filter alarms and emails from state here
    let recentAlarms = allAlarms.filter((a) => {
      // cl([a?.alarmTime, yesterdayTs])
      return a?.alarmTime > yesterdayTs
    })
    // use type and id to determine what should be shown in selected
    let selectedAlarms = allAlarms.filter((a) => {
      switch (this.props.selectedType) {
        case "account":
          return a.accountId == this.props.selectedId
        case "site":
          return a.siteId == this.props.selectedId
        case "zone":
          return a.zoneId == this.props.selectedId
        default:
          break
      }
    })

    let selectedAlarmEmails = allAlarmEmails.filter((a) => {
      switch (this.props.selectedType) {
        case "account":
          return a.accountId == this.props.selectedId
        case "site":
          return a.siteId == this.props.selectedId
        case "zone":
          return a.zoneId == this.props.selectedId
        case "user":
          return a.emails && a.contains(this.props.selectedId)
        default:
          break
      }
    })

    let recentSelectedAlarms = selectedAlarms.filter((a) => {
      // cl([a?.alarmTime, yesterdayTs])
      return a?.alarmTime > yesterdayTs
    })

    return (
        <div style={{ display: 'flex', flexDirection: 'row', gap: '20px', width: '100%', marginTop: '20px' }}>
          {/* Tab 1 */}
          <div
            id="activeAlarmsTab"
            style={{ border: '1px solid #ccc', borderRadius: '8px', width: '100%', height: '150px', textAlign: 'center', padding: '20px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', fontSize: '18px' }}
          >
            <h3 style={{ margin: 0, fontSize: '20px' }}>Total Active Alarms</h3>
            <p style={{ fontSize: '20px', marginTop: '20px' }}>{tabs == "selected" ? selectedAlarms.length : allAlarms.length}</p>
          </div>
    
          {/* Tab 2 */}
          <div
            id="pastAlarmsTab"
            style={{ border: '1px solid #ccc', borderRadius: '8px', width: '100%', height: '150px', textAlign: 'center', padding: '20px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', fontSize: '18px' }}
          >
            <h3 style={{ margin: 0, fontSize: '20px' }}>Alarms in Past 24 Hours</h3>
            <p style={{ fontSize: '20px', marginTop: '20px' }}>{tabs == "selected" ? recentSelectedAlarms.length : recentAlarms.length}</p>
          </div>
    
          {/* Tab 3 */}
          <div
            id="pastAlarmsEmailsTab"
            style={{ border: '1px solid #ccc', borderRadius: '8px', width: '100%', height: '150px', textAlign: 'center', padding: '20px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', fontSize: '18px' }}
          >
            <h3 style={{ margin: 0, fontSize: '20px' }}>Alarm Emails in Past 24 Hours</h3>
            <p style={{ fontSize: '20px', marginTop: '20px' }}>{tabs == "selected" ? selectedAlarmEmails.length : allAlarmEmails.length}</p>
          </div>
    
          {/* Tab 4 */}
          { (tabs === "general") && (<div
            id="serverHealthCheckTab"
            style={{ border: '1px solid #ccc', borderRadius: '8px', width: '100%', height: '150px', textAlign: 'center', padding: '20px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', fontSize: '18px' }}
          >
            <h3 style={{ margin: 0, fontSize: '20px' }}>Last Alarm Check</h3>
            <p style={{ fontSize: '20px', marginTop: '20px' }}>{serverHealthCheck}</p>
          </div>)}
        </div>
      );
    }
}
export default C18AlarmTabs;