import React from 'react';
import C18Select01 from './C18Select01'
// import C18DateFormat00 from './C18DateFormat00'
import C18Button00 from './C18Button00'
// import C18Anchor00 from './C18Anchor00'
// import {getParamId,getParamId2,getParamId800} from '../utils/utils'
import {getParamId,getParamId2,getParamId800,getPearlNumIO,wsTrans,
  xBoardShorts
} from '../utils/utils'
import {loadSitesInfo,loadZonesInfo,getZoneIndex,loadSiteData,getChannelType,getSiteName,getZoneName} from './C18utils'
// import {getEquipmentStatus} from './C18EquipmentStatus00'
import {sendSocket,} from '../../components/utils/ws';
import {dbVals,getZoneControllers,sendArray} from '../../components/utils/http';
import {cl,globs,dateToDisplayDate,getTime,getTimeI,constant,fiveBitsToInt,
  intToFiveBit,getRandomInt,countNumbers,getRandomString,allModuleTypes,
} from '../../components/utils/utils';
import {pInd,tableRange} from '../../components/utils/paramIds';
//
// import history from "../../history"

class C18Expansion00 extends React.Component{
  constructor(props) {
    super(props);
    this.state={
      loaded:false,
      mode:"sites",
      workPos:0,
      selXBoard:0,
      addModuleCode:"",
    }
    this.workWidth=200
    this.workTime=35
    this.xBoardTypes={
      "0":"None",					//  0: MB_SLAVE_TYPE_NONE
      "1":"24 Digital Out",	//  1: MB_SLAVE_TYPE_24OUT
      "2":"8 Digital Out",		//  2: MB_SLAVE_TYPE_8OUT
      "3":"4 Digital Out",		//  3: MB_SLAVE_TYPE_4OUT
      "4":"2 Digital Out",		//  4: MB_SLAVE_TYPE_2OUT
      "5":"EC/pH",				//  5: MB_SLAVE_TYPE_EC_PH
      "6":"2 Analog Out",		//  6: MB_SLAVE_TYPE_ANALOG_2OUT
      "7":"1 Analog Out",		//  7: MB_SLAVE_TYPE_ANALOG_1OUT
      "8":"3 Analog Out",		//  8: MB_SLAVE_TYPE_ANALOG_3OUT
      "9":"5 Analog Out",		//  9: MB_SLAVE_TYPE_ANALOG_5OUT
      "10":"Sensor",				// 10: MB_SLAVE_TYPE_SENSOR_MODULE
      "11":"Analog 16-In",				// 11: MB_SLAVE_TYPE_ANALOG_16INPUT
      "12":"Analog 8-In/Dig 8-Out",		// 12:  MB_SLAVE_TYPE_CONTROLLER
      "13":"Analog 10-In-DTH",        // 13:  MB_SLAVE_TYPE_ANALOG_10IN_DHT
      "14":"4 Analog/4 Digital Out",		// 14:  MB_SLAVE_TYPE_4A_4D_OUTPUT
      "15":"DTH",		// 15:  MB_SLAVE_TYPE_DTH
      "16":"Analog 7-In-DTH",		// 16:  MB_SLAVE_TYPE_ANALOG_7IN_DHT
      "17":"Analog 5-In-DTH",		// 17:  MB_SLAVE_TYPE_DTH_5
      "18":"Digital 4-Out-DTH",		// 18:  MB_SLAVE_TYPE_4D_DTH
      "19":"8 Relay Out DTH",		// 19:  MB_SLAVE_TYPE_8OUT_DTH
      "20":"Controller",		// 17:  MB_SLAVE_TYPE_CONTROLLER
      "101":"Analog Pearl",		// 19:  MB_SLAVE_TYPE_PEARL_ANALOG
    }

    this.mod2Type={
      "8outI2C":19,
      "4a4d":14,
      "1u4out":18,
      "1uin":17,
      "dth5L0":15,
      "dth5G0":15,
      "dismp":10,
    }

    this.loadInfo()
    this.subscribe_savePageEvent=globs.events.subscribe("savePageEvent",
      this.savePage)
    this.subscribe_newZoneData=globs.events.subscribe("newZoneData",this.newZoneData)
    this.setBreadCrumbs()
    this.dataCallbacks={}
//     if(this.props.parms.saveOK){this.props.parms.onChange({cmd:"savePage", data:{savePage:true}})}
  }

  componentWillUnmount=()=>{
    if(this.subscribe_savePageEvent){this.subscribe_savePageEvent.remove()}
//     if(this.subscribe_newZoneData){this.subscribe_newZoneData.remove()}
  }

  setBreadCrumbs=()=>{
//     cl(this.props.parms)
    let p=this.props.parms
    let siteName=getSiteName(p.site)
    let zoneName=getZoneName(p.zone)
    if(p){
      p.onChange(
        {
          cmd: "breadcrumbs",
          data:
            {breadcrumbs: [
              {t:"Sites", url:"/usa/c18/sites"},
              {t:siteName, url:`/usa/c18/sites/${this.props.parms.site}`},
              {t:zoneName, url:`/usa/c18/sites/${this.props.parms.site}/zones/${this.props.parms.zone}`},
//               {t:"iDoser", url:`/usa/c18/idoser/nutrients`},
              {t:"Expansion", url:`/usa/c18/sites/${p.site}/zones/${p.zone}/pearl/expansion`},
            ]},
        },
      )
    }
  }

  saveXBoardValue=(arr,x,k)=>{
//     cl(x,k)
    let id=this.xIDs[k]
    cl(id)
//     let curVal=+((dbVals.z[zInd]||{})[240]||{})[this.xIDs.numOutId+i*mult]||0
//           numOuts:+((dbVals.z[zInd]||{})[240]||{})[this.xIDs.numOutId+i*mult]||0,
  }

  saveXBoard=(arr,x)=>{
//     cl(x)
    let saveIds={startOut:this.xIDs.chIndex, startInp:this.xIDs.startInp}
    let mult=pInd[1900].config_expansion_boards[2]
//     cl(saveIds)
//     cl(this.xIDs)
    let now=getTimeI()
    if(getPearlNumIO(x.type)[0]){
//       cl(x.type)
      arr.push({
        c:240,
        d:x.startCh,
        f:2,
        i:saveIds.startOut+mult*x.boardIndex,
        t:now,
        z:this.zInfo.siteZoneIndex,
      })
    }
    if(getPearlNumIO(x.type)[1]){
//       cl(x.type)
      arr.push({
        c:240,
        d:x.startInp,
        f:2,
        i:saveIds.startInp+mult*x.boardIndex,
        t:now,
        z:this.zInfo.siteZoneIndex,
      })
    }
    cl(arr)
//     Object.keys(this.xIDs).forEach(k=>{
//       this.saveXBoardValue(arr,x,k)
//     })
  }

  ourRanges=[
    tableRange.CONFIG_EXPANSION_BOARDS,
    tableRange.EXPANSION_BOARDS,
    tableRange.CONFIG_EXPANSION_BOARDS_ANNEX,
  ]

  isOurId=(pid)=>{
//     cl(pid)
    let isOurs=false
    this.ourRanges.forEach(r=>{
//       cl(r)
      isOurs|=(pid>=r[0])&&(pid<r[1])
    })
    return isOurs
  }

  newZoneData=(cmd)=>{
//     cl(cmd)
    let ourIds=Object.values(this.xIDs||{})
//     cl(ourIds)
//     cl(this.xIDs)
    let ours=cmd.params.filter(p=>{
      return p.i&&this.isOurId(p.i)
    })
//     cl(ours)
    if(ours.length){
//       cl("got")
      let xBoards=this.loadXBoards(this.zInfo)
//       cl(xBoards)
      this.setState({xBoards:xBoards})
    }
//     cl(ours)
  }

  savePage=async(cmd)=>{
    if(cmd=="save"){
      let st=this.state

//       let x0=
      cl("saving")
      let saveArr=[]
      let xBoards=st.xBoards.slice(0)
      st.xBoards.forEach(x=>{
        this.saveXBoard(saveArr,x)
      })
      let zi=this.zInfo
      cl(saveArr)
//       await sendArray(saveArr,zi.virtual,zi.gatewayType,zi.controllerId,false)
      await this.saveZoneUids()
      globs.events.publish("saveOK",true)
//       cl(xBoards)
    }
  }

  i2h=(intVal)=>{
    let hexVals="0123456789ABCDEF"
    let ret=""
    for(let i=0;i<8;i++){
      ret=hexVals[intVal&0x0F]+ret
      intVal=intVal>>4
    }
    return ret
  }

  loadXBoards=(zInfo)=>{
    var getUId=(zInd,i,ofs)=>{
      return +((dbVals.z[zInd]||{})[240]||{})[this.xIDs.uniqueId0+i*mult+ofs]||0
    }
    let mult=pInd[1900].config_expansion_boards[2]
    let mult2=pInd[1900].snapshot_expansion_boards[2]
    let ceb="configuration_expansion_boards"
    let seb="snapshot_expansion_boards"
    let xca="pearl_xboard_cfg_anx"
    let zInd=zInfo.siteZoneIndex
    this.xIDs={
      numOutId:getParamId2(zInfo.gatewayType,ceb,"numOutputs"),
      chIndex:getParamId2(zInfo.gatewayType,ceb,"startChannelIndex"),// starting output
      addIndex:getParamId2(zInfo.gatewayType,ceb,"address"),
      typeId:getParamId2(zInfo.gatewayType,ceb,"boardType"),
      numInps:getParamId2(zInfo.gatewayType,ceb,"numInputs"),
      startInp:getParamId2(zInfo.gatewayType,ceb,"startInput"),
      mbPort:getParamId2(zInfo.gatewayType,ceb,"modbusPort"),
      uniqueId1:getParamId2(zInfo.gatewayType,xca,"uniqueId1"),
      zoneUid:getParamId2(zInfo.gatewayType,xca,"zoneUid"),
//       zoneUidName:getParamId2(zInfo.gatewayType,xca,"zoneUidName"),
      statusId:getParamId2(zInfo.gatewayType,seb,"boardStatus"),
    }
//     cl(this.xIDs.startInp,this.xIDs.numInps)
//     cl(this.xIDs)
//     cl(mult)
//     cl(this.xIDs)
//     cl(this.xIDs.uniqueId1)
    let xBoards=[]
//     cl(mult2,this.xIDs.statusId)
    for(let i=0;i<40;i++){
      let type=+((dbVals.z[zInd]||{})[240]||{})[this.xIDs.typeId+i*mult]||0
//       cl(type)
      if(type){
//         let uId0=+((dbVals.z[zInd]||{})[240]||{})[this.xIDs.uniqueId0+i*mult+0]||0
        let uId1=+((dbVals.z[zInd]||{})[240]||{})[this.xIDs.uniqueId1+i*mult]||0
//         let uId2=+((dbVals.z[zInd]||{})[240]||{})[this.xIDs.uniqueId0+i*mult+2]||0
//         let uId=this.i2h(getUId(zInd,i,0))+
//           this.i2h(getUId(zInd,i,1))+
//           this.i2h(getUId(zInd,i,2))
//         cl(uId)
        xBoards.push({
          type:type,
          numOuts:+((dbVals.z[zInd]||{})[240]||{})[this.xIDs.numOutId+i*mult]||0,
          startCh:+((dbVals.z[zInd]||{})[240]||{})[this.xIDs.chIndex+i*mult]||0,
          numInps:+((dbVals.z[zInd]||{})[240]||{})[this.xIDs.numInps+i*mult]||0,
          startInp:+((dbVals.z[zInd]||{})[240]||{})[this.xIDs.startInp+i*mult]||0,
          mbAddr:+((dbVals.z[zInd]||{})[240]||{})[this.xIDs.addIndex+i*mult]||0,
          mbPort:+((dbVals.z[zInd]||{})[240]||{})[this.xIDs.mbPort+i*mult]||0,
          status:+((dbVals.z[zInd]||{})[240]||{})[this.xIDs.statusId+i*mult2]||0,
          uniqueId:uId1,
          boardIndex:i,
        })
      }
    }
//     let uiTest=+((dbVals.z[0]||{})[240]||{})[this.xIDs.uniqueId+0*mult+1]||0
//     cl(this.xIDs.uniqueId0+0*mult+1)
//     cl((dbVals.z[0]||{})[240])
//     cl(xBoards)
    return xBoards
  }

//   updXBoards=async()=>{
//     let xBoards=await this.loadXBoards(this.zInfo)
//     this.setState({xBoards:xBoards})
//   }

  loadZoneUids=async(zi)=>{
    let query={
      accountId:zi.accountId,
      siteId:zi.siteId,
      zoneId:zi.zoneId,
    }
    let resp = (await wsTrans("usa", {cmd: "cRest", uri: "/s/moduleIds", method: "retrieve",
      sessionId: globs.userData.session.sessionId, body: query}))
    if(resp.data.error){return []}
//     cl(resp.data)
    return resp.data.filter(m=>{return m.uid})
  }

  saveZoneUids=async()=>{
// {
//     "_id" : ObjectId("65c4ff3828957414b5c11d4e"),
//     "qrcode" : 683149666,
//     "type" : "4a4d",
//     "uid" : 3045710917,
//     "moduleId" : "oo1MlP3A_zry8QBb",
//     "createdOn" : 1707409208 }
    let st=this.state
    cl(st.modules)
//     return
    let zi=this.zInfo
    let query={
      siteId:zi.siteId,
      zoneId:zi.zoneId,
      modules:st.modules,
    }
    cl("ssending")
    cl(query)
    let resp = (await wsTrans("usa", {cmd: "cRest", uri: "/s/moduleIds", method: "update",
      sessionId: globs.userData.session.sessionId, body: query}))
    cl("done")
//     if(resp.data.error){return []}
//     return resp.data
  }

  loadInfo=async()=>{
    let pa=this.props.parms
//     cl(pa)
    await loadZonesInfo()
    this.zInfo=globs.zonesInfo.info[getZoneIndex(this.props.parms.zone)]
//     cl(this.zInfo)

    await loadSiteData(pa.site)
    let modules=await this.loadZoneUids(this.zInfo)
//     await this.loadXBoards(this.zInfo)
//     cl("load xboards")
    let xBoards=await this.loadXBoards(this.zInfo)
//     cl(xBoards)
    this.setState({
      loaded:true,
      xBoards:xBoards,
      modules:modules,
      selXBoard:0,
      mbAddr:xBoards[0]?.mbAddr,
    })
//     setInterval(this.updXBoards,10000)
  }

  doWorkPos=()=>{
    let pos=(this.state.workPos||0)+(this.workWidth/this.workTime)
//     cl(pos)
    if(pos<this.workWidth){
      setTimeout(this.doWorkPos,1000)
    }else{
      pos=0
    }
    this.setState({workPos:pos})
  }

  doClearScan=(type)=>{
    let st=this.state
    let query={
      gatewayId:this.zInfo.gatewayId,
      cmd:type}
    if(type=="assignAddresses"){
      query.min=0
      query.max=30
    }
    if(type=="scanExpansion2"){
      query.comm=st.scanCom
    }

//     cl(this.state)
//     cl(this.props)
//     let gw=globs.gatewaysInfo.info.filter(gw=>{return gw})
//     cl(this.zInfo)
    this.workTime=(type=="clearExpansion")?10:35
    this.doWorkPos()
    wsTrans("usa", {cmd: "cRest", uri: "/s/controller", method: "update",
      sessionId: globs.userData.session.sessionId, body:query})
  }

//   newZoneData=(msg)=>{
//     cl(msg)
//   }

//   loadXBoard=(siteId,gwType,zInd,sessionId,ind)=>{
//     return new Promise((r,e)=>{
//       let params=[{
//         c:240,
//         i:this.xIDs.typeId,
//         z:zInd
//       }]
//       let p=params[0]
//       let key=`${p.z}-${p.c}-${p.i}`
//       this.dataCallbacks[key]r
//       let pack={
//         cmd:"data02",
//         gwType:gwType,
//         params:params,
//         s:siteId,
//         sessionId:sessionId,
//       }
//       sendSocket(pack)
//     })
//   }
//
//   getXBoardCount=(siteId,gwType,zInd,sessionId)=>{
//   }

  reloadXBoardStuff=async(siteId,gwType,zInd,sessionId,type)=>{
//     cl(type)
    var makeRequest=(id)=>{return({c:240,i:id,z:zInd})}
    let mult=pInd[1900].pearl_xboard_cfg_anx[2]
    var params,resp
    let mods=[]
//     cl(this.xIDs.zoneUid)
//     cl(this.xIDs.uniqueId1)
    for(let i=0;i<40;i++){
      switch(type){
        case "zoneUid":
          params=[
            makeRequest(this.xIDs.zoneUid+i*mult),
          ]
          break
        case "xBoard":
          params=[
            makeRequest(this.xIDs.typeId+i*mult),
            makeRequest(this.xIDs.numOutId+i*mult),
            makeRequest(this.xIDs.chIndex+i*mult),
            makeRequest(this.xIDs.addIndex+i*mult),
            makeRequest(this.xIDs.numInps+i*mult),
            makeRequest(this.xIDs.startInp+i*mult),
            makeRequest(this.xIDs.uniqueId1+i*mult),
//         makeRequest(this.xIDs.statusId+mult2),
          ]
          break
      }
      let pack={
        cmd:"data02",
        gwType:gwType,
        params:params,
        s:siteId,
        sessionId:sessionId,
      }
//       cl(pack)
      resp=await sendSocket(pack)
      cl(resp)
      if((resp?.params?.length||0)==0){break}
      mods.push(resp.params)
      if(type=="xBoard"){
        let bdType=dbVals.z[zInd][240][this.xIDs.typeId+i*mult]
//         cl(bdType)
        if(bdType==0){break}
      }
      if(type=="zoneUid"){
        let uid=dbVals.z[zInd][240][this.xIDs.zoneUid+i*mult]
//         cl(uid)
        if(uid==0){break}
      }
//       cl(resp)
//       let uid=dbVals.z[zInd][240][this.xIDs.zoneUid+i*mult]
//       cl(uid)
//       if(uid==0){break}
    }
    return mods.slice(0,mods.length-1)
  }

//   reloadZoneUids=async (siteId,gwType,zInd,sessionId)=>{
//     var makeRequest=(id)=>{return({c:240,i:id,z:zInd})}
//     let mult=pInd[1900].pearl_xboard_cfg_anx[2]
//     for(let i=0;i<40;i++){
//       let params=[
//         makeRequest(this.xIDs.zoneUid+i*mult),
//       ]
//       let pack={
//         cmd:"data02",
//         gwType:gwType,
//         params:params,
//         s:siteId,
//         sessionId:sessionId,
//       }
// //       cl(pack)
//       let resp=await sendSocket(pack)
// //       cl(resp)
//     }
//   }

//   reloadXBoards=async (siteId,gwType,zInd,sessionId,)=>{
//     var makeRequest=(id)=>{return({c:240,i:id,z:zInd})}
//     let mult=pInd[1900].config_expansion_boards[2]
//     let mult2=pInd[1900].snapshot_expansion_boards[2]
//     for(let i=0;i<40;i++){
//       let params=[
//         makeRequest(this.xIDs.typeId+i*mult),
//         makeRequest(this.xIDs.numOutId+i*mult),
//         makeRequest(this.xIDs.chIndex+i*mult),
//         makeRequest(this.xIDs.addIndex+i*mult),
//         makeRequest(this.xIDs.numInps+i*mult),
//         makeRequest(this.xIDs.startInp+i*mult),
//         makeRequest(this.xIDs.uniqueId0+i*mult),
// //         makeRequest(this.xIDs.statusId+mult2),
//       ]
//       let pack={
//         cmd:"data02",
//         gwType:gwType,
//         params:params,
//         s:siteId,
//         sessionId:sessionId,
//       }
//       let resp=await sendSocket(pack)
//       let bdType=dbVals.z[zInd][240][this.xIDs.typeId+i*mult]
//       if(bdType==0){break}
//     }
//   }

  updatePearlMods=async(mods,zInd)=>{
    let mult=pInd[1900].pearl_xboard_cfg_anx[2]
    let parms=[]
    for(let i=0;i<mods.length+1;i++){
      parms.push({
        z:zInd,
        c:240,
        i:this.xIDs.zoneUid+i*mult,
        d:mods[i]||0
      })
    }
    cl(parms)
    let zi=this.zInfo
    await sendArray(parms,zi.virtual,zi.gatewayType,zi.controllerId,false)
  }

  addCloudMod=/*async*/(uid,modules)=>{
    let st=this.state
    cl(st)
    let qrcode=getRandomInt()&0x3FFFFFFF // 30 bits
    let xBoard=(st.xBoards.filter(x=>{return x.uniqueId==uid})||[])[0]||{}
    var type=allModuleTypes.filter(m=>{return m.type==xBoard.type})[0]?.id
    cl(xBoard)
    let modNames=st.modules.map(m=>{return m.name})
    var name="xMod"
    for(let i=0;i<countNumbers.length;i++){
      let name0=`xMod ${countNumbers[i]}`
      if(!modNames.includes(name0)){
        name=name0
        break}
    }
    cl(this.zInfo)
    let mod={
      qrcode:qrcode,
      uid:uid,
      type:type,
      name:name,
      moduleId:getRandomString(16),
      createdOn:getTime(),
    }
    cl(mod)
//     let modules=st.modules.slice(0)
    modules.push(mod)
    globs.events.publish("savePageEnable",true)
//     return mod
//     let resp = (await wsTrans("usa", {cmd: "cRest", uri: "/s/moduleIds", method: "update",
//       sessionId: globs.userData.session.sessionId, body: {
//     this.addModule(qrCode,name)
  }

  reload=async()=>{
    let zi=this.zInfo
    let st=this.state
//     cl(zi)
    let siteId=zi.siteId
    let gwType=zi.gatewayType
    let zInd=zi.siteZoneIndex
    let sessionId=globs.userData.session.sessionId
    let xBoardParams=await this.reloadXBoardStuff(siteId,gwType,zInd,sessionId,"xBoard")
//     cl(xBoardParams)
    let modParams=await this.reloadXBoardStuff(siteId,gwType,zInd,sessionId,"zoneUid")
    let allPearlMods={}
    xBoardParams.forEach(params=>{allPearlMods[+params[6].d]=1})
    modParams.forEach(params=>{allPearlMods[+params[0].d]=1})
    cl(modParams)
    let pmOrig=Object.assign({},allPearlMods)
    cl(pmOrig)
    st.modules.forEach(m=>{if(m.uid){allPearlMods[m.uid]=1}})
    allPearlMods=Object.keys(allPearlMods).map(m=>{return +m})// reduce to an array
    let dif=false
    allPearlMods.forEach(pm=>{dif|=!pmOrig[pm]})
    cl(dif)

    cl(allPearlMods)
    let allCloudMods=st.modules.map(m=>{return m.uid})
//     let addCloudMods=[]
//     allPearlMods.forEach(pMod=>{
//       if(!allCloudMods.includes(+pMod)){addCloudMods.push(+pMod)}
//     })
    let addCloudMods=allPearlMods.filter(m=>{return !allCloudMods.includes(+m)})
//     let pmStartLength=allPearlMods.length
//     allCloudMods.forEach(cMod=>{
//       if(!allPearlMods.includes(cMod)){if(cMod){allPearlMods.push(+cMod)}}
//     })
    cl(allCloudMods)
    cl(addCloudMods)
    cl(allPearlMods)
    await this.updatePearlMods(allPearlMods,zInd)
    let modules=st.modules.slice(0)
    addCloudMods.forEach(m=>{this.addCloudMod(m,modules)})
    if(addCloudMods.length){globs.events.publish("savePageEnable",true)}
    let xBoards=this.loadXBoards(this.zInfo)
    this.setState({modules:modules,xBoards:xBoards})
  }

//   gotQrCode=async(msg)=>{
//     cl(msg)
//     if(msg.code){msg.qrcode=msg.code; delete msg.code}
//     // make call to backend for qrcode to get selected qrcode
//     // if qr code exists, save to selected module
//     let module = (await wsTrans("usa", {cmd: "cRest", uri: "/s/moduleIds", method: "retrieve",sessionId: globs.userData.session.sessionId,
//       body: msg})).data
//     // cl(module)
//     if (module.error) {
//       this.setState({errorMsg: `Error on fetch: ${module.error}`, statusMsg: ""})
//     } else {
//       module = module[0]
//       delete module._id
//       let vals = {
//         selModule: module,
//         qrcode: module.qrcode,//msg.code,
//         statusMsg: `Fetch for qr code ${module.qrcode} found a module!`,
//         errorMsg: "",
//         uid:module.uid,
//
//         selAccount: module.accountId || this.state.selAccount,
//         selSite: module.siteId || this.state.selSite,
//         selZone: module.zoneId || this.state.selZone,
//         selModuleName: module.name || "",
//       }
//       if (module.accountId && module.siteId && module.zoneId) {
//         let modules = (await wsTrans("usa", {
//           cmd: "cRest",
//           uri: "/s/moduleIds",
//           method: "retrieve",
//           sessionId: globs.userData.session.sessionId,
//             body: {
//               accountId: vals.selAccount,
//               siteId: vals.selSite,
//               zoneId: vals.selZone,
//             }
//           })).data
//           if (modules.error) {
//             cl(`error: ${modules.error}`)
//           } else {
//             Object.assign(vals, {modules: modules})
//           }
//       }
//       this.setState(vals)
//     }
//   }

  findModule=async(query)=>{
    return await wsTrans("usa", {cmd: "cRest", uri: "/s/moduleIds",
      method: "retrieve",
      sessionId: globs.userData.session.sessionId,
      body: query})
  }

  readCode=(str)=>{// read as a qr code
    str=str.toUpperCase()
    str=str.replaceAll("B","8")
    str=str.replaceAll("I","1")
    str=str.replaceAll("O","0")
    str=str.replaceAll("S","5")
    if(str[3]=="-"){
      str=str.substring(0,3)+str.substring(4,7)
    }
    if(str.length==6){
      return fiveBitsToInt(str)
    }
    if(!isNaN(str)){return +str}
  }

//   addModule=async(qrcode,name)=>{
//     let st=this.state
//     let zi=this.zInfo
//     let names=st.modules.map(m=>{return m.name})
//     var name
//     for (let i=1;i<40;i++){
//       name=`xMod${i}`
//       if(!names.includes(name)){break}
//     }
//     let resp = (await wsTrans("usa", {cmd: "cRest", uri: "/s/moduleIds", method: "update",
//       sessionId: globs.userData.session.sessionId, body: {
//         qrcode:qrcode,
//         accountId:zi.accountId,
//         siteId:zi.siteId,
//         zoneId:zi.zoneId,
//         name:name,
//     }}))
//     return name
//
//   }

  addModuleFromCode=async()=>{
    let st=this.state
    let code=st.addModuleCode
    let qrCode=this.readCode(code)
    cl(qrCode)
    let res=await this.findModule({qrcode:qrCode||""})
    cl(res)
    if(res.data[0]){
      let mod=res.data[0]
      let mods=st.modules.slice(0)
      mods.push(mod)
      globs.events.publish("savePageEnable",true)
      this.setState({modules:mods})
//       cl(mod)
    }

//     if(res.result=="notFound"){
//       let uid=parseInt(code,16)
//       cl(uid)
//       res=await this.findModule({uid:uid||""})
//     }
//     if(res.result=="notFound"){
//       this.setState({errorMsg:"Module not found"})
//       return
//     }
//     let mod=res.data[0]
//     if(mod.accountId){
//       this.setState({errorMsg:"Module already claimed"})
//       return
//     }
//     mod.name=await this.addModule(mod.qrcode)
//     let mods=st.modules.slice(0)
//     mods.push(mod)
//     cl(mods)
//
//     this.setState({errorMsg:"",modules:mods})
//     cl(res)
  }

  saveModules=()=>{

  }

  deleteModule=(vals)=>{
    cl(vals)
    cl("del mod")
    let st=this.state
    let mods=st.modules.slice(0).filter(m=>{return m.uid!=vals.uid})
    this.setState({modules:mods})
  }

  moveUpDownXBoard=(type,vals)=>{
    let st=this.state
    let xBoards=st.xBoards.slice(0)
    let dir=(type=="moveDown")?1:-1
    let pos=vals.pos
    let newPos=pos+dir
    if((newPos>=0)&&(newPos<xBoards.length)){
      let tmp=xBoards[pos]
      xBoards[pos]=xBoards[newPos]
      xBoards[newPos]=tmp
      let query={
        cmd:"swapXBoard",
        gatewayId:this.zInfo.gatewayId,
        pos:(pos<newPos)?pos:newPos}
      wsTrans("usa", {cmd: "cRest", uri: "/s/controller", method: "update",
        sessionId: globs.userData.session.sessionId, body:query})
      this.setState({xBoards:xBoards})
    }
  }

  updateMbAddr=()=>{
    let st=this.state
    cl(st)
    let xBoards=st.xBoards.slice(0)
    let xBoard=xBoards[st.selXBoard]
    xBoard.mbAddr=st.mbAddr
    let query={
      cmd:"setMbAddr",
      gatewayId:this.zInfo.gatewayId,
      ind:st.selXBoard,
      mbAddr:xBoard.mbAddr,
    }
    cl(query)
    wsTrans("usa", {cmd: "cRest", uri: "/s/controller", method: "update",
      sessionId: globs.userData.session.sessionId, body:query})
    this.setState({xBoards:xBoards})
  }

  onChange=(type,vals)=>{
//     cl(type,vals)
    let st=this.state
    switch(type){
      case "selXBoard":
        vals.mbAddr=st.xBoards[vals.selXBoard].mbAddr
      case "upd":
        this.setState(vals)
        break
      case "xBoard":
        if(!vals.mbAddr){globs.events.publish("savePageEnable",true)}
        let xBoards=st.xBoards.slice(0)
        let xBoard=xBoards[st.selXBoard]
        Object.assign(xBoard,vals)
        this.setState({xBoards:xBoards})
        break
      case "clearExpansion":
      case "scanExpansion":
      case "scanExpansion2":
      case "assignAddresses":
        return this.doClearScan(type)
      case "reload":
        this.reload()
        break
      case "addModuleFromCode":
        this.addModuleFromCode()
        break
      case "editName":
        if (st.selModule && st.selAccount && st.selSite && st.selZone) {
          globs.events.publish("savePageEnable",true)
        }
      case "deleteModule":
        globs.events.publish("savePageEnable",true)
        vals.e.stopPropagation()
        this.deleteModule(vals)
        break
      case "modName":
        globs.events.publish("savePageEnable",true)
        let mods=st.modules.slice(0)
        cl(mods)
        let mod=mods.filter(m=>{return m.uid==vals.uid})[0]||{}
        mod.name=vals.name
        this.setState({modules:mods})
        break
      case "moveUp":
      case "moveDown":
        vals.e.stopPropagation()
        this.moveUpDownXBoard(type,vals)
        break
      case "updateMbAddr":
        this.updateMbAddr()
        break
      case "mbAddr":
      default:
        this.setState(vals)
        break
    }
  }

  showExpansionTable=()=>{
//     COMM_STATUS_NONE,//0
//     COMM_STATUS_OK,1
//     COMM_STATUS_ERROR_PARTIAL,2
//     COMM_STATUS_ERROR,3
    let status=["None","OK","Partial","Error"]
    var showHeader=()=>{
      return(
        <tr key="head">
        <td></td>
        <td></td>
        <td></td>
        <td>xBoard</td>
        <td>type</td>
        <td>mbPort</td>
        <td>mbAddr</td>
        <td>numInps</td>
        <td>numOuts</td>
        <td>startCh</td>
        <td>startInp</td>
        <td>status</td>
        </tr>
      )
    }
//         <td>uniqueId</td>
    let st=this.state
    let opts=st.xBoards.map((x,i)=>{return({v:i,t:`Bd${i+1}`})})
//     cl(st.xBoards)
    let rows=[]
    let modInd={}
    st.modules.forEach(m=>{modInd[m.uid]=m})
//     cl(modInd)
    let butStyle={height:19,lineHeight:1,fontSize:14,marginLeft:0}
    let cellStyle={paddingRight:0}
//     cl(st.xBoards)
    st.xBoards.forEach((x,i)=>{
//       cl(x)
      let title=modInd[x.uniqueId]?.name||""
//       let mod=st.modules
      if(xBoardShorts[x.type]){// valid board type
        let bgColor=(i==st.selXBoard)?"#CCEEFF":"#FFFFFF"
        let m={}
//         cl(i,st.selXBoard,i==st.selXBoard)
        rows.push(
          <tr
            key={x.uniqueId}
            style={{cursor:"pointer",backgroundColor:bgColor}}
            onClick={e=>this.onChange("selXBoard",{selXBoard:i})}
          >
            <td width="10" style={cellStyle}><button style={butStyle}
            type="button" className="material-icons" aria-label="delete graph"
              onClick={e=>this.onChange("moveUp",{e:e,pos:i})}
            >
              arrow_upward
              </button></td>
            <td width="10" style={cellStyle}><button style={butStyle}
            type="button" className="material-icons" aria-label="delete graph"
              onClick={e=>this.onChange("moveDown",{e:e,pos:i})}
            >
              arrow_downward
              </button></td>
            <td></td>
            <td title={title}>{`Bd ${x.boardIndex+1}`}</td>
            <td>{`${xBoardShorts[x.type]}`}</td>
            <td>{`${x.mbPort}`}</td>
            <td>{`${x.mbAddr}`}</td>
            <td>{`${x.numInps}`}</td>
            <td>{`${x.numOuts}`}</td>
            <td>{`${x.startCh}`}</td>
            <td>{`${x.startInp}`}</td>
            <td>{`${status[x.status]}`}</td>
          </tr>
        )
      }
    })
    let gotXBoard=st.xBoards.map(x=>{return x.uniqueId})
//     cl(gotXBoard)
//     cl(st.modules)
    st.modules.forEach((m,i)=>{
//       cl(m)
      let i2=st.xBoards.length+i
      if(!gotXBoard.includes(m.uid)){
        let bgColor=(i2==st.selXBoard)?"#CCEEFF":"#FFFFFF"
        rows.push(
          <tr
            key={i2}
            style={{cursor:"pointer",backgroundColor:bgColor}}
            onClick={e=>this.onChange("selXBoard",{selXBoard:i2})}
          >
            <td></td>
            <td></td>
            <td width="10" style={cellStyle}><button style={butStyle}
            type="button" className="material-icons trash" aria-label="delete graph"
              onClick={e=>this.onChange("deleteModule",{e:e,uid:m.uid})}
            >
              delete_outline
              </button></td>
            <td>{m.name}</td>
            <td>{m.type}</td>
            <td colSpan="2">{`(unassigned)`}</td>
            <td>{``}</td>
            <td>{``}</td>
            <td>{``}</td>
            <td>{``}</td>
            <td>{``}</td>
          </tr>
        )
      }
//       cl(m)
    })
//             <td>{`${x.uniqueId}`}</td>
    return(
      <table style={{marginBottom:20}}><tbody>
      {showHeader()}
      {rows}
      </tbody></table>
    )
  }

  showExpansionSelect=()=>{
    let st=this.state
    let opts=st.xBoards.map((x,i)=>{return({v:i,t:`Bd${i+1}`})})
    return(
      <C18Select01 parms={{
        label:"Select Expansion",
        valueName:"selXBoard",
        selXBoard:st.selXBoard,
        opts:opts,
        onChange:(e,v)=>{this.onChange("selXBoard",v)}//this.onChange,
      }}/>
    )
  }

  makeLabCode=(qrcode)=>{
    let code=intToFiveBit(qrcode)
    return `${code.substring(0,3)}-${code.substring(3,6)}`
  }

  showExpansionEdit=()=>{
    let st=this.state
//     cl(st)
    let xBoard=st.xBoards[st.selXBoard]
//     cl(xBoard)
    var editIO,info,type,uid
    if(xBoard){
      type=this.xBoardTypes[xBoard.type]
      let fields=[]
      if(xBoard.numOuts){fields.push("startOut")}
      if(xBoard.numInps){fields.push("startInp")}
      let mod=(st.modules.filter(m=>{return m.uid==xBoard.uniqueId})||[])[0]
//       cl(mod)
      uid=xBoard.uniqueId
      if(mod){
        info=([
          <tr key={1}><td>Label Code:</td><td>{this.makeLabCode(mod.qrcode)}</td></tr>,
          <tr key={2}><td>QRCode:</td><td>{mod.qrcode}</td></tr>
        ])
      }
      editIO=(
        <div>
          <label htmlFor="modName">Module Name</label>
          <input type="text" id="modName"
            value={mod?.name||""}
            onChange={e=>{this.onChange("modName",{uid:mod?.uid||0,name:e.currentTarget.value})}}
          />
          <div className="clearfloat"></div><br/>
          <label htmlFor="mbAdd">Modbus Address</label>
          <input type="number" id="mbAdd"
            value={st.mbAddr}
            onChange={e=>{this.onChange("mbAddr",{mbAddr:+(e.currentTarget.value||0)})}}
          />
          <C18Button00 type="button" className="filled"
            onClick={e=>{this.onChange("updateMbAddr")}}
          >Update</C18Button00><br/><br/>
          <div className="clearfloat"></div><br/>

          {fields.includes("startInp")&&
            <>
              <label htmlFor="startInp">Starting Input</label>
              <input type="number" id="startInp"
                value={xBoard.startInp}
                onChange={e=>{this.onChange("xBoard",{startInp:+(e.currentTarget.value||0)})}}
              />
              <div className="clearfloat"></div><br/>
            </>
          }
          {fields.includes("startOut")&&
            <>
              <label htmlFor="startOut">Starting Output</label>
              <input type="number" id="startOut"
                value={xBoard.startCh}
                onChange={e=>{this.onChange("xBoard",{startCh:+(e.currentTarget.value||0)})}}
              />
              <div className="clearfloat"></div><br/>
            </>
          }
        </div>
      )
    }else{
      let ind=st.selXBoard-st.xBoards.length
//       cl(ind)
      let mod=st.modules[ind]
      if(!mod){return}
//       cl(mod)
      type=this.xBoardTypes[this.mod2Type[mod.type]]
      uid=mod.uid||0
      let labCode=""
      if(mod.qrcode){
        let code=intToFiveBit(mod.qrcode)
        labCode=`${code.substring(0,3)}-${code.substring(3,6)}`
      }

      cl(st.modules[ind])
      info=([
        <tr key={1}><td>Label Code:</td><td>{labCode}</td></tr>,
        <tr key={2}><td>QRCode:</td><td>{mod.qrcode}</td></tr>
      ])
      editIO=(
        <>
          <label htmlFor="modName">Module Name</label>
          <input type="text" id="modName"
            value={mod.name}
            onChange={e=>{this.onChange("modName",{uid:mod.uid,name:e.currentTarget.value})}}
          />
          <div className="clearfloat"></div><br/>
        </>

      )
//       uid=6
    }
//     cl(xBoard)
//     if(!xBoard){return}
//     cl(xBoard)
//     let fields={"2":["startOut"],"13":["startInp"],"12":["startOut","startInp"],"14":
//       ["startOut"]}[xBoard.type]||[]
//     cl(fields)
//         Starting Output Channel
//         Modbus Address
//         Modbus mbPort
//         Starting Input Channel
//         {`Modbus Port: ${xBoard.mbPort}`}<br/>
//         {`Modbus Addr: ${xBoard.mbAddr}`}<br/>
//         {`Number of Outs: ${xBoard.numOuts}`}<br/>
//         {`Number of Ins: ${xBoard.numInps}`}<br/>
    return(
      <div>
        <h2>{type}</h2>
        {/* <table style={{width:"initial"}}><tbody> */}
        {/* <tr><td>Unique ID:</td><td>{uid.toString(16)}</td></tr> */}
        {/* {info} */}
        {/* </tbody></table><br/> */}
        {/* {editIO} */}
      </div>
    )

  }

  showErrorMsg=()=>{
    let st=this.state
    if(st.errorMsg){
      return(
        <div style={{color: "red"}}>
          {st.errorMsg}
        </div>
      )
    }
  }

  showAddModule=()=>{
    let st=this.state
//     cl(st)
//     let disable=!(st.selAccount&&st.selSite&&st.selZone)
//     disabled={disable}
    return(
      <div>
      <label htmlFor="moduleCode">Module Code</label>
      Enter code (abc-123), numeric QR Code, or UID in Hex<br/>
      <input type="text" style={{display:"inline-block"}}
      value={st.addModuleCode}
      onChange={e=>this.onChange("addModuleCode",{addModuleCode:e.currentTarget.value})}
      />&nbsp;
      <C18Button00 type="button" className="outlined"
        onClick={e=>this.onChange("addModuleFromCode")}>Add Module Code</C18Button00>
      </div>
    )
  }

  showClearScan=()=>{
//         <div style={{width: 20, height: 20, backgroundColor:"red", position:"absolute"}}>here</div>
    let pos=this.state.workPos
    let st=this.state
//     cl(st)
    return(
      <div>
        {(pos!=0)&&
          <div style={{width: this.workWidth, height:20, backgroundColor:"#EEEEEE", marginBottom:20,
            borderRadius:5,
          }}>
            <div style={{position:"absolute",width:pos, height:20, borderRadius:5,
              backgroundColor:"#AACCAA",zIndex:0}}></div>
              <div style={{width:this.workWidth, position:"absolute",
                textAlign:"center", zIndex:10}}>Working. . .</div>

          </div>
        }
        <C18Button00 type="button" className="filled"
          onClick={e=>{this.onChange("reload")}}
        >Reload</C18Button00><br/><br/>
        <C18Button00 type="button" className="danger"
          onClick={e=>{this.onChange("clearExpansion")}}
        >
        Clear Expansion Module Table
        </C18Button00><br/>
        <br/>
        <C18Button00 type="button" className="danger"
          onClick={e=>{this.onChange("scanExpansion")}}
        >Scan Expansion Modules</C18Button00><br/><br/>
        <C18Button00 type="button" className="danger"
          onClick={e=>{this.onChange("scanExpansion2")}}
        >New Scan on Comm:</C18Button00>&nbsp;
        <input type="number" id="scanCom"
          value={st.scanCom||1}
          onChange={e=>{this.onChange("scanCom",{scanCom:+(e.currentTarget.value||0)})}}
        />

        <br/><br/>

        <C18Button00 type="button" className="danger"
          onClick={e=>{this.onChange("assignAddresses")}}
        >Assign Addresses</C18Button00>
      </div>
    )
  }

  render(){
//         {this.showExpansionSelect()}
    if(this.state.loaded){
//         {this.showClearScan()}
//         {this.showAddModule()}<br/>
      return(
        <div id="/expansion">
        {this.showExpansionTable()}
        {this.showExpansionEdit()}
        {/* {this.showErrorMsg()} */}
        </div>
      )
    }else{
      return <div id="content-area">loading equipment. . .</div>
    }
  }
}

export default C18Expansion00;
