import React from 'react';
import FUISelect from './FUISelect';
import FUINumerical from './FUINumerical';
import Checkbox from './Checkbox';
import TimeOfDay from './TimeOfDay';
import {proportionals} from '../utilsFui'
import {cl, globs, constant} from '../../components/utils/utils';

class EquipmentOverride extends React.Component{
  constructor(props) {
    super(props);
//     cl(globs)
//     cl(props)
    this.updateFui()
    this.subscribe_updateFui=globs.events.subscribe("updateFui", this.updateFui)
    this.subscribe_savePageEvent=globs.events.subscribe("savePageEvent", this.savePageEvent)
    this.chan=+props.zuci.split("-")[2]
    this.proportionalFuiPages=[
      "channel_Vent_Roof",
      "channel_Vent_Retractable_Roof",
      "channel_Vent_Side_Wall",
      "channel_Vent_Roof_1900",
      "channel_Vent_Retractable_Roof_1900",
      "channel_Vent_Side_Wall_1900",
      "channel_Curtain",
      "channel_Mix_Valve",
      "channel_Proportional_Microzone",
      "channel_PID",
      "channel_Variable_Out",
      "channel_Mix_Valve_Analog",
      "channel_DLI_Analog",
      "channel_PID_analog",
      "channel_Variable_Out_1900",
      "channel_Mix_Valve_Analog_1900",
      "channel_DLI_Analog_1900",
      "channel_PID_analog_1900",
    ]
    this.timedOnly=[
      "channel_Irrigation_Scheduled",
      "channel_Irrigation_Accumulated_Light",
      "channel_Irrigation_Cycle",
      "channel_Irrigation_Trigger",
      "channel_Irrigation_Soil_Trigger",
      "channel_Irrigation_VPD",
      "channel_Fill_Valve",
      "channel_PID",
      "channel_PID_analog",
      "channel_Variable_Out",
    ]
    
    let isProp=this.proportionalFuiPages.includes(props.type)
    
//     if(this?.props?.custom?.overrides){
//       if(this.props.custom.overrides.c){
//         this.chan=this.props.custom.overrides.c
//       }
//       setVal.overrides=this.props.custom.overrides
//     }
    var hp
//     cl(this.chan)
//     cl(props.current.channelInfo)
//     let chType=+props.current.channelInfo[this.chan].channelType
//     cl(chType)
//     let isProp=proportionals.includes(chType)
//     cl(chType,isProp)
    if(isProp){
      hp=[{v:-1, t:"---"}]
      for(let i=0;i<100;i++){hp.push({v:i, t:`${i} %`})}
    }else{
      hp=[
      {v:-1, t:"---"},
      {v:0, t:"Off"},
      {v:1, t:"On"}
      ]
    }
//     cl(hp)
    this.humProps={options:hp}
  }

  updateFui=()=>{
//     cl("update")
    if(!this.changed){
      this.props.getValue(this.props.ind, {type: "array", count: 9})
    }
  }
  
  savePageEvent=()=>{
    this.changed=false
  }

  componentWillUnmount=()=>{
    this.subscribe_updateFui.remove()
    this.subscribe_savePageEvent.remove()
  }

  getValue=(index,valueDescription)=>{
  }
  
  onChange=(index,value)=>{
    this.changed=true
//     cl(this.props.type)
    let vals=this.props.value.slice(0)
    cl(vals)
    let undefVal=(this.props.current.gwType==1900)?-1:""
    vals[index]=((value.value==undefined)||(value.value=="-1"))?undefVal:+value.value
    this.props.onChange(this.props.ind, {value: vals})
  }
  
  showHumidityOverride=(vals)=>{
//     cl(vals)
    vals=vals.slice(0)// convert to a local copy
    let pid0=this.props.cont.props
//     let humProps={options:[
//       {v:-1, t:"---"},
//       {v:0, t:"Off"},
//       {v:1, t:"On"},
//     ]}
    let key=5
//     cl(vals)
//     cl(this.humProps)
    for(let i=key;i<key+4;i++){
      let v=vals[i]
      if((v==undefined)||((typeof v=="string")&&(v.length==0))){vals[i]=-1}
//       if(vals[i]==undefined){vals[i]=-1}
    }
//     cl(vals)
    return ([
      <h2 key={100}>Humidity Overrides</h2>,
      <table key="101"><tbody><tr>
      <td>
      <FUISelect key={key} getValue={this.getValue} onChange={this.onChange} custom={this.humProps} 
      ind={key}
      value={vals[key]||0}
      saveOK={this.props.saveOK}
      cont={{title: "DH1", pid: pid0+key++}} />
      </td>
      <td>
      <FUISelect key={key}  getValue={this.getValue} onChange={this.onChange} custom={this.humProps} 
      ind={key}
      value={vals[key]}
      saveOK={this.props.saveOK}
      cont={{title: "DH2", pid: pid0+key++}} />
      </td>
      <td>
      <FUISelect key={key}  getValue={this.getValue} onChange={this.onChange} custom={this.humProps} 
      ind={key}
      value={vals[key]}
      saveOK={this.props.saveOK}
      cont={{title: "Low Temp", pid: pid0+key++}} />
      </td>
      <td>
      <FUISelect key={key}  getValue={this.getValue} onChange={this.onChange} custom={this.humProps} 
      ind={key}
      value={vals[key]}
      saveOK={this.props.saveOK}
      cont={{title: "Humidify", pid: pid0+key++}} />
      </td>
      </tr></tbody></table>
      ]
    )
  }
  
  showProportionalForce=(key,vals,pid0)=>{
//     cl(this.props)
    let prop=this.proportionalFuiPages.includes(this.props.type)
//     cl(prop)
    let forceProps={options:[
      {v:0, t:"Off"},
      {v:1, t:"On"},
    ]}
//     cl(vals[key])
    if(prop){
      return(
        <FUINumerical key={key}  getValue={this.getValue} onChange={this.onChange} custom={{"width":150,"decimal":0,"step":1,"unit":"%"}} 
        ind={key}
        value={vals[key]||0}
        saveOK={this.props.saveOK}
        cont={{title: "Force", pid: pid0+key++}}
        style={{ width: "60px", minWidth: "60px", maxWidth: "80px" }}
         />
      )
    }else{
      return(
        <FUISelect key={key}  getValue={this.getValue} onChange={this.onChange} custom={forceProps} 
        ind={key}
        value={vals[key]}
        saveOK={this.props.saveOK}
        cont={{title: "Force", pid: pid0+key}} />
      )
    }
  }

  showTimedOverride = (vals) => {
    let pid0 = this.props.cont.pid;
    let intProps = { options: [
      { v: 0, t: "Daily" },
      { v: 1, t: "One Time" },
    ]};
    let key = 0;
    return (
      <><h2 key={100} style={{ marginRight: '10px' }}>Timed Override</h2>
      <div key="timedOverride" className="responsive-flex">

      <span style={{ display: 'flex', alignItems: 'center' }}>
        <Checkbox key={key} getValue={this.getValue} onChange={this.onChange}
          ind={key}
          value={vals[key] || false}
          title="Enable"
          saveOK={this.props.saveOK}
          cont={{ pid: pid0 + key++ }} />
        </span>

        <FUISelect key={key} getValue={this.getValue} onChange={this.onChange}
          custom={Object.assign({}, intProps, { break: "none" })}
          ind={key}
          value={vals[key]}
          saveOK={this.props.saveOK}
          cont={{ title: "", pid: pid0 + key++ }}
          style={{ marginRight: '0px' }} />
        <TimeOfDay key={key} getValue={this.getValue} onChange={this.onChange}
          title="From"
          ind={key}
          value={vals[key]}
          custom={{ float: "left" }}
          saveOK={this.props.saveOK}
          cont={{ title: "Low Temp", pid: pid0 + key++ }}
            style={{ minWidth: '90px' , marginRight: '0px' }}  />
        <TimeOfDay key={key} getValue={this.getValue} onChange={this.onChange}
          title="To"
          ind={key}
          value={vals[key]}
          custom={{ float: "left" }}
          saveOK={this.props.saveOK}
          cont={{ title: "Low Temp", pid: pid0 + key++ }}
           style={{ minWidth: '90px' , marginRight: '0px' }} />
           <div style={{ display: 'flex', alignItems: 'center' ,  }}>
      {this.showProportionalForce(key, vals, pid0)}
      </div>
      </div></>
    );
  }

  render(){
    let vals=this.props.value
    if(typeof vals != "object") vals=[0,0,0,0,0,"","","",""]
//     cl(vals)
    let showHum=!this.timedOnly.includes(this.props.type)
//     cl([showHum,this.props.type])
//     cl(this.timedOnly)
//     cl(timedOnly.includes(this.props.type))
    return(
      <div
        title={(this.props?.rest?.parent||(e=>{}))({uri:"pid",pr:this.props})}
      >
      {showHum&&this.showHumidityOverride(vals)}
              {this.showTimedOverride(vals)}
      </div>
    );
  }
}

export default EquipmentOverride ;
