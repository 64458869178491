import React from 'react';
import C18Input00 from './C18Input00'
import C18Select00 from './C18Select00'
import C18Button00 from './C18Button00'
import C18TabsHeader00 from './C18TabsHeader00'
import {wsTrans,getParamId2,doGetPostBasic,getDatapointName} from '../utils/utils'
import {loadSitesInfo,loadZonesInfo,loadUsersInfo,loadAccountInfo,
  loadGatewaysInfo,getGatewayInfo,acctFeature,loadSiteData,
  getGatewayIndex, addToAdminLog,getSiteName,getZoneName} from './C18utils'
import {dbVals,} from '../../components/utils/http'
import {cl, constant, globs, getTime,getRandomString,
  dateToDisplayDate} from '../../components/utils/utils';
import history from "../../history"

const progressSize=100

class C18SaveRestore00 extends React.Component{
  constructor(props) {
    super(props);
    this.state={
      saveName:"",
      loaded:false,
      tab:"restore",
      selConfig:-1,
      sortMode:1,
      restoreProgress:0,
      copyConfig:0,
      comparePage:"diff",
      copyKeys:{},
      compKeys:{},
      copyList:[],
      compList:[],
      bothList:[],
      diffList:[],
      operationProgress:0,
      operationType:"none",

    }
    this.tabs=[
      {v:"save",t:"Save"},
      {v:"restore",t:"Restore"},
//       {v:"nor",t:"Pearl Nor"},
    ]
//     cl(props)
    this.restoreParts=[
// Equipment: 500-599, 600-1023
// Aux Controls: 1216-1343, 1344-3639, 3640-3895
// Expansion Boards: 1152-1215
// Controller Settings: 4216-4555, 5024-5027
// Zone Settings: 4716-5021,5022-5023
// ECPH: 4556-4566,4567-4635
// Setpoints:4636-4715
// Sensors: 5200-7199
      {v:"allParts",t:"All Parts",r:[[0,24576]]},
      {v:"xBoards",t:"Exp Modules",r:[[3896,4216],[17920,18432]]},
      {v:"sensors",t:"Sensors",r:[[5200,7250],[16384,17920],[19968,20016],[20480,22528],
        [4255,4277],[4293,4294],[4305,4305],[4307,4307],[4343,4344],[4355,4357],[4360,4360]]},
      {v:"equipment",t:"Equipment",r:[[500,1024],[23040,23552]]},
      {v:"setpoints",t:"Setpoints",r:[[4636,4716]]},
      {v:"auxControls",t:"Aux Controls",r:[[1216,3896],[5040,5200]]},
      {v:"intercontroller",t:"Intercontroller",r:[[4716,5022],[20016,20176]]},
      {v:"controller",t:"Controller",r:[[4216,4556],[4556,4636],[5022,5028],[5031,5040],
        [18432,18688],[20176,20480]]},
//       {v:"allParts",t:"All Parts",r:[[0,1151],[1216,24576]]},// skips xBoards
//       {v:"eqip",t:"Equipment",r:[[500,1023]]},
//       {v:"auxControls",t:"Aux Controls",r:[[1216,3895]]},
// //       {v:"xBoards",t:"Expansion Boards",r:[[1152,1215]]},
//       {v:"contSettings",t:"Controller Settings",r:[[4216,4555],[5024,5027],
//         [17408,17919],[7199,24576]]},
//       {v:"zoneSettings",t:"Zone Settings",r:[[4716,5023]]},
//       {v:"ecph",t:"ECPH",r:[[4556,4635]]},
//       {v:"setpoints",t:"Setpoints",r:[[4636,4715]]},
//       {v:"sensors",t:"Sensors",r:[[5200,7199]]},
// //       {v:"pAnnex",t:"Extra Pearl",r:[[7199,24576]]},
    ]
    this.loadInfo()
//     this.subscribe_savePageEvent=globs.events.subscribe("savePageEvent",this.saveZoneGroups)
//     this.props.parms.onChange({cmd:"savePage", data:{savePage:true}})
//     this.loadInfo()
    this.setBreadCrumbs()
    this.subscribe_newData=globs.events.subscribe("data",this.newData)
    this.subscribe_operationProgress=globs.events.subscribe("operationProgress",
      this.operationProgress)
    this.subscribe_toClientEvent=globs.events.subscribe("toClient",this.toClient)

  }
  
  componentWillUnmount=()=>{
    this.subscribe_newData.remove()
    this.subscribe_operationProgress.remove()
    this.subscribe_toClientEvent.remove()
  }

  toClient=(obj)=>{// copied from manageGateways
    cl(obj)
    let st=this.state
    let da=obj.data
    var prog
    if(da.index>=0){
      prog=progressSize*(da.index+1)/da.cnt
    }else{
      prog=0-st.downloadProgress
    }
    this.setState({operationProgress:prog})
  }

  operationProgress=(msg)=>{
    cl(msg)
    let op=msg.progress
    if(op==100){op=0}
    this.setState({operationProgress:op})
  }

  setBreadCrumbs=()=>{
    let pa=this.props.parms
    let siteName=getSiteName(pa.site)
    let zoneName=getZoneName(pa.zone)
//     cl(pa)
    if(pa){
      pa.onChange(
        {
          cmd: "breadcrumbs",
          data:
            {breadcrumbs: [
              {t:"Sites", url:"/usa/c18/sites"},
              {t:siteName, url:`/usa/c18/sites/${pa.site}`},
              {t:zoneName, url:`/usa/c18/sites/${pa.site}/zones/${pa.zone}`},
              {t:"Zone Config", url:`/usa/c18/sites/${pa.site}/zones/${pa.zone}/saveRestore`},
            ]},
        },
      )
    }
  }
  
//   componentWillUnmount=()=>{
//     this.subscribe_savePageEvent.remove()
//   }
  set800RestoreParts=()=>{
    this.restoreParts=[
      {v:"allParts",t:"All Parts",r:[[0,1007]]},
      {v:"config",t:"Config",r:[[0,150]]},
      {v:"equipment",t:"Equipment",r:[[150,250]]},
      {v:"xBoards",t:"Exp Boards",r:[[250,850]]},
      {v:"controller",t:"Controller",r:[[1000,1007]]},
    ]
  }

  loadInfo=async()=>{
    await loadUsersInfo()
    await loadZonesInfo()
    await loadSitesInfo()
    this.zone=globs.zonesInfo.info.filter(z=>{return z.zoneId==this.props.parms.zone})[0]
//     cl(this.zone)
    if(this.zone.gatewayType==800){this.set800RestoreParts()}
    let user=globs.usersInfo.info.filter(u=>{
      return u.userId==globs.userData.session.userId})[0]
//     cl(user)
    let resp=await wsTrans("usa", {cmd: "cRest", uri: "/s/zoneConfig", method: "retrieve", 
      sessionId: globs.userData.session.sessionId, body: {}})
//     cl(resp)
    let saves=resp.data
//     cl(user)
    if(user?.p3){
      let resp2=await wsTrans("usa", {cmd: "cRest", uri: "/tp/tpZoneConfig", 
        method: "retrieve", sessionId: globs.userData.session.sessionId, 
        body: {accountId:user.p3AccountId}})
      saves=saves.concat(resp2.data)
    }else{}
    saves.forEach(sa=>{
      let zi=globs.zonesInfo.info.filter(z=>{return z.zoneId==sa.zoneId})[0]
      sa.zName=zi?.zoneName||"(None)"
    })
//     cl(saves)
    let flags={}
//     cl(this.restoreParts)
    this.restoreParts.forEach(rp=>{flags[rp.v]=true})
    resp=await this.loadConfig("wIjJ9QmLxbji3sbs")// configId of defaults
    if(!resp){
      resp=await this.loadConfig("QTVIEHSQ*XyVrx*g")// configId of defaults
//       cl(resp)
    }
    this.defaults={}
    if(resp){
      resp.forEach(d=>{
        this.defaults[`${d.c}-${d.i}`]=d.d
      })
    }
//     cl(defaults[113])
//     cl(Object.keys(this.defaults).length)
    this.setState({loaded:true,saves:saves,restoreFlags:flags})
//     this.getRestoreProgress()
  }

  saveConfig=async()=>{
    let user=globs.usersInfo.info.filter(u=>{
      return u.userId==globs.userData.session.userId})[0]
    cl(user)
    let pa=this.props.parms
    let st=this.state
    let zi=globs.zonesInfo.info.filter(z=>{return z.zoneId==pa.zone})[0]
    let configId=getRandomString(16)
    let query={siteId:pa.site,zoneId:pa.zone,zoneInd:zi.siteZoneIndex,
      configId:configId,name:st.saveName,zoneInfo:zi}
    let saves=st.saves.slice(0)
    if(user?.p3){
      query.accountId=user.p3AccountId
      cl(query)
      let resp=await wsTrans("usa", {cmd: "cRest", uri: "/tp/tpZoneConfig", method: "create", 
        sessionId: globs.userData.session.sessionId, body: query})
    }else{
      cl(query)
      let resp=await wsTrans("usa", {cmd: "cRest", uri: "/s/zoneConfig", method: "create",
        sessionId: globs.userData.session.sessionId, body: query})
    }
    query.ts=getTime()
    query.zName=zi.zoneName
    if(st.saveName.substring(0,19)=="VirtualZoneTemplate"){
      saves=saves.filter(s=>{return s.name!=st.saveName})
    }
    saves.push(query)
    this.setState({saves:saves, saveName: ""})
  }
  
  makePidRange=(flags)=>{
    let ranges=[]
    let parts=this.restoreParts
    if(flags.allParts){
      ranges.push(
//         {i:{$gte:0,$lte:0x5000}}// all 1800 values
        [0,0x5000]// all 1800 values
      )
    }else{
      Object.keys(flags).forEach(k=>{
        if(flags[k]){
          let rp=this.restoreParts.filter(p=>{return p.v==k})[0]
          rp.r.forEach(ids=>{
            ranges.push(ids)
//               {i:{$gte:ids[0],$lte:ids[1]}}
//             )
          })
        }
      })
    }
    return ranges
  }
  
  restoreConfig=async(type)=>{
//     cl("restore config")
//     return
    let st=this.state
    let pa=this.props.parms
    cl(st.restoreFlags)
    let ranges=this.makePidRange(st.restoreFlags)
    cl(ranges)
//     cl(st)
    let config=st.saves.filter(sa=>{return sa.configId==st.selConfig})[0]
//     cl(config)
    let zi=globs.zonesInfo.info.filter(z=>{return z?.zoneId==config?.zoneId})[0]
    let zi2=globs.zonesInfo.info.filter(z=>{return z.zoneId==pa.zone})[0]
//     cl(pa.zone)
//     cl(zi2)
    let da=new Date(1000*config.ts)
    let da2=`${dateToDisplayDate(da,"mm/dd/yyyy")} ${dateToDisplayDate(da,"hh:mm")}`
    let text=`This will restore the selected Parts of Settings from the Saved Configuration 
    called ${config.name} of ${zi?.zoneName||"Unknown"} saved on ${da2} to the current zone, ${zi2.zoneName}. Are you sure you want to do this?`
    let res=await this.props.parms.getPopup({text:text, buttons:["Cancel","Yes"]})
    if(res=="Yes"){
//       cl("restore")
      let query={
        configId:config.configId,
        siteId:globs.userData.session.siteId,
        gatewayId:zi2.gatewayId,
        zoneInd:zi2.siteZoneIndex,
        ranges:ranges,
        restoreType:type,
        fullRestore:st.restoreFlags.allParts,
      }
      let resp=await wsTrans("usa", {cmd: "cRest", uri: "/s/zoneConfig", method: "update",
        sessionId: globs.userData.session.sessionId, body: query})
      globs.userData.siteLoaded=null
      setTimeout(x=>{loadSiteData(this.zone.siteId)},3000)
//       await loadSiteData(this.zone.siteId)
      this.setState({operationProgress:0})
      if(resp?.data?.data){
        let text=`CRC is ${resp.data.data.crcOK?"OK!":"Bad"}`
        await this.props.parms.getPopup({text:text, buttons:["OK"]})
      }
//       let resp=await wsTrans("usa", {cmd: "cRest", uri: "/s/mqttQueueCount", method: "retrieve",
//         sessionId: globs.userData.session.sessionId, body: {gatewayId:this.zone.gatewayId}})

      this.maxQueueCount=1
      this.curQueueCount=1
//       setTimeout(this.getRestoreProgress, 3000)
//       this.getRestoreProgress()
    }
  }

  deleteConfig=async()=>{
    let st=this.state
    let saves=st.saves.slice(0)
    let cf=saves.filter(s=>{return s.configId==st.selConfig})[0]
//     cl(cf)
    let text=`Are you sure that you want to delete the Saved Configuration called ${cf.name}?`
    let res=await this.props.parms.getPopup({text:text, buttons:["Cancel","Yes"]})
    if(res=="Yes"){
      for(let i=0; i<st.saves.length;i++){
        if(st.saves[i].configId==st.selConfig){
          saves.splice(i,1)
        }
      }
      let resp=await wsTrans("usa", {cmd: "cRest", uri: "/s/zoneConfig", method: "delete", 
        sessionId: globs.userData.session.sessionId, body: {configId:st.selConfig}})
      this.setState({saves:saves})
    }
  }

  sortSaves=(tasks,sortMode)=>{
    let field=["","zName","name","ts"][Math.abs(sortMode)]
    let dir=(sortMode>0)?1:-1
    tasks.sort((a,b)=>{
      
      if(a[field]>b[field]){return dir}
      if(a[field]<b[field]){return 0-dir}
      return 0
    })
    return tasks
  }

  markImage=async(e)=>{
    let files=e.target.files[0]
    if (!files) return
    let pa=this.props.parms
    let st=this.state
    let configId=getRandomString(16)
    let zi=globs.zonesInfo.info.filter(z=>{return z.zoneId==pa.zone})[0]
    let query={siteId:pa.site,zoneId:pa.zone,zoneInd:zi.siteZoneIndex,
      configId:configId,name:st.saveName,zoneInfo:zi}
    query.ts=getTime()
    query.zName=zi.zoneName
    let saves=st.saves.slice(0)
    saves.push(query)
    let data = new FormData()
    data.append("type", "config")
    data.append("siteId", pa.site)
    data.append("zoneId", pa.zone)
    data.append("zoneInd", zi.siteZoneIndex)
//     data.append("zoneInfo", zi)
    data.append("configId", configId)

    data.append("name", st.saveName)
    data.append("sessionId", globs.userData.session.sessionId)
    data.append('file', files)
    let url=`${constant.expressUrl}/usa/images`
    let method="POST"
    let type="multipart/form-data"
    let ret=await doGetPostBasic(url, method, data, type)
    let ret2 = await ret.json()
    cl(ret2)
    this.setState({saves:saves})
    this.onChange("config",{config: ret2.config})
  }

  downloadConfig=()=>{
    let st=this.state
    let sessionId=globs.userData.session.sessionId
    cl(st)
    let zc=(st.saves.filter(sa=>{return sa.configId==st.selConfig})||{})[0]
    cl(zc)
    let name=zc.name.replaceAll(" ","_")
    cl(name)
    let url=`${constant.expressUrl}/usa/csv/Cfg_${name}.csv?type=zoneConfig&session=${sessionId}&configId=${zc.configId}`
    window.open(url)
  }

  loadConfig=async(configId)=>{
    let resp=await wsTrans("usa", {cmd: "cRest", uri: "/s/zoneConfig", method: "retrieve",
      sessionId: globs.userData.session.sessionId, body: {configId:configId}})
    let cfg=resp.data[0]?.zoneConfig
    if(!cfg){return}
    cfg=cfg.filter(c=>{return c.f!=0})
//     cl(cfg)
//     if(cfg){cl(cfg[400])}
    return cfg
  }

  removeDefaults=(config)=>{
//     cl(config.length)
    let config2=config.filter(c=>{
      return c.d!=this.defaults[`${c.c}-${c.i}`]
    })
//     cl(config2.length)
    return config2
  }

  selCompEntry=(vals)=>{
    let st=this.state
    let list={
      copy:st.copyList,
      comp:st.compList,
      both:st.bothList,
      diff:st.diffList,
    }[st.comparePage]
    cl(vals)
    cl(list[vals.ind])
    cl(st.copyKeys[list[vals.ind]])
    let key=list[vals.ind]
    let parts=key.split("-")
    let compEntry={
      chan:parts[0],
      pid:parts[1],
      copy:st.copyKeys[list[vals.ind]],
      comp:st.compKeys[list[vals.ind]],
    }
    this.setState({compEntry:compEntry})

  }

  compareConfig=async()=>{

    var makeKeys=(conf)=>{
      let keys={}
      conf.forEach(
        c=>{
          if(c.d!==null){keys[`${c.c}-${c.i}`]=c.d}
        }
      )
      return keys
    }

    var doCompare=(conf1,conf2)=>{
      let k1=makeKeys(conf1)
      let k2=makeKeys(conf2)
      let only1=[]
      let diff=[]
      let both=[]
      Object.keys(k1).forEach(k=>{
        if(k in k2){
          if(k1[k]==k2[k]){both.push(k)}else{diff.push(k)}
          delete k2[k]
        }else{
          only1.push(k)
        }
      })
      return {k1:k1, k2:k2, only1: only1, only2: Object.keys(k2), diff: diff, both:both}
    }

    let st=this.state
//     cl(st)
    let copyConfig=await this.loadConfig(st.copyConfig)
//     copyConfig=this.removeDefaults(copyConfig)
    let compConfig=await this.loadConfig(st.selConfig)
//     compConfig=this.removeDefaults(compConfig)
    let comp=doCompare(copyConfig,compConfig)
    cl(comp)
    this.setState({
      copyKeys:comp.k1,
      compKeys:comp.k2,
      copyList:comp.only1,
      compList:comp.only2,
      bothList:comp.both,
      diffList:comp.diff,
    })
  }

  onChange=async(type,vals)=>{
    cl(type,vals)
    let st=this.state
//     cl(st.saves)
    switch(type){
      case "tabs":
//         cl(vals)
        this.setState(vals)
        break
      case "saveName":
        this.setState(vals)
        break
      case "saveConfig":
        this.saveConfig()
        break
      case "sort":
        if(Math.abs(vals.sortMode)==st.sortMode){
          vals.sortMode=0-st.sortMode
        }
        let saves=this.sortSaves(st.saves.slice(0),vals.sortMode)
        vals.saves=saves
//         Object.assign(vals,{saves:saves})
        this.setState(vals)
        break
      case "selConfig":
        if(vals.selConfig==st.copyConfig){
          vals.copyConfig=-1
        }
        this.setState(vals)
        break
      case "flags":
        let flags=Object.assign({},st.restoreFlags)
        if(vals.item=="allParts"){
          Object.keys(flags).forEach(k=>{
            flags[k]=vals.flag
          })
        }else{
          flags[vals.item]=vals.flag
          if(!vals.flag){flags["allParts"]=false}
        }
        this.setState({restoreFlags:flags})
        break
      case "restoreConfig":
        this.restoreConfig("json")
        break
      case "norRestoreConfig":
        this.restoreConfig("nor")
        break

      case "deleteConfig":
        this.deleteConfig()
        break
      case "downloadConfig":
        this.downloadConfig()
        break
      case "copyConfig":
        this.setState({copyConfig:st.selConfig})
        break
      case "compareConfig":
        this.compareConfig()
        break
      case "compareShow":
        this.setState(vals)
        break
      case "selCompEntry":
        this.setState(vals)
        return this.selCompEntry(vals)
      case "saveToNor":
      case "restoreFromNor":
      case "basicDefault":
      case "factDefault":
      case "resetCont":
        this.setState({operationType:type,operationProgress:0})
        return this.sendControllerCommand(type)

//       case "saveToNor":
// //         cl("save to nor")
//         this.savePearlNor()
//         break
//       case "restoreFromNor":
// //         cl("restore from nor")
//         this.restorePearlNor()
//         break
//       case "defaultConfig":
// //         cl("restore from nor")
//         this.defaultConfig()
//         break
    }
  }

  showUpload=()=>{
    return(
      <>
          <form style={{display:"inline-block"}}>
            <C18Button00 type="button" className="filled"
            onClick={()=>this.onChange("uploadConfig",{})}>Upload</C18Button00>

            <C18Input00 type="file"  onChange={this.markImage} style={{
              width:82,
              height:40,
              marginLeft:-82,
              marginTop:"initial",
              textAlign:"initial",
              cursor: "pointer",
            }}/>
          </form>
      </>
    )
  }
 
  showSave=()=>{
//     cl("save")
//     cl(this.props)
    let pa=this.props.parms
    let zi=globs.zonesInfo.info.filter(z=>{return z.zoneId==pa.zone})[0]
    let si=globs.sitesInfo.info.filter(s=>{return s.siteId==pa.site})[0]
//     let si=globs.sitesInfo.info.filter(s=>{return s.siteId=pa.site})
//     cl([zi,si])
    let zName=zi.zoneName
    let sName=si.name
    let desc=`This will save the current settings of ${zName} in ${sName}, with \
the current date and the name given below.`
    return(
      <div>
        <p>{desc}</p>
        <label htmlFor="save-name">Configuration Save Name</label>
        <C18Input00 type="text" id="save-name" className="with-right-button"
          value={this.state.saveName}
          onChange={e=>this.onChange("saveName",{saveName:e.currentTarget.value})}
        />
        <C18Button00 type="button" className="filled"
        onClick={()=>this.onChange("saveConfig",{})}>Save</C18Button00>
        &nbsp;{this.showUpload()}
      </div>
    )
  }
  
  showSaveHead=()=>{
    let sort=this.state.sortMode// 1-5 for the columns
    let icons=[]
    for(let i=0;i<3;i++){
      let icon=""
      if(Math.abs(sort)==i+1){
        icons.push((sort<0)?"keyboard_arrow_up":"keyboard_arrow_down")
      } else{
        icons.push("")
      }
    }
    return(
      <tr>
          <th><button type="button" aria-label="sort"
            onClick={()=>this.onChange("sort",{sortMode:1})}
          >Zone <span className="material-icons-outlined">{icons[0]}</span></button></th>
          <th><button type="button" aria-label="sort"
            onClick={()=>this.onChange("sort",{sortMode:2})}
          >Name <span className="material-icons-outlined">{icons[1]}</span></button></th>
          <th><button type="button" aria-label="sort"
            onClick={()=>this.onChange("sort",{sortMode:3})}
          >Date <span className="material-icons-outlined">{icons[2]}</span></button></th>

      </tr>
    )
  }
  
  showRestoreParts=()=>{
//     cl(this.state.restoreFlags)
//     let lines=[]
    let lines=this.restoreParts.map((rp,i)=>{
//       cl(this.restoreParts)
//       cl(this.state.restoreFlags[rp.v])
      return(
        <tr key={`rp${i}`}>
        <td><input id={`rp${i}`} type="checkbox"
          checked={this.state.restoreFlags[rp.v]}
          onChange={e=>this.onChange("flags",{item:rp.v, flag:e.currentTarget.checked})}
        />
        <label htmlFor={`rp${i}`} style={{display:"inline-block",marginLeft:20}}>
        {rp.t}</label>
        </td>
        
        </tr>
      )
    })
    return(
      <div style={{width:500,padding:20,
        borderStyle:"solid",borderWidth:1,borderRadius:10}}>
        <table style={{width:"100%"}}><tbody>
        
        {lines}
        </tbody></table>
      </div>
    )
  }
  
  showSaveList=()=>{
    let st=this.state
    let lines=st.saves.map((sa,i)=>{
      // cl(sa)
      let da=new Date(1000*sa.ts)
      let da2=`${dateToDisplayDate(da,"mm/dd/yyyy")} ${dateToDisplayDate(da,"hh:mm")}`
//       let zi=globs.zonesInfo.info.filter(z=>{return z.zoneId==sa.zoneId})[0]
//       cl(zi)
//       cl(zi.zoneName)
      let bgColor=(sa.configId==st.selConfig)?"#CCFFFF":"#FFFFFF"
      if(sa.configId==st.copyConfig){bgColor="#FFFFCC"}
      let zName=sa.zName
      let cName=sa.name
      let maxLen=25
      if(zName.length>maxLen){zName=sa.zName.substring(0,maxLen-3)+"..."}
      if(cName?.length>maxLen){cName=sa.name.substring(0,maxLen-3)+"..."}
      return(
        <tr key={i} style={{cursor:"pointer",backgroundColor:bgColor}}
          onClick={e=>this.onChange("selConfig",{selConfig: sa.configId})}
        >
        <td>{zName}</td>
        <td>{cName}</td>
        <td>{da2}</td>
        </tr>
      )
    })
    return(
      <div style={{width:500,padding:20,
        borderStyle:"solid",borderWidth:1,borderRadius:10}}>
        <table style={{width:"100%"}}><tbody>
        {this.showSaveHead()}
        {lines}
        </tbody></table>
      </div>
    )
  }

//   getRestoreProgress=async()=>{
//     let resp=await wsTrans("usa", {cmd: "cRest", uri: "/s/mqttQueueCount", method: "retrieve",
//       sessionId: globs.userData.session.sessionId, body: {gatewayId:this.zone.gatewayId}})
// //     cl(resp)
//     let queueCount=resp.data.queueCount
//     if(this.maxQueueCount<queueCount){this.maxQueueCount=queueCount}
//     this.curQueueCount=queueCount
//     let prog=(this.maxQueueCount-this.curQueueCount)/(this.maxQueueCount||1)
// //     if(prog==0){prog=1}
// //     cl(this.maxQueueCount,this.curQueueCount,prog)
//     if(this.curQueueCount<1){
//       prog=1
//     }else{
//       setTimeout(this.getRestoreProgress,5000)
//     }
//     this.setState({restoreProgress:prog})
//     if(prog==1){
//       await this.props.parms.getPopup({text:"Configuration has been Restored", buttons:["OK"]})
//     }
//   }

  showRestoreProgress=()=>{
    let rp=this.state.operationProgress/100
    if(rp>0){
      let wid=500*rp
      return(
        <div>
          <h3>Restore OK!</h3>
        </div>
      )
//         <>
//           <div style={{
//             width:500,height:10,
//             border:1,borderStyle:"solid",borderRadius:5,borderColor:"#AAAAAA",
//           }}>
//             <div style={{
//               width:wid,height:10,backgroundColor:"#ccffcc",
//               border:1,borderRadius:5,borderColor:"#AAAAAA",
//
//             }}>
//             </div>
//           </div>
//           <br/>
//         </>
    }else{return null}
  }

  showRestore=()=>{
    let st=this.state
    let delDisable=this.state.selConfig==-1
//     cl(this.zone)
    let disabled=(delDisable)||((!this.zone.connected)&&(!this.zone.virtual))
    var showCompareConfig=()=>{
      // if(acctFeature("compareConfig")){
        return(
          <>
            &nbsp;<C18Button00 type="button" className="filled" disabled={disabled}
            onClick={()=>this.onChange("copyConfig",{})}>Copy</C18Button00>
            &nbsp;<C18Button00 type="button" className="filled" disabled={disabled}
            onClick={()=>this.onChange("compareConfig",{})}>Compare</C18Button00>
          </>
        )
      // }
    }

    var doShow=()=>{
      let st=this.state
      let list={
        copy:st.copyList,
        comp:st.compList,
        both:st.bothList,
        diff:st.diffList,
      }[st.comparePage]
//       cl(list)
      let rows=list.map((l,i)=>{
        let parts=l.split("-")
        let pid=+parts[1]
        let name0=getDatapointName(pid,1900)
//         cl(name0)
        var name1
        if(!name0){
          name1=l
        }else{
          name1=name0.split(" - ")[1]
          if(!name1){name1=l}
        }
//         cl(pid)
        return(
          <tr key={i}><td title={l} style={{cursor:"pointer"}}
          onClick={x=>this.onChange("selCompEntry",{ind:i})}
          >
          {`${name1}`}
          </td></tr>
        )
      })
//       cl(st.compList)
      return rows
    }

    var showCompEntry=()=>{
      let st=this.state
      if(st.compEntry){
        let ce=st.compEntry
        return(
          <div style={{float:"left",marginLeft:50,marginTop:10,
            border:"1px solid black",borderRadius:10}}>
            <table><tbody>
              <tr><td>Channel</td><td>{ce.chan}</td></tr>
              <tr><td>Pid</td><td>{ce.pid}</td></tr>
              <tr><td>Copy</td><td>{JSON.stringify(ce.copy||"")}</td></tr>
              <tr><td>Compare</td><td>{JSON.stringify(ce.comp||"")}</td></tr>
            </tbody></table>
          </div>
        )
      }

    }

    var showCompareResults=()=>{
      let st=this.state
      var mkStyle=(page)=>{
        let bgColor=(page==st.comparePage)?"#EEEEEE":"#FFFFFF"
        return {textAlign:"center",cursor:"pointer",backgroundColor:bgColor}
      }
      // if(acctFeature("compareConfig")){
        return(
        <div style={{float:"left",marginLeft:50}}>
          <h3>Compare Results</h3>
          <div style={{width:400,height:500,
            border:"1px solid #AAAAAA",borderRadius:10,overflowY:"auto",
//             cursor:"pointer",
          }}>
            <table width="100%" style={{marginTop:8}}><tbody>
            <tr>
              <td
                style={mkStyle("copy")}
                onClick={e=>this.onChange("compareShow",{comparePage:"copy"})}>Copy
                <br/>{st.copyList.length}</td>
              <td
                style={mkStyle("comp")}
                onClick={e=>this.onChange("compareShow",{comparePage:"comp"})}>Comp
                <br/>{st.compList.length}</td>
              <td
                style={mkStyle("both")}
                onClick={e=>this.onChange("compareShow",{comparePage:"both"})}>Both
                <br/>{st.bothList.length}</td>
              <td
                style={mkStyle("diff")}
                onClick={e=>this.onChange("compareShow",{comparePage:"diff"})}>Diff
                <br/>{st.diffList.length}</td>
            </tr>
            <tr><td colSpan="4">
            <table width="100%"><tbody>
            {doShow()}
            </tbody></table>

            </td></tr>
            </tbody></table>
          </div>
        </div>
        )
      // }
    }

    return(
      <div>
        <div style={{float:"left"}}>
          <h3>Saved Configurations</h3>
          {this.showSaveList()}
          <div className="clearfloat"/><br/>
          <C18Button00 type="button" className="danger"
          disabled={delDisable}
          onClick={e=>this.onChange("deleteConfig")}>Delete</C18Button00>

          <h3>Parts to Restore</h3>
          {((!this.zone.connected)&&(!this.zone.virtual))&&
            <h4 style={{color:"red"}}>This zone is not connected, and cannot be restored to</h4>
          }
          {this.showRestoreParts()}
          <div className="clearfloat"/><br/>
          {this.showRestoreProgress()}
          &nbsp;<C18Button00 type="button" className="filled" disabled={disabled}
          onClick={()=>this.onChange("restoreConfig",{})}>Restore</C18Button00>
          &nbsp;<C18Button00 type="button" className="filled" disabled={disabled}
          onClick={()=>this.onChange("downloadConfig",{})}>Download</C18Button00>
          {showCompareConfig()}
        </div>
        {showCompareResults()}
        {showCompEntry()}
      </div>
    )
//           <C18Button00 type="button" className="filled" disabled={disabled}
//           onClick={()=>this.onChange("norRestoreConfig",{})}>NORestore</C18Button00>
  }

  newData=(zData)=>{
//     cl(zData)
    let st=this.state
    let pid=getParamId2("1900","pearl_snaps","operation_progress")
    let zInd=this.zone.siteZoneIndex
    let params=zData.params
    let opProg=0
    for(let i=0;i<params.length;i++){
      let p=params[i]
      if((p.z==zInd)&&(p.c==240)&&(p.i==pid)){opProg=p.d}
    }
//     cl(opProg)
    let opType=(opProg<100)?st.operationType:"none"
    if(opProg>0){
      this.setState({operationProgress:opProg,operationType:opType})
    }
  }

//   updateOperationProgress=()=>{
//     let st=this.state
//     let pid=getParamId2("1900","pearl_snaps","operation_progress")
//     let zInd=this.zone.siteZoneIndex
//     let opProg=dbVals.z[zInd][240][pid]
//     let opType=(opProg<100)?st.operationType:"none"
//     this.setState({operationProgress:opProg,operationType:opType})
//   }

  sendControllerCommand=async(cmd)=>{
    cl(this.zone.gatewayId)
//     return
    await wsTrans("usa", {cmd: "cRest", uri: "/s/controller", method: "update",
      sessionId: globs.userData.session.sessionId, body:
      {gatewayId:this.zone.gatewayId,cmd:cmd}})
  }

//   savePearlNor=()=>{
//     sendControllerCommand("saveToNor");
//     let st=this.state
// //     this.setState({operationType:"saveToNor",operationProgress:0})
// //     this.updateOperationProgress()
//     wsTrans("usa", {cmd: "cRest", uri: "/s/controller", method: "update",
//       sessionId: globs.userData.session.sessionId, body:
//       {gatewayId:this.zone.gatewayId,cmd:"saveToNor"}})
//   }
//
//   restorePearlNor=()=>{
//     let st=this.state
//     this.setState({operationType:"restoreFromNor",operationProgress:0})
//     wsTrans("usa", {cmd: "cRest", uri: "/s/controller", method: "update",
//       sessionId: globs.userData.session.sessionId, body:
//       {gatewayId:this.zone.gatewayId,cmd:"restoreFromNor"}})
//   }
//
//   defaultConfig=()=>{
//     let st=this.state
//     wsTrans("usa", {cmd: "cRest", uri: "/s/controller", method: "update",
//       sessionId: globs.userData.session.sessionId, body:
//       {gatewayId:this.zone.gatewayId,
//         cmd:"defaultConfig",
//         }})
//   }

  showPearlNor=()=>{
    let st=this.state
    cl(st)
    var saveToNorProgress=(st.operationType=="saveToNor")?st.operationProgress:0
    var restoreFromNorProgress=(st.operationType=="restoreFromNor")?st.operationProgress:0
    var saveWid=+(400*saveToNorProgress/100)
    var restWid=+(400*restoreFromNorProgress/100)
//     cl(saveWid,restWid)
    let dispSaveProgress=st.operationType=="saveToNor"
    let dispRestProgress=st.operationType=="restoreFromNor"


    return(
      <div>
        <div style={{width:500,border:"1px solid",borderRadius:10,
          padding:20}}>
          <h3 style={{marginTop:-10}}>Save To Nor</h3>
          <C18Button00 type="button" className="filled"
          onClick={()=>this.onChange("saveToNor",{})}>Save</C18Button00>
          <div style={{width:400,height:20,border:"1px solid",marginTop:10,
            display:(dispSaveProgress)?"block":"none"
          }}>
            <div style={{width:saveWid,height:18,backgroundColor:"#88EE88"}}/>
          </div>
        </div>

        <div style={{width:500,border:"1px solid",borderRadius:10,
          padding:20,marginTop:20}}>
          <h3 style={{marginTop:-10}}>Restore from Nor</h3>
          <C18Button00 type="button" className="filled"
          onClick={()=>this.onChange("restoreFromNor",{})}>Restore</C18Button00>
          <div style={{width:400,height:20,border:"1px solid",marginTop:10,
            display:(dispRestProgress)?"block":"none"
          }}>
            <div style={{width:restWid,height:18,backgroundColor:"#88EE88"}}/>
          </div>
        </div>

        <div style={{width:500,border:"1px solid",borderRadius:10,
          padding:20,marginTop:20}}>
          <h3 style={{marginTop:-10}}>Default Config</h3>
          <C18Button00 type="button" className="filled" onClick={()=>this.onChange(
            "basicDefault",{})}>Basic Defaults</C18Button00>&nbsp;
          <C18Button00 type="button" className="filled" onClick={()=>this.onChange(
            "factDefault",{})}>Factory Defaults</C18Button00>&nbsp;
          <C18Button00 type="button" className="filled" onClick={()=>this.onChange(
            "resetCont",{})}>Reset Controller</C18Button00>&nbsp;
        </div>

      </div>
    )
  }

  showSaveRestore=()=>{
    switch(this.state.tab){
      case "save":
        return this.showSave()
        break
      case "restore":
        return this.showRestore()
        break
      case "nor":
        return this.showPearlNor()
        break
    }
    
  }
  
  render(){
//     cl(this.props)
//     cl(this.state)
    if(this.state.loaded){
      return(
        <div id="zone_save_restore">
          <div className="tabs">
            <C18TabsHeader00
              tabId={this.state.tab}
              tabs={this.tabs}
              onChange={o=>this.onChange("tabs",o)}
            />
            <div className="tab-panels">
              <div className="tab-panel selected" role="tabpanel">
                {this.showSaveRestore()}
                <div className="clearfloat"></div>
              </div> 
            </div>
          </div>
        </div>
      )
    }else{
      return <div id="content-area">loading. . .</div>
    }
  }
}
      
export default C18SaveRestore00;
