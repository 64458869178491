import React from 'react';
import C18Select00 from './C18Select00';
import { makeOpts } from '../utils/utils';
import { cl } from '../../components/utils/utils';

class C18SearchTabs00 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          loaded: false,
          selectedId: "a036uzDCxohZ7ovD",
          selectedAlarmId: "",
          serverStatus: "",
          allAlarms: [],
          allAlarmEmails: [],
          recentAlarms: [],
          selectedAlarms: [],
          recentSelectedAlarms: [],
          selectedAlarmEmails: [],
          selectedRecord: "",
          sortColumn: "",
          sortOrder: "",
        };
  
        this.host = 'https://api01.link4cloud.com'
        this.bgColor1=(this.dark)?"#202020":"#FFFFFF"
        this.bgColor2=(this.dark)?"#203030":"#FFFFFF"
        this.selColor1=(this.dark)?"#606060":"#DDDDDD"
      }
  
    showSearchBox=(type)=>{
    //     cl(st.searchRes[type])
        return(
            <div style={{width:"100%",height:200,backgroundColor:this.bgColor2,marginRight:30,
            padding:10,overflowY:"auto",
            }}>
            <table><tbody>
            {this.props.searchRes[type].map((r,i)=>{
            let txt=(r.t)?r.t:"No Name"
    //         cl(r)
            let bg=(r.v==this.props.searchSel)?this.selColor1:null
            return(
                <tr key={i} style={{backgroundColor:bg,cursor:"pointer"}}
                onClick={e=>this.props.onChange("sel",{type:type,searchSel:r.v,id:r.id})}
                ><td>{txt}</td></tr>
            )})}
            </tbody></table>
            </div>
        )
        }

    showSelectType=(relTypes,type)=>{
      let parms={
        account:{t:"Accounts",v:"accountSel"},
        user:{t:"Users",v:"userSel"},
        site:{t:"Sites",v:"siteSel"},
        gateway:{t:"Gateways",v:"gatewaySel"},
        zone:{t:"Zones",v:"zoneSel"},
      }[type]
      return(
        <div className="custom-select">
          <div className="clearfloat"></div><br/>
          <label htmlFor="">Related {parms.t}</label>
          <C18Select00 id=""
            parms={{list:true,height:200}}
            value={this.props[parms.v]}
            onChange={e=>{
              let vals={}
              vals[parms.v]=e.currentTarget.value
              this.props.onChange(parms.v,vals)}
            }
          >
            {makeOpts(relTypes)}
          </C18Select00>
        </div>
      )
    }
              
    showAccounts=()=>{
        //     let selAccount=st.accounts.filter(a=>{return a.accountId==st.selectedId})[0]
            let height = "auto"
            let type = "account"
            return(
              <div style={{width:300,height:height,backgroundColor:this.bgColor1,borderStyle:"solid",
                borderWidth:1,borderRadius:10,boxShadow:"5px 10px 10px #888888",padding:"0px 10px 20px 10px",
                margin:20,verticalAlign:"top",
                display:"inline-block"
              }}
                >
              <h3 title="Click to Refresh" style={{cursor:"pointer"}}
                onClick={e=>{this.props.onChange("refresh",{element:"accounts"})}}
              >Accounts</h3>
              {
                ((this.props.searchRes?.account?.length)?
                this.showSearchBox("account"):
                ((this.props.relAccounts.length>0)&&this.showSelectType(this.props.relAccounts,type)))
              }
              </div>
            )
          }
        
    showSites=()=>{
    let type="site"
    let height = "auto"
    return(
        <div style={{width:300,height:height,backgroundColor:this.bgColor1,borderStyle:"solid",
        borderWidth:1,borderRadius:10,boxShadow:"5px 10px 10px #888888",padding:"0px 10px 20px 10px",
        margin:20,verticalAlign:"top",
        display:"inline-block"
        }}
        >
        <h3 title="Click to Refresh" style={{cursor:"pointer"}}
        onClick={e=>{this.props.onChange("refresh",{element:"sites"})}}
        >Sites</h3>
        {
        ((this.props.searchRes||{})[type]?.length)?
        this.showSearchBox(type):((this.props.relSites.length>0)&&this.showSelectType(this.props.relSites,type))
        }
        </div>
    )
    }
    
    showZones=()=>{
    let height = "auto"
    let type="zone"
    return(
        <div style={{width:300,height:height,backgroundColor:this.bgColor1,borderStyle:"solid",
        borderWidth:1,borderRadius:10,boxShadow:"5px 10px 10px #888888",padding:"0px 10px 20px 10px",
        margin:20,verticalAlign:"top",
        display:"inline-block"
        }}
        >
        <h3 title="Click to Refresh" style={{cursor:"pointer"}}
        onClick={e=>{this.onChange("refresh",{element:"zones"})}}
        >Zones</h3>
        {
        ((this.props.searchRes||{})[type]?.length)?
        this.showSearchBox(type):((this.props.relZones.length>0)&&this.showSelectType(this.props.relZones,type))
        }
        </div>
    )
    }

    showUsers=()=>{
    let type="user"
    let height = "auto"
    return(
        <div style={{width:300,height:height,backgroundColor:this.bgColor1,borderStyle:"solid",
        borderWidth:1,borderRadius:10,boxShadow:"5px 10px 10px #888888",padding:"0px 10px 20px 10px",
        margin:20,verticalAlign:"top",
        display:"inline-block"
        }}
        >
        <h3 title="Click to Refresh" style={{cursor:"pointer"}}
        onClick={e=>{this.onChange("refresh",{element:"users"})}}
        >Users</h3>
        {
        (
        ((this.props.searchRes?.account?.length)?
        this.showSearchBox(type):
        ((this.props.relUsers.length>0)&&this.showSelectType(this.props.relUsers,type)))
        )
        }
        </div>
    )
    }

    showGateways=()=>{
      let st=this.state
      let height = "auto"

      const { gateways, searchRes, selectedType, relGateways } = this.props;
      // let selGateway=gateways/*.gw*/.filter(g=>{return g.gatewayId==st.selectedId})[0]
      let type="gateway"
      return(
        <div style={{width:300,height,backgroundColor:this.bgColor1,borderStyle:"solid",
          borderWidth:1,borderRadius:10,boxShadow:"5px 10px 10px #888888",padding:"0px 10px 20px 10px",
          margin:20,verticalAlign:"top",
          display:"inline-block"
        }}
          >
        <h3 title="Click to Refresh" style={{cursor:"pointer"}}
          onClick={e=>{this.props.onChange("refresh",{element:"gateways"})}}
        >Gateways</h3>
        {/* {((searchRes||{})[type]?.length)?
          this.showSearchBox(type):
          ((selectedType==type)?
            this.showSelectedInfo(type):
            (relGateways.length>0)&&
              this.showSelectType(relGateways,type)
          )
        } */}
        {
        ((this.props.searchRes||{})[type]?.length)?
        this.showSearchBox(type):((this.props.relGateways.length>0)&&this.showSelectType(this.props.relGateways,type))
        }
        </div>
      )
    }
          
      
  render() {
    const { pageType } = this.props;
    return (
        <div>
            <label style={{marginTop:"8px" , fontSize:"15px" , color:"#393D46"}}>Search</label>
            <input type="text" style={{width:"30%" , padding:"15px"}}
            value={this.props.oneSearch}
            onChange={e=>this.props.onChange("updSearch",{oneSearch:e.currentTarget.value})}
            />
            {this.props.accounts && this.showAccounts()}
            {this.props.users && this.showUsers()}
            {this.props.sites && this.showSites()}
            {this.props.zones && this.showZones()}
            {this.props.gateways && this.showGateways()}
        </div>
        ); 
    }
}
export default C18SearchTabs00;