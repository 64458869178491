import React from 'react';
import {cl,show,constant,globs,getTime,getRandomString, allZones, allAddons, zoneTypes, getRecurlySuccessUrl, recurlySubdomain,az,validAppVersions,ufs,
  dateToDisplayDate, avatarCount,allTimezones,zoneQtyDiscount
} from '../../components/utils/utils'
import config from '../../components/utils/config'
import {openWS} from '../../components/utils/ws';

import {doGetCurSite,doGetCurZone} from '../../components/utils/ws'
import {doGetPost} from '../../components/utils/httpauth'
import {sendArray,putZValue,dbVals,getZoneControllers} from '../../components/utils/http'
import C18Host00 from './C18Host00';
import {updateSidebar, getSidebar} from './C18Host00'

import {sensorIds, getParamId,getParamId2,getParamId800,stages,humStages,tempUnit,
  lightUnit,windUnit,
  bVolUnit,sVolUnit,nuteUnit,getPearlUsed,saveSession} from '../utils/utils'
import {wsTrans,checkLoggedIn,getParmValue,getUId,saveTokens,setSensorIdNames,siteSensorNames,saveLocalStorage,getLocalStorage} from '../utils/utils'

import {p,pi,pInd} from '../../components/utils/paramIds'
import history from '../../history'


/* React Native Restful Transactions */
var restKey=0
var restArr={}

/* Godot Restful Transactions */
var restKeyGd=0
var restArrGd={}

var rnRest=(uri,method,body)=>{
  return new Promise((r,e)=>{
    restArr[restKey]={r:r,e:e}
    let msg=JSON.stringify({cmd:"rest",key:restKey++,uri:uri,method:method,body:body})
    window.ReactNativeWebView.postMessage(msg)
  })
}

var rnCl=(body)=>{
  let msg=JSON.stringify({cmd:"cl",key:restKey++,body:body})
  window.ReactNativeWebView.postMessage(msg)
}

var resp=(obj)=>{
  restArr[+obj.key].r(obj.data)
}

var restResp=(obj,data)=>{
  let obj2={
    cmd:"resp",
    key:obj.key,
    data: data,
  }
  let msg=JSON.stringify(obj2)
  window.ReactNativeWebView.postMessage(msg)
}

var handleRnMsg=(obj)=>{
  let cmds={resp:resp,rest:rest}
  if(cmds[obj.cmd]){cmds[obj.cmd](obj);}
}

var rest=(obj)=>{
  let endPoints={"/time":time, "/device":device, "/sidebar":sidebar, 
  "/authenticate":authenticate, "/gj":growJournal, "/url2":url, "/utils": webUtils,
  "/task": tasks,}
  try {
    endPoints[obj.uri](obj)
  } catch (e) {
    rnCl(e)
  }
}

var webUtils=(obj)=>{
  switch (obj.method) {
    case "random":
      getRandomString(16)      
      restResp(obj, {randomStr: getRandomString(16)})
      break
  }
}

var time=(obj)=>{
  restResp(obj,"dataBack")
}

var device=async(obj)=>{
  switch (obj.method) {
    case "retrieve":
      // get available device info to send to device (name, theme, etc)
      let deviceId = getUId()
      restResp(obj, deviceId)
      break
    case "update":
      // update device table with device info (token, etc) using device id (if supplied)
      await loadDevicesInfo()
      // update device on table with info
      await updateDeviceInfo(obj.body)
      restResp(obj, {device: globs.device, session: globs.userData.session})
      break
  }
}

/**************** Godot Helper Code *******************************/

// var gdRest=(uri,method,body)=>{
//   return new Promise((r,e)=>{
//     restArrGd[restKeyGd]={r:r,e:e}
//     let msg=JSON.stringify({"cmd":"rest","key":restKeyGd++,"uri":uri,"method":method,"body":body,"src":"cloud"})
//     window.postMessage(msg)
//   })
// }

var respGd=(obj)=>{
  restArrGd[+obj.key].r(obj.data)
}

var restRespGd=(obj,data)=>{
  cl("responding to godot's message")
  let obj2={
    "cmd":"resp",
    "key":obj.key,
    "data": data,
    "src":"cloud",
  }
  cl(obj2)
  let msg=JSON.stringify(obj2)
  window.postMessage(msg)
}

var handleGdMsg=(obj)=>{
  cl(obj)
  let cmds={resp:respGd,rest:restGd}
  cl(cmds[obj.cmd])
  cmds[obj.cmd](obj);
}

var restGd=(obj)=>{
  cl(obj)
  let endPoints={"/test": test}
  try {
    endPoints[obj.uri](obj)
  } catch (e) {
    cl(e)
  }
}

var test=async(obj)=>{
  cl("test godot end point")
  restRespGd(obj, "responding to godot msg")
}

var updateGodotState=(obj)=>{
//   cl("update")
//   cl(obj)
  switch(obj.data.state){
    case "ready":
//       cl("ready")
      globs.events.publish("godotReady")
      break
  }
//   cl("update")
}

var godotState=(obj)=>{
//   cl("state")
  switch(obj.method){
    case "update":
      return updateGodotState(obj)
  }
}

// Round Two:

var callCloud=(msg)=>{
//   cl(msg)
  if(gdRest.listener){return gdRest.listener(msg)}
  let cmds={"/godotState":godotState}
  let obj=JSON.parse(msg)
  cmds[obj.uri](obj)
  
//   cl(obj)
  return "return from call Cloud"
}

var gdSetListener=(listenFunc)=>{
  gdRest.listener=listenFunc
//   cl(gdRest.listener)
}

var gdRemoveListener=(listenerKey)=>{
  gdRest.listener=null
  cl(gdRest.listener)
}

// var testGodot=()=>{
//   cl("testing")
//   callGodot("/resource","create","body")
// }

var setGodot=(func)=>{
//   cl("setGodot")
  gdRest.gdFunc=func
  return //"godot is set"
//   setTimeout(testGodot,1000)
}

var restKeyGd=0
var restGdStore={}

var callGodot=(uri,method,body)=>{
//   console.trace()
//   cl(body)
//   console.trace()
  return new Promise((r,e)=>{
    let msg=JSON.stringify({"key":restKeyGd,"uri":uri,"method":method,"body":body})
    restGdStore[restKeyGd++]=[r,e]// save callback
    gdRest.gdFunc(msg)//uri,method,data
  })
}

var returnCloud=(msg)=>{
//   cl(msg)
  let obj=JSON.parse(msg)
  let funcs=restGdStore[obj.key]
  delete restGdStore[obj.key]
  funcs[0](obj)// resolve the promise
}

var gdRest={
  callCloud:callCloud,
  returnCloud:returnCloud,
  setGodot:setGodot,
  callGodot:(uri,method,data)=>callGodot(uri,method,data),
  listener:null,
  gdSetListener:gdSetListener,
  gdRemoveListener:gdRemoveListener,
}

var getLatLongCenter=(latLngArr)=>{// array of [lat,lng]
  let lats=latLngArr.map(ll=>{return +ll[0]})
  let lngs=latLngArr.map(ll=>{return +ll[1]})
//   cl(lats)
//   let latMin=
//   let latMax=
//   let lngMin=Math.min(lngs)
//   let lngMax=Math.max(lngs)
//   cl(Math.min(lats))
  let latMin=Math.min(...lats)
  let latMax=Math.max(...lats)
  let lngMin=Math.min(...lngs)
  let lngMax=Math.max(...lngs)
  let lat=(latMin+latMax)/2
  let lng=(lngMin+lngMax)/2
  let latCos=Math.cos(lat*constant.RAD_PER_DEG)
  
  let latRng=latMax-latMin
  let lngRng=(lngMax-lngMin)*latCos
  let rng=(lngRng>latRng)?lngRng:latRng
  var zoom
  if (rng<.005){
    zoom=20
  }else{
    zoom=22+Math.floor(Math.log(rng))
  }
  
//   let lat=(Math.min(...lats)+Math.max(...lats))/2
//   let lng=(Math.min(...lngs)+Math.max(...lngs))/2
  return {lat:lat,lng:lng,zoom:zoom,latCos:latCos}
//   cl(lats)
//   cl(lngs)
// //   let lngs=latLngArr.map{ll=>(return ll[1]})
//   
//   let latMin=1000
//   let latMax=-1000
//   let lngMin=1000
//   let lngMax=-1000
//   latLngArr.forEach(ll=>{
//     if(ll[0]<latMin){latMin}
//   })
}

var latLongCenterToPos=(lat,lng,cent,latCos)=>{
  let y=(lat-cent.lat)*constant.METERS_PER_DEG
  let x=(lng-cent.lng)*latCos*constant.METERS_PER_DEG
  return {x:x,y:y}
}

var posToLatLngCenter=(pos,cent)=>{
//   cl(pos)
//   cl(cent)
  let lat=cent.lat+(0-pos[2])/constant.METERS_PER_DEG
//   cl(cent.lat)
//   cl(pos[2])
  let lng=cent.lng+pos[0]/(cent.latCos*constant.METERS_PER_DEG)
//   let sz=1450/Math.pow(2,zm-16)
//   2**(zm-16)=1450/sz
//   ln(2**(zm-16))=ln(1450/sz)
//   (zm-16)=ln(1450/sz)/ln(2)
//   zm=16+ln(1450/sz)/ln(2)
//   ln(2**x)=x*ln(2)
  
  let zoom=16 + Math.round(Math.log(1450/pos[1])/Math.log(2))
//   cl([lat,lng,zoom])
  return {
    lat:lat,
    lng:lng,
    zoom:zoom,
  }
}

var downloadFile=async(url)=>{
  let res=await fetch(url,{})
  let ret=await res.arrayBuffer()
  let ret2=new Uint8Array(ret)
  return ret2
  }
  
var makeGodotResourceUrl=(path)=>{
    let proto="http"
    let host="ngsg.link4cloud.com:3105"
    return `${proto}://${host}/usa/godot/${path}`
  }

// this probably obsolete - nope! still used for zoneScenes
var resToGodot=async(path,type,contents)=>{//dir,name,
// contents is a Uint8Array
    let uri="/resource"
    let method="create"
    let data={path:path,type:type,contents:contents}//dir:dir,name:name,
//     cl(data)
//     cl("sending to godot")
    return await gdRest.callGodot(uri,method,data)
  }
  
// var oldLoadGodotPack=async(packName)=>{
// //     let path=parts.join("/")
// //     path=path.substr(0,path.lastIndexOf('.'))+'.pck'
//     let path=`packs/${packName}`
//     cl(path)//assets/greenhouse2.pck
//     let src=makeGodotResourceUrl(path)
//     cl(src)
//     let pck=await downloadFile(src)
//     cl(pck.byteLength)
//     await resToGodot(path,"pack",pck)
// }

// var createGodotDirectories=async()=>{
//   let dirs=["assets","packs","scenes","scripts"]
//   let files=await ufs.list()
//   cl(files)
//   for(let i=0;i<dirs.length;i++){
//     let d=dirs[i]
//     let path=`/userfs/${d}`
//     if(!files.includes(path)){
//       await ufs.set(path,"")
//     }
//   }
// //   dirs.forEach(d=>{
// //   })
// }
  
var loadGodotPack=async(packName)=>{// put pack in Godot file system
  let files=await ufs.list()// get list of files
  let packDir="/userfs/packs"
  if(!files.includes(packDir)){ufs.set(packDir,"")}// create the directory
  let resPath=`packs/${[packName]}`
  let resUrl=`/userfs/${resPath}`
  if(files.includes(resUrl)){return}// file already there
  let url=makeGodotResourceUrl(resPath)
  let cont=await downloadFile(url)// get the pack
  ufs.set(resUrl,cont)// put it in ufs
  return await gdRest.callGodot("/packs","update",{path:resPath})
  cl("wrote pack")
}
  
// var loadGodotPackO=async(packName)=>{
//   var sendPack=async(method)=>{
//     let uri="/packs"
//     let data={path:resPath}//dir:dir,name:name,
//     return await gdRest.callGodot(uri,method,data)
//   }
// // ['/userfs/packs', '/userfs/packs/electric_fan.pck'] - files created by Godot
// //   return oldLoadGodotPack(packName)
//   let files=await ufs.list()
//   let packDir="/userfs/packs"
//   if(!files.includes(packDir)){ufs.set(packDir,"")}
//   let resPath=`packs/${[packName]}`
//   await sendPack("create")// create a "placeholder"
//   let resUrl=`/userfs/${resPath}`
//   let tmp=await ufs.getb(resUrl)
//   cl(tmp?.byteLength)
//   cl(await ufs.list())
//   
//   if(!files.includes(resUrl)){// download and save pack if not there
//     let url=makeGodotResourceUrl(resPath)
//     let cont=await downloadFile(url)
//     ufs.set(resUrl,cont)
//     cl("wrote pack")
//   }else{
//     cl("already found pack")
//   }
//   await sendPack("update")
// }
  



  
/**************** End Godot Helper Code *******************************/

var taskCategories=[
  {v:"greenhouse",t:"Greenhouse"},
  {v:"waterPlants",t:"Water Plants"},
  {v:"hydroponic",t:"Hydroponic"},
  {v:"flowerBeds",t:"Flower Beds"},
  {v:"generalMaintenance",t:"General Maintenance"},
  {v:"manualLabor",t:"Manual Labor"},
  {v:"plantGrowth",t:"Plant Growth"},
  {v:"generalPlantCare",t:"General Plant Care"},
  {v:"straightTruck",t:"Straight Truck"},
  {v:"prune",t:"Prune"},
  {v:"seasonalPosition",t:"Seasonal Position"},
  {v:"plantMaintenance",t:"Plant Maintenance"},
  {v:"dig",t:"DIG"},
  {v:"assemblyLine",t:"Assembly Line"},
  {v:"regularBasis",t:"Regular Basis"},
]

var taskStatus=[
  {v:"template",t:"Template"},
  {v:"created",t:"Created"},
  {v:"approved",t:"Approved"},
  {v:"readyToStart",t:"Ready To Start"},
  {v:"inProgress",t:"In Progress"},
  {v:"problem",t:"Problem"},
  {v:"onHold",t:"On Hold"},
  {v:"cancelled",t:"Cancelled"},
  {v:"waitingForReview",t:"Waiting For Review"},
  {v:"done",t:"Done"},
]

var sidebar=async(obj)=>{
  return
  // switch (obj.method) {
  //   case "retrieve":
  //     // get current sidebar state for this page
  //     await C18Host00.getSidebar()
  //     break
  //   case "update":
  //     // update sidebar state for this page (on swipe from device)
  //     C18Host00.updateSidebar(obj)
  //     break
  // }
}

var authenticate=async(obj)=>{
  switch (obj.method) {
    case "retrieve":
      // give credentials to device? (if they agree to biologin)
      break
    case "update":
      cl("mobile login")
      // check if email and password exist, bioLogin is enabled
      if (obj.body.email && obj.body.password && obj.body.bioEnabled == 2) {
        // do auto login
        let r = await wsTrans("usa", {cmd: "cRest", uri: "/o/users/login", method: "create", 
          body: { 
            email: obj.body.email,
            password: obj.body.password,
            rememberMe: obj.body.rememberMe || false,
            force: obj.body.force || false // if logged in elsewhere
          }
        })
        switch(r.result) {
          case "ok":
          globs.sitesInfo.got=false
          globs.zonesInfo.got=false
          globs.usersInfo.got=false
          saveTokens(r.data.accessToken, r.data.refreshToken, r.session)
          setTimeout(()=>{ 
            // push history to c18host so nav state changes
            let adds = [{
              userId:globs.userData.session.userId,
              time:Math.floor(getTime()),
              action:"login",
              deviceName: globs.device?.deviceName,
              oldVal:"",
              newVal:"",
            }]
            addToAdminLog(adds)
            history.push("/usa/c18/sites")
            // window.location="/usa/c18/sites"
          },1000)

          // request info from device (now that we know who user is)
          // get deviceId
          let deviceId = getUId()
          let device = await rnRest("/device", "retrieve", {deviceId: deviceId, session: globs.userData.session, appVersion: validAppVersions})
          // rn app returns device with info
          if (device.deviceId) {
            getUId(device.deviceId)
          }
          // create device (if not already made)
          await loadDevicesInfo()
          // update device on table with info
          await updateDeviceInfo(device)
        break
      }
    }
  }
}

var growJournal=async(obj)=>{
  // test history push
  let method = obj.body.method
  let body = obj.body.body
  switch (method) {
    // case "retrieve":
      // break
    case "create":
      // create new gj entry with included msg and tags
      // needs adjustments since we don't have p
      let da=Math.floor(getTime())//new Date().getTime()/1000
      body.level = "account"
      let gjEntry={
        threadId: body.threadId||getRandomString(16),
        growJournalId: body.growJournalId||getRandomString(16),
        // level:p.level,
        level:body.level||"site", // for now
        siteId:(body.level=="site")?null:body.siteId,
        zoneId: ((body.level=="site")||(body.level=="account"))?null:body.zoneId,//this.props.parms.zone.zoneId,
        userId: globs.userData.session.userId,
        body: body.msg,//this.state.note,
        subject: "",//this.state.gjSubject,
        modTime: da,//this.state.modTime,//Math.floor(this.state.dispTime.getTime()/1000),
        dispTime: body.dispTime||da,//this.state.dispTime,//Math.floor(this.state.modTime.getTime()/1000),
        tags:body.tags,
        images: body.images || [],
        original: true,
      }
      // this.props.parms.onChange({cmd:"addGJEntry",gjEntry:gjEntry})
      cl(gjEntry)
      // this.setState({images:[],tags:[],note:""})
      let r2=await wsTrans("usa", {cmd: "cRest", uri: "/s/growJournal", method: "create", 
        sessionId: globs.userData.session.sessionId,
        body: gjEntry})
      break
    case "view":
      // bring user to view gj using logic in messagelist
      // mark notification as read
      body.flags=body.flags&~constant.CHAT_FLAG_UNREAD
//     cl(msg.flags)
      wsTrans("usa", {cmd: "cRest", uri: "/s/notifications", method:"update",
        sessionId: globs.userData.session.sessionId,
        body: {
          flags:body.flags,
          notificationId:body.notificationId,
        }})
      switch(body.level){
        case "account":
          history.push(`/usa/c18/sites/SPgrowJournal`)
          break
        case "site":
          history.push(`/usa/c18/sites/${body.site}/SPgrowJournal`)
          break
        case "zone":
          history.push(`/usa/c18/sites/${body.site}/zones/${body.zone}/SPgrowJournal`)
          break
        case "config":
          history.push(`/usa/c18/sites/${body.site}/fui/${body.pageType}/${body.zuci}/SPgrowJournal`)
          break
        default:
          history.push(`/usa/c18/sites/SPgrowJournal`)
          break
      }
      break
  }
}

var tasks=async(obj)=>{
  // test history push
  let method = obj.body.method
  let body = obj.body.body
  switch (method) {
    // case "retrieve":
      // break
    case "create":
      // TODO add some way to create task from push notification??
      break
    case "view":
    // mark notification as read
      body.flags=body.flags&~constant.CHAT_FLAG_UNREAD
//     cl(msg.flags)
      wsTrans("usa", {cmd: "cRest", uri: "/s/notifications", method:"update",
        sessionId: globs.userData.session.sessionId,
        body: {
          flags:body.flags,
          notificationId:body.notificationId,
        }})
      // bring user to view task
      // var url=`/usa/c18/tasks`
      // let configPart=""
      // if (body.category) {
      //   let cats=body.category.split(", ")
      //   cats.forEach(ca=>{
      //     let parts=ca.split("-")
      //     let cnt=(ca.match(/-/g)||[]).length
      //     if(parts.length==5){
      //       configPart=`/${parts[0]}/${parts[1]}-${parts[2]}-${parts[3]}-${parts[4]}`
      //     }
      //   })
      // }
      // if (body.site && body.site != "sites" && body.site != "taskEdit") {
      //   url=`/usa/c18/sites/${body.site}/SPtasks`
      // }
      // if (body.zone && body.zone != "") {
      //   url=`/usa/c18/sites/${body.site}/zones/${body.zone}/SPtasks`
      // }
      // if (configPart != "") {
      //   url=`/usa/c18/sites/${body.site}/fui${configPart}/SPtasks`
      // }
      // history.push(url)
      // break
      switch(body.level){
        case "account":
          history.push(`/usa/c18/sites/SPtasks`)
          break
        case "site":
          history.push(`/usa/c18/sites/${body.site}/SPtasks`)
          break
        case "zone":
        case "zones":
  //         cl(`/usa/c18/sites/${ta.site}/zones/${ta.zone}`)
          history.push(`/usa/c18/sites/${body.site}/zones/${body.zone}/SPtasks`)
          break
        case "config":
          history.push(`/usa/c18/sites/${body.site}/fui/${body.pageType}/${body.zuci}/SPtasks`)
          break
        default:
          history.push(`/usa/c18/tasks`)
          break
      }
  }
}

var url=async(obj)=>{
  switch (obj.method) {
    // case "retrieve":
    //   break
    case "update":
      // change page to url
      cl("changing page to " + obj.body.body.url)
      cl("***********************reload")
      window.location.href = obj.body.body.url
      // handling on device for now?
      break
  }
}

/* end React Native Restful Transactions */


var login=()=>{
//   cl(globs)
//   cl("c18utils login")
//   cl(show("main"))
  return new Promise(async(r,e)=>{
    
    if(globs?.userData?.loggedIn){
      r(true)
      return
    }
//     cl("check")
//     cl(show("main"))
    let res=await checkLoggedIn()
//     cl(res)
//     cl(res)
//     cl(globs.userData)
//     console.trace()
    if(res){
//       cl(show("main"))
      loadSitesInfo()//.then(r=>{cl(globs.sitesInfo)})
//       cl(show("main"))
      loadZonesInfo()//.then(r=>{cl(globs.zonesInfo)})
//       cl(show("main"))
      loadUser()
//       cl(show("main"))
      globs.userData.mode="c18"
      r(true)
    }else{// not true if "login type": register, forgot pw, etc.
//       cl("not logged in - redirect to login page")
//       globs.userData=null// now, 'loggedIn' is set from the start
      history.push('/usa/c18/login')
      r(false)
    }
  })
}

var loadUser=async()=>{
//   return
//   cl('load user')
//   cl(globs)
  let userId=globs.userData.session.userId
  let user=await wsTrans("usa", {cmd: "cRest", uri: "/s/users", method: "retrieve", 
    sessionId: globs.userData.session.sessionId, body: {userId:userId}},true,[userId])
//   cl(user)
//   cl(globs.userData.session.userId)
  globs.user=user.data[0]
}

pi[1900].zone_configuration_settings["Temperature Units"] = 11
pi[1900].zone_configuration_settings["Windspeed Units"] = 12
pi[1900].zone_configuration_settings["Light Units"] = 13
pi[1900].zone_configuration_settings["Volume Measurement Units"] = 143
pi[1900].zone_configuration_settings["Nutrient Units"] = 144


var getOneZoneSensorAlarmInfo=(z)=>{// the whole zone object
//   need to get al low and high and current for all sensors, and the unit!
// now, needs to handle expansion controller
  let zi=z.siteZoneIndex
  let zc=getZoneControllers(zi)
//   cl(zc)
  let tabInfo=pInd[1800].config_annex_sensors
  let size=tabInfo[2]
  let units={
    degf:tempUnit(zi),
    mph:windUnit(zi),
    wpm2:lightUnit(zi),
    EC:nuteUnit(zi),
    ml:sVolUnit(zi),
  }
  let sensorInfo={}
  zc.forEach((inNet,i)=>{
    if(inNet){
      let pref=`e${i}`
//       cl(pref)
      Object.keys(sensors).forEach(k=>{
        let se=sensors[k]
        let al=+getParmValue(zi,240+i,se.ind,+getParamId("config_annex_sensors","alarmLow"))||0
        let ah=+getParmValue(zi,240+i,se.ind,+getParamId("config_annex_sensors","alarmHigh"))||0
        let lv=+getParmValue(zi,240+i,se.ind,+getParamId("config_annex_sensors","alarmLevel"))||0
        let un=(units[se.unit]||{}).t||se.unit
//         cl(un)
        let val=+getParmValue(zi,240+i,0,+getParamId("snapshots",se.pid))||0
        sensorInfo[`${pref}${k}`]={val:val,al:al,ah:ah,un:un,lv:lv}
      })
    }
  })
//   cl(sensorInfo)
  z.current.sensorInfo=sensorInfo
//   cl(z.current)
}

var addAddedSensorZoneAlarmInfo=(z)=>{
//   console.trace()
//   cl(z)
//   cl(globs.addedSensorsInfo)
  let gai=globs.addedSensorsInfo.info
  let zi=z.siteZoneIndex
  if(globs.addedSensorsInfo.got){
    if(gai[z.siteId]){
      if(gai[z.siteId][zi]){
        if(z.current.sensorInfo){
//           cl(z)
          Object.keys(gai[z.siteId][zi]).forEach(k=>{
            let s=gai[z.siteId][zi][k]
            let id=`e0as${k}`
//             cl(zi,+k+60,getParamId("config_annex_sensors","alarmLow"))
            let al=+getParmValue(zi,240,+k+60,+getParamId("config_annex_sensors","alarmLow"))||0
            let ah=+getParmValue(zi,240,+k+60,+getParamId("config_annex_sensors","alarmHigh"))||0
/*
            let ah=+getParmValue(zi,240,+k,+getParamId("config_added_sensors","value"))||0*/
//             cl(al,ah)
            z.current.sensorInfo[id]={
              "val": 65,
              "al": al,
              "ah": ah,
              "un": "°F",
              "lv": 0             
            }
//             cl(s)
          })
        }
      }
    }
  }
}

var addModbusSensorZoneAlarmInfo=(z)=>{
//   cl(z)
}

var getOneZoneAlarmInfo=(z)=>{
//   let zd=dbVals.z[z.siteZoneIndex]
  let zi=z.siteZoneIndex
  globs.siteZoneTypes[zi]=z.gatewayType||"1800"
  let tanks=[]
  for(let i=0;i<8;i++){
    tanks.push({
      ec:+getParmValue(zi,192+i,0,getParamId("snapshot_ecphs","ec1")),
      ph:+getParmValue(zi,192+i,0,getParamId("snapshot_ecphs","ph1")),
      temp:+getParmValue(zi,192+i,0,getParamId("snapshot_ecphs","temperature1")),
               
      ecLAL:+getParmValue(zi,192+i,0,getParamId("configuration_ecph","lowECThreshold")),
      ecHAL:+getParmValue(zi,192+i,0,getParamId("configuration_ecph","highECThreshold")),
      phLAL:+getParmValue(zi,192+i,0,getParamId("configuration_ecph","lowPHThreshold")),
      phHAL:+getParmValue(zi,192+i,0,getParamId("configuration_ecph","highPHThreshold")),
    })
  }
//   cl(getParmValue(zi,241,0,getParamId("snapshots","inTemperature")))
//   cl(zi)
//   cl(getParamId("snapshots","inTemperature"))
//   cl(getParmValue(0,241,0,23))
  z.current={
    inTemp:+getParmValue(zi,240,0,getParamId("snapshots","inTemperature")),
    inTemp0:+getParmValue(zi,240,0,getParamId("snapshots","inTemperature")),
    inTemp1:+getParmValue(zi,241,0,getParamId("snapshots","inTemperature")),
    inTempLSP:+getParmValue(zi,240,0,getParamId("snapshots","heatSetpoint")),
    inTempHSP:+getParmValue(zi,240,0,getParamId("snapshots","coolSetpoint")),
    inTempLAL:+getParmValue(zi,240,0,getParamId("configuration_zone_settings","Low Alarm Temperature Below Heat Setpoint Threshold")),
    inTempHAL:+getParmValue(zi,240,0,getParamId("configuration_zone_settings","High Alarm Temperature Above Cool Setpoint Threshold")),
    inTempUnit:tempUnit(zi),
    ecUnit:nuteUnit(zi),
    tanks:tanks,
  }
//   cl("add added")
  getOneZoneSensorAlarmInfo(z)// adds sensor alarms to z.current
  addAddedSensorZoneAlarmInfo(z)
  addModbusSensorZoneAlarmInfo(z)
//   cl(z.current)
//   cl(dbVals.z[z.siteZoneIndex])
}

var getZoneAlarmInfo=(siteId)=>{// call with null siteId to get *all* sites
  globs.siteZoneTypes=[]
  if(globs.zonesInfo.got){
    globs.zonesInfo.info.forEach(z=>{
//       cl(z)
      if(!siteId||(z.siteId==siteId)){getOneZoneAlarmInfo(z)}
    })
  }
//   cl(globs.siteZoneTypes)
}

var saveZoneConnectedInfo=(r)=>{
  if(globs?.zonesInfo?.info&&(globs?.userData?.session)){
    (globs?.zonesInfo?.info).forEach(z=>{
    let inNetId=getParamId("configuration_controllers","isInNetwork")
      
      if(z.siteId==globs.userData.session.siteId){
        let inNet=((dbVals.z[z.siteZoneIndex]||[])[240]||{})[inNetId]
        if(inNet!=undefined){
//           cl(inNet)
          if(z.inNet!=inNet){
            z.inNet=+inNet
            wsTrans("usa", {cmd: "cRest", uri: "/s/zones", method: "update", 
              sessionId: globs.userData.session.sessionId, body: {zoneId:z.zoneId,inNet:z.inNet}})
          }
        }
      }
    })
  }
}

var getPearlChannels=()=>{
// go through *all* zones (0-63)
// for each get the xBoard info,
// in globs.sitesInfo.xBoards, for each zone, have a list of the channels and a/d flags
  let pearlZoneChannels={}
  for(let i=0;i<63;i++){
    if((dbVals.z[i]||{})[255]){
//       cl((dbVals.z[i]||{})[255])
//       cl(`Get Pearl ${i}`)
      let res=getPearlUsed(i)
//       cl(res)
      pearlZoneChannels[i]=res
    }
  }
//   cl("done get")
  return pearlZoneChannels
}

var getZoneContacts=(siteId)=>{
//   cl("get zone contacts")
  if(!globs.contacts[siteId]){globs.contacts[siteId]={}}
  (globs.zonesInfo.info||[])
  .filter(z=>{return z.siteId==siteId})
  .forEach(z=>{
    globs.contacts[siteId][z.siteZoneIndex]=z.updateTime
  })
//   cl(globs.contacts)
}

var checkConnected=(site,zInd)=>{
//   cl(site,zInd)
  let zone=(globs.zonesInfo.info.filter(z=>{
    return (z.siteId==site)&&(z.siteZoneIndex==zInd)})||[])[0]
  if((zone?.virtual)||(zone?.gatewayType==800)){return true}
//   cl(globs.userData?.session?.groupId)
  if(globs.userData?.session?.groupId){return true}
//   cl(site,zInd)
//   return false
  if(zInd==undefined){return true}
//   cl("c2")
  if(globs.contacts[site]){
    let now=getTime()
//     cl(globs.contacts)
    let contact=globs.contacts[site][zInd]
//     cl(globs.contacts[site][0])
//     cl(globs.contacts[site])
//     cl(`Check Connected: site: ${site}, zone: ${zInd}, contact: ${now-contact}`)
    return (now-contact)<120
  }
}

var loadZoneData=(zoneId)=>{
// this loads all current Zone data from db00c into dbVals
  if(!zoneId){return}
//   cl("get zone data")
  globs.userData.session.zoneId=zoneId
  let gzd=globs.zoneData
  return new Promise(async(r,e)=>{
    if((globs.userData.zoneLoaded)&&(globs.userData.zoneLoaded==globs.userData.session.zoneId)){ /*cl("aleady loaded");*/ r(); return }
    await openWS(constant.wsUrl)
    if(gzd.res){gzd.res.push(r); return}// add it to the list of responses
    gzd.res=[r]// create the list of responses
    doGetCurZone(
        r=>{
//           cl("got zone data")
          globs.userData.zoneLoaded=globs.userData.session.zoneId
          gzd.res.forEach(re=>{re(true)})// activate the list of responses
          delete gzd.res
        },
        e=>{})
  })
}

var loadSiteData=(siteId)=>{
//   cl(new Error())// to show the calling routine
// load the actual zone data using getcursite00
//   cl(siteId)
//   console.trace()
  if(!siteId){return}
  globs.userData.session.siteId=siteId
  let gsd=globs.sitesData
  return new Promise(async(r,e)=>{
    await openWS(constant.wsUrl)
    if((globs.userData.siteLoaded)&&(globs.userData.siteLoaded==globs.userData.session.siteId)){ /*cl("aleady loaded");*/ r(); return }
    if(gsd.res){gsd.res.push(r); return}// add it to the list of responses
    gsd.res=[r]// create the list of responses
    getZoneContacts(siteId)
//     cl(gsd.res)
    doGetCurSite(
        r=>{
//           cl("got cur site")
          getZoneAlarmInfo(siteId)
          saveZoneConnectedInfo(r)
          gsd.pearlChannels=getPearlChannels(r)
//           cl(gsd)
          globs.userData.siteLoaded=globs.userData.session.siteId
          saveSession(globs.userData.session)
          gsd.res.forEach(re=>{re(true)})// activate the list of responses
          delete gsd.res
//           cl(dbVals)
        },
        e=>{})
  })
}

var makeFeatureFlags=()=>{
  let gai=globs.accountInfo.info
  globs.userData.session.features=gai?.features
  saveSession(globs.userData.session)
//   cl("made")
//   cl(gai.features)
}

var acctFeature=(feat)=>{
//   cl(feat)
//   cl(globs.userData.session?.features)
//   cl(globs.userData.session.features)
//   cl(globs.userData.session.features.includes(feat))
//   cl(globs.userData.session.features)
  let ret=globs.userData.session?.features?.includes(feat)||false
//   cl(ret)
  return ret
}
  
var loadAccountInfo=()=>{
  let gai=globs.accountInfo
  return new Promise(async(r,e)=>{
    if(gai.got){r(true); return;}// already got
    if(gai.res){gai.res.push(r); return}// add it to the list of responses
    gai.res=[r]// create the list of responses
    wsTrans("usa", {cmd: "cRest", uri: "/s/accounts", method: "retrieve", 
      sessionId: globs.userData.session.sessionId, body: {accountId:globs.userData.session.accountId}},true).then(
        r=>{
          gai.info=r.data
          makeFeatureFlags()
//           cl(r.data)
          gai.got=true
          gai.res.forEach(re=>{re(true)})// activate the list of responses
          delete gai.res
        },
        e=>{})
  })
}

var loadSubscriptionInfo=async()=>{
//   cl("load sub info")
  let gsbi = globs.subscriptionInfo
  if(["mjdemo"].includes(config.host)){
    let end_date = new Date()
    end_date.setDate(end_date.getDate() + 30)
    gsbi.info= {
      "accountId": globs.userData.session.accountId,
      "add_ons": {"zone_qty":{"zone":{"unlocked":0,"locked":0}}},
      "end_date": end_date.toISOString(), // current time + 30 days
      "plan_code": "cloud2p0trialsub",
      "plan_name": "Link4 Cloud Trial Subscription",
      add_ons:{zone_qty:{zone:{unlocked:30}}},
    }
    return
  }
  return new Promise(async(r,e)=>{
    if(gsbi.got){r(true); return;}// already got
    if(gsbi.res){gsbi.res.push(r); return}// add it to the list of responses
    gsbi.res=[r]// create the list of responses
    let getSubscription = {
      cmd: "cRest", uri: "/s/subscriptions", method: "retrieve", sessionId: globs.userData?.session?.sessionId,
      body: {accountId: globs.userData.session?.accountId, domain: recurlySubdomain}
    }
//     console.trace()// login, loadZonesInfo, 
    let data = await wsTrans("usa", getSubscription,true)
    // cl(data.data)
    gsbi.info=data.data
    // check zones info
    // assign
    gsbi.got=true
    gsbi.res.forEach(re=>{re(true)})// activate the list of responses
    delete gsbi.res
  })
}

var updateSubscription = async (plan, add_ons)=>{
  let updateSub = {
    cmd: "cRest", uri: "/s/subscriptions", method: "update", sessionId: globs.userData.session.sessionId,
    body: {accountId: globs.userData.session.accountId, plan: plan, add_ons: add_ons, domain: recurlySubdomain}
  }
  let ret = await wsTrans("usa", updateSub)
  return ret.data
}

var previewSubscription = async (plan, add_ons)=>{
  let previewSub = {
    cmd: "cRest", uri: "/s/subscriptions", method: "preview", sessionId: globs.userData.session.sessionId,
    body: {accountId: globs.userData.session.accountId, plan: plan, add_ons: add_ons, domain: recurlySubdomain}
  }
  let ret = await wsTrans("usa", previewSub)
  return ret.data
}

var createPlan = async (recurly_config)=>{
  // make plan free for testing purposes
  // if (["ryan"].includes(config.server)) {
  //   recurly_config.price = 1.00
  // }
  let plan = {
    cmd: "cRest", uri: "/s/subscriptions", method: "create", sessionId: globs.userData.session.sessionId,
    body: {accountId: globs.userData.session.accountId, config: recurly_config, domain: recurlySubdomain}
  }
  let ret = await wsTrans("usa", plan)
  return ret.data
}

var getRecurlyAccount = async ()=>{
  let getAcct = {
    cmd: "cRest", uri: "/s/subscriptions", method: "account", sessionId: globs.userData.session.sessionId,
    body: {accountId: globs.userData.session.accountId, domain: recurlySubdomain}
  }
//   console.trace()// C18MenuBar getRecurlyAccount
  let ret = await wsTrans("usa", getAcct,true)
  return ret.data
}

var updateRecurlyAccount = async ()=>{
  let updateAcct = {
    cmd: "cRest", uri: "/s/subscriptions", method: "updateAccount", sessionId: globs.userData.session.sessionId,
    body: {accountId: globs.userData.session.accountId, domain: recurlySubdomain}
  }
  let ret = await wsTrans("usa", updateAcct)
}

var getBilling = async ()=>{
  let billing = {
    cmd: "cRest", uri: "/s/subscriptions", method: "billing", sessionId: globs.userData.session.sessionId,
    body: {accountId: globs.userData.session.accountId, domain: recurlySubdomain}
  }
  let ret = await wsTrans("usa", billing)
  return ret.data
}

var getBalance = async ()=>{
  let balance = {
    cmd: "cRest", uri: "/s/subscriptions", method: "balance", sessionId: globs.userData.session.sessionId,
    body: {accountId: globs.userData.session.accountId, domain: recurlySubdomain}
  }
  let ret = await wsTrans("usa", balance)
  return ret.data
}

var getRecurlyAccounts = async () => {
  let getAccts = {
    cmd: "cRest", uri: "/s/subscriptions", method: "accounts", sessionId: globs.userData.session.sessionId,
    body: {domain: recurlySubdomain}
  }
  let ret = await wsTrans("usa", getAccts)
  cl(ret.data)
  return ret.data
}

var verifyBilling = async () => {
  let getAccts = {
    cmd: "cRest", uri: "/s/subscriptions", method: "verifyBilling", sessionId: globs.userData.session.sessionId,
    body: {accountId: globs.userData.session.accountId, domain: recurlySubdomain}
  }
  let ret = await wsTrans("usa", getAccts)
  cl(ret.data)
  return ret.data
}

var loadPresetsInfo=()=>{
  if(!globs.userData?.session){return}
  let gpi=globs.presetsInfo
  return new Promise(async(r,e)=>{
    if(gpi.got){r(true); return;}// already got
    if(gpi.res){gpi.res.push(r); return}// add it to the list of responses
    gpi.res=[r]// create the list of responses
    wsTrans("usa", {cmd: "cRest", uri: "/s/graphingPresets", method: "retrieve", 
      sessionId: globs.userData.session.sessionId, body: {accountId:globs.userData.session.accountId}}).then(
        r=>{
//           cl(r.data)
          gpi.info=r.data
          gpi.got=true
          gpi.res.forEach(re=>{re(true)})// activate the list of responses
          delete gpi.res
        },
        e=>{})
  })
}

var loadSummaryPresetsInfo=()=>{
  cl("load summary presets info")
  if(!globs.userData?.session){return}
  let gspi=globs.summaryPresetsInfo
  return new Promise(async(r,e)=>{
    if(gspi.got){r(true); return;}// already got
    if(gspi.res){gspi.res.push(r); return}// add it to the list of responses
    gspi.res=[r]// create the list of responses
    wsTrans("usa", {cmd: "cRest", uri: "/s/summaryPresets", method: "retrieve", 
      sessionId: globs.userData.session.sessionId, body: {accountId:globs.userData.session.accountId}}).then(
        r=>{
//           cl(r.data)
          gspi.info=r.data
          gspi.got=true
          gspi.res.forEach(re=>{re(true)})// activate the list of responses
          delete gspi.res
        },
        e=>{})
  })
}

var getSummaryPresetIndex=(presetId)=>{
  let gpi=globs.summaryPresetsInfo.info
//   cl(gpi)
//   cl(presetId)
  for(let i=0;i<gpi.length;i++){
    if(gpi[i].presetId==presetId){return i}
  }
}

var getPresetIndex=(presetId)=>{
  let gpi=globs.presetsInfo.info
//   cl(gpi)
//   cl(presetId)
  for(let i=0;i<gpi.length;i++){
    if(gpi[i].presetId==presetId){return i}
  }
}

var addGroupPrivsToZones=(pTab)=>{
// for those groups that the user has privileges to all of the zones in it,
// he has privileges for that group
// and the groupId will be added to the zone Ids.
//   cl(globs.zonesInfo.groups)
  if(!Array.isArray(globs.zonesInfo.groups)){return}
  globs.zonesInfo.groups.forEach(si=>{
//     cl(`Site: ${si.siteId}`)
    si.groups.forEach(gr=>{
//       cl(gr)
      let groupFlags=0xFFFF
      gr.zones.forEach(z=>{
        groupFlags&=(pTab["zone"][z]?.flags||0)
//         cl(z,pTab["zone"][z]?.flags)
      })
      if(groupFlags){
//         cl(gr.groupId)
        pTab["zone"][gr.groupId]={flags:groupFlags}
      }
    })
  })
}
  
var makePrivsTab=()=>{// this only makes sense with the sing user version of privsInfo
  let pTab={account:{},site:{},zone:{},}
//   cl(globs.privsInfo.info[0].flags)
//   cl(globs.privsInfo.info)
//   cl(globs.zonesInfo)
  globs.privsInfo.info.forEach(p=>{
    switch(p.level){
      case "super":
      case "account":
        pTab[p.level]=p
        break
      case "site":
        pTab["account"].flags|=constant.AREA_PRIVS_READ
        pTab[p.level][p.siteId]=p
        break
      case "zone":
        let siteId=globs.zonesInfo.z2s[p.zoneId]
        if(!pTab.site[siteId]){pTab.site[siteId]={flags:0}}
        pTab.site[siteId].flags|=constant.AREA_PRIVS_READ
        pTab[p.level][p.zoneId]=p
        break
    }
  })
  addGroupPrivsToZones(pTab)
//   cl(pTab)
  globs.privsInfo.tab=pTab
}

// var fixRolePrivs=async()=>{
//   cl(globs)
//   let userId=globs.userData.session.userId
//   await loadUsersInfo()
//   let user=globs.usersInfo.info.filter(u=>{return u.userId==userId})[0]
//   let role=globs.usersInfo.info.filter(u=>{return u.userId==user.role})[0]
//   cl(role)
//   cl(user)
//   cl(globs.privsInfo.info)
// }

var loadPrivsInfo=(query)=>{
//   cl(query)
//   console.trace()
  if(!globs?.userData?.session){return}
//   cl(query)
  let gpi=globs.privsInfo
  if(gpi.got&&(gpi.query!=query)){
    gpi.got=false
  }
  gpi.query=query
  let gus=globs.userData.session
  if(!query){query={
    $or:[
      {accountId:gus.accountId},
      {level:"super"}], 
    userId:gus.userId}
    
  }// just for user, by default
//   cl(query)
  return new Promise(async(r,e)=>{
    await loadAccountInfo()
    await loadZonesInfo()// added 20240212 to get group info
    await loadUsersInfo()
    let user=globs.usersInfo.info.filter(u=>{return u.userId==gus.userId})[0]
    let role=globs.usersInfo.info.filter(u=>{return u.userId==user?.role})[0]
    if(role&&query.userId){query.userId=user.role}
    gpi.thirdPartyAccounts=await getThirdPartyAccounts(
      gus.userId)
//     cl(gpi.thirdPartyAccounts)
//     cl(gus.userId)
    if(gpi.got){r(true); return;}// already got
    if(gpi.res){gpi.res.push(r); return}// add it to the list of responses
    gpi.res=[r]// create the list of responses
    wsTrans("usa", {cmd: "cRest", uri: "/s/areaPrivs", method: "retrieve", 
      sessionId: gus.sessionId, body: query},true).then(
        r=>{
          gpi.info=r.data
//           cl(r.data)
//           cl(globs.privsInfo.info)
//           fixRolePrivs()
//           cl(gpi.info)
          makePrivsTab()
          gpi.got=true
          gpi.res.forEach(re=>{re(true)})// activate the list of responses
          delete gpi.res
        },
        e=>{})
  })
}

var getThirdPartyUsers=async()=>{
    let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/thirdParty", 
      method: "retrieve", sessionId: globs.userData.session.sessionId, 
      body:{}},true) // for current accountId
//     cl(res.data)
    let users3P=res.data.filter(u=>{return u.p3AccountId})
//     cl(users3P)
    return users3P //res.data
//     let users=[]
//     cl(res)
//     res.data.forEach(r=>{
// //       users.push(r.userId)
//       users.push(r)
//     })
// //     cl(users)
//     return users
}

var getThirdPartyAccounts=async(userId)=>{
    let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/thirdParty", 
      method: "retrieve", sessionId: globs.userData.session.sessionId, 
      body:{userId:userId}},true,[userId]) 
    let accounts=[]
    res.data.forEach(r=>{
      accounts.push(r.accountId)
    })
//     cl(users)
    return accounts
}

var loadUiInfo=(userId)=>{
  let u=globs.usersInfo.info.filter(us=>{return us.userId==userId})[0]
//   cl(u)
  globs.usersInfo.uiInfo=u?.uiInfo
  globs.usersInfo.groupInfo=u?.groupInfo
}

var loadUsersInfo=()=>{
//   console.trace()
  let gui=globs.usersInfo
//   cl(gui)
  return new Promise(async(r,e)=>{
    if(gui.got){r(true); return;}// already got
    if(gui.res){gui.res.push(r); return}// add it to the list of responses
    gui.res=[r]// create the list of responses
    let users3P=await getThirdPartyUsers()// returns userIds and accountIds
    let p3Users=users3P.map(u=>{return u.userId})
//     cl(users3P)
    let query={
      type:"thirdParty",// get normal users and 3rd party
      $or:[
        {accountId:globs.userData.session.accountId},
        {userId: {$in:p3Users}},
      ]}
//     cl(query)
    wsTrans("usa", {cmd: "cRest", uri: "/s/users", method: "retrieve", 
      sessionId: globs.userData.session.sessionId, body: query},true).then(
        async(r)=>{
          gui.info=r.data
          gui.info.forEach(u=>{if(p3Users.includes(u.userId)){
            u.p3=true
            let p3u=users3P.filter(p3u=>{return p3u.userId==u.userId})[0]
            // cl(p3u)
            u.p3AccountId=p3u.p3AccountId
            
          }})
//           cl(gui)
          loadUiInfo(globs.userData.session.userId)
          sortUsersInfo()
          await saveDefaultAvatars()
          gui.got=true
          gui.res.forEach(re=>{re(true)})// activate the list of responses
          delete gui.res
        },
        e=>{})
  })
}

var sortUsersInfo=()=>{
//   cl(globs.usersInfo.info.length)
  globs.usersInfo.info.sort((a,b)=>{
    let emaila=(a.email||"").toLowerCase()
    let emailb=(b.email||"").toLowerCase()
//     cl(a,b)
    if(emaila>emailb){return 1}
    if(emaila<emailb){return -1}
//     if(a.email.toLowerCase()>b.email.toLowerCase()){return 1}
//     if(a.email.toLowerCase()<b.email.toLowerCase()){return -1}
    return 0
  })
}

var saveDefaultAvatars=async()=>{
  // check against account to reduce duplicates
  let names = {}
  for (let i = 0; i < avatarCount; i++) {
    let name = `sto_${i}`
    names[name] = 0
  }
  for (let i = 0; i< globs.usersInfo.info.length; i++) {
    let u = globs.usersInfo.info[i]
    if (u.avatar && u.avatar.includes("sto_")) {
      names[u.avatar]++
    }
  }
  // sort avatars by count and return
  let sortedNames = Object.keys(names).map(function(key) {
    return [key, names[key]]
  })
  sortedNames.sort(function(first, second) {
    return first[1] - second[1]
  })
  let j = 0
  for (let i = 0; i< globs.usersInfo.info.length; i++) {
    let u = globs.usersInfo.info[i]
    if (!u.avatar) {
      u.avatar = await saveDefaultAvatar(sortedNames[j % avatarCount][0], u.userId)
      j++ 
    }
  }
}

var getUserIndex=(userId)=>{
  let gui=globs.usersInfo.info
  let idx = -1
  for(let i=0;i<gui.length;i++){
    // if(gui[i].userId==userId){return i}
    if(gui[i].userId==userId){
      // no match yet
      if (idx == -1) {
        idx = i
      // match found already, test if valid
      } else if (gui[i].email) {
          idx = i
      }
    }
  }
  return idx
}
  
var loadAddedSensorsInfo=()=>{
  let gai=globs.addedSensorsInfo
  return new Promise(async(r,e)=>{
    if(gai.got){r(true); return;}// already got
    if(gai.res){gai.res.push(r); return}// add it to the list of responses
    gai.res=[r]// create the list of responses
    let typeId=getParamId("config_added_sensors","type")
    let valId=getParamId("config_added_sensors","value")
    wsTrans("usa", {cmd: "cRest", uri: "/s/accountSite", 
      method: "retrieve", sessionId: globs.userData.session.sessionId, 
      body: {i:{$gte:typeId,$lte:valId}}}).then(
//     wsTrans("usa", {cmd: "cRest", uri: "/s/alarmLog", method: "retrieve", 
//       sessionId: globs.userData.session.sessionId, body: {d:1}}).then(
        r=>{
          let ret={}
          r.data.forEach(a=>{
            if(!ret[a.s]){ret[a.s]={}}
            if(!ret[a.s][a.z]){ret[a.s][a.z]={}}
            if(!ret[a.s][a.z][a.c]){ret[a.s][a.z][a.c]={}}
            ret[a.s][a.z][a.c][a.i]=a
//             ret.push(a)
          })
//           cl(ret)
          gai.info=ret//r.data
          gai.got=true
          gai.res.forEach(re=>{re(true)})// activate the list of responses
          delete gai.res
        },
        e=>{})
  })
}

var loadSensorsInfo=()=>{
//   console.trace()
  // cl("load sensors")
  let gsi=globs.sensorsInfo
  // cl(gsi)
  return new Promise(async(r,e)=>{
    if(gsi.got){r(true); return;}// already got
    if(gsi.res){gsi.res.push(r); return}// add it to the list of responses
    gsi.res=[r]// create the list of responses
    wsTrans("usa", {cmd: "cRest", uri: "/s/sensors", method: "retrieve", 
      sessionId: globs.userData.session.sessionId, body: {accountId:globs.userData.session.accountId}}).then(
        r=>{
          gsi.info=r.data
//           cl(r.data)
          gsi.got=true
          gsi.res.forEach(re=>{re(true)})// activate the list of responses
          delete gsi.res
        },
        e=>{})
  })
  cl(gsi)
}

var fixSensorsZone=(sensors)=>{// temp, add the 'c0' for expansion controllers
//   cl(sensors)
  if(sensors){
    let sNames=sensors.sensorNames
    Object.keys(sNames).forEach(sn=>{
      if(!["e0","e1","e2","e3","ca"].includes(sn.substring(0,2))){
        sNames[`e0${sn}`]=sNames[sn]
        delete(sNames[sn])
      }
    })
  }
//   cl(sensors)
}

var getSensorsZone=(zoneId)=>{
// siteId should not be necessary, but we have bogus entries in the DB with the wrong siteId
  let siteId=globs.zonesInfo.z2s[zoneId]
//   cl(siteId)
  let sensors=globs.sensorsInfo.info.filter(se=>{
    return (se.zoneId==zoneId)&&(se.siteId==siteId)})[0]
//   cl(globs.sensorsInfo.info)
//   cl(sensors)
  if(sensors){
//     cl(sensors)
//     cl(sensors?.sensorNames)
//     cl(Object.keys(sensors.sensorNames).length)
    fixSensorsZone(sensors)
  }
  return sensors||{}
}

var loadSitesInfo=()=>{
/*you can always call loadSites. If it's the first time, then it will wait for the response 
If the request has been made, it will wait for that request, and not make another
If the sites have already been retrieved, then it just returns
This load the sitesInfo from the sites table, *not* the site's data*/
//   cl(globs)
  let gsi=globs.sitesInfo
//   console.trace()
  return new Promise(async(r,e)=>{
    if(gsi.got){r(true); return;}// already got
    if(gsi.res){gsi.res.push(r); return}// add it to the list of responses
    gsi.res=[r]// create the list of responses
//     cl("getting sitesInfo")
//     console.trace()
    wsTrans("usa", {cmd: "cRest", uri: "/s/sites", method: "retrieve", 
      sessionId: globs.userData.session.sessionId, body: {accountId:globs.userData.session.accountId}},true).then(
        r=>{
          gsi.info=r.data
          gsi.got=true
          gsi.res.forEach(re=>{re(true)})// activate the list of responses
          delete gsi.res
        },
        e=>{})
  })
}

var getSiteIndex=(siteId)=>{
  for(let i=0;i<globs?.sitesInfo?.info?.length;i++){
    if(globs.sitesInfo.info[i].siteId==siteId){return i}
  }
  return -1
}

var getSiteInfo=(siteId)=>{
  let index=getSiteIndex(siteId)
  if(index>=0){return globs.sitesInfo.info[index]}
//   for(let i=0;i<globs.sitesInfo.info.length;i++){
//     let s=globs.sitesInfo.info[i]
//     if(s.siteId==siteId){return s}
//   }
}
  
var getSiteName=(siteId)=>{

  if(siteId==='allSites') {
    return 'All Sites'
  }
//   let siteInfo=
  return getSiteInfo(siteId)?.name
//   for(let i=0;i<globs.sitesInfo.info.length;i++){
//     let s=globs.sitesInfo.info[i]
//     if(s.siteId==siteId){return s.name}
//   }
}
  
var loadAlarmsInfo=()=>{
  let gai=globs.alarmsInfo
  return new Promise(async(r,e)=>{
    if(gai.got){r(true); return;}// already got
    if(gai.res){gai.res.push(r); return}// add it to the list of responses
    gai.res=[r]// create the list of responses
//     cl(globs.userData)
//     console.trace()
    wsTrans("usa", {cmd: "cRest", uri: "/s/alarmLog", method: "retrieve", 
      sessionId: globs.userData.session.sessionId, body: {d:1}},true).then(
        r=>{
          let ret=[]
          if (r.data) {
          r.data.forEach(a=>{
            ret.push(a)
//             if(["dev","stage"].includes(config.server)){
//               if(["d1fIue45CWvP@Nik","NgTWZibHrcKqvdPu","bcCa9WH9I4vWBx0e"].includes(a.s)){
//                 ret.push(a)
//               }
//             }else{
//               if(!["AlLo","AlHi"].includes(a.a)){ret.push(a)}
//             }
          })
          gai.info=ret//r.data
          gai.got=true
          gai.res.forEach(re=>{re(true)})// activate the list of responses
          delete gai.res
          }
        },
        e=>{})
  })
}

var loadInfo=(gIi,uri,search)=>{
//   let gIi=globs.camerasInfo
  return new Promise(async(r,e)=>{
    if(gIi.got){r(true); return;}// already got
    if(gIi.res){gIi.res.push(r); return}// add it to the list of responses
    gIi.res=[r]// create the list of responses
    wsTrans("usa", {cmd: "cRest", uri: uri, method: "retrieve", 
      sessionId: globs.userData.session.sessionId, body: search||{}}).then(
        r=>{
//           cl(uri)
          let ret=[]
          r.data.forEach(a=>{
            ret.push(a)
          })
          gIi.info=ret//r.data
          gIi.got=true
          gIi.res.forEach(re=>{re(true)})// activate the list of responses
          delete gIi.res
        },
        e=>{})
  })
}

var loadCamerasInfo=()=>{
  return loadInfo(globs.camerasInfo,"/s/cameras")// returning a promise
}

var setSiteZoneTypes=(siteId)=>{
  globs.siteZoneTypes=[]
  globs.zonesInfo.info.forEach(z=>{
    if(z.siteId==siteId){
      globs.siteZoneTypes[z.siteZoneIndex]=z.gatewayType||"1800"
    }
  })
}

var makeZoneToSite=()=>{
    globs.zonesInfo.z2s={}
    globs.zonesInfo.info.forEach(z=>{
      globs.zonesInfo.z2s[z.zoneId]=z.siteId
    })
  }
  
var makeSiteZoneIndexToZone=()=>{
  let sz2z={}
  globs.zonesInfo.info.forEach(z=>{
    if(!sz2z[z.siteId]){sz2z[z.siteId]={}}
    sz2z[z.siteId][z.siteZoneIndex]=z
  })
  globs.zonesInfo.sz2z=sz2z
}

/* BEGIN SUBSCRIPTION METHODS */

var getZoneCount = (type, state, tier) => {
  let qty = state.add_ons.zone_qty[type]
  let filter = Object.entries(qty).filter(([t, val]) => t == tier)
  let count = filter.map((arr) => arr[1]).reduce((val1, val2) => parseInt(val1) + parseInt(val2), 0)
  return count
}

var getZonesCount = (type, state, paid=false) => {
  // cl(type)
  let count = 0
  if (state.add_ons) {
    let qty = state.add_ons.zone_qty[type]
    let filter = Object.entries(qty).filter(([tier, val]) => (paid) ? tier == zoneTypes["unlocked"] : true)
    count = filter.map((arr) => arr[1]).reduce((val1, val2) => parseInt(val1) + parseInt(val2), 0)
  }
  return count
}

var getZoneDiscount = (state, sku="standard") => {
  let count_arr = Object.entries(allZones).map(([type, price]) => getZonesCount(type, state, true))
  let total_count = count_arr.reduce((val1, val2) => val1 + val2)
  let discount = 0
  // gives dollar value off
  if (total_count >= 25) {
    // return depending on type
    discount = zoneQtyDiscount[sku][1]
  } else if (total_count >= 11) {
    discount = zoneQtyDiscount[sku][0]
  }
  // cl([total_count, sku, discount])
  return discount
}

var getTotal = (plan) => {
  // cl(plan)
  let zone_total = 0
  if (plan.add_ons) {
    let annual = (plan.add_ons.annual) ? 12 : 1
    let annual_discount = (plan.add_ons.annual) ? .9 : 1
    Object.entries(allZones).map(([type, price])=>{
      let qtys = plan.add_ons?.zone_qty[type]||{}
      return Object.entries(qtys).map(([tier, val]) => {
        if (tier == zoneTypes["unlocked"]) {
          if (plan.protect_count > 0) {
            let protect_total = (price["unlocked_protect"] - getZoneDiscount(plan, "protect")) * plan.protect_count * annual * annual_discount
            zone_total += protect_total
            // cl([price["unlocked_protect"], getZoneDiscount(plan, "protect"), plan.protect_count, protect_total])
          }
          let t_total = (price[tier] - getZoneDiscount(plan)) * (val - plan.protect_count) * annual * annual_discount
          // cl([price[tier], getZoneDiscount(plan), (val - plan.protect_count), t_total])
          zone_total += t_total
        }
      })
    })
  }
  // Object.entries(allAddons).map(([type, price])=> {
  //   if (type == "API Access" && state.add_ons.api_access) {
  //     total += price * annual
  //   }
  // })
  return zone_total
}

var onCheckout = async (state) => {
  // TODO disable outside controls
  // display spinner
  // use subscription update preview to get price difference from recurly
  let newPrice = getTotal(state)
  let planConfig = {
    add_ons: state.add_ons,
    auto_renew: state.auto_renew,
    price: newPrice,
    name: globs.accountInfo.info.name,
    success_url: window.location.href.split("usa")[0] + `usa/c18/admin/manageSubscription`
  }
  let plan = await createPlan(planConfig)
  if (plan) plan.plan_code = plan.code
  let preview = await previewSubscription(plan, state.add_ons)
  // dismiss spinner
  // set state checkout to true, save preview and plan info to state
  return {plan: plan, preview: preview}
  // this.mySetState({displayCheckout: true, plan: plan, preview: preview})
  // if display checkout is false, wipe plan and preview from state
}

var makeZoneTier=async()=>{
    if (!globs.subscriptionInfo?.info) await loadSubscriptionInfo()
    let sub = globs.subscriptionInfo?.info
    if(!sub?.add_ons){return}
    let licenses = sub.add_ons.zone_qty.zone[zoneTypes["unlocked"]] || 0
    let changedZones = []
//     cl(globs.zonesInfo.info)
    let sorted = globs.zonesInfo.info.sort((a, b) => {
      if (a.siteId != b.siteId) {
        return (a.siteId < b.siteId) ? -1 : 1
      } else {
        if (a.siteZoneIndex == b.siteZoneIndex) return 0
        return (a.siteZoneIndex < b.siteZoneIndex) ? -1 : 1
      }
    })
//     cl(sorted)
    let unlockedZones = sorted.filter((z) => {
      return z.zoneTier && z.zoneTier == zoneTypes["unlocked"] || z.zoneTier == "basic"
    })
    let lockedZones = sorted.filter(z => z.zoneTier == null || z.zoneTier == zoneTypes["locked"] || z.zoneTier == "inactive")
//     cl("paid slots init: " + licenses)
    // filter by unlocked and unpaid
//     cl(unlockedZones)
//     cl(lockedZones)
  // sort zones by site and then site-zone index?
//     cl("paid slots: " + licenses)
    unlockedZones.forEach(z=>{
      if (licenses > 0) {
        licenses--
      } else {
        cl("downgrade?")
        // downgrade - TODO re-enable later
        z.zoneTier = zoneTypes["locked"]
        changedZones.push(z)
      // alert user that slot is not available
      }
    })
    // auto-assign - TODO re-enable later 
    lockedZones.forEach(z=>{
      if (licenses > 0) {
        licenses--
        // z.zoneTier = zoneTypes["unlocked"]
        // changedZones.push(z)
      } else if (z.zoneTier == null || z.zoneTier == "basic") {
        z.zoneTier = zoneTypes["locked"]
        changedZones.push(z)
      }
    })
//     cl("paid slots remaining: " + licenses)
//     cl(changedZones)
    // get locked zone count from current zone count
    sub.add_ons.zone_qty.zone[zoneTypes["locked"]] = sorted.filter(z => z.zoneTier == zoneTypes["locked"])?.length || 0
    // sub.add_ons.zone_qty.zone[zoneTypes["disabled"]] = sorted.filter(z => z.zoneTier == zoneTypes["disabled"])?.length || 0
//     cl(sub)
    // write to db for changed zones
    if (changedZones.length) {
      for (let i = 0; i < changedZones.length; i++) {
        const res = await wsTrans("usa", {cmd: "cRest", uri: "/s/zones", method: "update", 
          sessionId: globs.userData.session.sessionId, body: {zoneId: changedZones[i].zoneId, zoneTier: changedZones[i].zoneTier,}})
      }
    
//       // update recurly add ons?
//       let updated = await updateSubscription({plan_code: sub.plan_code, plan_name: sub.plan_name}, sub.add_ons, sub.custom_fields)
// //       cl(updated)
    }
}

var getLockedZones = (siteId=null) => {
  let gzi=globs.zonesInfo.info
  return gzi.filter(z => z.zoneTier == zoneTypes["locked"] && (siteId == null || z.siteId == siteId))
}

var getZones = (siteId=null) => {
  let gzi=globs.zonesInfo.info
  return gzi.filter(z => (siteId == null || z.siteId == siteId))
}

/* END SUBSCRIPTION METHODS */

  
var getZoneId=(siteId, zoneIndex)=>{
//   cl("get zoneid")
//   console.trace()
  let gzi=globs.zonesInfo.info
//   cl(gzi)
//   cl(siteId)
  let zones=gzi.filter(z=>{return z.siteId==siteId})
//   cl(zones)
  let zone=zones.filter(z=>{return z.siteZoneIndex==zoneIndex})[0]
//   cl(zone)
  if(!zone){zone=zones[0]}
  return zone.zoneId
//   for(let i=0;i<gzi.length;i++){
//     let z=gzi[i]
//     if(z.siteId==siteId){
//       if(z.siteZoneIndex==zoneIndex){return z.zoneId}
//     }
//   }
}

var tzToMins=(tz0)=>{
//   let tzId=allTimezones.filter(tz=>{return tz==tz0})[0]
  let tzId=allTimezones.indexOf(tz0)
//   cl(tzId)
  return tzId
}

var load800Zones=async(zones)=>{
  let zones800=zones.filter(z=>{return z.gatewayType==800})
//   cl(zones800)
  if(!zones800.length){return}
  let serials=zones800.map(z=>{return z.serialNo})
  let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/iGrow800Controllers",
    method: "retrieve",sessionId: globs.userData.session.sessionId,
    body: {serials:serials}})
//   cl(res)
  let tzLookup={}
  if(res.data){res.data.forEach(r=>{tzLookup[r.controller_id]=r})}
  let wZones=[]
  zones800.forEach(z=>{
    let ni=tzLookup[z.controllerId]
    let upd=(z.time_zone!=ni?.time_zone)||(z.zoneName!=ni?.controllerName)
    z.lastContactTime=ni?.lastContactTime
    z.updateTime=z.lastContactTime
    z.connected=(getTime()-z.lastContactTime)<60
//     cl(z)
    if(upd){
      let tzId=tzToMins(ni?.time_zone)
      let tzOfs=60*(12-((tzId+24)%24))// yields 12 - -11
      z.tzOfs=tzOfs
      z.time_zone=ni?.time_zone
      z.zoneName=ni?.controllerName
      wZones.push({zoneId:z.zoneId,time_zone:z.time_zone,
      zoneName:z.zoneName,tzOfs:z.tzOfs,
//         connected:z.connected,updateTime:z.updateTime,
      })}
  })
  if(wZones.length){
    await wsTrans("usa", {cmd: "cRest", uri: "/s/zones",
      method: "update",sessionId: globs.userData.session.sessionId,
      body: {zones:wZones}})
  }
}
  
var loadZonesInfo=()=>{
/*you can always call loadSites. If it's the first time, then it will wait for the response 
If the request has been made, it will wait for that request, and not make another
If the sites have already been retrieved, then it just returns
This load the sitesInfo from the sites table, *not* the site's data

20211209: This has been redone
We're not cleaning up when a site or a zone is erased.
So, here, we find all the current sites, and then make sure that the zones that we show are
*only* the ones associated with real sites.
*/
  let gzi=globs.zonesInfo
  return new Promise(async(r,e)=>{
    let siP=loadSitesInfo()
    if(!globs?.userData?.session){r()}
    if(gzi.got){r(true); return;}// already got
    if(gzi.res){gzi.res.push(r); return}// add it to the list of responses
    gzi.res=[r]// create the list of responses
    let ziP=wsTrans("usa", {cmd: "cRest", uri: "/s/zones", method: "retrieve", 
      sessionId: globs.userData.session.sessionId, body: {accountId:globs.userData.session.accountId}},true)
    let giP= wsTrans("usa",{cmd: "cRest", uri: "/s/zoneGroups", method: "retrieve",
      sessionId: globs.userData.session.sessionId, body: {}})
    let [siR,ziR,giR]=await Promise.all([siP,ziP,giP])
    // console.log("🚀 ~ returnnewPromise ~ ziR:", ziR)
    if(ziR.data){
//       cl(ziR.data.length)
      await load800Zones(ziR.data)
      let sites=[]
      globs.sitesInfo.info.forEach(s=>{sites.push(s.siteId)})
//       cl(sites)
      let zones=[]
      ziR.data.forEach(z=>{
//         if(z.gatewayId=="ZAHN0AUF9MGY4Y35"){cl(z);cl(z.updateTime)}
        if((z.gatewayType==1900)||z.virtual){z.inNet=1}
//         cl(z.siteId,sites.includes(z.siteId))
        if(sites.includes(z.siteId)){zones.push(z)}})
      gzi.info=zones//ziR.data
//       cl(globs.userData.session?.features)
//       if(acctFeature("zoneGroups")){gzi.groups=giR.data}
      gzi.groups=giR.data
//       cl(gzi.groups)
//       cl(globs.userData.session?.features?.includes("zoneGroups"))
//       cl(gzi.groups)
      makeZoneToSite()
      makeSiteZoneIndexToZone()
      await makeZoneTier()
      gzi.got=true
      gzi.res.forEach(re=>{re(true)})// activate the list of responses
      delete gzi.res
    }
  })
}

var getZoneIndex=(zoneId)=>{
  let gzi=globs.zonesInfo.info
  for(let i=0;i<gzi.length;i++){
    if(gzi[i].zoneId==zoneId){return i}
  }
}

var getZoneIndexFromSiteIndex=(siteId,zoneIndex)=>{
  let gzi=globs.zonesInfo.info
  for(let i=0;i<gzi.length;i++){
    if((gzi[i].siteId==siteId)&&(gzi[i].siteZoneIndex==zoneIndex)){return i}
  }
}

var getZoneInfo2=(zoneId)=>{
  return globs.zonesInfo.info.filter(z=>{return z.zoneId==zoneId})
}

// this seems totally broke!
var getZoneInfo=(zoneId)=>{
  return globs.zonesInfo.info[getZoneIndex(zoneId)]
//   let gzi=globs.zonesInfo.info
//   for(let i=0;i<gzi.length;i++){
//     if(gzi[i].zoneId==zoneId){return gzi[i]}
//   }
}

var getZoneInfoFromSI=(siteId,index)=>{
    let gzi=globs.zonesInfo.info
    for(let i=0;i<gzi.length;i++){
      let z=gzi[i]
//       cl(z)
      if((z.siteId==siteId)&&(z.siteZoneIndex==index)){return z}
    }
  }

var getZoneName=(zoneId)=>{// really should use getZoneInfo from above!
//     cl(globs.zonesInfo)
  let gzi=globs.zonesInfo.info
  for(let i=0;i<gzi.length;i++){
    let z=gzi[i]
//     cl(z)
    if(z.zoneId==zoneId){return z.zoneName}
  }
}

var loadMBInfo=async()=>{// was site-specific, with siteId
  let gmi=globs.mbInfo
  return new Promise(async(r,e)=>{
    await loadGatewaysInfo()
    let g2s={}
    if (globs.gatewaysInfo.info) globs.gatewaysInfo.info.forEach(g=>{g2s[g.gatewayId]=g.siteId})// gateway to site
    if(gmi.got){r(true); return;}// already got
    if(gmi.res){gmi.res.push(r); return}// add it to the list of responses
    gmi.res=[r]// create the list of responses
    let data={}
    let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/modbusDevices", method: "retrieve", 
      sessionId: globs.userData.session.sessionId, body: {}})// all devices for account
    data.devices=res.data
    data.devices.forEach(d=>{d.siteId=g2s[d.gatewayId]})// add siteId
    res=await wsTrans("usa", {cmd: "cRest", uri: "/s/modbusRegisters", method: "retrieve", 
      sessionId: globs.userData.session.sessionId, 
      body: {}})
    data.registers=res.data

    
    res=await wsTrans("usa", {cmd: "cRest", uri: "/s/modbusIndexNames", method: "retrieve", 
      sessionId: globs.userData.session.sessionId, 
      body: {}})
    data.indexNames=res.data
    res=await wsTrans("usa", {cmd: "cRest", uri: "/s/modbusIndexValues", method: "retrieve", 
      sessionId: globs.userData.session.sessionId, 
      body: {}})
    data.indexValues=res.data
//     cl(data)
    //     cl(data.devices)
//     cl(data.registers)
    
    
    
//     let devices=res.data
//     let typeIds={}
//     globs.mbDevices.forEach(d=>{
//       typeIds[d.typeId]=1
//     })
//     let types2=Object.keys(typeIds).map(k=>{return +k})
//     cl(typeIds)
//       cl(types2)
//     
//     
//     cl(devices)
//     cl(res.data)
//     let data = await wsTrans("usa", {cmd: "cRest", uri: "/s/subscriptions", method: "retrieve", 
//       sessionId: globs.userData.session.sessionId,
//       body: {accountId: globs.userData.session.accountId, domain: recurlySubdomain}
//     })

    gmi.info=data
//     cl(globs.mbInfo)
    // check zones info
    // assign
    gmi.got=true
    gmi.res.forEach(re=>{re(true)})// activate the list of responses
    delete gmi.res
  })
  
  
  
//   let gateways=[]
//   globs.gatewaysInfo.info.forEach(gw=>{
//     if(gw.siteId==siteId){
//       gateways.push(gw.gatewayId)// get the gateways for the selected site
//     }
//   })
//   cl(gateways)
//   let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/modbusDevices", method: "retrieve", 
//     sessionId: globs.userData.session.sessionId, 
//     body: {gatewayId:{$in:gateways}}})
//   globs.mbDevices=res.data
//   let devices=res.data
//   let typeIds={}
//   globs.mbDevices.forEach(d=>{
//     typeIds[d.typeId]=1
//   })
//   let types2=Object.keys(typeIds).map(k=>{return +k})
//   cl(typeIds)
//     cl(types2)
//   res=await wsTrans("usa", {cmd: "cRest", uri: "/s/modbusRegisters", method: "retrieve", 
//     sessionId: globs.userData.session.sessionId, 
//     body: {typeId:{$in:types2}}})
//   
//   
//   cl(devices)
//   cl(res.data)
}

var doMbConv=()=>{
  
}

var intToBase64=(intVal)=>{
    let array= new Uint32Array([intVal]);
    let arr2=new Uint8Array(array.buffer)
    let str=""
    arr2.forEach(a=>{str+=String.fromCharCode(a)})
    return btoa(str).substr(0, 6)
}

var getMbRegInfo=(siteId,zone, mbAddr, regAddr)=>{
//   cl(globs.mbInfo)
  let dev=globs.mbInfo.info?.devices?.filter(d=>{
    return((d.siteId==siteId)&&(d.zone==zone)&&(d.addr==mbAddr))
  })[0]
//   cl(globs.mbInfo.info)
//   console.trace()
  let reg=globs.mbInfo.info.registers.filter(r=>{
    return(
      (r?.typeId==dev?.typeId)&&(r?.addr==regAddr)
    )
  })[0]
//   cl(reg)
//   cl(dev)
//   cl([siteId,zone,mbAddr,regAddr])
//   cl(globs.mbInfo.info.devices)
  return{
    name:`${dev?.name}-${reg?.name}`,
    gatewayId:reg?.gatewayId,
    unit:reg?.unit||0,
    conv:reg?.conv,
    cParm:reg?.cParm,
    type:reg?.type,
    typeId:reg?.typeId,
    alEn:reg?.alEn,
    alLo:reg?.alLo,
    alHi:reg?.alHi,
    alLevel:reg?.alLevel,
  }
}
  
var loadNutrientInfo=async()=>{// was site-specific, with siteId
  let gni=globs.nutrientInfo
  return new Promise(async(r,e)=>{
    if(gni.got){r(true); return;}// already got
    if(gni.res){gni.res.push(r); return}// add it to the list of responses
    gni.res=[r]// create the list of responses
    let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/nutrients", method: "retrieve", 
      sessionId: globs.userData.session.sessionId, body: {}})// all devices for account
    gni.info=res.data
    gni.ind={}
    gni.info.forEach(n=>{gni.ind[n.nutrientId]=n})
    gni.got=true
    gni.res.forEach(re=>{re(true)})// activate the list of responses
    delete gni.res
  })
}
  
var loadRecipeInfo=async()=>{// was site-specific, with siteId
  let gri=globs.recipeInfo
  return new Promise(async(r,e)=>{
    if(gri.got){r(true); return;}// already got
    if(gri.res){gri.res.push(r); return}// add it to the list of responses
    gri.res=[r]// create the list of responses
    let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/recipes", method: "retrieve", 
      sessionId: globs.userData.session.sessionId, body: {}})// all devices for account
    gri.info=res.data
    gri.got=true
    gri.res.forEach(re=>{re(true)})// activate the list of responses
    delete gri.res
  })
}
  
var loadAreaInfo=async()=>{// was site-specific, with siteId
  let gai=globs.areaInfo
  return new Promise(async(r,e)=>{
    if(gai.got){r(true); return;}// already got
    if(gai.res){gai.res.push(r); return}// add it to the list of responses
    gai.res=[r]// create the list of responses
    let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/areas", method: "retrieve", 
      sessionId: globs.userData.session.sessionId, body: {}})// all devices for account
    gai.info=res.data
    gai.got=true
    gai.res.forEach(re=>{re(true)})// activate the list of responses
    delete gai.res
  })
}
  
var loadStationEvents=async()=>{// was site-specific, with siteId
  let gsi=globs.stEventInfo
  return new Promise(async(r,e)=>{
    if(gsi.got){r(true); return;}// already got
    if(gsi.res){gsi.res.push(r); return}// add it to the list of responses
    gsi.res=[r]// create the list of responses
    let gateways={}
    globs.zonesInfo.info.forEach(z=>{if(z.gatewayId){gateways[z.gatewayId]=1}})
    let gateways2=Object.keys(gateways)
//     cl(gateways2)
    let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/stationEvents", method: "retrieve",
      sessionId: globs.userData.session.sessionId, body: {gatewayId:{$in:gateways2}}})// all devices for account
    gsi.info=res.data
//     cl(gsi.info)
    gsi.got=true
    gsi.res.forEach(re=>{re(true)})// activate the list of responses
    delete gsi.res
  })
}

var loadStationInfo=async()=>{// was site-specific, with siteId
  let gsi=globs.stInfo
  return new Promise(async(r,e)=>{
    await loadNutrientInfo()
    await loadRecipeInfo()
    await loadStationEvents()
    await loadAreaInfo()
    if(gsi.got){r(true); return;}// already got
    if(gsi.res){gsi.res.push(r); return}// add it to the list of responses
    gsi.res=[r]// create the list of responses
    let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/stations", method: "retrieve", 
      sessionId: globs.userData.session.sessionId, body: {}})// all devices for account
    gsi.info=res.data
    gsi.got=true
    gsi.res.forEach(re=>{re(true)})// activate the list of responses
    delete gsi.res
  })
}
  
var makeFuiIndex=(gfpi)=>{
  let ind={}
  gfpi.info.forEach(f=>{
    ind[f.type]=f
  })
  gfpi.index=ind
}

var loadFuiPagesInfo=()=>{
  let gfpi=globs.fuiPagesInfo
  return new Promise(async(r,e)=>{
    if(!globs?.userData?.session){r()}// not logged in
    if(gfpi.got){r(true); return;}// already got
    if(gfpi.res){gfpi.res.push(r); return}// add it to the list of responses
    gfpi.res=[r]// create the list of responses
    doGetPost("/fuipages", "GET", ()=>{}).then(
      r=>{
        if(r.pages){
          gfpi.info=r.pages
          makeFuiIndex(gfpi)
//           cl(gfpi)
//           makeZoneToSite()
          gfpi.got=true
          gfpi.res.forEach(re=>{re(true)})// activate the list of responses
          delete gfpi.res
        }
      });
  })
}

var loadReportsInfo=()=>{
  let gri=globs.reportsInfo
  return new Promise(async(r,e)=>{
    if(gri.got){r(true); return;}// already got
    if(gri.res){gri.res.push(r); return}// add it to the list of responses
    gri.res=[r]// create the list of responses
    wsTrans("usa", {cmd: "cRest", uri: "/s/reports", method: "retrieve", 
      sessionId: globs.userData.session.sessionId, body: {accountId:globs.userData.session.accountId}},true).then(
        r=>{
          gri.info=r.data
          gri.got=true
          gri.res.forEach(re=>{re(true)})// activate the list of responses
          delete gri.res
        },
        e=>{})
  })
}

// var loadAnalysisInfo=()=>{
//   // load various report and data visualization info
//   let gri=globs.reportsInfo
//   return new Promise(async(r,e)=>{
//     if(gri.got){r(true); return;}// already got
//     if(gri.res){gri.res.push(r); return}// add it to the list of responses
//     gri.res=[r]// create the list of responses
//     wsTrans("usa", {cmd: "cRest", uri: "/s/reports", method: "retrieve", 
//       sessionId: globs.userData.session.sessionId, body: {accountId:globs.userData.session.accountId}},true).then(
//         r=>{
//           gri.info=r.data
//           gri.got=true
//           gri.res.forEach(re=>{re(true)})// activate the list of responses
//           delete gri.res
//         },
//         e=>{})
//   })
// }

var tagColor=(tag)=>{
//   cl(globs.tagsInfo.info)
//   cl(tag)
  return (globs.tagsInfo.info[tag]||{})?.color||"#AAAAAA"
}

var loadTagsInfo=()=>{
// this is different, in that it creates a lookup table in globs.tagsInfo.info
  let gti=globs.tagsInfo
  return new Promise(async(r,e)=>{
    if(gti.got){r(true); return;}// already got
    if(gti.res){gti.res.push(r); return}// add it to the list of responses
    gti.res=[r]// create the list of responses
    wsTrans("usa", {cmd: "cRest", uri: "/s/tags", method: "retrieve", 
      sessionId: globs.userData.session.sessionId, body: {accountId:globs.userData.session.accountId}}).then(
        r=>{
          gti.info={}
          r.data.forEach(da=>{gti.info[da.tag]=da})
          gti.info["documentation"] = {tag: "documentation", color:"#5ca3cc"}
          gti.info["link4"] = {tag: "link4", color:"#204080"}
//           gti.info=r.data
          gti.got=true
          gti.res.forEach(re=>{re(true)})// activate the list of responses
          delete gti.res
        },
        e=>{})
  })
}

var getReportsIndex=(reportId)=>{
  let gri=globs.reportsInfo.info
//   cl(globs)
  for(let i=0;i<gri.length;i++){
    if(gri[i].reportId==reportId){return i}
  }
}

var loadSchedulesInfo=()=>{
  let gsi=globs.schedulesInfo
  return new Promise(async(r,e)=>{
    if(gsi.got){r(true); return;}// already got
    if(gsi.res){gsi.res.push(r); return}// add it to the list of responses
    gsi.res=[r]// create the list of responses
    wsTrans("usa", {cmd: "cRest", uri: "/s/reportSchedules", method: "retrieve", 
      sessionId: globs.userData.session.sessionId, body: {accountId:globs.userData.session.accountId}},true).then(
        r=>{
          gsi.info=r.data
          gsi.got=true
          gsi.res.forEach(re=>{re(true)})// activate the list of responses
          delete gsi.res
        },
        e=>{})
  })
}

var getSchedulesIndex=(scheduleId)=>{
  let gsi=globs.schedulesInfo.info
  for(let i=0;i<gsi.length;i++){
    if(gsi[i].reportScheduleId==scheduleId){return i}
  }
}

var loadReportSchedulesInfo=()=>{
  let grsi=globs.reportSchedulesInfo
  return new Promise(async(r,e)=>{
    if(grsi.got){r(true); return;}// already got
    if(grsi.res){grsi.res.push(r); return}// add it to the list of responses
    grsi.res=[r]// create the list of responses
    wsTrans("usa", {cmd: "cRest", uri: "/s/reportSchedules", method: "retrieve", 
      sessionId: globs.userData.session.sessionId, body: {accountId:globs.userData.session.accountId}},true).then(
        r=>{
          grsi.info=r.data
          grsi.got=true
          grsi.res.forEach(re=>{re(true)})// activate the list of responses
          delete grsi.res
        },
        e=>{})
  })
}

var updateDeviceInfo=async(body)=> {
  // these fields should stay the same whenever device info is updated
  if(!globs.userData?.session){return}
  body.userId = globs.userData.session.userId
  body.accountId = globs.userData.session.accountId
  body.updatedAt = Math.floor(getTime())

//   cl(body)
  let putDevice={cmd: "cRest", uri: "/s/devices", method: "update", sessionId: globs.userData.session.sessionId,
      body: body}
  await wsTrans("usa", putDevice)
  globs.device = body
//   cl(globs.device)
  // update device in devices
}

var getDevice = () => {
    let device = "Unknown";
    const ua = {
        "Generic Linux": /Linux/i,
        "Android": /Android/i,
        "BlackBerry": /BlackBerry/i,
        "Bluebird": /EF500/i,
        "Chrome OS": /CrOS/i,
        "Datalogic": /DL-AXIS/i,
        "Honeywell": /CT50/i,
        "iPad": /iPad/i,
        "iPhone": /iPhone/i,
        "iPod": /iPod/i,
        "macOS": /Macintosh/i,
        "Windows": /IEMobile|Windows/i,
        "Zebra": /TC70|TC55/i,
    }
    Object.keys(ua).map(v => navigator.userAgent.match(ua[v]) && (device = v));
//     cl(device)
    return device;
}


var getBrowser=()=>{
  let agent = window.navigator.userAgent.toLowerCase();
  let browser =
    agent.indexOf('edge') > -1 ? 'Edge'
      : agent.indexOf('edg') > -1 ? 'Edge'
      : agent.indexOf('opr') > -1 && window.opr ? 'Opera'
      : agent.indexOf('chrome') > -1 && window.chrome ? 'Chrome'
      : agent.indexOf('trident') > -1 ? 'Internet Explorer'
      : agent.indexOf('firefox') > -1 ? 'Firefox'
      : agent.indexOf('safari') > -1 ? 'Safari'
      : 'Unknown';
  return browser
}

// load user devices - if user doesn't have a device, create one, update db, and return
var loadDevicesInfo=()=>{
  if(!globs.userData?.session){return}
  let gdi=globs.devicesInfo
  return new Promise(async(r,e)=>{
    if(gdi.got){r(true); return;}// already got
    if(gdi.res){gdi.res.push(r); return}// add it to the list of responses
    gdi.res=[r]// create the list of responses
//     cl("get")
    wsTrans("usa", {cmd: "cRest", uri: "/s/devices", method: "retrieve", 
      sessionId: globs.userData.session.sessionId, body: {accountId:globs.userData.session.accountId, userId: globs.userData.session.userId}},true).then(
        async (r)=>{
//           cl("got")
          gdi.info=r.data
//           cl(dType)
//           cl(gdi.info)
//           cl(r.data)
//           cl(globs.device)
          // get device id from local storage
          let deviceId = getUId()
//           cl(deviceId)
          if (!globs.device) {
           globs.device=gdi.info?.find((d) => (d.userId==globs.userData.session.userId)&&(d.deviceId == deviceId))
          }
//           cl(globs.device)
          // if no device is found, create a new device
          if (!globs.device) {
            // make device id
            let body = {
                deviceId: deviceId,
                deviceInfo: navigator.userAgent, // user agent - will be replaced once device communicates info
                deviceTheme: "originalLight",
                deviceName: `${getBrowser()} on ${getDevice()}`, // use user agent to define the device
                createdAt: Math.floor(getTime())
            }
            await updateDeviceInfo(body)
          } 
          gdi.got=true
          gdi.res.forEach(re=>{re(true)})// activate the list of responses
          delete gdi.res
        },
        e=>{})
  })
}

var loadGatewaysInfo=(reload)=>{
//   cl("load gateways")
//   cl(reload)
  let ggi=globs.gatewaysInfo
  return new Promise(async(r,e)=>{
    if(ggi.got&&!reload){r(true); return;}// already got
    if(ggi.res){ggi.res.push(r); return}// add it to the list of responses
    ggi.res=[r]// create the list of responses
    wsTrans("usa", {cmd: "cRest", uri: "/s/gateways", method: "retrieve2", 
      sessionId: globs.userData.session.sessionId, body: {accountId:globs.userData.session.accountId}},true).then(
        r=>{
//           cl("got")
          ggi.info=r.data
          ggi.got=true
          ggi.res.forEach(re=>{re(true)})// activate the list of responses
          delete ggi.res
        },
        e=>{})
  })
}

var getGatewayIndex=(gatewayId)=>{
  let ggi=globs.gatewaysInfo.info
  for(let i=0;i<ggi.length;i++){
    if(ggi[i].gatewayId==gatewayId){return i}
  }
}

var getGatewayInfo=(gatewayId)=>{
  return globs.gatewaysInfo.info[getGatewayIndex(gatewayId)]
//   let ggi=globs.gatewaysInfo.info
//   for(let i=0;i<ggi.length;i++){
//     if(ggi[i].gatewayId==gatewayId){return ggi[i]}
//   }
}

var getPearlType=(gwinfo)=>{
  let type = (gwinfo?.fwVersion||"").split(".").pop().toLowerCase()
  if (type == "a") {
    return "Analog"
  } else if (type == "d") {
    return "Digital"
  } else if (type == "p") {
    return "Protect"
  }
  return ""
}

var getPearlCount=(type)=>{
  // iterate through gateways and return count of give type
  let count = 0
  let ggi=globs.gatewaysInfo.info
  for(let i=0;i<ggi.length;i++){
    if (getPearlType(ggi[i]) === type) count += 1
  }
  return count
}

var setTitle=(title)=>{
//   cl(title)
  document.title=title
}

var readValObjType=(val)=>{// only works for numbers@
  if((typeof val == "object")&&(val!=null)){
    return +val.val
  }else{
    return +val
  }
}

var getChannelType800=(gwType,z,c)=>{
//   cl(gwType,z,c)
  let chTypeId=getParamId800("igCE","channelType")
//   cl(chTypeId)
  let ch = (dbVals.z[+z]||{})[+c];
//   cl(ch)
//   cl(ch[chTypeId])
  let types=["channel_On_Off_800",
             "channel_Vent_800",
             "channel_Curtain_800",
             "channel_Alarm_800",
             "channel_Co2_800",
            ]

  return types[ch[chTypeId]]
}

var getChannelType=(gwType, z, c)=>{
//   cl(gwType)
  if(gwType==800){return getChannelType800(gwType,z,c)}
//   cl(pi)
//   cl(dbVals.z.length)
//   cl([z, c])
  if(!dbVals.z.length) return
  let chType = {};
  chType[0] = "channel_None";
  chType[10] = "channel_On_Off";
  chType[20] = "channel_Irrigation_Scheduled";
  chType[21] = "channel_Irrigation_Accumulated_Light";
  chType[22] = "channel_Irrigation_Cycle";
  chType[23] = "channel_Irrigation_Trigger";
  chType[24] = "channel_Irrigation_Soil_Trigger";
  chType[25] = "channel_Irrigation_VPD";
  chType[30] = "channel_CO2";
  chType[40] = "channel_Light_Supplemental";
  chType[41] = "channel_Light_Scheduled";
  chType[42] = "channel_Light_Cyclic";
  chType[43] = "channel_Light_DLI";
  chType[50] = "channel_Microzone";
  chType[60] = "channel_Supply_Pump";
  chType[61] = "channel_Peristaltic_Recirculating_Pump";
  chType[62] = "channel_Peristaltic_Batch_Pump";
  chType[63] = "channel_Peristaltic_Balance_Pump";
  chType[70] = "channel_Fill_Valve";
  chType[80] = "channel_Vent_Roof";
  chType[81] = "channel_Vent_Retractable_Roof";
  chType[82] = "channel_Vent_Side_Wall";
  chType[90] = "channel_Curtain";
  chType[100] = "channel_Mix_Valve";
  chType[110] = "channel_Proportional_Microzone";
  chType[120] = "channel_PID";
  chType[121] = "channel_PID_analog";
  chType[180] = "channel_Variable_Out";
  chType[190] = "channel_Vent2";
  chType[200] = "channel_Curtain2";
  chType[210] = "channel_Mix_Valve2";
  chType[220] = "channel_Microzone2";
  chType[230] = "channel_PID2";
  chType[240] = "channel_Cravo2";
  chType[280] = "channel_Mix_Valve_Analog";
  chType[290] = "channel_DLI_Analog";
//   chType[300] = "channel_Variable_Out_Analog";

  let z0 = z;
  let c0 = (c*1);// 40 * (u*1) + 
//   if (!dbVals.z[z0][c0]){
//     dbVals.z[z0][c0] = dbVals.z[z0][0];
//   }
//   cl(dbVals.z)
//   cl([dbVals,z0,c0])
//   cl(dbVals.z)
//   cl(dbVals.z[z0])
//   cl(dbVals.z[z0][c0])
//   cl([z0,c0])
//   cl(dbVals)
  let ch = (dbVals.z[z0]||{})[c0];
//   cl(ch)
  if(!ch) return chType[0]
//   cl(ch)
  var dif
//   cl(dbVals.z[0][2][508])
//   cl(dbVals.z[z0][2][508])
//   cl(dbVals.z[0][c0][508])
//   cl(dbVals.z[z0][c0][508])
//   cl(dbVals.z[+z0][+c0][508])
//   cl(c0)
//   cl(z0)

  let pid = p.PID_BASE_CONF_CHANNELS + pi[1800].channels_configuration["channelType"];
//   let ty1 = 10 * (typeof ch[pid] == "object") ? ch[pid].val : ch[pid]
  let ty1 = 10 * readValObjType(ch[pid]);
//   cl(pid)
//   cl(ch)
//   cl(ch[508])
//   cl(ch[pid])
//   cl(ty1)
//       cl([pid, ty1]);
  switch(ty1){
    case 20:
      pid = p.PID_BASE_CONF_CHAN_DATA + pi[1800].config_channels_configuration["irrigation_mode"];
      dif=readValObjType(ch[pid])
      ty1 += (dif)?dif:0 //readValObjType(ch[pid])
      break;
    case 40:
      pid = p.PID_BASE_CONF_CHAN_DATA + pi[1800].config_channels_configuration["light_mode"];
      dif=readValObjType(ch[pid])
      ty1 += (dif)?dif:0 //readValObjType(ch[pid])
      break;
    case 60:
      pid = p.PID_BASE_CONF_CHAN_DATA + pi[1800].config_channels_configuration["pump_type"];
      dif=readValObjType(ch[pid])
      ty1 += (dif)?dif:0 //readValObjType(ch[pid])
      break;
    case 80:
      pid = p.PID_BASE_CONF_CHAN_DATA + pi[1800].config_channels_configuration["vent_type"];// was vent_mode
//       cl(pid)
      dif=readValObjType(ch[pid])
      ty1 += (dif)?dif:0 //readValObjType(ch[pid])
      break;
    case 120:
//       cl([z,c])
      pid = p.PID_BASE_CONF_CHANNELS + pi[1800].channels_configuration["isAnalog"];
//       cl(pid)
      dif=readValObjType(ch[pid])
//       cl(ch[pid])
//       cl(dif)
      ty1 += (dif)?dif:0 //readValObjType(ch[pid])
//       cl(ty1)
      break;
    default:
      if(!chType[ty1]){ty1=0}
      break;
  }
  let suf=""
//   cl(ty1)
  if((gwType==1900)&&([10,41,50,80,81,82,121,180,280,290].includes(ty1))){
    suf="_1900"
  }
    if((Math.floor(ty1/10)==8)&&(gwType==1900)){// if a vent
        suf="_1900"
    }
  return chType[ty1]+suf;
}

var getChannelUnit=(channelType)=>{
//     cl(channelType)
  switch (channelType) {
    case "channel_Vent_Roof":
    case "channel_Vent_Retractable_Roof":
    case "channel_Vent_Side_Wall":
    case "channel_Vent_Curtain":
    case "channel_Curtain":
      return "%"
    default:
        return ""
  }
}

var getChannelUnitGroup=(channelType)=>{
//   cl(channelType)
  switch (channelType) {
    case "channel_On_Off":
    case "channel_On_Off_1900":
      return "On/Off"
    case "channel_Irrigation_Scheduled":
    case "channel_Irrigation_Scheduled_1900":
    case "channel_Irrigation_Accumulated_Light":
    case "channel_Irrigation_Accumulated_Light_1900":
    case "channel_Irrigation_Cycle":
    case "channel_Irrigation_Cycle_1900":
    case "channel_Irrigation_Trigger":
    case "channel_Irrigation_Trigger_1900":
    case "channel_Irrigation_Soil_Trigger":
    case "channel_Irrigation_Soil_Trigger_1900":
    case "channel_Irrigation_VPD":
    case "channel_Irrigation_VPD_1900":
      return "Irrigation"
    case "channel_CO2":
    case "channel_CO2_1900":
      return "CO2"
    case "channel_Light_Supplemental":
    case "channel_Light_Supplemental_1900":
    case "channel_Light_Scheduled":
    case "channel_Light_Scheduled_1900":
    case "channel_Light_Cyclic":
    case "channel_Light_Cyclic_1900":
    case "channel_Light_DLI":
    case "channel_Light_DLI_1900":
      return "Light"
    case "channel_Vent_Roof":
    case "channel_Vent_Retractable_Roof":
    case "channel_Vent_Side_Wall":
    case "channel_Vent_Roof_1900":
    case "channel_Vent_Retractable_Roof_1900":
    case "channel_Vent_Side_Wall_1900":
      return "Vent"
    case "channel_Vent_Curtain_1900":
    case "channel_Curtain_1900":
    case "channel_Vent_Curtain":
    case "channel_Curtain":
      return "Curtain"
    default:
      return ""
  }
}

var getChannelDefault=(channelType)=>{
//   cl(channelType)
  switch (channelType) {
    case "channel_On_Off":
    case "channel_On_Off_1900":
    case "channel_Irrigation_Scheduled":
    case "channel_Irrigation_Scheduled_1900":
    case "channel_Irrigation_Accumulated_Light":
    case "channel_Irrigation_Accumulated_Light_1900":
    case "channel_Irrigation_Cycle":
    case "channel_Irrigation_Cycle_1900":
    case "channel_Irrigation_Trigger":
    case "channel_Irrigation_Trigger_1900":
    case "channel_Irrigation_Soil_Trigger":
    case "channel_Irrigation_Soil_Trigger_1900":
    case "channel_Irrigation_VPD":
    case "channel_Irrigation_VPD_1900":
    case "channel_CO2":
    case "channel_CO2_1900":
    case "channel_Light_Supplemental":
    case "channel_Light_Supplemental_1900":
    case "channel_Light_Scheduled":
    case "channel_Light_Scheduled_1900":
    case "channel_Light_Cyclic":
    case "channel_Light_Cyclic_1900":
    case "channel_Light_DLI":
    case "channel_Light_DLI_1900":
      return [[0, 1]]
    case "channel_Vent_Roof":
    case "channel_Vent_Retractable_Roof":
    case "channel_Vent_Side_Wall":
    case "channel_Vent_Curtain":
    case "channel_Curtain":
    case "channel_Vent_Roof_1900":
    case "channel_Vent_Retractable_Roof_1900":
    case "channel_Vent_Side_Wall_1900":
    case "channel_Vent_Curtain_1900":
    case "channel_Curtain_1900":
    case "channel_PID_analog":
    case "channel_PID_analog_1900":
    case "channel_Variable_Out":
    case "channel_Mix_Valve_Analog":
    case "channel_DLI_Analog":
    case "channel_Variable_Out_1900":
    case "channel_Mix_Valve_Analog_1900":
    case "channel_DLI_Analog_1900":
      return [[0, 100]]
    default:
      return null
  }
}

// var getAllChannelsInfo=()=>{
// // find the channels that are valid, and get their types and names
//   cl("get all channels info")
// }

var ECpHAlarmNames=[
  "EC Deviation",
  "pH Deviation",
  "EC Low",
  "EC High",
  "pH Low",
  "pH High",
  "EC Service",
  "EC Calibration",
  "pH Service",
  "pH Calibration",
  "EC Error",
  "pH Error",
]

var tempAlarmNames=[
  "In Temp Low",
  "In Temp High",
  "In Temp Sensor",
]

var auxAlarmNames=[
  "Aux Alarm Low",
  "Aux Alarm High",
]
var makeTempAlarmName=(p,d)=>{
  let pred=`in zone ${p.z+1}`
//   return [makeBitAlarmName(d,p.d,tempAlarmNames), pred]
}

var makeECpHAlarmName=(p,d)=>{// return an array of alarm statements
  let pred=`in zone ${p.z+1}, mixing tank ${p.c-191}`
//   return [makeBitAlarmName(d,p.d,ECpHAlarmNames), pred]
}

var makeAuxAlarmName=(p,d)=>{
//   let name=(p.i==pi[1800].p.PID_BASE_SNAPSHOTS+lc.SN_auxLowAlarmStatus)?auxAlarmNames[0]:auxAlarmNames[1]
  let name="aux"
  let state=(p.d)?"on":"off";
  let pred=`in zone ${p.z+1}`
  return [[`${name} is ${state}`], pred]
}

var alarmNames={
  E1Sn:{name:"EC Sensor 1 ", type:"ec", level:1},// needs to store the tank, too
  E1Sr:{name:"EC Sensor 1 Service ", type:"ec", level:5},
  E1Ca:{name:"EC Sensor 1 Calibration ", type:"ec", level:4},
  E2Sn:{name:"EC Sensor 2 ", type:"ec", level:1},
  E2Sr:{name:"EC Sensor 2 Service ", type:"ec", level:5},
  E2Ca:{name:"EC Sensor 2 Calibration ", type:"ec", level:4},
  E3Sn:{name:"EC Sensor 3 ", type:"ec", level:1},
  E3Sr:{name:"EC Sensor 3 Service ", type:"ec", level:5},
  E3Ca:{name:"EC Sensor 3 Calibration ", type:"ec", level:4},
  ECLo:{name:"EC Low ", type:"ec", level:2},
  ECHi:{name:"EC High ", type:"ec", level:2},
  ECDe:{name:"EC Deviation ", type:"ec", level:3},

  P1Sn:{name:"pH Sensor 1 ", type:"ec", level:1},
  P1Sr:{name:"pH Sensor 1 Service ", type:"ec", level:6},
  P1Ca:{name:"pH Sensor 1 Calibration ", type:"ec", level:4},
  P2Sn:{name:"pH Sensor 2 ", type:"ec", level:1},
  P2Sr:{name:"pH Sensor 2 Service ", type:"ec", level:6},
  P2Ca:{name:"pH Sensor 2 Calibration ", type:"ec", level:4},
  P3Sn:{name:"pH Sensor 3 ", type:"ec", level:1},
  P3Sr:{name:"pH Sensor 3 Service ", type:"ec", level:5},
  P3Ca:{name:"pH Sensor 3 Calibration ", type:"ec", level:4},
  PhLo:{name:"pH Low ", type:"ec", level:2},
  PhHi:{name:"pH High ", type:"ec", level:2},
  PhDe:{name:"pH Deviation ", type:"ec", level:3},

  reLo:{name:"Low Alarm Relay", type:"re", level:1},// relay
  reHi:{name:"High Alarm Relay", type:"re", level:1},
  intLo:{name:"Inside Temperature Low ", type:"it", level:2},// inTemp
  intHi:{name:"Inside Temperature High ", type:"it", level:2},
  intSn:{name:"Inside Temperature Sensor ", type:"it", level:1},
  auxAl:{name:"Aux ", type:"al", level:1},
// for compatibility:  
  ITLo:{name:"Inside Temperature Low ", type:"it", level:2},// inTemp
  ITHi:{name:"Inside Temperature High ", type:"it", level:2},
  ITSn:{name:"Inside Temperature Sensor ", type:"it", level:1},
  
  TmOt:{name:"Timeout ", type:"re", level:1},
//   AuxA:{name:"Aux Alarm", type:"ax", level:3},// aux - needs to store the alarm ID, too
  // need to add timeout, vpd, modbus alarms
  inTLoSa:{name:"Inside Temperature Low ", type:"it", level:2},
  inTHiSa:{name:"Inside Temperature High ", type:"it", level:2},
}
  for(let i=0;i<32;i++)alarmNames[`auxAl${i}`]={name:`Aux  ${i+1}`,type:"ax",level:3}
  
// var sensorAlarms=["inT","inH","inL","inC","ouT","ouH","ouL","bpT","oWs","oWd","dPr","bPr","ran",
//   "sno","vpd","at0","at1","at2","at3","at4","vp0","vp1","vp2","vp3","vp4","sm0","sm1","sm2",
//   "sm3","sm4"]
  
var sensors={
      inT:{ind:0,map:3,pid:"inTemperature",title:"Inside Temperature",unit:"degf",unitGroup:"Temperature",level:1},
      inH:{ind:1,map:5,pid:"inHumidity",title:"Inside Humidity",unit:"% RH",unitGroup:"Humidity",level:1},
      inL:{ind:2,map:249,pid:"inLight",title:"Inside Light",unit:"wpm2",unitGroup:"Light",level:2},
      inC:{ind:3,map:15,pid:"co2",title:"Inside CO2",unit:"PPM",unitGroup:"CO2",level:2},
      ouT:{ind:4,map:11,pid:"outTemperature",title:"Outside Temperature",unit:"degf",unitGroup:"Temperature",level:5},
      ouH:{ind:5,map:13,pid:"outHumidity",title:"Outside Humidity",unit:"% RH",unitGroup:"Humidity",level:5},
      ouL:{ind:6,map:17,pid:"outLight",title:"Outside Light",unit:"wpm2",unitGroup:"Light",level:5},
      bpT:{ind:7,map:177,pid:"outTemperatureSecondary",title:"Black Plate Temp",unit:"degf",unitGroup:"Temperature",level:5},
      oWs:{ind:8,map:19,pid:"windSpeed",title:"Wind Speed",unit:"mph",unitGroup:"Wind",level:5},
      oWd:{ind:9,map:21,pid:"windDirection",title:"Wind Direction",unit:"deg",unitGroup:"Wind Direction",level:5},
      dPr:{ind:10,map:251,pid:"differentialPressure",title:"Differential Pressure",unit:"kPa",unitGroup:"Pressure",level:5},
      bPr:{ind:11,map:181,pid:"barometricPressure",title:"Barometric Pressure",unit:"kPa",unitGroup:"Pressure",level:5},
      ran:{ind:12,map:23,pid:"rain",title:"Rain",unit:"",unitGroup:"Weather",level:5},
      sno:{ind:13,map:253,pid:"snow",title:"Snow",unit:"",unitGroup:"Weather",level:5},
      vpd:{ind:14,map:0,pid:"vpd",title:"VPD",unit:"kPa",unitGroup:"VPD",level:3},
      at0:{ind:15,map:25,pid:"analogTemperature1",title:"Analog Temp 1",unit:"degf",unitGroup:"Temperature",level:6},
      at1:{ind:16,map:27,pid:"analogTemperature2",title:"Analog Temp 2",unit:"degf",unitGroup:"Temperature",level:6},
      at2:{ind:17,map:29,pid:"analogTemperature3",title:"Analog Temp 3",unit:"degf",unitGroup:"Temperature",level:6},
      at3:{ind:18,map:31,pid:"analogTemperature4",title:"Analog Temp 4",unit:"degf",unitGroup:"Temperature",level:6},
      at4:{ind:19,map:33,pid:"analogTemperature5",title:"Analog Temp 5",unit:"degf",unitGroup:"Temperature",level:6},
      vp0:{ind:20,map:45,pid:"ventPosition1",title:"Vent Position 1",unit:"%",unitGroup:"Position",level:6},
      vp1:{ind:21,map:47,pid:"ventPosition2",title:"Vent Position 2",unit:"%",unitGroup:"Position",level:6},
      vp2:{ind:22,map:49,pid:"ventPosition3",title:"Vent Position 3",unit:"%",unitGroup:"Position",level:6},
      vp3:{ind:23,map:51,pid:"ventPosition4",title:"Vent Position 4",unit:"%",unitGroup:"Position",level:6},
      vp4:{ind:24,map:53,pid:"ventPosition5",title:"Vent Position 5",unit:"%",unitGroup:"Position",level:6},
      sm0:{ind:25,map:55,pid:"soilMoisture1",title:"Soil Moisture 1",unit:"%",unitGroup:"Saturation",level:6},
      sm1:{ind:26,map:57,pid:"soilMoisture2",title:"Soil Moisture 2",unit:"%",unitGroup:"Saturation",level:6},
      sm2:{ind:27,map:59,pid:"soilMoisture3",title:"Soil Moisture 3",unit:"%",unitGroup:"Saturation",level:6},
      sm3:{ind:28,map:61,pid:"soilMoisture4",title:"Soil Moisture 4",unit:"%",unitGroup:"Saturation",level:6},
      sm4:{ind:29,map:63,pid:"soilMoisture5",title:"Soil Moisture 5",unit:"%",unitGroup:"Saturation",level:1},
    }

var make1800ECAlarm=(p,d,alarm,zone,tank)=>{
  return [[alarm.name], zone]
}

var make1800PHAlarm=(p,d,alarm,zone,tank)=>{
  return [[alarm.name], zone]
}

var make1800ITAlarm=(p,d,alarm,zone,tank)=>{
  return [[alarm.name], zone]
}

var make1800REAlarm=(p,d,alarm,zone,tank)=>{
  return [[alarm.name], zone]
}

var make1800AXAlarm=(p,d,alarm,zone,tank)=>{
  return [[alarm.name], zone]
}

var make1800AlarmName=(id,p,d)=>{
  cl(p)
  let alarm=alarmNames[id]
  let types={"ec":make1800ECAlarm, "ph":make1800PHAlarm, "it":make1800ITAlarm, "re":make1800REAlarm, "ax":make1800AXAlarm}
  let zone=`in Zone ${p.z+1}`// should be using zone names here
  let tank=`, mixing tank ${p.c-191}`
  cl(alarm.type)
  if(types[alarm.type]){
    return types[alarm.type](p,d,alarm,zone,tank)
  }else{
    return [["Unknown Alarm"],zone]
  }
}

var getAddedSensorAlarmInfo=(id,siteId,zi)=>{
//   globs.addedSensorsInfo.info.forEach(s=>{
//     
//   })
  let len=id.length
  let chan=+id.substring(4,len-4)// e0as0HiSa
  let typeId=getParamId("config_added_sensors","type")
//   cl(globs.addedSensorsInfo.info)
  let sType=(((globs.addedSensorsInfo.info[siteId]||{})[zi]||{})[chan]||{})[typeId]?.d
  let name="added sensor"
  let en=+getParmValue(zi,240,60+chan,+getParamId("config_annex_sensors","alarmEnable"))
  let sInd=getSiteIndex(siteId)
  let id2=`z${az(zi,2)}as${chan}`
  let lv=+(globs.sitesInfo.info[sInd].alarmLevels||{})[id2]||1
  
  return {
    name:`AS ${chan} ${addedSensorTypeNames[sType]} Sensor Alarm`,
    enable:(en)?1:0,
    type:"it",
    level:lv,
  }
}

var getSensorAlarmInfo=(id,siteId,zi)=>{
//   cl(id)
  let standardLevels={}
  let len=id.length
//   cl(id.substring(len-2,len))
  if(id.substring(len-2,len)=="Sa"){
    if(id.substring(2,4)=="as"){return getAddedSensorAlarmInfo(id,siteId,zi)}
    let id2=id.substring(2,5)
    let id3=id.substring(0,5)
    let type=(id.substring(5,7)=="Lo")?"Low":"High"
    let se=sensors[id2]
//     cl(se)
//     cl(sensors)
//     cl(id2)
    if(!se){return}
    let sInd=getSiteIndex(siteId)
//     cl(siteId)
//     cl(sInd)
//     cl(globs.sitesInfo.info)
//     cl(globs.sitesInfo.info[sInd])
//     if(id=="inCHiSa"){
//       cl(sInd)
//       cl(id2)
//       cl(globs.sitesInfo.info[sInd].alarmLevels)
//     }
    let lv=+(globs.sitesInfo.info[sInd].alarmLevels||{})[id2+"Sa"]||1
    
    let en=+getParmValue(zi,240,se.ind,+getParamId("config_annex_sensors","alarmEnable"))
//     ||sensors[id2].level
//     cl(getParamId("config_annex_sensors","alarmLevel"))
//     cl(lv)
    let name=sensors[id2].title
    if(Object.keys(siteSensorNames||{}).length){
//       cl(siteSensorNames)
//       cl(zi)
//       cl(siteSensorNames[zi])
//       cl(id3)
      let na=(siteSensorNames[zi]||{})[id3]?.name
      if(na)name=na
//       name=siteSensorNames[zi][id3].name
    }
    return {
      name:`${name} ${type} Sensor Alarm`,
      enable:(en)?1:0,
      type:"it",
      level:lv,
    }
//     cl([id2,type])
//   inTLoSa:{name:"Inside Temperature Low Alarm", type:"it", level:2},
  }
}

var getMbAlarmInfo=(id,siteId,zi)=>{
//   cl(id,siteId,zi)
  if(id.substring(0,2)=="MB"){
    let zone=globs.zonesInfo.info.filter(z=>{
      return (z.siteId==siteId)&&(z.siteZoneIndex==zi)})[0]
    if(!zone){return}
    let gatewayId=zone.gatewayId
    let parts=id.split("-")
    let mbAddr=+parts[1]
    let regAddr=+parts[2]
    let dev=globs.mbInfo.info.devices.filter(d=>{
      return (d.addr==mbAddr)&&(d.zone==zi)&&(d.gatewayId==gatewayId)})[0]
//     cl(dev)
    let typeId=dev?.typeId
    let reg=globs.mbInfo.info.registers.filter(r=>{
      return (r.typeId==typeId)&&(r.addr==regAddr)&&(r.gatewayId==gatewayId)
    })[0]
//     cl(reg)
    if(!dev || !reg){return false}
//     cl(dev,reg)
    let name=`${dev.name} ${reg.name}`

    let en=reg?.alEn
    let lv=+reg?.alLevel
    if(reg.conv==constant.MB_CONV_INDEX){
      let indId=reg.cParm
      let ivInd=+parts[3]
      let ind=globs.mbInfo.info.indexValues.filter(ix=>{
        return (ix.gatewayId==gatewayId)&&
          (ix.indexId==indId)&&
          (ix.ivIndex==ivInd)
      })[0]
      name=name+` ${ind.value}`
      en=ind.enable
      lv=ind.level
    }
    let ret={
      name:`${name} Modbus Alarm`,
      enable:(en)?1:0,
      type:"mb",
      level:lv,
    }
    return ret
  }
}

var fixECpHAlarmId=(alarmId)=>{
//   cl(isNaN(alarmId.slice(-1)))
  if(!isNaN(alarmId.slice(-1))){alarmId=alarmId.slice(0,-1)}
  return alarmId
}

var getAlarmLevel=(id,levels)=>{
  if(!levels){return 1}
//   cl(id)
  if(id.slice(-2)=="Sa"){
    id=id.substring(2,id.length-4)+"Sa"
  }else{
    id=id.substr(2)
  }
//   cl(id)
//   cl(levels)
  return levels[id]
}

var getAlarmInfo=(id,siteId,zi)=>{
//   cl(id)
//   cl(id,zi)
//   cl([id,siteId,zi])
//   cl(dbVals)
  let si=globs.sitesInfo.info.filter(s=>{return s.siteId==siteId})[0]
//   cl(si.alarmLevels)
  let ret=getSensorAlarmInfo(id,siteId,zi)||
    getMbAlarmInfo(id,siteId,zi)
//   cl(ret)
  if(ret){return ret}
//   cl(zi)
  if(!dbVals.z[zi]){// at the account level
    let id0=id.substring(2)
    let level=getAlarmLevel(id,si.alarmLevels)
    let name=(alarmNames[id0]||{}).name
    let unit=+id.substring(1,2)
    if(unit){name=`E${unit}-${name}`}
//     cl(name)
    return {level:level,name:name}
  }
  let sInd=getSiteIndex(siteId)
  id=fixECpHAlarmId(id)
//   cl(id)
  let ai=Object.assign({},alarmNames[id.substring(2)]||{})
  let unit=+id.substring(1,2)
  if(unit){ai.name=`E${unit}-${ai.name}`}
  
//   cl(id)
//   cl(globs.sitesInfo.info[sInd])
//   cl(sInd)
  ai.level=+(globs.sitesInfo.info[sInd].alarmLevels||{})[id.substring(2)]
  return ai
//   return {name:alarmNames[id].name } 
}

var alLevel=(id,siteId,zi)=>{
  return getAlarmInfo(id,siteId,zi).level
//   return (alarmNames[id]||{}).level
}

var makeAvatarPath=(av)=>{
    var path
    if(av){
      path=`${constant.expressUrl}/usa/images/avatars/uploads/${av[0]}/${av[1]}/${av[2]}/${av.substr(3)}.jpeg`
    }else{
      path=`${constant.expressUrl}/usa/images/avatars/stockAvatar.jpg`
    }
    return path
}

var saveDefaultAvatar=async(avatar, userId)=>{
  // check against dict to reduce dupes
  let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/users", method: "update", 
            sessionId: globs.userData.session.sessionId, body: {userId:userId, avatar:avatar}})
  return avatar
}

var addToAdminLog=(vals)=>{// vals is an *array* of changes to record
//   cl(vals.length)
  if (vals.length > 0) {
//     cl("updating admin log with " + vals)
      wsTrans("usa", {cmd: "cRest", uri: "/s/adminLog", method: "update", 
    sessionId: globs.userData.session.sessionId, body: {adds:vals}})
  }
}

var addToTracking=(vals)=>{
  if(globs?.userData?.session){
    Object.assign(vals,{userId:globs?.userData?.session?.userId})
    wsTrans("usa", {cmd: "cRest", uri: "/s/tracking", method: "update", 
    sessionId: globs.userData.session.sessionId, body: vals})
  }
}

var checkSave=async(that,saveFunc,popInfo)=>{
//   cl(that.modified)
    if(!that.props.parms.pageModified){return}
    let res=await that.props.parms.getPopup(popInfo)
    globs.events.publish("savePageEnable",false)
    if(res=="Save"){
      saveFunc()
    }
  }

var checkOwner=()=>{
   return globs.accountInfo.info.owner==globs?.userData?.session?.userId
}

var checkRole = () => {
  let ind=getUserIndex(globs?.userData?.session?.userId)
  let user=globs.usersInfo.info[ind]
  return user.role == "admin"
}

var checkAdmin=async()=>{
  let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/areaPrivs", method: "retrieve", 
    sessionId: globs.userData.session.sessionId, body: {accountId:globs.userData.session.accountId}})
  let allPrivs=res.data
  // cl(allPrivs)
  // cl(globs.userData.session.userId)
  let saPriv = allPrivs.find(p => p.level == "super" && p.userId == globs?.userData?.session?.userId)
  let aPriv = allPrivs.find(p => p.level == "account" && p.userId == globs?.userData?.session?.userId)
  // cl([saPriv, aPriv])
  // cl([saPriv?.flags & constant.SUPER_PRIVS_ADMIN, aPriv?.flags & constant.AREA_PRIVS_ADMIN])
  return saPriv?.flags & constant.SUPER_PRIVS_ADMIN || aPriv?.flags & constant.AREA_PRIVS_ADMIN
}

var redirectPrivs=()=>{
  if (!checkOwner && !checkAdmin) {
      cl("***********************reload")
     window.location.href = window.location.href.split("usa")[0] + `usa/c18/sites`
  }
}

var privs=(level,id,flag)=>{
//   cl(level,id,flag)
//   console.trace()
  let gpt=globs.privsInfo.tab
//   cl(gpt)
//   cl(gpt[level])
//   cl((gpt[level]||{})[id])
  if(!gpt){return false}
//   cl(globs.privsInfo)
//   cl(globs.privsInfo.tab)
//   cl(gpt)
  var p0,p00,p1,p2
  p00=((gpt["super"]||{}).flags)?0x0FF:0// used for super and owner to grant all privileges
//   cl(p00)
//   cl(globs.accountInfo)
  if((level!="super")&&(globs?.accountInfo?.info?.owner==
    globs?.userData?.session?.userId)){p00=0x0FF}// owner override
//   cl(p00)
  p0=gpt["account"].flags
  switch(level){
    case "super":
//       cl(p00)
//       cl(+p00!=0)
      return +p00!=0
      break
    case "account":
      return (p00|p0)&+flag
    case "site":
      p1=(gpt[level][id]||{}).flags
//       cl([p0,p1])
      return (p00|p1)&+flag//(p0|p1)&flag
    case "zone":
      let siteId=globs.zonesInfo.z2s[id]
//       p1=(gpt["site"][siteId]||{}).flags||0
      p2=(gpt[level][id]||{}).flags||0
//       cl(siteId,p1,p2,flag)
//       cl(id)
//       cl(gpt[level])
//       if(gpt[level][id]==undefined){console.trace()}
//       cl(gpt[level][id])
//       cl([p1,p2])
//       cl(p00,p2,p00|p2)
      let px=(p00|p2)&(+flag)
      if(!px){
        cl(`BAD PRIVS: ${[p00,p2,flag,px]}`)}
      return (p00|p2)&(+flag)//(+p0|+p1|+p2)&+flag
    default:
      return true
  }
  return true
}

var getSetpoints=(zInd)=>{// assumes that siteData has been loaded
// pi[1800].config_setpoints["setpointIndex"] = 0 // 8 * 10
// pi[1800].config_setpoints["unix_timestamp(modified)"] = 1
// pi[1800].config_setpoints["enabled"] = 2
// pi[1800].config_setpoints["startTimeOfDay"] = 3
// pi[1800].config_setpoints["astroAdjust"] = 4
// pi[1800].config_setpoints["rampMinutes"] = 5
// pi[1800].config_setpoints["heatSetpoint"] = 6
// pi[1800].config_setpoints["coolSetpoint"] = 7
// pi[1800].config_setpoints["humidifySetpoint"] = 8
// pi[1800].config_setpoints["dehumidifySetpoint"] = 9
    
    let zoneData=(dbVals.z[zInd]||{})[255]||{}
    let tab=pInd[1800].config_setpoints// 4636, 4, 10, 8
    let sps=[]
    let namId=getParamId("configuration_setpoints","name")
    let enId=getParamId("configuration_setpoints","enabled")
    let entSize=tab[2]
    let startId=getParamId("configuration_setpoints","startTimeOfDay")
    let endId=getParamId("configuration_setpoints","startTimeOfDay")
    for(let i=0;i<8;i++){
      if(+zoneData[enId+ i*entSize]){
        let st=+zoneData[startId+i*entSize]
        let name=zoneData[namId+i*entSize]
        if(!isNaN(name)){name=`Time Period ${i+1}`}
        sps.push({id:i,start:st,name:name})
      }
    }
    sps.sort((a,b)=>{
      if(a.start>b.start){return 1}
      if(a.start<b.start){return -1}
      return 0
    })
//     cl(sps)
    return sps
  }
  
var getUnitTime=()=>{
  
}

var getSetpoints800=(zInd)=>{
//returns sps - an array of maps
  let parms=["Enable","HeatSetpoint","CoolSetpoint","HumidifySetpoint","Co2Setpoint",
    "DehumidifySetpoint","RampTimeMinutes","StartTimeMode","StartTimeHourMinute"]
  let pref=["day","night","dif"]
  let zone=dbVals.z[zInd]//this.zone.siteZoneIndex
//   cl(zone)
  let ch=240
  let sps=[]
  for(let i=0;i<3;i++){
    let pr=pref[i]
    if(!i||(true||pr.Enable)){
      let sp={id:pr,name:pr}
      parms.forEach(pa=>{
        let paId=`${pr}${pa}`
        let pid=pi[800].igC[paId]+pInd[800].igC[0]
        sp[pa]=zone[ch][pid]
      })
      sp.start=sp.StartTimeHourMinute
      if((pr=="day")||(sp.Enable)){sps.push(sp)}
    }
  }
  sps.sort((a,b)=>{
    if(a.StartTimeHourMinute>b.StartTimeHourMinute){return 1}
    if(a.StartTimeHourMinute<b.StartTimeHourMinute){return -1}
    return 0
  })
//   cl(sps)
  return sps
}

var getCurrentSetpoint800=(gwType,zInd)=>{
  let sps=getSetpoints800(zInd)
  let pid=pi[800].igR["controllerTime"]+pInd[800].igR[0]
  let zone=dbVals.z[zInd]//this.zone.siteZoneIndex
  let contTime=zone[240][pid]
  let mins=Math.floor((contTime / 60) % 1440)
  let curSp=sps.length-1
  sps.forEach((s,i)=>{
//     cl(s.StartTimeHourMinute)
    if(s.StartTimeHourMinute<mins) curSp=i
  })
//   cl(sps)
//   cl(curSp)
//   cl(mins)
  return [sps,curSp,mins]
}

var lastContTime=0
  
var lastContTime=0

var getCurrentSetpoint=(gwType,zInd)=>{
  if(gwType==800){return getCurrentSetpoint800(gwType,zInd)}
  let da=new Date()
  let tzo=0-da.getTimezoneOffset()
  let sps=getSetpoints(zInd)

  let contTimeId=(gwType==1900)?// Pearl
    getParamId("snapshots","currentTime"):// pearl
    getParamId("snapshots","unix_timestamp(unitTime)")// 1800
  let contTime=+((dbVals.z[zInd]||{})[240]||{})[contTimeId]
  // cl(contTime)// correct controller local time
  let contMins=Math.floor((contTime / 60) % 1440);
  if(gwType==1900){
    let tzId=getParamId2(gwType,"pearl_config","time_TimeZoneShift_mins")
    tzo=((dbVals.z[zInd||0]||{})[240]||{})[tzId]||0
//     cl(tzo)
//     let da=new Date()
//     let tzo=da.getTimezoneOffset()
    contTime=contTime-60*tzo// convert to utc
    // cl(contTime)
  }
  if(isNaN(contTime)){contTime=lastContTime}
  lastContTime=contTime
  let mins=Math.floor((contTime / 60) % 1440)
  let curSp=sps.length-1
  sps.forEach((s,i)=>{
    if(s.start<contMins) curSp=i
  })
//   cl(mins)
  return [sps,curSp,contMins]
}
  
var saveTable=async(tab,gatewayId)=>{
//   cl(gatewayId)
    let tabCont=await wsTrans("usa", {cmd: "cRest", uri: "/s/tables", method: "retrieve", 
      sessionId: globs.userData.session.sessionId,
      body: {table:tab,gatewayId:gatewayId}})
  }
  
var saveToAdminLog=(adds, type, data)=>{
    let addObj={
      userId:globs.userData.session.userId,
      time:Math.floor(getTime())
    }
    adds.push(
      Object.assign({},addObj,
      {
      action: type,
      oldVal: "",
      newVal: data,
      })
    )
  }
  
var getTimePeriod=()=>{
    let end=Math.floor(getTime())+3*3600
    let begin=end-60*86400
    return [begin,end]
  }
  
var loadGJ=async(skip,limit,filter,parms)=>{
//   cl(filter)
//   cl(parms)
//     cl(this.props)
//     cl(this.state)
    let p=parms//this.props.parms
//     cl(p)
    if(!p){return[]}
    var tag,tags
    let method="retrievePaging"// used for account, site, and zone levels
    if(p.pageType){// pageType is only on fui pages
      tag=`${p.pageType}-${p.zuci}`
//       tags={$elemMatch:{$eq:tag}}
      method="retrievePagingTags"
    }
//     cl(method)
    // pass in increasing range of beginning and end?
    let [begin,end]=getTimePeriod()
    var query
    let original=(parms.mode!="images")
    if(p.level=="account"){
      query={
        level:p.level,
//         original: original,
        dispTime: {$gt: begin, $lte: end},
      }
    }else{// for site and zone levels
      query={
        level:p.level,
//         original: original,
        zoneId: p.zoneId, 
        siteId: p.siteId,
        dispTime: {$gt: begin, $lte: end},
        tag:tag,
      }
      if(p.level=="site"){
        delete query.level
        delete query.siteId
        delete query.zoneId
        query["$or"]=[{level:"account"},{level:"site",siteId:p.siteId},{level:"zone",siteId:p.siteId}]
      }
      if(p.level=="zone"){
        delete query.level
        delete query.zoneId
        query["$or"]=[{level:"zone",zoneId:p.zoneId},{level:"sensor",zoneId:p.zoneId},{level:"config",zoneId:p.zoneId},{level:"site",siteId:p.siteId}]
      }
      if(p.level=="sensor"){
        delete query.level
      }
      if(p.level=="config"){
        delete query.level
      }
    }
    if(original){query.original=true}
    query.filter=filter
//     cl(query)
//     cl(method)
    let r=await wsTrans("usa", {cmd: "cRest", uri: "/s/growJournal", method: method, 
      sessionId: globs.userData.session.sessionId,
      body: query, page:{skip:skip,limit:limit,sort:{dispTime:-1}}})
//     cl(r.data)
    let entries=r.data
    entries.forEach(e=>{
      e.t=e.dispTime; 
      e.u=e.userId; 
      e.ty="GJ"
    })
    if((p.level!="account")&&(p.level!="site")){
      for(let i=entries.length-1;i>=0;i--){
        if(entries[i].level=="account"){delete entries.splice(i,1)}
      }
    }
//     cl(entries)
    return entries
  }

var loadGJ2=async(limit, filter, parms, lastDisp, lastId)=>{
    let p=parms
    if(!p){return[]}
    var tag
    let method="retrievePagingCursor"// used for account, site, and zone levels
    if(p.pageType){// pageType is only on fui pages
      tag=`${p.pageType}-${p.zuci}`
      method="retrievePagingTagsCursor"
    }
    var query={
        level:p.level,
    }
    if(p.level!="account"){
      delete query.level
      if (tag) query.tag = tag
      if(p.level=="site"){
        query["$or"]=[{level:"account"},{level:"site",siteId:p.siteId},{level:"zone",siteId:p.siteId}]
      }
      if(p.level=="zone"){
        query.siteId = p.siteId
        query["$or"]=[{level:"zone",zoneId:p.zoneId},{level:"sensor",zoneId:p.zoneId},{level:"config",zoneId:p.zoneId},{level:"site",siteId:p.siteId}]
      }
      if(p.level=="sensor"){
        query.zoneId=p.zoneId
      }
    }
//     cl(query)
    // query to get entries after last detected date
    if (lastId == "") {
      query.dispTime = {$lt: lastDisp}
    } else {
      query._id = lastId
    }
    let original=(parms.mode!="images")
    if(original){query.original=true}
    query.filter=filter
    // cl(query)
//     cl(method)
    let r=await wsTrans("usa", {cmd: "cRest", uri: "/s/growJournal", method: method, 
      sessionId: globs.userData.session.sessionId,
      body: query, page:{limit:limit,sort:{dispTime:-1, _id:-1}}})
//     cl(r.data)
    let entries=r.data
    entries.forEach(e=>{
      e.t=e.dispTime; 
      e.u=e.userId; 
      e.ty="GJ"
    })
    if((p.level!="account")&&(p.level!="site")){
      for(let i=entries.length-1;i>=0;i--){
        if(entries[i].level=="account"){delete entries.splice(i,1)}
      }
    }
//     cl(entries)
    return entries
  }

  var loadConfigLog2=async(p, limit, lastDisp, lastId)=>{
    // cl([p, limit, lastDisp, lastId])
    let zoneInfo=getZoneInfo(p.zoneId)
    let zoneType= zoneInfo?.gatewayType||1800
//     cl(zoneType)
    let zInd=zoneInfo?.siteZoneIndex
    var query={z: zInd, s: p.siteId,}
//     cl(p)
    switch(p.level){
      case "account":
        return []
      case "site":
        delete query.z
        break
      case "zone":
        break
      case "sensor":
        break
      case "config":
        if (p.pageType.indexOf("channel") <0) {// this was blocking non-equipment pages
          break
        }
        let parts=p.zuci.split("-")
        query.c=+parts[2]
//         cl(p)
        break
    }
//     cl(query)
    if(query.c>=40){
      loadConfigZone(p, query, zoneType)
    }
    // query to get entries after last detected date
    if (lastId == "") {
      query.t = {$lt: lastDisp}
    } else {
      query._id = lastId
    }
    let r=await wsTrans("usa", {cmd: "cRest", uri: "/s/configLog", method: "retrieve", 
      sessionId: globs.userData.session.sessionId,
      body: query, page:{limit:limit,sort:{t:-1, _id:-1}}})// get one more to determine "load more"
    let entries=r.data
    return entries
  }

  var loadConfigZone=async(p, query, zType=1800)=>{// this has to be adjusted to account for the index
    var params=[]
    var makeParamTab=()=>{
      Object.keys(pInd[zType]).forEach(k=>{
        let p=pInd[zType][k]
        params.push({id:p[0],size:p[2]})
      })
      params.sort((a,b)=>{
        if(a.id>b.id){return 1}
        if(a.id<b.id){return -1}
        return 0
      })
    }
    var adjustIndex=(pid,ind)=>{// if the value has an index, then adjust the pid
      if(!ind){return pid}
      for(let i=0;i<params.length;i++){
        if(params[i+1].id>pid){
          return pid+ind*params[i+1].size
        }
      }
    }
    makeParamTab()
    let fp=globs.fuiPagesInfo.index[p.pageType]
    let zuci=p.zuci
    let [z,u,c,ind]=p.zuci.split("-")
    let pids=[]
    fp.controls.forEach(co=>{
      pids.push(adjustIndex(co.pid,ind))
    })
    query.i={"$in":pids}
  }
  
/***************** Read Sensor Values from C18ZoneSensors ****************************/

var getTankNames=(siteZoneIndex)=>{
//   cl("get tanknames")
//   cl(siteZoneIndex)
//   cl(dbVals)
//   console.trace()
    let zone=dbVals.z[siteZoneIndex]
//     cl(sensorIds)
    let pid=sensorIds['e0taN'].id
    let tankNames=[]
    for(let i=192;i<200;i++){
//       cl(i)
//       cl(pid)
//       if(!zone){console.trace()}
//       cl(zone)
      let tn=(((zone||{})[i]||{})[pid])?zone[i][pid]:`Tank ${i-191}`
      tankNames.push(tn)
    }
    return tankNames
  }
  
var fixSensorOrder=(sensorOrder,zInd)=>{
//   cl(sensorOrder)
  sensorOrder.forEach((s,i)=>{
    if(!["e0","e1","e2","e3","ca"].includes(s.substring(0,2))){
      sensorOrder[i]=`e0${s}`
    }
  })
}

var makeDefaultSetpointNames=(zInd)=>{
  let spNames=["Day","Night","DIF"]
  let base=getParamId("configuration_setpoints","name")
  let tabInfo=pInd[1800].config_setpoints
  let inc=tabInfo[2]
  let now=Math.floor(getTime())
  let parms=[]
  for(let i=0;i<8;i++){
    let pid=base+i*inc
    let name=spNames[i]||`Period ${i+1}`
    if(putZValue(zInd,255,pid,name)){
      parms.push({
        c:255,// zone wide - not! unit-specific
        d:name,
        f:1,
        i:pid,
        t:now,
        z:zInd,
      })
    }
  }
}

var makeDefaultSensors=(zone)=>{
/*
We have to generate two tables: one for the sensor names, to go in the sensors db table
the other for the sensors to show, to go in the zone sensorOrder array
this should also add default setpoint names*/
//   cl("make default sensors")
  cl(zone)
  let zInd=zone.siteZoneIndex
  let zc=getZoneControllers(zInd)
  let base=p.PID_BASE_CONFIG_CONTROLLER_SETTINGS
  let mappedSensors=[]
  let sensorNames={}
  let map2Info={}// mapId to id, shortId
  Object.keys(sensors).forEach(k=>{
    let se=sensors[k]
    map2Info[se.map]={shortId:k,id:se.pid}
  })
  for(let i=0;i<4;i++){//make sensorNames, and mappedSensors
    if(zc[i]){// if valid unit
      Object.keys(map2Info).forEach(map=>{
        let v=+((dbVals.z[zInd]||{})[240+i]||{})[+map+base]||0// get mapped sensors
        if(v){// if mapped
          let inf=map2Info[map]
          mappedSensors.push({unit:i,shortId:inf.shortId,id:inf.id})
        }
      })
    Object.keys(sensors).forEach(se=>{sensorNames[`e${i}${se}`]=sensors[se].title})
    }
  }
  // cl(mappedSensors)
  base=p.PID_BASE_CONFIG_SENSORS
  let sensorOrder=["e0zoS", 'e0inT', 'e0inH']
  makeDefaultSetpointNames(zInd)
  mappedSensors.forEach(s=>{
    let z=zInd
    let c=240+s.unit
    let i=base+pi[1800].sensors[s.id]
    let d=dbVals.z[z][c][i]
    let id=`e${s.unit}${s.shortId}`
    if (!sensorOrder.includes(id)) {
      if(d){
        sensorNames[id]=d
      }
      sensorOrder.push(id)
    }
  })
  let zi=getZoneInfo(zone.zoneId)
  let si=globs.sensorsInfo.info.filter(se=>{return se.zoneId=zone.zoneId})[0]
  if(!si){si={siteId:zi.siteId,zoneId:zi.zoneId,sensorNames:{}}}
  zi.sensorOrder=sensorOrder
  si.sensorNames=sensorNames
  let writeZone={zoneId:zi.zoneId,sensorOrder:sensorOrder}
  let writeSensor={siteId:si.siteId,zoneId:si.zoneId,sensorNames:sensorNames}
  wsTrans("usa", {cmd: "cRest", uri: "/s/zones", method: "update", 
    sessionId: globs.userData.session.sessionId, body: writeZone})
  wsTrans("usa", {cmd: "cRest", uri: "/s/sensors", method: "update", 
    sessionId: globs.userData.session.sessionId, body: writeSensor})
  
}

// var makeDefaultSensors=(zone)=>{
// //   cl(zoneId)
// //   let zone=globs.zonesInfo.info.filter(z=>{return z.zoneId==zoneId})[0]
// //   cl(zone)
//   loadLCSensors(zone.siteZoneIndex)
//   
// }

var makeSensors2=(that,gwType,siteId,zoneId)=>{
// the actual work of makeSensors, without the await
    let sensors=[]
    let zone=getZoneInfo2(zoneId)[0]
    if(!zone){return[]}
    if(!zone?.sensorOrder||(zone?.sensorOrder.length==0)){

// <<<<<<< HEAD
//     let zone=getZoneInfo(zoneId)
//     if(!zone){return[]}
// //     cl(zoneId)
//     if(!zone?.sensorOrder||(zone.sensorOrder.length==0)){
// =======
//     let zone=getZoneInfo2(zoneId)[0]
//     if (!zone) return [sensors,zone,zone?.siteZoneIndex]
//     if(!zone?.sensorOrder||(zone?.sensorOrder.length==0)){
// >>>>>>> bf35aa30b55defd878e063faf6df39d955a23e7d
      cl("make defaults")
      makeDefaultSensors(zone)

    }
    that.tankNames=getTankNames(zone.siteZoneIndex)
    let zInd=zone.siteZoneIndex
    let sensorOrder=zone.sensorOrder||[]
    fixSensorOrder(sensorOrder,zInd)
    let zc=getZoneControllers(zInd)
    sensorOrder.forEach(s=>{
      if(s.substring(0,2)=="ca"){
        addSensor(that,gwType,sensors,s,siteId,zInd)
      }else{
        let unit=+s.substring(1,2)
        if(zc[unit]){addSensor(that,gwType,sensors,s,siteId,zInd)}
      }
    })
    return [sensors,zone,zInd]
}
  
var makeSensors=async(that,gwType,siteId,zoneId)=>{
//   if(gwType==1800){console.trace()}
//   cl("make sensors")
    await loadZonesInfo()
    await loadSensorsInfo()
    await loadSiteData(siteId)
//     cl("site data loaded")
    await setSensorIdNames(zoneId)
    return makeSensors2(that,gwType,siteId,zoneId)
  }
  
var getChPid=(vid,zInd)=>{
  if(!sensorIds){return [0]}
//   console.trace()
//     if(this.zone.gatewayType==3300){return [0,1]/*this.getCurVal3300(vid)*/}
//     let zone=dbVals.z[this.zone.siteZoneIndex]
    let isExp=["e0","e1","e2","e3"].includes(vid.substring(0,2))
    let vid2=(isExp)?vid:`e0${vid}`
//     cl(vid2)
  
    let ecs=[// snapshot values
      "ec0","ph0","tp0",
      "ec1","ph1","tp1",
      "ec2","ph2","tp2",
      "ec3","ph3","tp3",
      "ec4","ph4","tp4",
      "ec5","ph5","tp5",
      "ec6","ph6","tp6",
      "ec7","ph7","tp7"]
    let chs=["c00","c01","c02","c03","c04","c05","c06","c07","c08","c09","c10","c11"]
    let zos=["taH","taL"]
    let ecc=[// configuration
      "eL0","eH0","pL0","pH0",
      "eL1","eH1","pL1","pH1",
      "eL2","eH2","pL2","pH2",
      "eL3","eH3","pL3","pH3",
      "eL4","eH4","pL4","pH4",
      "eL5","eH5","pL5","pH5",
      "eL6","eH6","pL6","pH6",
      "eL7","eH7","pL7","pH7"]
    let ans=[
      "at0","at1","at2","at3","at4",
      "vp0","vp1","vp2","vp3","vp4",
      "sm0","sm1","sm2","sm3","sm4",
    ]
    let cnt=["cnT"]// canopy temp
    if(ans.includes(vid)){
//       cl(sensorIds[vid])
      let pid=sensorIds[vid].id
      return [240,pid]
//       return zone[240][pid]
    }
    if(ecs.includes(vid.substring(2))){
      let type=pInd[1800].snapshot_ecphs// set the channel to tank
      let ch=192+(+vid.substr(4))
//       cl(ch)
//       cl(sensorIds)
      let pid=sensorIds[vid.substr(0,4)].id
//       cl(pid)
      return [ch,pid]
//       return zone[ch][pid]
    }
    if(cnt.includes(vid.substring(2))){
      let pid0=getParamId(
        "configuration_zone_settings",
        "Vapor Pressure Deficit Media Sensor")
      let map=dbVals.z[zInd][255][pid0]
      let pid=getParamId("snapshots","inTemperature")
      if(map<5){
        pid=getParamId("snapshots","analogTemperature1")+(+map)
      }
      return [240,pid]
    }
    if(ecc.includes(vid)){
//       cl(sensorIds)
      let type=pInd[1800].config_ecph// set the channel to tank - or is it set to index????
      let ch=192+(+vid.substr(2))
      let pid=sensorIds[vid.substr(0,2)].id
//       cl([ch,pid])
      return [ch,pid]
//       return zone[ch][pid]
    }
    if(chs.includes(vid)){
      let ch=+vid.substr(1)
      let pid=sensorIds["chP"].id
      return [ch,pid]
//       return zone[ch][pid]
    }
//     cl(zos)
//     cl(sensorIds)
    if(zos.includes(vid)){
      let pid=sensorIds[vid2].id
//       cl(pid)
      return [255,pid]
//       return (zone)?zone[255][pid]:0
    }
//     cl(vid2)
//     cl(sensorIds[vid2])

//     cl(sensorIds)
    if(sensorIds[vid2]){
//       cl("sensor")
      let pid=sensorIds[vid2].id
//       cl(pid)
      let controller=+vid.substring(1,2)
      return [240+controller,pid]
    }else{return [240]}

  }
  
var getCurVal=(vid,zInd)=>{
//   cl(zInd)
//   cl(vid)
    let zone=dbVals.z[zInd]//this.zone.siteZoneIndex
    if(!zone){return 0}
//     cl(zone)
    let [ch,pid]=getChPid(vid,zInd)
//     cl(vid,ch,pid)
//     cl([ch,pid])
//     cl(zone)
    let val=(zone[ch]||[])[pid]
    //  cl(val)
//     if(vid=="zoS"){if}
    return val
  }
  
var getCurVal2=(vid,zInd)=>{
    let zone=dbVals.z[zInd]//this.zone.siteZoneIndex
    let [ch,pid]=getChPid(vid,zInd)
    return{val:zone[ch][pid],ch:ch,pid:pid}
//     return zone[ch][pid]
  }

var getChPid800=(vid,zInd)=>{
//   let vid2=vid.substring(2)
//   cl(vid)
  let sensorIds={
    "inT":pi[800].igR["inTemperature"]+pInd[800].igR[0],
    "spC":pi[800].igR["coolSetpoint"]+pInd[800].igR[0],
    "spH":pi[800].igR["heatSetpoint"]+pInd[800].igR[0],
    "inH":pi[800].igR["inHumidity"]+pInd[800].igR[0],
    "spU":pi[800].igR["humidifySetpoint"]+pInd[800].igR[0],
    "spD":pi[800].igR["dehumidifySetpoint"]+pInd[800].igR[0],
    "inL":pi[800].igR["inLight"]+pInd[800].igR[0],
    "inC":pi[800].igR["inCo2"]+pInd[800].igR[0],

    "ouT":pi[800].igR["outTemperature"]+pInd[800].igR[0],
    "ouL":pi[800].igR["outLight"]+pInd[800].igR[0],
    "oWs":pi[800].igR["windSpeed"]+pInd[800].igR[0],
    "oWd":pi[800].igR["windDirection"]+pInd[800].igR[0],
    "ran":pi[800].igR["isInRain"]+pInd[800].igR[0],

    "taL":pi[800].igC["alarmLowTemperature"]+pInd[800].igC[0],
    "taH":pi[800].igC["alarmHighTemperature"]+pInd[800].igC[0],
    "haL":pi[800].igC["alarmLowHumidity"]+pInd[800].igC[0],
    "haH":pi[800].igC["alarmHighHumidity"]+pInd[800].igC[0],
  }
//   cl(sensorIds)
  return [240,sensorIds[vid]]
//   return [ch,pid]
}

var getCurVal800=(vid,zInd)=>{
//   cl(vid,zInd)
  let zone=dbVals.z[zInd]//this.zone.siteZoneIndex
  let [ch,pid]=getChPid800(vid,zInd)
//   cl(ch,pid)
//   cl(vid)
//   cl(zone[ch])
//   cl(zone[ch][pid])
  let val=Math.floor(100*zone[ch][pid])/100
  return{val:val,ch:ch,pid:pid}
}

var hdhTimer=(gwType,zInd)=>{
//   cl("timer")
  let sess=globs.userData.session
  let contDiff=((sess.zoneTimeOffsets||{})[sess.siteId]||{})[zInd]//=p.d-Math.floor(now)
  if(!contDiff&&(contDiff!=0)){return}
//   cl(gwType)
//   if(gwType==1800){
//     console.trace()
//     cl(gwType)}
  let onRemId=getParamId2(gwType,"pearl_snaps","hdhOntimeRemaining")
  let offRemId=getParamId2(gwType,"pearl_snaps","hdhOfftimeRemaining")
  let lSecId=getParamId2(gwType,"pearl_snaps","hdhLsec")
  let stateId=getParamId2(gwType,"pearl_snaps","hdhState")
  let onRem=+((dbVals.z[zInd]||{})[240]||{})[onRemId]
  let offRem=+((dbVals.z[zInd]||{})[240]||{})[offRemId]
  let lSec=+((dbVals.z[zInd]||{})[240]||{})[lSecId]
  let state=+((dbVals.z[zInd]||{})[240]||{})[stateId]
  let now=Math.floor(getTime())
//   cl(onRem,offRem,state)
  let secs=(+onRem)?+onRem:+offRem
//   cl(onRem,offRem)
//   cl(onRem)
//   cl(lSec,onRem,lSec+onRem,(lSec+onRem)%86400)
//   cl((now+contDiff)%86400)
//  let secsRem=((lSec+onRem)%86400)-((now+contDiff)%86400)// this looks correct!
  let secsRem=((lSec+secs)%86400)-((now+contDiff)%86400)
// below, the '4' is empirical
  if(secsRem<=4){state=(onRem>offRem)?2:1}// state has changed
//   cl(secsRem,state)
  return state
//   cl(secsRem,lSec)
//   cl(lSec)
//   cl((lSec-now))
//   cl((lSec-now)%86400)
//   cl(lSec)
// how to calculate, in real timestamp, when the change will happen
// onRem is how many seconds after lSec it's going to happen
// so, when is that?
// now+contDiff is the *current* time on the controller
// lSec+onRem is the time of the change
// so, lSec+onRem - cont time is the countdown
//   cl(contDiff)

}
  
var updateInHum=(that,gwType,type,sensor)=>{
  var haH,haL,vspH,vspDH
  switch(gwType){
    case 800:
      vspH=+((getCurVal800("spU",sensor.zone)).val)||72
      vspDH=+((getCurVal800("spD",sensor.zone)).val)||68
      haL=+((getCurVal800("haL",sensor.zone)).val)||10
      haH=+((getCurVal800("haH",sensor.zone)).val)||10
      sensor.vals=[haL,vspH,vspDH,haH]
      sensor.val=+((getCurVal800("inH",sensor.zone)).val)
      sensor.cVal=sensor.val
      sensor.cUn="% RH"
//       cl(sensor)
      return sensor
    default:
      let hdhState=hdhTimer(gwType,sensor.zone)
      let alarmsActive=getAlarmStatus(sensor.site,sensor.zone,["inHLoSa","inHHiSa"])
      vspH=(+getCurVal("e0spU",sensor.zone))||30
      vspDH=(+getCurVal("e0spD",sensor.zone))||70
      if(hdhState==2){// temp mode
        let vstT=+getCurVal("e0stT",sensor.zone)
        sensor.foot=stages[vstT]
      }else{
        let vstH=+getCurVal("e0stH",sensor.zone)
        sensor.foot=humStages[vstH]
      }
      sensor.vals=[0,vspH,vspDH,100]
      let al=""
      let pref=sensor.id.substring(0,2)
      if(alarmsActive.includes(`${pref}inHLoSa`)){al="lo"}
      if(alarmsActive.includes(`${pref}inHHiSa`)){al="hi"}
      sensor.al=al

      sensor.cUn="% RH"
      return sensor
  }
}
  
var mergeCloseValues=(arr,decimal)=>{
    for(let i=0;i<arr.length-1;i++){
      arr[i]=Math.round(arr[i]*decimal)/decimal
      if((arr[i]+2)>arr[i+1]){
//         cl(i,arr[i],arr[i+1])
        arr[i]=arr[i+1]
      }
    }
  }
  
var getAlarmStatus=(site,zone,alarms)=>{
    let gai=globs.alarmsInfo.info
    if(!gai){return[]}
//     cl(gai)
    let alarmsActive=[]
    for(let i=0;i<gai.length;i++){
      let a=gai[i]
//       cl(a)
//       if(a.s==site){cl(a.z,zone)}
//       if((a.s==site)&&(a.z==zone)){cl(a.a)}
//       cl(a.a.substring(2))
      if((a.s==site)&&(a.z==zone)&&(alarms.includes(a.a.substring(2)))){
//         cl("add alarm")
        alarmsActive.push(a.a)
      }
    }
//     cl(alarmsActive)
    return alarmsActive
  }
  
// var updateInTemp800=(that,gwType,type,sensor)=>{
//   cl(type,sensor)
// }

var updateInTemp=(that,gwType,type,sensor)=>{
//   cl(gwType)
//   cl(type)
//   if(gwType==800){return updateInTemp800(that,gwType,type,sensor)}
//   cl(sensor)
//     let p=this.props.parms
//     cl(sensor.zone)
    let alarmsActive=getAlarmStatus(sensor.site,sensor.zone,["intHi","intLo","intSn","inTLoSa","inTHiSa"])
//     cl(alarmsActive)
//     switch(sensor.gatewayType){
    var vspC,vspH,taH,taL
    switch(gwType){
//       case 3300:
//         cl(this.zone)
//         cl(type,sensor)
//         return sensor
      case 800:
        vspC=+((getCurVal800("spC",sensor.zone)).val)||72
        vspH=+((getCurVal800("spH",sensor.zone)).val)||68
        taH=+((getCurVal800("taH",sensor.zone)).val)||10
        taL=+((getCurVal800("taL",sensor.zone)).val)||10
//         cl(vspC,vspH,taH,taL)
        sensor.vals=[taL,vspH,vspC,taH]
        sensor.val=+((getCurVal800("inT",sensor.zone)).val)
        sensor.cVal=sensor.val
        sensor.cUn=tempUnit(sensor.zone).t
//         cl(sensor)
        return sensor
      default:
        vspC=(+getCurVal("e0spC",sensor.zone))||72
//         cl(vspC)
        vspH=(+getCurVal("e0spH",sensor.zone))||68
        taH=(+getCurVal("taH",sensor.zone))||10
        taL=(+getCurVal("taL",sensor.zone))||10
//         cl([vspC, vspH, taH, taL])
        if (taL > 9000) taL = 10
        if (taH <= 0) taH = 10

        sensor.vals=[vspH-taL,vspH,vspC,vspC+taH]
//         cl(sensor.vals)
//         this.mergeCloseValues(sensor.vals,10)
//         cl(sensor)
//         cl(dbVals)
        let vstT=+getCurVal("e0stT",sensor.zone)
//         cl(vstT)
        sensor.cUn=tempUnit(sensor.zone).t
        if(type.substring(2)=="inT"){
//           cl(alarmsActive)
          sensor.foot=stages[vstT]
          let al=""
          let pref=sensor.id.substring(0,2)
          if(alarmsActive.includes(`${pref}intLo`)){al="lo"}
          if(alarmsActive.includes(`${pref}inTLoSa`)){al="lo"}
          if(alarmsActive.includes(`${pref}intHi`)){al="hi"}
          if(alarmsActive.includes(`${pref}inTHiSa`)){al="hi"}
          if(alarmsActive.includes(`${pref}intSn`)){al="lohi"}
          sensor.al=al
        }
//         cl(sensor)
        return sensor
    }
  }
  
var updateEc=(that,gwType,type,sensor)=>{
//   cl(sensor)
//   console.trace()
//   cl(type)
    let tank=+type.substr(4)// looking for 'ec0', now 'e0ec0'
    let exp=type.substring(0,2)
    let veL=(+getCurVal(`${exp}eL${tank}`,sensor.zone))||500
    let veH=(+getCurVal(`${exp}eH${tank}`,sensor.zone))||1500
//     cl([veL,veH])
    let points=[0,500,1000,1500,2000,4000,5000,10000]
    var min=0,max
    let v=sensor.val
    let vmin=(veL<v)?veL:v
    let vmax=(veH>v)?veH:v
//     if(vmin>sensor.val){mvin=sensor.val}
//     if(vmax<sensor.val){vmax=sensor.val}
    // cl([veH,v])
    points.forEach(p=>{
      if(p/vmin<0.9){min=p}
      // cl([max,p/vmax,p,vmax])
      if(!max&&(p/vmax>1.1)){max=p}
    })
    if(!max){max=2000}
    sensor.vals=[min,veL,veH,max]
//     cl(sensor.vals)
//     cl(that.tankNames)
//     cl(tank)
    sensor.name=`EC - ${that.tankNames[tank]}`
    sensor.cUn=nuteUnit(that.zInd).t
//     cl(sensor)
    return sensor
  }
  
var getECpHMinMax=(v,vMin,vMax,points)=>{
    vMin=(vMin<v)?vMin:v
    vMax=(vMax>v)?vMax:v
    var min,max
    points.forEach(p=>{
      if(p/vMin<0.9){min=p}
      if(!max&&(p/vMax>1.1)){max=p}
    })
    return [min,max]
  }
  
var updatepH=(that,gwType,type,sensor)=>{
    let tank=+type.substr(4)
//     cl(tank)
//     cl(`pL${tank}`)
    let exp=type.substring(0,2)
    let vpL=(+getCurVal(`${exp}pL${tank}`,sensor.zone))||2
    let vpH=(+getCurVal(`${exp}pH${tank}`,sensor.zone))||12
    let points=[-2,0,2,4,6,8,10,12,14,16]
    if(!sensor.val){sensor.val=7}
    let [min,max]=getECpHMinMax(sensor.val,vpL,vpH,points)
    sensor.name=`pH - ${that.tankNames[tank]}`
    if(!max){max=14}
    sensor.vals=[min,vpL,vpH,max]
//     cl(sensor)
    return sensor
  }
  
var updateTp=(that,gwType,type,sensor)=>{
    let tank=+type.substr(4)
//     let vpL=+getCurVal(`pL${tank}`,sensor.zone)
//     let vpH=+getCurVal(`pH${tank}`,sensor.zone)
    let points=[-20,0,30,40,60,80,100,120]
    if(sensor.val<32){sensor.val=32;sensor.cVal=32}
    let [min,max]=getECpHMinMax(sensor.val,sensor.val,sensor.val,points)
    let mid=(min+max/2)
//     cl(that.tankNames)
    sensor.name=`Temp - ${that.tankNames[tank]}`
    if(!min){min=0}
    if(!max){max=120}
    sensor.vals=[min,max]
    sensor.colors=[
        [sensor.vals[0],"#b0d9ff"],
        [sensor.vals[1],"#FFFFFF"],
      ]
    sensor.cUn=tempUnit(that.zInd).t
    return sensor
  }
  
var updateAt=(that,gwType,type,sensor)=>{
    let index=+type.substr(2)
    sensor.vals=[0,120]
    sensor.cUn=tempUnit(that.zInd).t
    sensor.colors=[
        [sensor.vals[0],"#b0d9ff"],
        [sensor.vals[1],"#FFFFFF"],
      ]
    return sensor
  }
  
var updateVo=(that,gwType,type,sensor)=>{
    let index=+type.substr(2)
    sensor.vals=[0,10]
    sensor.cUn="Volts"//tempUnit(that.zInd).t
    sensor.colors=[
        [sensor.vals[0],"#b0d9ff"],
        [sensor.vals[1],"#FFFFFF"],
      ]
    return sensor
  }

var updateRan=(that,gwType,type,sensor)=>{
    //   cl("dvo")
        let index=+type.substr(2)
        sensor.vals=[0,1]
        sensor.cUn=""//tempUnit(that.zInd).t
        sensor.type="ono"
        sensor.colors=[
            [sensor.vals[0],"#b0d9ff"],
            [sensor.vals[1],"#FFFFFF"],
          ]
        return sensor
      }

var updateDVo=(that,gwType,type,sensor)=>{
//   cl("dvo")
    let index=+type.substr(2)
    sensor.vals=[0,10]
    sensor.cUn="Volts"//tempUnit(that.zInd).t
    sensor.type="ono"
    sensor.colors=[
        [sensor.vals[0],"#b0d9ff"],
        [sensor.vals[1],"#FFFFFF"],
      ]
    return sensor
  }

var updateVp=(that,gwType,type,sensor)=>{
    sensor.vals=[0,100]
    sensor.colors=[
        [sensor.vals[0],"#b0d9ff"],
        [sensor.vals[1],"#FFFFFF"],
      ]
    sensor.cUn="%"
    return sensor
  }
  
var updateSm=(that,gwType,type,sensor)=>{
    sensor.vals=[0,100]
    sensor.cUn="%"
    sensor.colors=[
        [sensor.vals[0],"#b0d9ff"],
        [sensor.vals[1],"#FFFFFF"],
      ]
    sensor.cUn="%"
    return sensor
  }
  
var updateGn=(that,gwType,type,sensor)=>{
    sensor.vals=[0,100]
    sensor.cUn="%"
    sensor.colors=[
        [sensor.vals[0],"#b0d9ff"],
        [sensor.vals[1],"#FFFFFF"],
      ]
    sensor.cUn="%"
//     cl(sensor)
    return sensor
  }

var updateInLight=(that,gwType,type,sensor)=>{
//   cl(sensor.val)
    switch(gwType){
      case 800:
        sensor.val=+((getCurVal800("inL",sensor.zone)).val)
        if(sensor.val<0){sensor.val=0}
        sensor.cVal=sensor.val
      default:
        let alarmsActive=getAlarmStatus(sensor.site,sensor.zone,["inLLoSa","inLHiSa"])
        sensor.vals=[0,4500]
        sensor.cUn=lightUnit(sensor.zone).t
        sensor.colors=[
            [sensor.vals[0],"#b0d9ff"],
            [sensor.vals[1],"#FFFFFF"],
          ]
        let al=""
        let pref=sensor.id.substring(0,2)
        if(alarmsActive.includes(`${pref}inLLoSa`)){al="lo"}
        if(alarmsActive.includes(`${pref}inLHiSa`)){al="hi"}
        sensor.al=al
        return sensor
    }
  }
  
var updateInCo2=(that,gwType,type,sensor)=>{
    switch(gwType){
      case 800:
        sensor.val=+((getCurVal800("inC",sensor.zone)).val)
        if(sensor.val<0){sensor.val=0}
        sensor.cVal=sensor.val
      default:
        let alarmsActive=getAlarmStatus(sensor.site,sensor.zone,["inCLoSa","inCHiSa"])
        sensor.vals=[0,4000]
        sensor.cUn="PPM"
        sensor.colors=[
            [sensor.vals[0],"#b0d9ff"],
            [sensor.vals[1],"#FFFFFF"],
          ]
        let al=""
        let pref=sensor.id.substring(0,2)
        if(alarmsActive.includes(`${pref}inCLoSa`)){al="lo"}
        if(alarmsActive.includes(`${pref}inCHiSa`)){al="hi"}
        sensor.al=al
        return sensor
    }
  }
  
var updateVPD=(that,gwType,type,sensor)=>{
//   cl(sensor)
    let vpL=(+getCurVal(`e0vpL`,sensor.zone))||1.0
    let vpH=(+getCurVal(`e0vpH`,sensor.zone))||2.0
//     cl(vpL,vpH)
    let points=[0,1,2,3,4,5]
    let [min,max]=getECpHMinMax(sensor.val,vpL,vpH,points)
    min=min||0.5
    max=max||2.5
//     cl([min,max,vpL,vpH])
    let val=Math.round(sensor.val*100)/100
    sensor.val=val
    sensor.cVal=val
    
//     sensor.vals=[min,vpL,vpH,max]
    sensor.vals=[0,vpL,vpH,max]
    sensor.cUn="kPa"
//     cl(sensor.vals)
    return sensor
  }
  
var updateOutside=(that,gwType,type,sensor)=>{
  let tempUn=tempUnit(sensor.zone).t
  let lightUn=lightUnit(sensor.zone).t
  let windUn=windUnit(sensor.zone).t
  let bVolUn=bVolUnit(sensor.zone).t
  let sVolUn=sVolUnit(sensor.zone).t
  let nuteUn=nuteUnit(sensor.zone).t
  let sType=type.substring(2)

  let minMaxUn={
    "ouT":{minmax:[0,120],unit:tempUn},
    "ouH":{minmax:[0,100],unit:"% RH"},
    "ouL":{minmax:[0,4500],unit:lightUn},
    "bpT":{minmax:[0,150],unit:tempUn},
    "oWs":{minmax:[0,120],unit:windUn},
    "oWd":{minmax:[0,120],unit:""},
    "dPr":{minmax:[0,120],unit:"kPa"},
    "bPr":{minmax:[0,120],unit:"kPa"},
    "ran":{minmax:[0,1],unit:""},
    "sno":{minmax:[0,1],unit:""},
  }[sType]
//   cl(type)
//   cl(sensor)
    if(gwType==800){
      sensor.val=+((getCurVal800(type.substring(2),sensor.zone)).val)
      if((sensor.val==-70)&&(sType=="ouT")){sensor.val=0}
      sensor.cVal=sensor.val
    }
  
    sensor.vals=minMaxUn.minmax//[0,4000]
    sensor.cUn=minMaxUn.unit
    sensor.colors=[
        [sensor.vals[0],"#b0d9ff"],
        [sensor.vals[1],"#FFFFFF"],
      ]
    let al=""
    let pref=sensor.id.substring(0,2)
//     if(alarmsActive.includes(`${pref}inCLoSa`)){al="lo"}
//     if(alarmsActive.includes(`${pref}inCHiSa`)){al="hi"}
    sensor.al=al
//     cl(sensor)
    return sensor
}
  
var updateZoneSetpoint=(that,gwType,type,sensor)=>{
//     cl(sensor.cVal)
    let[sps,curSp,mins]=getCurrentSetpoint(gwType,sensor.zone)
//     cl(`Got SP Time of ${mins}`)
//     if(mins==0){
//       cl(sensor)
//       cl(sensor.cVal)
//       return sensor}// sometimes mins returns as 0 for no reason
//     cl("still going!")
    let times=[]
    sps.forEach((s,i)=>{
      s.col="#c8c3ff"
      times.push(s.start)
    })
    if(sps[curSp]){
      sps[curSp].col="#0e01ac"
      Object.assign(sensor,{name: sps[curSp].name||"Time Period", name2:sps[curSp].name2, type: "sp", vals:sps, cVal: sps[curSp].id+1, val: mins})
    }
    return sensor
  }
  
//   addInTempSensor=(sensors)=>{
//     let sensor={type: "ga", vals:[5, 17, 24, 35], cVal: 19, val: 19}
//     this.updateInTemp(sensor)
//     sensors.push(sensor)
// //     sensors.push(
// //       {name: "Inside Temperature", type: "ga", vals:[5, 17, 24, 35], cVal: 19, cUn: "\u00B0C", val: 19, foot: "Normal"}
// //     )
//   }

var addedSensorTypeNames={
  temp:"Temperature", 
  hum:"Humidity",
}

var updateAddedSensor=(that,sensor,site,zone)=>{
//   cl(zone)
//   cl(sensor)// e0as1
//   cl(that.props)
//   cl(that.state)
//   cl(sensorIds)
//   cl(sensorIds[sensor])
//   cl([sensor,site,zone])
  let se=sensorIds[sensor]
  if(!se){return}
//   cl(se)
  let parts=se?.unit?.split("x")||[]
  let unit={"DegC":"\u00B0F","RH%":"%"}[parts[0]]
//   cl(se)
  let name=se.name
  let parms=pInd[1800].config_annex_sensors
//   cl(parms)
  let rlId=(60+se.ch)*parms[2]+getParamId("config_annex_sensors","rangeLow")
  let rhId=(60+se.ch)*parms[2]+getParamId("config_annex_sensors","rangeHigh")
  let mlId=(60+se.ch)*parms[2]+getParamId("config_annex_sensors","midLow")
  let mhId=(60+se.ch)*parms[2]+getParamId("config_annex_sensors","midHigh")
  
//   cl(pid)
  
  let val=dbVals.z[zone][se.ch][se.id]
//   let val=12
  let raLow=dbVals.z[zone][240][rlId]||0
  let raHi=dbVals.z[zone][240][rhId]||0
  let miLow=dbVals.z[zone][240][mlId]||0
  let miHi=dbVals.z[zone][240][mhId]||0
//   cl(zone,se.ch,getParamId("config_annex_sensors","rangeLow"))
//   cl(raLow)
  let ret={
    name:name,
    name2:name,
    type:"ga",
    vals:[raLow,miLow,miHi,raHi],
    cVal:val/se.mult,
    cUn:unit,
    val:val/se.mult,
    id:sensor,
    site:site,
    zone:zone,
    al:"",
  }
  return ret
}

var updateCam=(that,sensor,site,zone)=>{
//   cl("update cam")
  if(!globs.camerasInfo.info){return}
  let camId=sensor.substring(4)
  let cam=globs.camerasInfo.info.filter(c=>{return c.cameraId==camId})[0]
//   cl(cam)
//   cl(globs.camerasInfo.info)
  return{
    type:"ca",
    id:sensor,
    name:cam.name,
    site:cam.siteId,
    zone:cam.zoneId,
  }
}

//   updateMB=(that,gwType,type,sensor)=>{
//     return sensor
//   }

var makeMBScale=(val)=>{
  let ranges=[1,2,5,10,20,50,100,200,500,1000,2000,5000]
  let range=5000
  for (let i=0;i<ranges.length;i++){
    if(val<ranges[i]){range=ranges[i]; break}
  }
  return[0,0,0,range]
}

var showPc=(station,ed)=>{
//   cl(station)
  let ni=globs.nutrientInfo.ind
//   let ed=station.eventData
  return (ed.pcs||[]).map((pc,i)=>{
    return(
      <div key={i}>
      {
        [...Array(8).keys()].map(j=>{
          let pcNute=pc.nutes[j]
          let nuteId=pcNute.nuteId
          let cps=pcNute.cps
          let vps=pcNute.vps
          let recNute=ed.recNuteInd[nuteId]
          let opac=(recNute)?1:0.15
          let conc=(recNute)?
            ` ${recNute.ingConc} ml/gal (${cps/100} ml / ${vps/100} gals)`:
            " (none)"
//           cl(recNute)
          let nute=ni[nuteId]
          let color=nute.color||"white"
          return(
          <div key={j} 
          title={nute.name+conc}
            style={{width:10,height:10,backgroundColor:color,opacity:opac,
              display:"inline-block",
            margin:"0px 4px"}}>
          </div>
        )})
      }
      </div>
    )
  })
}

var readStationStatus=async()=>{
  let gsi=globs.stEventInfo
  let gateways={}
  globs.zonesInfo.info.forEach(z=>{if(z.gatewayId){gateways[z.gatewayId]=1}})
  let gateways2=Object.keys(gateways)
  let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/stationEvents", method: "retrieve",
    sessionId: globs.userData.session.sessionId, body: {gatewayId:{$in:gateways2}}})// all devices for account
//   cl(res.data)
  gsi.info=res.data
}

var updateST=(that,sensor,site,zone)=>{
// this needs to update from the db each time.
//   console.trace()
//   cl(globs)
  readStationStatus()
  let zi=globs.zonesInfo.info.filter(z=>{
    return (z.siteId==site)&&(z.siteZoneIndex==zone)
  })[0]
  let gatewayId=zi.gatewayId
  let stationId=+sensor.substring(4)
  let station=(globs.stInfo.info||[]).filter(st=>{
    return (st.stationId==stationId) && (st.gatewayId==gatewayId)
  })[0]
//   cl(station)
  let eds=(globs.stEventInfo.info||[]).filter(ev=>{
//     cl(ev)
    return (ev.gatewayId==gatewayId)&&(ev.stationId==stationId)});
//   cl(ed)
  let ed=eds[eds.length-1]
  if(!ed){return}
//   let ed=station.eventData
  ed.recNuteInd={};
  (ed.recipe||[]).forEach(n=>{ed.recNuteInd[n.ingId]=n})// used in showPC
  let areaNames=(ed.areas||[]).map(a=>{return a[1]})
  let da=new Date(1000*ed.start)
  let daEnd=new Date(1000*(ed.start+(ed.duration||0)))
  let start=dateToDisplayDate(da,"mm/dd/yyyy h:mm ap")
  let end=dateToDisplayDate(daEnd,"mm/dd/yyyy h:mm ap")
  let volume=ed.volume// currently *in* gallons. prob shouldn't be
  let state={preparing:"Preparing",running:"Running",done:"Done"}[ed.state]||"Done"
  let netVolume=Math.round(ed.curVolume-ed.startVolume)||0
  let pcCnt=(ed.pcs||[]).length
  let vSpace=(57 - (15 * pcCnt))/7

//   cl(start)
//   cl(da)
//   cl(areaNames)
  let msg=(
    <div>
      <div style={{marginTop:vSpace}}>Event {state}</div>
      <div style={{marginTop:vSpace}}>From: {start}</div>
      <div style={{marginTop:vSpace}}>To: {end}</div>
      <div style={{marginTop:vSpace}}>{ed.recipeName}</div>
      <div style={{marginTop:vSpace}}>Delivered: {netVolume} of {volume} gal</div>
      <div style={{marginTop:vSpace}}>To: {areaNames.join(", ")}</div>
      <div style={{marginTop:vSpace}}>{showPc(station,ed)}</div>
    </div>
  )
  let ret={
    id:sensor,
    al:"",//regAlarm,
    name:station?.name,
    msg:msg,
    cVal:"",
    cUn:"",
    site:site,
    gateway:station?.gatewayId,
    type:"st",//gaugeTypes[regType]||"mb",
//     regType:regInfo.type,
//     indText:indText,
    val:"",//val,
    vals:[0,20,40,50],
    zone:zone,
  }
//   cl(ret)
  return ret
}
  
var updateMB=(that,sensor,site,zone)=>{
//   let gaugeTypes={}
//   gaugeTypes[constant.MB_TYPE_ALARM_TYPE]="mbAl"
//   gaugeTypes[constant.MB_CONV_INDEX]="mbInd"
//   console.trace()
//   cl(sensor)
//   cl(site)
//   cl(zone)
  let str=sensor.substring(4)
  let buf=Uint8Array.from(atob(str), c=>c.charCodeAt(0))
//   cl(buf)
  let regAdd=buf[0]+(buf[1]<<8)
  let mbAdd=buf[2]
  zone=buf[3]
  let regInfo=getMbRegInfo(site,zone,mbAdd,regAdd)
//   cl(regInfo)
  let gaugeType="mb"
//   cl(regInfo)
  let val=((dbVals.z[zone | 0x40]||{})[mbAdd]||{})[regAdd]
  let regType=regInfo.type>>8
//   cl(regType)
//   cl([constant.MB_TYPE_ALARM_TYPE,constant.MB_TYPE_ARCHIVE].includes(regType))
  let regAlarm=""
  if([constant.MB_TYPE_ALARM_TYPE,constant.MB_TYPE_ARCHIVE].includes(regType)&&
    (regInfo.alEn)){
//     cl("do alarm")
//     gaugeType="mbAl"
    if(val>regInfo.alHi){regAlarm="hi"}
    if(val<regInfo.alLo){regAlarm="lo"}
//     cl(regAlarm)
//     cl(val)
//     regAlarm=(val!=0)?"lohi":""
  }
  let indText=""
  if(regInfo.conv==constant.MB_CONV_INDEX){
//     cl(regInfo)
    gaugeType=(regType==constant.MB_TYPE_ALARM_TYPE)?"mbIndAl":"mbInd"
//     cl(regInfo)
    let indName=globs.mbInfo.info.indexNames.filter(indN=>{
//       cl(indN)
      return (indN.gatewayId==regInfo.gatewayId)&&
        (indN.deviceType==regInfo.typeId)&&(indN.convIndexId==regInfo.cParm)
      
    })[0]
//     cl(indName)
    let convId=indName.convIndexId
    let indValue=globs.mbInfo.info.indexValues.filter(indV=>{
//       cl(indV)
      return (indV.gatewayId==regInfo.gatewayId)&&
        (indV.indexId==convId)&&(indV.ivIndex==val)})[0]
//     cl(indValue)
    indText=indValue?.value||""
    regAlarm=(indValue.enable)?"lohi":""
//     cl(indValue)
//     cl(indText)
  }
  if(regInfo.conv==constant.MB_CONV_MULTIPLY){
    val=val/regInfo.cParm
  }
//   cl(val)
  let vals=makeMBScale(val)
//   cl(vals)
//   let res=atob(str)
//   cl(res)
  let ret={
    id:sensor,
    al:regAlarm,
    name:regInfo.name,
    cVal:val,
    cUn:regInfo.unit,
    site:site,
    type:gaugeType,//gaugeTypes[regType]||"mb",
    regType:regInfo.type,
    indText:indText,
    val:val,//val,
    vals:vals,//[0,20,40,50],
    zone:zone,
  }
//   cl(ret)
  return ret
}

var updateDliChan=(that,chan,type,sensor,site,zone)=>{
  let inLight=getCurVal("e0inL",zone)
  let outLight=getCurVal("e0ouL",zone)
  if(inLight<0){inLight=500}
  if(outLight<0){outLight=2000}
  let equipNameId=getParamId2(1900,"configuration_channels","channelName")
  let chanPosId=getParamId2(1900,"snapshot_channels","position")
  var supOutputId,progDliId,supOutputId,stopDliId,curDliId
  if(type==constant.EQ_HID){
    supOutputId=getParamId2(1900,"configuration_channel_data","dliEnergyOutput")
    progDliId=getParamId2(1900,"configuration_channel_data","dliThreshold")
    stopDliId=getParamId2(1900,"configuration_channel_data","dliEndTime")
    curDliId=getParamId2(1900,"snapshot_channel_data","dailyLightIntegral")
  }else{
    supOutputId=getParamId2(1900,"pearl_chan_anx_conf","analogDliOutput")
    progDliId=getParamId2(1900,"pearl_chan_anx_conf","analogDliThreshold")
    stopDliId=getParamId2(1900,"pearl_chan_anx_conf","analogDliEndTime")
    curDliId=getParamId2(1900,"pearl_chan_anx_stat","analogDliAccum")
  }

  let unitTimeId=getParamId2(1900,"snapshots","currentTime")
  let equipName=dbVals.z[zone][chan][equipNameId]
  let supOutput=0.0036*dbVals.z[zone][chan][supOutputId]
  let progDli=dbVals.z[zone][chan][progDliId]
  let curDli=(1e-6)*dbVals.z[zone][chan][curDliId]
//   curDli=12345
  let chanPos=dbVals.z[zone][chan][chanPosId]
//   cl(chanPos)
  let stopDli=dbVals.z[zone][chan][stopDliId]
  let unitTime=dbVals.z[zone][240][unitTimeId]
  let curMinute=Math.floor((unitTime%86400)/60)

  let curMinimumOK=progDli-supOutput*(stopDli-curMinute)/60
  if(curMinimumOK<0){curMinimumOK=0}
//   cl(progDli,supOutput,stopDli,curMinute)
  let ret=  {
    al:"",
    cUn:"Moles",
    cVal:curDli,
    foot:`${inLight} uMol/s`,//"1.6 mol/hr",
    icons:[
//     {
//       x:10,// location in percent
//       y:10,
//       s:20,// max dimension, in percent
//       a:50,// alpha
//       i1:"cloud_br.png",
//       i2:"cloud_wr.png",
//     },
    {
      x:95,// location in percent
      y:7,
      s:13,// max dimension, in percent
//       w:10,
//       h:10,
      a:chanPos,// alpha
      i1:"bulb_br.png",
      i2:"bulb_pr.png",
    }
    ],
    id:sensor,
    name:"DLI02",
    name2:"ch3",
    site:site,
    type:"chan",
    val:curDli,
    vals:[0,curMinimumOK,0,progDli],
    zone:zone,
  }
//   cl(ret)
  return ret

}

var updateLightChan=(that,chan,type,sensor,site,zone)=>{
  let lightModeId=getParamId2(1900,"configuration_channel_data","light_mode")
  let lightMode=dbVals.z[zone][chan][lightModeId]
  if(lightMode==constant.HID_DLI){return updateDliChan(that,chan,type,sensor,site,zone)}
}

var updateChan=(that,sensor,site,zone)=>{
// site is siteId, zone is zoneIndex, sensor is e0c01
  let chan=+sensor.substr(3)
  let equipTypeId=getParamId2(1900,"configuration_channels","channelType")
  let equipType=+dbVals.z[zone][chan][equipTypeId]
  if(equipType==constant.EQ_HID){return updateLightChan(that,chan,equipType,sensor,site,zone)}
  if(equipType==constant.EQ_DLI_A){return updateDliChan(that,chan,equipType,sensor,site,zone)}
}

var updateDli=(that,gwType,type,sensor)=>{
//   cl(sensor.val)
  return{
    al:"",
    cUn:"Moles",
    cVal:sensor.val,
    id:sensor.id,
    name:"DLI",
    name2:"DLI",
//     site:site,
    type:"chan",
    val:sensor.val,
    vals:[0,70],
//     zone:zone,
  }
}

var updateSensor=(that,gwType,sensor,site,zone)=>{
  // cl("update sensor")
  var isChan=(sensorId)=>{
      return ((sensorId[0]=="c")&&(!isNaN(sensorId.substr(1))))
  }
  // cl(gwType,sensor,site,zone)
//   cl(gwType)
//   console.trace()
//   cl(sensor)
//   cl(zone)
//   console.trace()
//   cl(sensor)
  if(!sensor){return}
  let sensor2=sensor
  let sensorPref=""
  if(["e0","e1","e2","e3","ca"].includes(sensor.substring(0,2))){
    sensor2=sensor.substring(2)
    sensorPref=sensor.substring(0,2)
  }
  if(sensorPref=="ca"){return updateCam(that,sensor,site,zone)}
//   cl(sensor2)
  if(sensor2.substring(0,2)=="MB"){return updateMB(that,sensor,site,zone)}
  if(sensor2.substring(0,2)=="ST"){return updateST(that,sensor,site,zone)}
//   cl(sensor2)
  if(isChan(sensor2)){return updateChan(that,sensor,site,zone)}
//   let gwType=1800
  if(sensor2.substring(0,2)=="as"){
    return updateAddedSensor(that,sensor,site,zone)
  }
//   cl(sensor2)
    let addSensors={
      "zoS":updateZoneSetpoint,
      "inT":updateInTemp,
      "inL":updateInLight,
      "dli":updateDli,
      "inH":updateInHum,
      "inC":updateInCo2,
      "ouT":updateOutside,
      "ouH":updateOutside,
      "ouL":updateOutside,
      "bpT":updateOutside,
      "oWs":updateOutside,
//       "oWd":updateOutside,
      "dPr":updateOutside,
      "bPr":updateOutside,
      "ran":updateRan,
//       "sno":updateOutside,
      "fbT":updateInTemp,
      "cnT":updateInTemp,
      "vpd":updateVPD,
      "ec":updateEc,
      "ph":updatepH,
      "tp":updateTp,
      "at":updateAt,
      "vo":updateVo,
      "dv":updateDVo,
      "vp":updateVp,
      "sm":updateSm,
      "gn":updateGn,
    }
//     cl([sensor,site,zone])// inC, siteId, zoneIndex
    var sensorSearch
//     cl(sensor2)
    if(sensor2 in addSensors){// check here first, for vpd vs vp0-5
      sensorSearch=sensor2
    }else{
      let shortS=sensor2.substr(0,2)
      sensorSearch=(["ec","ph","tp","at","vo","dv","vp","sm","gn"].includes(shortS))?shortS:sensor2
    }
//     cl(sensorSearch)
    if(sensorSearch in addSensors){
//       cl(zone)
      let val=getCurVal(sensor,zone)
//       cl(sensor,zone)
//       cl(val)
//       if(sensor=="inT"){cl(val)}
      // if null value, use clean10k func to display "--"
      if (val=='None'||(val==10000)||(val==0)||(val==-1)||((val>774)&&(val<780))) {
        val = "--"
      }
//       cl(val)
//       if(sensor=="inT"){cl(`inTemp: ${val}`)}
//       cl(sensor)
//       cl(sensorSearch)
//       cl(sensorIds)
//       cl(sensor)
//       cl(sensorIds[sensor])
//       cl(sensorIds.e0at0.name)
//       cl(sensorIds)
      let name=(sensorIds[sensor]?.name)?sensorIds[sensor]?.name:sensorIds[sensor]?.name
//       cl(name)
      let name2=(sensorIds[sensor]?.name)?sensorIds[sensor]?.name2:sensorIds[sensor]?.name2
      let sInfo={name: name, name2:name2, type: "ga", vals:[0,0,1,1], cVal: val, cUn: "", val: val, foot: "", id:sensor,site:site,zone:zone}
//       cl(sensor)
//       cl(sensor.cVal)
//       cl(gwType,sensor,sInfo)
//       cl(sensorSearch)
      let s2=addSensors[sensorSearch](that,gwType,sensor,sInfo)
      if((sensor2!="zoS")&&(sensor2!="vpd")){mergeCloseValues(s2.vals,10)}

//       let name=(sensorIds[sensorSearch]?.name)?sensorIds[sensorSearch].name:sensorIds[sensor].name
//       let name2=(sensorIds[sensorSearch]?.name)?sensorIds[sensorSearch].name2:sensorIds[sensor].name2
//       let sInfo={name: name, name2:name2, type: "ga", vals:[0,0,1,1], cVal: val, cUn: "", val: val, foot: "", id:sensor,site:site,zone:zone}
//       let s2=addSensors[sensorSearch](that,sensor,sInfo)
//       if((sensorSearch!="zoS")&&(sensorSearch!="vpd")){mergeCloseValues(s2.vals,10)}
//       cl(s2)
      return s2
    }
    
  }
  
var updateSensors=(that,gwType,sensors,site,zone)=>{
    // cl("update sensors")
    for(let i=0;i<sensors.length;i++){
      if(sensors[i]){
        sensors[i]=updateSensor(that,gwType,sensors[i].id,site,zone)
      }
    }
    return sensors
  }
  
var addSensor=(that,gwType,sensors,sensor,site,zone)=>{
//     cl(sensor)
//     cl(sensors)
//     cl(sensor)
//   cl(sensors)
//   cl(sensor)
//     let gwType=1800
//   cl(sensor)
    let s2=updateSensor(that,gwType,sensor,site,zone)
    // cl(sensor,s2)
    if(s2){sensors.push(s2)}
  }
  
/***************** End Read Sensor Values from C18ZoneSensors ****************************/  

var getSuperUserFlags=()=>{
  let su=globs.privsInfo.info?.filter(pr=>{return pr.level=="super"})
  return ((su||[])[0]||{}).flags
}

var makeImagePath=(id, ext)=>{
    return `${id[0]}/${id[1]}/${id[2]}/${id.substr(3)}`// .${ext}
  }
  
var HSVtoRGB=(h, s, v)=>{// hsv are 0-1
    var r, g, b, i, f, p, q, t;
//     if (arguments.length === 1) {
//         s = h.s, v = h.v, h = h.h;
//     }
    i = Math.floor(h * 6);
    f = h * 6 - i;
    p = v * (1 - s);
    q = v * (1 - f * s);
    t = v * (1 - (1 - f) * s);
    switch (i % 6) {
        case 0: r = v; g = t; b = p; break;
        case 1: r = q; g = v; b = p; break;
        case 2: r = p; g = v; b = t; break;
        case 3: r = p; g = q; b = v; break;
        case 4: r = t; g = p; b = v; break;
        case 5: r = v; g = p; b = q; break;
    }
    return {
        r: Math.round(r * 255),
        g: Math.round(g * 255),
        b: Math.round(b * 255)
    };
}

var RGBtoHSV=(r, g, b)=>{
//     if (arguments.length === 1) {
//         g = r.g, b = r.b, r = r.r;
//     }
    var max = Math.max(r, g, b), min = Math.min(r, g, b),
        d = max - min,
        h,
        s = (max === 0 ? 0 : d / max),
        v = max / 255;

    switch (max) {
        case min: h = 0; break;
        case r: h = (g - b) + d * (g < b ? 6: 0); h /= 6 * d; break;
        case g: h = (b - r) + d * 2; h /= 6 * d; break;
        case b: h = (r - g) + d * 4; h /= 6 * d; break;
    }

    return {
        h: h,
        s: s,
        v: v
    };
}
var makeColor=(h1,h2,frac,s,v)=>{
    var d2h=(dec)=>{
      return `00${dec.toString(16)}`.slice(-2)
    }
    let h=h1+frac*(h2-h1)
    let rgb=HSVtoRGB(h,s,v)
    return `#${d2h(rgb.r)}${d2h(rgb.g)}${d2h(rgb.b)}`
  }
  
var makePingId=(first,revPingIds)=>{
    if(!revPingIds[`@${first}`]){return first}
    let dig=2
    while(revPingIds[`@${first}${dig}`]){dig+=1}
    return `${first}${dig}`
  }
  
var makePingIds=()=>{
    let pingIds={all:"@All",link4:"@Link4_Tech_Support"}
    let revPingIds={"@Link4_Tech_Support":"link4","@All":"all"}
    globs.usersInfo.info.forEach(u=>{
      let pingId=`@${makePingId(u.name,revPingIds)}`
      pingIds[u.userId]=pingId
      revPingIds[pingId]=u.userId
    })
//     cl(pingIds)
    return[pingIds,revPingIds]
  }


var replacePingIdsWithUserIds=(note,revPingIds)=>{
    let notifications=[]
    // check for multiple names
    let names = []
    globs.usersInfo.info.forEach(u=>{
      if (note.includes(u.name)) {
        names.push(u.name);
      }
    })
    for (let name of names) {
      let pingId=`@${name}`
      let userId=revPingIds[pingId]
      if(userId){notifications.push(userId)}
      note = note.replaceAll(pingId, `@${userId}`)
    }
    return[note,notifications]
  }
  
var fixPingIds=(body,pingIds)=>{// replaces the userIds with pingIds
//     cl(body)
//     cl(pingIds)
    let parts=body.split("@")
    for(let i=1;i<parts.length;i++){
      let seg=parts[i]
      let pos=seg.indexOf(' ')
      let userId=seg.substring(0,pos)
//       cl(userId)
//       let seg=parts[i].split(" ")[0]
// //       cl(seg)
//       let useerId=seg
// //       let userId=seg.substring(0,16)
//       cl(userId)
      let pingId=pingIds[userId]
//       cl(pingId)
      if (!pingId) pingId = "@"
      parts[i]=`${pingId}${seg.substring(pos)}`
//       cl(parts[i])
    }
//     cl(parts)
    body=parts.join("")
    return body
  }

var notifyHandlers=async(ta,parms)=>{
  let now=Math.floor(getTime())
    cl(ta)
    let notes=[]
    ta.handlers.forEach(h=>{
      cl(h)
      notes.push({
        date:now,
        subject:"Task",
        body:ta.title,
        level:ta.scope,
        zone:ta.zone,
        userId:h,
        from:globs.userData.session.userId,
        site:ta.site,
        pageType:parms?.pageType,
        type:"task",
        zuci:parms?.zuci,
        flags:constant.CHAT_FLAG_UNREAD,
        notificationId:getRandomString(16),
        taskId: ta.taskId,
      })
    })
    cl(notes)
    await wsTrans("usa", {cmd: "cRest", uri: "/s/notifications", method:"create", 
      sessionId: globs.userData.session.sessionId,body:{notes:notes}
    })
  }
  
  var i2h=(intVal)=>{
    let hexVals="0123456789ABCDEF"
    let ret=""
    for(let i=0;i<8;i++){
      ret=hexVals[intVal&0x0F]+ret
      intVal=intVal>>4
    }
    return ret
  }

  var loadXBoards=(that,zInfo)=>{
//     cl(zInfo)
    var getUId=(zInd,i,ofs)=>{
      return +((dbVals.z[zInd]||{})[240]||{})[that.xIDs.uniqueId0+i*mult+ofs]||0
    }
    let mult=pInd[1900].config_expansion_boards[2]
    let mult2=pInd[1900].snapshot_expansion_boards[2]
    let ceb="configuration_expansion_boards"
    let seb="snapshot_expansion_boards"
    let xca="pearl_xboard_cfg_anx"
    let zInd=zInfo.siteZoneIndex
    that.xIDs={
      numOutId:getParamId2(zInfo.gatewayType,ceb,"numOutputs"),
      chIndex:getParamId2(zInfo.gatewayType,ceb,"startChannelIndex"),// starting output
      addIndex:getParamId2(zInfo.gatewayType,ceb,"address"),
      typeId:getParamId2(zInfo.gatewayType,ceb,"boardType"),
      numInps:getParamId2(zInfo.gatewayType,ceb,"numInputs"),
      startInp:getParamId2(zInfo.gatewayType,ceb,"startInput"),
      mbPort:getParamId2(zInfo.gatewayType,ceb,"modbusPort"),
      uniqueId0:getParamId2(zInfo.gatewayType,xca,"uniqueId0"),
      statusId:getParamId2(zInfo.gatewayType,seb,"boardStatus"),
    }
//     cl(that.xIDs.startInp,that.xIDs.numInps)
//     cl(that.xIDs)
//     cl(mult)
//     cl(that.xIDs)
//     cl(that.xIDs.uniqueId0)
    let xBoards=[]
//     cl(mult2,that.xIDs.statusId)
    for(let i=0;i<40;i++){
      let type=+((dbVals.z[zInd]||{})[240]||{})[that.xIDs.typeId+i*mult]||0
//       cl(type)
      if(type){
        let uId0=+((dbVals.z[zInd]||{})[240]||{})[that.xIDs.uniqueId0+i*mult+0]||0
        let uId1=+((dbVals.z[zInd]||{})[240]||{})[that.xIDs.uniqueId0+i*mult+1]||0
        let uId2=+((dbVals.z[zInd]||{})[240]||{})[that.xIDs.uniqueId0+i*mult+2]||0
        let uId=i2h(getUId(zInd,i,0))+
          i2h(getUId(zInd,i,1))+
          i2h(getUId(zInd,i,2))
//         cl(uId)
        xBoards.push({
          type:type,
          numOuts:+((dbVals.z[zInd]||{})[240]||{})[that.xIDs.numOutId+i*mult]||0,
          startCh:+((dbVals.z[zInd]||{})[240]||{})[that.xIDs.chIndex+i*mult]||0,
          numInps:+((dbVals.z[zInd]||{})[240]||{})[that.xIDs.numInps+i*mult]||0,
          startInp:+((dbVals.z[zInd]||{})[240]||{})[that.xIDs.startInp+i*mult]||0,
          mbAddr:+((dbVals.z[zInd]||{})[240]||{})[that.xIDs.addIndex+i*mult]||0,
          mbPort:+((dbVals.z[zInd]||{})[240]||{})[that.xIDs.mbPort+i*mult]||0,
          status:+((dbVals.z[zInd]||{})[240]||{})[that.xIDs.statusId+i*mult2]||0,
          uniqueId:uId,
          boardIndex:i,
        })
      }
    }
//     let uiTest=+((dbVals.z[0]||{})[240]||{})[that.xIDs.uniqueId+0*mult+1]||0
//     cl(that.xIDs.uniqueId0+0*mult+1)
//     cl((dbVals.z[0]||{})[240])
//     cl(xBoards)
    return xBoards
  }

  var getGroupInfo=(groupId)=>{
    if(acctFeature("zoneGroups")&&Array.isArray(globs.zonesInfo.groups)){
      let siteGroups=globs.zonesInfo.groups?.filter(g=>{
        return g.siteId==globs.userData.session.siteId})[0]
      return siteGroups?.groups.filter(g=>{return groupId==g.groupId})[0]
    }
  }

  var setGroupId=async(groupId,zoneId)=>{
//     cl(globs.userData.session.features)
//     cl(zoneId)
    if(acctFeature("zoneGroups")){
      globs.userData.session.groupId=groupId
      globs.userData.session.groupZoneId=zoneId
      await wsTrans("usa", {cmd: "cRest", uri: "/s/sessions", method: "update",
        sessionId: globs.userData.session.sessionId,
        body: {groupId:groupId,groupZoneId:zoneId}})
    }
  }

  // parse account/site data to get weather data. Identifies if it came from weather or site
  var getValidWeatherLocation=(type="account", id="")=>{
    let body = {}
    let data = globs.accountInfo.info
    if (type === "site") {
      data = getSiteInfo(id)
      if (data.coordinates) {
        Object.assign(body, { siteId: id, coordinates: data.coordinates })
      } 
      if(data.postalCode){
        Object.assign(body, { siteId: id, postalCode: data.postalCode })
      }
      if (data.thoroughfare && data.locality && data.administrativeArea) {
        let address = `${data.thoroughfare} ${data.locality} ${data.administrativeArea}`
        address = address.replaceAll(" ", "%20")
        Object.assign(body, { siteId: id, address: address })
      }
    }
    if (JSON.stringify(body) === '{}') {
      // search account
      data = globs.accountInfo.info
      if (data.coordinates) {
        Object.assign(body, { accountId: globs.userData.session.accountId, coordinates: data.coordinates })
      } 
      if(data.postalCode){
        Object.assign(body, { accountId: globs.userData.session.accountId, postalCode: data.postalCode })
      }
      if (data.thoroughfare && data.locality && data.administrativeArea) {
        let address = `${data.thoroughfare} ${data.locality} ${data.administrativeArea}`
        address = address.replaceAll(" ", "%20")
        Object.assign(body, { accountId: globs.userData.session.accountId, address: address })
      }
    }
    return body
  }

export {login,setTitle,loadSitesInfo,loadPrivsInfo,loadGatewaysInfo,loadUsersInfo,
  sortUsersInfo,getUserIndex,getGatewayInfo,loadAccountInfo,
  loadUser,getSiteName,getSiteInfo,getSiteIndex,loadZonesInfo,getZoneInfo,
  getZoneInfo2,
  getZoneIndexFromSiteIndex,
  getZoneIndex,getZoneName,getZoneId,loadSiteData,getChannelType,
  loadPresetsInfo,getPresetIndex,loadCamerasInfo,loadAlarmsInfo,getAlarmInfo,alLevel,
  makeAvatarPath,setSiteZoneTypes,loadReportsInfo,addToAdminLog,addToTracking,
  getZoneAlarmInfo,
  loadReportSchedulesInfo, getGatewayIndex, getReportsIndex,loadSchedulesInfo,checkSave,getSchedulesIndex,privs,
  getSetpoints,getCurrentSetpoint,
  loadDevicesInfo,loadFuiPagesInfo,saveTable,saveToAdminLog,loadGJ,updateSensors,
  addSensor,makeSensors,loadSubscriptionInfo,alarmNames,sensors,updateSensor,
  previewSubscription,
  createPlan,updateSubscription,getRecurlyAccount, getBilling, verifyBilling, updateDeviceInfo,handleRnMsg,rnRest,restKey,restArr,restResp,
  loadSensorsInfo,getSensorsZone,getSuperUserFlags,rnCl,getTankNames, getBalance,
  getZoneCount, getZonesCount, getZoneDiscount, getTotal, onCheckout, makeZoneTier, getLockedZones,makeImagePath,taskCategories,taskStatus, updateRecurlyAccount,
  checkOwner, checkAdmin,getThirdPartyAccounts, redirectPrivs,addedSensorTypeNames,loadAddedSensorsInfo,getRecurlyAccounts,
  RGBtoHSV,HSVtoRGB,makeColor,loadTagsInfo,tagColor,replacePingIdsWithUserIds,
  makePingIds,
  fixPingIds,acctFeature,notifyHandlers,loadMBInfo,getMbRegInfo,
  handleGdMsg,restGd,restKeyGd,restArrGd,restRespGd,gdRest,getLatLongCenter,
  latLongCenterToPos,posToLatLngCenter,downloadFile,makeGodotResourceUrl,
  resToGodot,loadGodotPack,loadStationInfo,loadSummaryPresetsInfo, getSummaryPresetIndex,
  intToBase64,loadGJ2,loadConfigLog2,getChannelUnit,getChannelDefault,getZoneInfoFromSI,getChannelUnitGroup,loadXBoards,checkConnected,setGroupId,getGroupInfo,getZoneContacts,
  getPearlType,getPearlCount,loadZoneData,makeSensors2,getValidWeatherLocation
}
