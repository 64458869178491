import React from 'react';
// import FormControlLabel from '@material-ui/core/FormControlLabel';// import ConfTextField from './ConfTextField';
// import Checkbox from '@material-ui/core/Checkbox';
import C18MuiFormControlLabel from '../../usa/components/C18MuiFormControlLabel'
import C18MuiCheckbox from '../../usa/components/C18MuiCheckbox'
import {cl, globs} from '../../components/utils/utils';

class FuiCheckbox extends React.Component{
  constructor(props) {
    super(props);
//     cl(props)
    this.mask=props?.custom?.mask||0xFF// bit mask
    this.props.getValue(props.ind, {type: "scalar"})
    this.subscribe_updateFui=globs.events.subscribe("updateFui", this.updateFui)
  }

  componentWillUnmount=()=>{
    this.subscribe_updateFui.remove()
  }

  updateFui=()=>{
    if(!this.changed){this.props.getValue(this.props.ind, {type: "scalar"})}
  }

  onChange=(e)=>{
    let val=+this.props.value
//     cl(val,e.target.checked)
    val=(e.target.checked) ? val|this.mask : val&~this.mask
//     cl(val)
    this.props.onChange(this.props.ind,
      {value: val})
    this.changed=true// blocks updating
//     cl(val)
  }

  render(){
    let val=+this.props.value&this.mask
//     cl(this.props.value,val);
    return(
      <div id={this.props?.custom?.infoId} className="checkbox-field">
        <span><span className="override">
          <C18MuiCheckbox
            id="check"
            title={(this.props?.rest?.parent||(e=>{}))({uri:"pid",pr:this.props})}
            checked={val != 0}
            onChange={this.onChange}
            value="checkedB"
            color="primary"
            saveOK={this.props.saveOK}
          />
          <label>{this.props.title}</label></span>
        </span>
      </div>
    );
  }
}

  export default FuiCheckbox ;
