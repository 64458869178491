import React from 'react';
import { cl, globs, constant } from '../../components/utils/utils';
import { wsTrans } from '../utils/utils'
import { getSiteInfo, getValidWeatherLocation } from './C18utils'
import DayClearIcon from '../../visualization/icons/sun-icon.svg';
import NightClearIcon from '../../visualization/icons/moon-icon.svg';
import DayCloudIcon from '../../visualization/icons/day-cloudy-icon.svg';
import NightCloudIcon from '../../visualization/icons/night-cloudy-icon.svg';
import OvercastIcon from '../../visualization/icons/cloud-icon.svg';
import DaySnowIcon from '../../visualization/icons/day-cloud-snow-icon.svg';
import NightSnowIcon from '../../visualization/icons/night-cloud-snow-icon.svg';
import DayRainIcon from '../../visualization/icons/day-cloud-rain-icon.svg';
import NightRainIcon from '../../visualization/icons/night-cloud-rain-icon.svg';
import DayThunderstormIcon from '../../visualization/icons/day-cloud-rain-lightning-icon.svg';
import NightThunderstormIcon from '../../visualization/icons/night-cloud-rain-lightning-icon.svg';
import FunnelIcon from '../../visualization/icons/hurricane-icon.svg';

class C18WeatherInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
        }
    }

    componentDidMount=()=>{
      this.loadInfo()
    }

    loadInfo = async () => {
      let body = { endpoint: "forecast" }
      // cl("globs", globs.accountInfo.info)
      cl(this.props.parms)
      // looking at sidebar from site/zone view
      if (this.props.parms.siteId) {
        // try to get info from site address
        let siteLocation = getValidWeatherLocation("site", this.props.parms.siteId)
        Object.assign(body, siteLocation)
      // looking at sidebar from sites view
      } else {
        let accountLocation = getValidWeatherLocation()
        Object.assign(body, accountLocation)
      }
      if (body.coordinates || body.address || body.postalCode) {
        let forecast = await wsTrans("usa", {
            cmd: "cRest", uri: "/s/weather", method: "retrieve",
            sessionId: globs.userData.session.sessionId, body: body
        })
        this.setState({ forecast: forecast.data?.properties , loading: false  })
        // cl(forecast)
      } else {
        this.setState({ loading: false }); 
      }
    }

    findMatchingWord(sentence) {

        const wordList = [
          'sunny',
          'fair',
          'clear',
          'windy',
          'breezy',
          'clouds',
          'cloudy',
          'overcast',
          'snow',
          'ice',
          'rain',
          'drizzle',
          'showers',
          'thunderstorm',
          'pellets',
          'funnel',
          'tornado',
          'hurricane',
          'storm',];
    
        const lowercaseSentence = sentence.toLowerCase();
        for (let i = 0; i < wordList.length; i++) {
          if (lowercaseSentence.includes(wordList[i])) {
            return wordList[i];
          }
        }
        return wordList[0];
      }

    makeWeatherIconsEnum = (forecastedSentence, isDaytime) => {

        const sentence = forecastedSentence;
        const matchingWord = this.findMatchingWord(sentence);
        let forecastedIcon = [];
    
        if (isDaytime) {
          let icons = {
            "sunny": DayClearIcon,
            'fair': DayClearIcon,
            'clear': DayClearIcon,
            'windy': DayClearIcon,
            'breezy': DayClearIcon,
            'clouds': DayCloudIcon,
            'cloudy': DayCloudIcon,
            'overcast': OvercastIcon,
            'snow': DaySnowIcon,
            'ice': DaySnowIcon,
            'rain': DayRainIcon,
            'drizzle': DayRainIcon,
            'showers': DayRainIcon,
            'thunderstorm': DayThunderstormIcon,
            'pellets': DayThunderstormIcon,
            'funnel': FunnelIcon,
            'tornado': FunnelIcon,
            'hurricane': FunnelIcon,
            'storm': FunnelIcon,
          }
          forecastedIcon = icons[matchingWord]
        }
    
        else {
          let icons = {
            "sunny": NightClearIcon,
            'fair': NightClearIcon,
            'clear': NightClearIcon,
            'windy': NightClearIcon,
            'breezy': NightClearIcon,
            'clouds': NightCloudIcon,
            'cloudy': NightCloudIcon,
            'overcast': OvercastIcon,
            'snow': NightSnowIcon,
            'ice': NightSnowIcon,
            'rain': NightRainIcon,
            'drizzle': NightRainIcon,
            'showers': NightRainIcon,
            'thunderstorm': NightThunderstormIcon,
            'pellets': NightThunderstormIcon,
            'funnel': FunnelIcon,
            'tornado': FunnelIcon,
            'hurricane': FunnelIcon,
            'storm': FunnelIcon,
          }
    
          forecastedIcon = icons[matchingWord]
        }
    
        return forecastedIcon;
      }

    showForecast = () => {
        if (!this.state.forecast) {
            return <div style={{ textAlign: "center" }}><i>No weather information available</i></div>;
        } else {
            let units = {
                "degF": String.fromCharCode(176) + "F",
                "degC": String.fromCharCode(176) + "C",
                "percent": "%",
            }
            // let curr = this.state.forecast.periods[0]
            // let name = curr.name
            // let temp = `${curr.temperature}\u00B0${curr.temperatureUnit}`
            // let short_forecast = curr.shortForecast
            // let detailed_forecast = curr.detailedForecast
            // let wind_dir = curr.windDirection
            // let wind_spd = curr.windSpeed
            // let pop = `${curr.probabilityOfPrecipitation.value} ${units[curr.probabilityOfPrecipitation.unitCode.split(":")[1]]}`
            // let dewpoint = `${curr.dewpoint.value} ${units[curr.dewpoint.unitCode.split(":")[1]]}`
            // let rh = `${curr.relativeHumidity.value} ${units[curr.relativeHumidity.unitCode.split(":")[1]]}`
            // skip every other period
            return (
                <span>
                {this.state.forecast.periods&&this.state.forecast.periods.reduce((acc, t, i, arr) => {
                    if (t.isDaytime || (!t.isDaytime && (i === 0 || !arr[i - 1].isDaytime))) {
                      let nextPeriod = arr[i + 1]; // Next period 
                      let tempDisplay;
              
                      if (t.isDaytime && nextPeriod && !nextPeriod.isDaytime) {
                        // Min-Max
                        let minTemp = Math.min(t.temperature, nextPeriod.temperature);
                        let maxTemp = Math.max(t.temperature, nextPeriod.temperature);
                        tempDisplay = minTemp === maxTemp
                            ? `${maxTemp}°${t.temperatureUnit}`
                            : `${minTemp}-${maxTemp}°${t.temperatureUnit}`;
                      } else {
                        // Single Temp
                        tempDisplay = `${t.temperature}°${t.temperatureUnit}`;
                      }
                      let icon = this.makeWeatherIconsEnum(t.shortForecast||"", t.isDaytime)
                      let pop = `${t.probabilityOfPrecipitation?.value||0}${units[t.probabilityOfPrecipitation?.unitCode.split(":")[1]]}`
                      acc.push(
                        <span key={i}>
                          <span style={{ fontWeight: "600" }}>{`${t.name}`} </span>
                          <img src={icon} width="20px" />
                          <br></br>
                          <span>Temperature: {tempDisplay}</span>
                          <br></br>
                          <span>Wind: {t.windDirection} | {t.windSpeed}</span>
                          <br></br>
                                {t.relativeHumidity &&
                                    <span>Relative Humidity: {`${t.relativeHumidity?.value||""}${units[t.relativeHumidity?.unitCode.split(":")[1]]||""}`}</span>
                                }
                                {t.relativeHumidity &&
                                   <br></br>
                                }
                          <span>Rain: {pop}</span>
                          <br></br>
                          <br></br>
                        </span>
                      )
                    }
                    return acc;
                  }, [])}
              </span>
            )
        } 
    }


    render() {
      return ((this.state.loading) ?
        <div id="/sidebar/weatherInfo" >
          <div className="loading-container">
              <div className="loadside-spinner"></div>
              <i>Loading weather information...</i>
          </div>
        </div>
        :
        <div id="/sidebar/weatherInfo" >
          {this.showForecast()}
        </div>
      );
    }
}

export default C18WeatherInfo;
