import React from 'react';
import Slider from '@material-ui/core/Slider';
import {cl,globs} from '../../components/utils/utils';

class C18MuiSlider extends React.Component{
  constructor(props) {
    super(props);
//     cl(props)
    this.state={
    }
  }

  valuetext = (val) => {
    return this.props.marks.find((mark) => mark.value === val)?.time;
  }

  valueLabelFormat = (val) => {
    return this.props.marks.findIndex((mark) => mark.value === val);
  }


  render(){
//     cl(this.props.value)
//     cl(this.props.children)
    cl(this.props)
    let pr=this.props
    cl(pr.marks)
    if(globs.noMui){
      return(
        <div align="center">
          <div style={{width:"60%"}}>
           <Slider
              size="small"
              track={false}
              aria-label={pr.label}
              valueLabelDisplay="auto"
              getAriaValueText={this.valuetext}
              defaultValue={pr.marks[0].value}
              valueLabelFormat={this.valueLabelFormat}
              step={pr.step} // should be in ms
              min={pr.marks[0].value}
              max={pr.marks[pr.marks.length - 1].value}
              marks={pr.marks} // array of obj with int val and str label
              onChangeCommitted={pr.onChange}
            /> 
          </div>
        </div>
      )
      return null
    }else{
      return React.createElement(Slider, pr)
    }
  }
}
      
export default C18MuiSlider;
 