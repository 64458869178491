import React from 'react';
import C18Anchor00 from './C18Anchor00'
import C18CustomSelect01 from './C18CustomSelect01'
import config from '../../components/utils/config'
import {wsTrans,sensorIds,getUId,doGetPostBasic,makeOpts} from '../utils/utils'
import {loadSitesInfo,loadZonesInfo,getZoneInfo,loadSiteData,getSiteName,getZoneName,privs,loadPrivsInfo,
  getSuperUserFlags,acctFeature,getGroupInfo} from './C18utils'
import {cl,constant,globs} from '../../components/utils/utils';
import {getZoneControllers} from '../../components/utils/http';


class C18Settings00 extends React.Component{
  constructor(props) {
    super(props);
//     cl(props)
//     props.parms.newProps(this.newProps)// register newProps function
    this.state={
      selController:0,
      loaded:false,
    }
//     cl(globs.userData.winSize)
    

//     if(acctFeature("videoTest")){
//       this.settingsInfo.account.push(
//         {name: "Video Test", mode: "usa", url: "/usa/c18/admin/vidTest"}
//       )
//     }
//     cl(this.settingsInfo.account)
    this.loadInfo()
  }

  makeSettingsInfo=()=>{
    let zgFui=(
      acctFeature("zoneGroups")&&
      globs.userData.session.groupId)?
      "zgfui":"fui"
//     cl(globs)
//     cl(this.props)
    let pa=this.props.parms

    let zoneInfo=(globs.zonesInfo.info.filter(z=>{return pa.zone==z.zoneId})||{})[0]
    let gwType=(zoneInfo?.gatewayType||1800)
//     cl(gwType)
    let phonePhlag=(globs.userData?.winSize.length > 0 ? globs.userData?.winSize[0] : 1000)<460
//     cl(phonePhlag)
    this.settingsInfo={
      sensor1800:[// Sensor Settings
        {name: "Input Mapping", mode: "settings", url: `/mapping`},
        {name: "Input Calibration", mode: "settings", url: `/calibration`},
        {name: "Input Multipliers", mode: "fui", url: `/${zgFui}/unit_Input_Multipliers`},
        {name: "Setpoints", mode: "fui",
          url: (phonePhlag)? `/${zgFui}/zone_Setpoints`:`/${zgFui}/setpoints`},
//         {name: "Setpoints", mode: "settings", url: `/setpoints`},
        {name: "Miscellaneous", mode: "fui", url: `/${zgFui}/unit_Miscellaneous`},
        {name: "Select Sensors", mode: "usa", url: `/usa/c18/admin/selectSensors`},
//         {name: "Sync Status", mode: "usa", url: `/usa/c18/admin/syncStatus`},
        ],
      sensor800:[// Sensor Settings
        {name: "Setpoints", mode: "fui", url: `/${zgFui}/setpoint_800`},
        {name: "Temperature Options", mode: "fui", url: `/${zgFui}/adv_temperature_800`},
        {name: "Humidity Options", mode: "fui", url: `/${zgFui}/adv_humidity_800`},
        {name: "CO2 Options", mode: "fui", url: `/${zgFui}/adv_co2_800`},
        {name: "VPD Sensor Parameters", mode: "fui", url: `/${zgFui}/VPD_sensor_parameters`},
        {name: "Mapping", mode: "fui", url: `/${zgFui}/adv_mapping_800`},
        {name: "Calibration", mode: "fui", url: `/${zgFui}/adv_calibration_800`},
        {name: "Units", mode: "fui", url: `/${zgFui}/adv_unit-measurement_800`},
//         {name: "Notifications & Alarms", mode: "fui", url: `/${zgFui}/notification-alarm_800`},
//         {name: "History & Logs", mode: "fui", url: `/${zgFui}/history-logs_800`},
        {name: "Self Sensor Test", mode: "fui", url: `/${zgFui}/self_sensor_test_800`},
        {name: "Select Sensors", mode: "usa", url: `/usa/c18/admin/selectSensors`},

//         {name: "Equipment Options", mode: "fui", url: `/${zgFui}/equipment-options_800`},
//         {name: "Controller Name", mode: "fui", url: `/${zgFui}/adv_name_800`},
//         {name: "Security", mode: "fui", url: `/${zgFui}/adv_security_800`},
//         {name: "Date and Time Format", mode: "fui", url: `/${zgFui}/adv_date-time-format_800`},
//         {name: "Bump Off", mode: "fui", url: `/${zgFui}/adv_bump-off_800`},
//         {name: "Location", mode: "fui", url: `/${zgFui}/adv_location_800`},
//         {name: "Grow Week", mode: "fui", url: `/${zgFui}/adv_growWeek_800`},
//         {name: "Sync Status", mode: "usa", url: `/usa/c18/admin/syncStatus`},
//         {name: "Input Mapping", mode: "settings", url: `/mapping800`},
//         {name: "Input Calibration", mode: "settings", url: `/calibration800`},
//         {name: "Input Multipliers", mode: "fui", url: `/${zgFui}/unit_Input_Multipliers800`},
//         {name: "Setpoints", mode: "fui",
//           url: (phonePhlag)?"/${zgFui}/zone_Setpoints":"/${zgFui}/setpoints800`},
// //         {name: "Setpoints", mode: "settings", url: `/setpoints`},
//         {name: "Miscellaneous", mode: "fui", url: `/${zgFui}/unit_Miscellaneous800`},
//         {name: "Select Sensors", mode: "usa", url: `/usa/c18/admin/selectSensors800`},
// //         {name: "Sync Status", mode: "usa", url: `/usa/c18/admin/syncStatus`},
        ],
      sensor1900:[// Sensor Settings
        {name: "Input Mapping", mode: "settings", url: `/mapping`},
        {name: "Input Calibration", mode: "settings", url: `/calibration`},
        {name: "Input Multipliers", mode: "fui", url: `/${zgFui}/unit_Input_Multipliers`},
        {name: "Setpoints", mode: "fui", url: `/${zgFui}/setpoints`},
//         {name: "Setpoints", mode: "settings", url: `/setpoints`},
        {name: "Miscellaneous", mode: "fui", url: `/${zgFui}/unit_Miscellaneous`},
        {name: "Select Sensors", mode: "usa", url: `/usa/c18/admin/selectSensors`},
//         {name: "Sync Status", mode: "usa", url: `/usa/c18/admin/syncStatus`},
        ],
      sensor3200:[// Sensor Settings
        {name: "Input Configuration", mode: "fui", url: `/${zgFui}/3200_input_configuration`},
        {name: "Setpoints", mode: "fui", url: `/${zgFui}/3200_setpoints`},
        {name: "Select Sensors", mode: "usa", url: `/usa/c18/admin/selectSensors`},
        ],
      sensor3300:[// Sensor Settings
        {name: "Input Mapping", mode: "settings", url: `/mapping`},
        {name: "Input Configuration", mode: "fui", url: `/${zgFui}/3300_input_configuration`},
        {name: "Setpoints", mode: "fui",
          url: `/${zgFui}/zone_Setpoints`},
//           url: (phonePhlag)?"/${zgFui}/zone_Setpoints":"setpoints`},
        {name: "Select Sensors", mode: "usa", url: `/usa/c18/admin/selectSensors`},
        ],
      equipment1800:[// Equipment Settings
        {name: "Zone Configuration", mode: "settings", url: `/zone_configuration`},
        {name: "Aux Controls", mode: "fui", url: `/${zgFui}/zone_Aux_Controls`},
        {name: "Irrigation", mode: "fui", url: `/${zgFui}/all_irrigation`},
        // hide until expansion is working again
        // {name: "Expansion", mode: "pearl", url: `/usa/c18/sites/${this.props.parms.site}/zones/${this.props.parms.zone}/pearl/expansion`},
        {name: "Stages", mode: "fui", url: `/${zgFui}/temp_Staging`},
        {name: "Hum/DeHum", mode: "fui", url: (phonePhlag)?`/${zgFui}/zone_Hum_DeHum`:`/${zgFui}/HumDeHum`},
        // {name: "iDoser", mode: "idoser", url: `/usa/c18/sites/${this.props.parms.site}/zones/${this.props.parms.zone}/idoser/nutrients`},
        // {name: "Modbus", mode: "modbus", url: `/usa/c18/sites/${this.props.parms.site}/zones/${this.props.parms.zone}/modbus/devices`},
        {name: "Retractable Greenhouse", mode: "fui", url: `/${zgFui}/zone_SP_Retractable_Greenhouse`},
        {name: "Smart Cool", mode: "fui", url: `/${zgFui}/zone_Smartcool`},
        {name: "Drive to Average", mode: "fui", url: `/${zgFui}/zone_SP_Drive_to_Avg`},
        {name: "Influence Factors", mode: "fui", url: `/${zgFui}/zone_SP_Influence_Factors`},
//         {name: "Temp Staging", mode: "fui", url: `/${zgFui}/temp_Staging`},
        {name: "Crop Recipes", mode: "cropRecipes", url:
          `/usa/c18/cropRecipes`},
        ],
      equipment1900:[// Equipment Settings
        {name: "Zone Configuration", mode: "settings", url: `/zone_configuration`},
        {name: "Aux Controls", mode: "fui", url: `/${zgFui}/zone_Aux_Controls`},
        {name: "Irrigation", mode: "fui", url: `/${zgFui}/all_irrigation`},
        // hide until expansion is working again
        {name: "Expansion", mode: "pearl", url: `/usa/c18/sites/${this.props.parms.site}/zones/${this.props.parms.zone}/pearl/expansion`},
        {name: "Stages", mode: "fui", url: `/${zgFui}/temp_Staging`},
        {name: "Hum/DeHum", mode: "fui", url: `/${zgFui}/HumDeHum`},
        {name: "iDoser", mode: "idoser", url: `/usa/c18/sites/${this.props.parms.site}/zones/${this.props.parms.zone}/idoser/nutrients`},  
        {name: "Modbus", mode: "modbus", url: `/usa/c18/sites/${this.props.parms.site}/zones/${this.props.parms.zone}/modbus/devices`},
        // {name: "Modbus GP", mode: "modbusGp", url: `/usa/c18/sites/${this.props.parms.site}/zones/${this.props.parms.zone}/modbusGp`},
        {name: "Retractable Greenhouse", mode: "fui", url: `/${zgFui}/zone_SP_Retractable_Greenhouse`},
        {name: "Smart Cool", mode: "fui", url: `/${zgFui}/zone_Smartcool`},
        {name: "Drive to Average", mode: "fui", url: `/${zgFui}/zone_SP_Drive_to_Avg`},
        {name: "Influence Factors", mode: "fui", url: `/${zgFui}/zone_SP_Influence_Factors`},
        ],
      equipment3200:[// Equipment Settings
        {name: "Stages and Alarms", mode: "fui", url: `/${zgFui}/3200_stages_alarms`},
//         {name: "Temperature Stage Settings", mode: "fui", url: `/${zgFui}/3200_temp_zone_Stages`},
//         {name: "Humidity Stage Settings", mode: "fui", url: `/${zgFui}/3200_hum_zone_Stages`},
//         {name: "Light Stage Settings", mode: "fui", url: `/${zgFui}/3200_light_zone_Stages`},
//         {name: "CO2 Stage Settings", mode: "fui", url: `/${zgFui}/3200_co2_zone_Stages`},
        ],
      equipment3300:[// Equipment Settings
//         {name: "Stages and Alarms", mode: "fui", url: `/${zgFui}/3300_stages_alarms`},
        {name: "Alarm Limits", mode: "fui", url: `/${zgFui}/zone_Alarms`},
        {name: "Stage Settings", mode: "fui", url: `/${zgFui}/zone_Stages`},
        ],
      equipment800:[// Equipment Settings
//         {name: "Equipment Options", mode: "fui", url: `/${zgFui}/equipment-options_800`},
//         {name: "Controller Name", mode: "fui", url: `/${zgFui}/adv_name_800`},
        {name: "Security", mode: "fui", url: `/${zgFui}/adv_security_800`},
        {name: "Date & Time", mode: "fui", url: `/${zgFui}/adv_date-time-format_800`},
        {name: "Bump Time", mode: "fui", url: `/${zgFui}/adv_bump-off_800`},
        {name: "Location", mode: "fui", url: `/${zgFui}/adv_location_800`},
        {name: "Grow Week", mode: "fui", url: `/${zgFui}/adv_growWeek_800`},
        {name: "Stages", mode: "fui", url: `/${zgFui}/temp_Staging_800`},
        ],
      account:[// Account Settings
        {name: "Manage Account", mode: "usa", url: `/usa/c18/admin/manageAccount2`},
//         {name: "Manage Users", mode: "usa", url: `/usa/c18/admin/manageUsers2`},
        {name: "Manage Users", mode: "usa", url: `/usa/c18/admin/manageUsers3`},
        {name: "Manage Sites", mode: "usa", url: `/usa/c18/admin/manageSites2`},
        {name: "Manage Site Access", mode: "usa", url: `/usa/c18/admin/manageSiteAccess2`},
        {name: "Manage Gateways", mode: "usa", url: `/usa/c18/admin/manageGateways2`},
        {name: "Manage Alarms", mode: "usa", url: `/usa/c18/admin/manageAlarms2`},
        {name: "Manage Zones", mode: "usa", url: `/usa/c18/admin/manageZones2`},
//         {name: "Manage Zone Groups", mode: "usa", url: `/usa/c18/admin/manageZoneGroups`},
        {name: "Manage Subscription", mode: "usa", url: `/usa/c18/admin/manageSubscription`},
//         {name: "System Status", mode: "usa", url: `/usa/c18/admin/systemStatus`},
        ],
      mapping: [// Sensor Settings / Mapping
        {name: "Basic Mapping", mode: "fui", url: `/${zgFui}/unit_Input_Mapping`},
        {name: "Pearl Mapping", mode: "pearl", url: `/usa/c18/sites/${this.props.parms.site}/zones/${this.props.parms.zone}/pearl/mapping`},
        {name: "Pearl Input Config", mode: "pearl", url: `/usa/c18/sites/${this.props.parms.site}/zones/${this.props.parms.zone}/pearl/inputConfig`},
        {name: "Analog Temperature Sensor Mappings", mode: "fui", url: `/${zgFui}/unit_Analog_Temp_Mapping`},
        {name: "Irrigation Sensor Mappings", mode: "fui", url: `/${zgFui}/unit_Irrigation_Sensor_Mapping`},
        {name: "Vent Position Sensor Mappings", mode: "fui", url: `/${zgFui}/unit_Vent_Position_Mapping`},
        {name: "Mixing Tanks", mode: "fui", url: `/${zgFui}/unit_Mixing_Tanks`},
//         {name: "Mixing Tank Sensors2", mode: "settings", url: `/tankMapping`},
        {name: "Generic Mapping", mode: "fui", url: `/${zgFui}/unit_Generic_Mapping`},
        {name: "Network Sensors", mode: "fui", url: `/${zgFui}/unit_Network_Sensors`},
        {name: "Accumulator", mode: "fui", url: `/${zgFui}/unit_Accumulator`},
        ],
      calibration: [// Sensor Settings / Calibration
        {name: "Basic Calibration", mode: "fui", url: `/${zgFui}/unit_Input_Calibration`},
//         {name: "Pearl Calibration", mode: "pearl", url: `/usa/c18/sites/${
//           this.props.parms.site}/zones/${this.props.parms.zone}/pearl/calibration`},
        {name: "Analog Temperature Sensor Calibration", mode: "fui", url: `/${zgFui}/unit_Analog_Temp_Calibration`},
        {name: "Soil Moisture Sensor Calibration", mode: "fui", url: `/${zgFui}/unit_Soil_Moisture_Calibration`},
        {name: "Vent Position Sensor Calibration", mode: "fui", url: `/${zgFui}/unit_Vent_Position_Calibration`},
        {name: "Mixing Tank Calibration", mode: "fui", url: `/${zgFui}/unit_Mixing_Tank_Calibration`},
//         {name: "Mixing Tank Calibration2", mode: "settings", url: `/tankCalibration`},
        {name: "Generic Calibration", mode: "fui", url: `/${zgFui}/unit_Generic_Calibration`},
        ],
      zone_configuration: [// Equipment Settings / Zone Configuration
//           url: `/usa/c18/sites/${this.props.parms.site}/zones/${this.props.parms.zone}/saveRestore`},
        {name: "Stage Settings", mode: "fui", url: `/${zgFui}/zone_Stages`},
        {name: "Deadband Settings", mode: "fui", url: `/${zgFui}/zone_Deadband`},
        {name: "Alarm Limits", mode: "fui", url: `/${zgFui}/zone_Alarms`},
        {name: "Units", mode: "fui", url: `/${zgFui}/zone_Units`},
        {name: "Output Settings", mode: "fui", url: `/${zgFui}/zone_Output`},
        {name: "Irrigation", mode: "fui", url: `/${zgFui}/zone_Irrigation`},
        {name: "Peristaltic", mode: "fui", url: `/${zgFui}/zone_Peristaltic`},
        {name: "Lighting", mode: "fui", url: `/${zgFui}/zone_Lighting`},
        {name: "Fallback Settings", mode: "fui", url: `/${zgFui}/zone_Fallback`},
        {name: "History Locations, DST", mode: "fui", url: `/${zgFui}/zone_History`},
        {name: "Heat and Cool Demand", mode: "fui", url: `/${zgFui}/zone_H-C_Demand`},
        ],
        select_sensor_3300:[

        ],
//       setpoints:[// Equipment Settings
//         {name: "Setpoint 1", mode: "fui", url: `/${zgFui}/zone_Setpoints/0-0-0-0`},
//         {name: "Setpoint 2", mode: "fui", url: `/${zgFui}/zone_Setpoints/0-0-0-1`},
//         {name: "Setpoint 3", mode: "fui", url: `/${zgFui}/zone_Setpoints/0-0-0-2`},
//         {name: "Setpoint 4", mode: "fui", url: `/${zgFui}/zone_Setpoints/0-0-0-3`},
//         {name: "Setpoint 5", mode: "fui", url: `/${zgFui}/zone_Setpoints/0-0-0-4`},
//         {name: "Setpoint 6", mode: "fui", url: `/${zgFui}/zone_Setpoints/0-0-0-5`},
//         {name: "Setpoint 7", mode: "fui", url: `/${zgFui}/zone_Setpoints/0-0-0-6`},
//         {name: "Setpoint 8", mode: "fui", url: `/${zgFui}/zone_Setpoints/0-0-0-7`},
//         ],
//       tankCalibration:[// Equipment Settings
//         {name: "Tank 1 Calibration", mode: "fui", url: `/${zgFui}/unit_Mixing_Tank_Calibration/0-0-192-0`},
//         {name: "Tank 2 Calibration", mode: "fui", url: `/${zgFui}/unit_Mixing_Tank_Calibration/0-0-193-0`},
//         {name: "Tank 3 Calibration", mode: "fui", url: `/${zgFui}/unit_Mixing_Tank_Calibration/0-0-194-0`},
//         {name: "Tank 4 Calibration", mode: "fui", url: `/${zgFui}/unit_Mixing_Tank_Calibration/0-0-195-0`},
//         {name: "Tank 5 Calibration", mode: "fui", url: `/${zgFui}/unit_Mixing_Tank_Calibration/0-0-196-0`},
//         {name: "Tank 6 Calibration", mode: "fui", url: `/${zgFui}/unit_Mixing_Tank_Calibration/0-0-197-0`},
//         {name: "Tank 7 Calibration", mode: "fui", url: `/${zgFui}/unit_Mixing_Tank_Calibration/0-0-198-0`},
//         {name: "Tank 8 Calibration", mode: "fui", url: `/${zgFui}/unit_Mixing_Tank_Calibration/0-0-199-0`},
//         ],
//       tankMapping:[// Equipment Settings
//         {name: "Tank 1 Mapping", mode: "fui", url: `/${zgFui}/unit_Mixing_Tanks/0-0-192-0`},
//         {name: "Tank 2 Mapping", mode: "fui", url: `/${zgFui}/unit_Mixing_Tanks/0-0-193-0`},
//         {name: "Tank 3 Mapping", mode: "fui", url: `/${zgFui}/unit_Mixing_Tanks/0-0-194-0`},
//         {name: "Tank 4 Mapping", mode: "fui", url: `/${zgFui}/unit_Mixing_Tanks/0-0-195-0`},
//         {name: "Tank 5 Mapping", mode: "fui", url: `/${zgFui}/unit_Mixing_Tanks/0-0-196-0`},
//         {name: "Tank 6 Mapping", mode: "fui", url: `/${zgFui}/unit_Mixing_Tanks/0-0-197-0`},
//         {name: "Tank 7 Mapping", mode: "fui", url: `/${zgFui}/unit_Mixing_Tanks/0-0-198-0`},
//         {name: "Tank 8 Mapping", mode: "fui", url: `/${zgFui}/unit_Mixing_Tanks/0-0-199-0`},
//         ],

    }
//     cl("ad")
    if(acctFeature("autoSetup")){
      this.settingsInfo.sensor1900.push(
        {name: "Auto Setup", mode: "usa", url: "/usa/c18/admin/autoSetup"}
      )
    }
    if(acctFeature("testing")){
      this.settingsInfo.account.push(
        {name: "Test Report Writer", mode: "usa", url: "/usa/c18/admin/testReportWriter"}
      )
    }
    if(acctFeature("syncStatus")){
      this.settingsInfo.sensor1800.push(
        {name: "Sync Status", mode: "usa", url: "/usa/c18/admin/syncStatus"},
      )
    }
    if(acctFeature("zoneGroups")&&globs.userData.session.groupId){
      this.settingsInfo.equipment1900=this.settingsInfo.equipment1900.filter(eq=>{
        return !["Expansion","iDoser","Modbus"].includes(eq.name)
      })
      this.settingsInfo.equipment1800=this.settingsInfo.equipment1800.filter(eq=>{
        return !["Expansion","iDoser","Modbus"].includes(eq.name)
      })
//       this.settingsInfo.account.push(
//         {name: "Manage Zone Groups", mode: "usa", url: "/usa/c18/admin/manageZoneGroups"}
//       )
    }
    if(gwType!=1800){
      let line={name: "Save & Restore Settings", mode: "usa", url: `/usa/c18/saveRestore`}
      this.settingsInfo.zone_configuration.splice(4,0,line)
      this.settingsInfo.equipment800.push(line)//splice(4,0,line)
    }
    if(acctFeature("cameras")){
      this.settingsInfo.sensor1800.push(
        {name: "Cameras", mode: "usa", url: "/usa/c18/admin/cameras"},
      )
    }


    // configure uiInfo in tech portal
//     if((globs.user && globs.user.uiInfo?.showEditInfoPages) || ((getSuperUserFlags()&(constant.SUPER_PRIVS_EDIT)))){
    if(acctFeature("editInfo")){
      this.settingsInfo.account.push(
        {name: "Edit Info Pages", mode: "usa", url: "/usa/c18/admin/editInfoPages"}
      )
    }
    // TODO check user specific TECH_PORTAL and SALES_PORTAL bool
    if((getSuperUserFlags()&(constant.SUPER_PRIVS_ADMIN))&&acctFeature("techPortal")){
      this.settingsInfo.account.push(
//         {name: "Tech Portal", mode: "usa", url: "/usa/c18/admin/techPortal"},
        {name: "Tech Portal", mode: "usa", url: "/usa/c18/admin/techPortal2"}
      )
      this.settingsInfo.equipment1900.push(
        {name: "Modbus GP", mode: "modbusGp", url: `/usa/c18/sites/${this.props.parms.site}/zones/${this.props.parms.zone}/modbusGp`}
                                      )
    }
    if(acctFeature("svgEditor")){
      this.settingsInfo.account.push(
//         {name: "Tech Portal", mode: "usa", url: "/usa/c18/admin/techPortal"},
        {name: "SVG Editor", mode: "usa", url: "/usa/c18/svgEditor"}
      )
    }
    if(acctFeature("salesPortal")){
//     if((getSuperUserFlags()&(constant.SUPER_PRIVS_ADMIN)) || (globs.user && globs.user.uiInfo?.showSalesPortal)){

      this.settingsInfo.account.push(
        {name: "Sales Portal", mode: "usa", url: "/usa/c18/admin/salesPortal"}
      )
    }
//     if((getSuperUserFlags()&(constant.SUPER_PRIVS_ADMIN)) || (globs.user && globs.user.uiInfo?.showSystemStatus))
    if(acctFeature("systemStatus")){
      this.settingsInfo.account.push(
        {name: "System Status", mode: "usa", url: "/usa/c18/admin/systemStatus"}
      )
    }
    if(acctFeature("accesses")){
      this.settingsInfo.account.push(
        {name: "Page Accesses", mode: "usa", url: "/usa/c18/admin/pageAccesses"}
      )
    }
    if(acctFeature("debug")){
      this.settingsInfo.account.push(
        {name: "Copy Link4Greenhouse", mode: "usa", url: "/usa/c18/admin/link4Greenhouse"}
      )
    }
    if(acctFeature("qrcodeListen")){
      this.settingsInfo.account.push(
        {name: "Firmware Hub", mode: "usa", url: "/usa/c18/admin/qrcodeReceive"}
      )
    }
    if(acctFeature("zoneGroups")){
      this.settingsInfo.account.push(
        {name: "Manage Groups", mode: "usa", url: "/usa/c18/admin/manageGroups"},
      )
    }
    if(!this.zoneInfo?.virtual){
      this.settingsInfo.equipment1800.splice(5, 0, 
        {name: "iDoser", mode: "idoser", url: `/usa/c18/sites/${this.props.parms.site}/zones/${this.props.parms.zone}/idoser/nutrients`},
      )
      this.settingsInfo.equipment1800.splice(6, 0,
        {name: "Modbus", mode: "modbus", url: `/usa/c18/sites/${this.props.parms.site}/zones/${this.props.parms.zone}/modbus/devices`},
      )
    }
    if(privs("account",0,constant.AREA_PRIVS_ADMIN)){
      this.settingsInfo.equipment1800.push(
        {name: "Groups", mode: "usa", url: "/usa/c18/admin/selectGroup"},
      )
      this.settingsInfo.equipment1900.push(
        {name: "Groups", mode: "usa", url: "/usa/c18/admin/selectGroup"},
      )
    }
    if(acctFeature("fwPortal")){
      this.settingsInfo.account.push(
        {name: "Firmware Portal", mode: "usa", url: "/usa/c18/admin/firmwarePortal"}
      )
    }

    // add expansion page if super user
    if((getSuperUserFlags()&(constant.SUPER_PRIVS_ADMIN))){
      this.settingsInfo.equipment1900.push(
        {name: "Expansion", mode: "pearl", url: `/usa/c18/sites/${this.props.parms.site}/zones/${this.props.parms.zone}/pearl/expansion`},
      )
    }

    

//     cl(this.props)
//     cl(this.state)
//     cl(this.zoneInfo)
  }
  
  loadInfo=async()=>{
//     cl("loadInfo")
    this.setBreadcrumbs(this.props.parms.settingsType)
    this.setNotify()
    await this.checkSensorPage()
//     cl("back")
    await this.setSitenZoneforAdminPages()
    this.makeSettingsInfo()
    this.setState({loaded:true})
    
  }

  setSitenZoneforAdminPages=()=>{
    let si=globs.sitesInfo.info
    let zi=globs.zonesInfo.info
    this.siteId=si[0]?.siteId||""
   
      for(let i=0;i<zi.length;i++){
        let z=zi[i]
        if(z.siteId==this.siteId){ 
          this.zoneId=z.zoneId; break; }
      }

  }
  
  checkSensorPage=async()=>{
//     cl(this.props.parms.settingsType)
    if(["equipment1800","sensor1800","mapping","calibration",]
      .includes(this.props.parms.settingsType)){
//     if(this.props.parms.settingsType=="sensor1800"){
//       cl(this.props.parms.zone)
      await loadZonesInfo()
      await loadSiteData(this.props.parms.site)
      var gi
      if(acctFeature("zoneGroups")){
        gi=getGroupInfo(globs.userData.session.groupId)
      }
      let zoneId=(gi)?gi.zones[0]:this.props.parms.zone
      this.zoneInfo=getZoneInfo(zoneId)
//       cl(this.zoneInfo)
      let zInd=this.zoneInfo.siteZoneIndex
//       cl(zInd)
      this.zoneControllers=getZoneControllers(zInd)
      return
    }
    
  }
  
  newUrl=(msg)=>{
//     cl(msg)
    if(msg.cmd=="settingsType"){
      this.setBreadcrumbs(msg.data)
    }
  }
  
  setNotify=()=>{// called from constructor
    this.props.notify({id: "newUrl", func: this.newUrl})
  }
  
  componentWillUnmount=()=>{
    this.props.notify({id: "newUrl", func: this.newUrl, unMount: true})
  }
  
  setBreadcrumbs=async(settingsType)=>{
//     cl("set bread")
    var gi
    if(acctFeature("zoneGroups")){
      gi=getGroupInfo(globs.userData.session.groupId)
    }
    let zoneId=(gi)?gi.groupId:this.props.parms.zone
    let baseUrl=`/usa/c18/sites/${this.props.parms.site}/zones/${zoneId}`
    await loadSitesInfo()
    await loadZonesInfo()
    await loadPrivsInfo()
    let siteName=getSiteName(this.props.parms.site)
    let zoneName=(gi)?gi.name:getZoneName(this.props.parms.zone)

//     cl(gi)
//     let zoneEntry=(gi)?
//     {t:gi.name,
//         url:`/usa/c18/sites/${this.props.parms.site}/zones/${gi.groupId}`}:
//     {t:zoneName, url:`/usa/c18/sites/${this.props.parms.site}/zones/${this.props.parms.zone}`}
    let bc= [
      {t:"Sites", url:"/usa/c18/sites"},
      {t:siteName, url:`/usa/c18/sites/${this.props.parms.site}`},
//       zoneEntry,
      {t:zoneName, url:`/usa/c18/sites/${this.props.parms.site}/zones/${zoneId}`},
    ]
//     cl(bc)
//     let baseUrl=`/usa/c18/sites/${this.props.parms.site}/zones/${this.props.parms.zone}`
//     let bc=[
//               {t:"Sites", url:"/usa/c18/sites"},
//               {t:"San Diego", url:`/usa/c18/sites/${this.props.parms.site}`},
//               {t:"Greenhouse 02", url:baseUrl},
//             ]
    let ss={t:"Sensor Settings", url:`${baseUrl}/settings/sensor1800`}
    let es={t:"Equipment Settings", url:`${baseUrl}/settings/equipment1800`}
//     cl(settingsType)
//     cl(baseUrl)
    switch (settingsType){
      case "sensor1800":
        bc.push(
          {t:`${zoneName} Sensor Settings`, url:`${baseUrl}/settings/sensor1800`}
        )
        break
      case "sensor1900":
        bc.push(
          {t:`${zoneName} Sensor Settings`, url:`${baseUrl}/settings/sensor1900`}
        )
          break
      case "equipment1800":
        bc.push(
          {t:`${zoneName} Equipment Settings`, url:`${baseUrl}/settings/equipment1800`}
        )
        break
      case "equipment1900":
        bc.push(
          {t:`${zoneName} Equipment Settings`, url:`${baseUrl}/settings/equipment1900`}
        )
          break
      case "account":
        bc=[
          {t:"Sites", url:"/usa/c18/sites"},
          {t:"Admin Settings", url:`/usa/c18/admin`}
        ]
        break
      case "calibration":
        bc.push(
          ss,
          {t:"Calibration", url:`${baseUrl}/settings/calibration`}
        )
        break
      case "setpoints":
        bc.push(
          ss,
          {t:"Setpoints", url:`${baseUrl}/settings/setpoints`}
        )
        break
      case "mapping":
        bc.push(
          ss,
          {t:"Input Mapping", url:`${baseUrl}/settings/mapping`}
        )
        break
      case "syncStatus":
        bc.push(
          ss,
          {t:"Input Mapping", url:`${baseUrl}/settings/syncStatus`}
        )
        break
      case "tankCalibration":
        bc.push(
          ss,
          {t:"Calibration", url:`${baseUrl}/settings/calibration`},
          {t:"Tank Calibration", url:`${baseUrl}/settings/calibration`},
        )
        break
      case "tankMapping":
        bc.push(
          ss,
          {t:"Mapping", url:`${baseUrl}/settings/mapping`},
          {t:"Tank Mapping", url:`${baseUrl}/settings/mapping`},
        )
        break
      case "zone_configuration":
        bc.push(
          es,
          {t:"Zone Configuration", url:`${baseUrl}/settings/zone_configuration`}
        )
        break
      case "sensor800":
        bc.push(
//           ss,
          {t:"Sensor Settings", url:`${baseUrl}/settings/sensor800`}
        )
        break
      case "equipment800":
        bc.push(
          {t:"Equipment Settings", url:`${baseUrl}/settings/equipment800`}
        )
        break
      default:
        break
    }
//     cl(bc)
//     cl("bread")
    this.props.parms.onChange(
      {
        cmd: "breadcrumbs",
        data:{breadcrumbs: bc}
      },
    )
//     cl(bc)
//     cl(bc[bc.length-1].t)
    this.props.parms.onChange(
      {
        cmd: "pageTitle",
        data:{pageTitle: bc[bc.length-1].t}
      },
    )
  }
  
//   newProps=()=>{
//     cl("new props")
//   }


  copyLink4Greenhouse=async()=>{
    let st=this.state
    cl(st)
    cl(globs.userData.session.siteId)
    cl("copy greenhouse")
    let res=await wsTrans("usa", {cmd: "cRest", uri: '/s/iGrow800Acct',
      method: 'retrieve', sessionId: globs.userData.session.sessionId,
      body: {igAccountId:74,siteId:globs.userData.session.siteId,
        controllerType:1}})
    cl(res)
  }

  settingsClick = (e,mode, url) => {
// if this is loading another settings page, we have to update the breadcrumbs *now*
    // pass info into onChange
    cl([mode, url])
    e.preventDefault()
    let parms=this.props.parms
//     cl(mode,url)
    if(url=="/usa/c18/admin/link4Greenhouse"){return this.copyLink4Greenhouse()}
    if(mode=="settings"){
//       cl("update bread: ")
      this.setBreadcrumbs(url.substring(1))
    }
    this.props.parms.onChange(
      {
        cmd:"setUrl",
        data:{site: parms.site, zone: parms.zone, mode: mode, url: url, 
          controllerSel:parms.controllerSel},
      },
    )
    // since data will be updated, page will reload?
  }

  removeForGroups=(config)=>{
    let skipNames=["Select Sensors","Sync Status","Cameras","Crop Recipes","Irrigation",
      "Aux Controls","Save & Restore Settings","Pearl Mapping","Pearl Input Config",
      "Auto Setup"]
    if(globs.userData.session.groupId){
      config=config.filter(c=>{return !skipNames.includes(c.name)})
    }
    return config
  }

  displaySettings = () => {
    let config = this.settingsInfo[this.props.parms.settingsType]||[]
    if(acctFeature("zoneGroups")){
      config=this.removeForGroups(config)
    }
    if(this.props.parms.settingsType=="account"){
//       cl("account settings")
      if(!privs("account",0,constant.AREA_PRIVS_ADMIN)){
        config=[]
//         config=[
//           {name: "Manage Site Access", mode: "usa", url: "/usa/c18/admin/manageSiteAccess2"},
//         ]
      }
    }
//     cl("display")
    let lines=config.map((e, i) => {
//       cl(e)
      return(
        <C18Anchor00 id={e.url} to="" key={i} className="button outlined" onClick={(ev)=>this.settingsClick(ev,e.mode,e.url)}>{e.name}</C18Anchor00>
      )
    })
    return (
        <div className="button-list">
        {lines}
        </div>
    )
  }
  
  onChange=(type,vals)=>{
//     cl(type,vals)
    switch(type){
      case "cont":
        this.setState(vals)
        vals.cmd="selController"

        this.props.parms.onChange(vals)
        break
    }
    
  }
  
  showControllerSelect=()=>{
//     cl("show")
    let options=[{v:0,t:"Base Controller"}]
//     cl(this.zoneControllers)
    this.zoneControllers.forEach((c,i)=>{
      if(i&&(+c)){options.push({v:i,t:`Expansion ${i}`})}
    })
    return(
        <C18CustomSelect01 parms={{
          id:"contSel",
          title:"Controller Select",
          value:this.props.parms.selController,
          onChange:e=>this.onChange("cont",{selController:e.currentTarget.value}),
          options:options,
        }}/>
    )
    
  }
  
  render(){
//     cl("render settings")
//     cl(this.state)
//     cl(this.props)
    let controllerPage=["sensor1800","mapping","calibration",].includes(
      this.props.parms.settingsType)
//     cl(this.zoneControllers)
    let cnt=0
    if(this.zoneControllers){this.zoneControllers.forEach(c=>{cnt+=c})}
    if(this.state.loaded){
      return(
        <div id={this.props.parms.settingsType}>
        {(controllerPage&&(cnt>1))&&
          this.showControllerSelect()
        }
          {this.displaySettings()}
        </div>
      )
    }else{
      return <div id="content-area">loading. . .</div>
    }
  }
}

// this.settingsInfo={
//   sensor:[
//     {name: "Input Mapping", type: "settings", url: "/mapping"}.
//     /*other lines*/
//     {name: "Input Multipliers", type: "fui", url: "/fui/unit_Input_Multipliers"}.
//   ],
// 	
// }

      
export default C18Settings00;
