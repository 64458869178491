import React from 'react';
// import C18Button00 from './C18Button00'
// import C18Anchor00 from './C18Anchor00'
// import config from '../../components/utils/config'
import {cl, globs,constant,zoneTypes,dateToYrMoDa} from '../../components/utils/utils';
// import {toggleAClass} from '../utils/utils';
import {loadSitesInfo,getSiteIndex,loadZonesInfo, loadGatewaysInfo, getGatewayInfo, getPearlType} from './C18utils'
import C18Input00 from './C18Input00'
import C18MuiInput from './C18MuiInput';


class C18SiteZoneSub00 extends React.Component{
  constructor(props) {
    super(props);
    this.state={
      sortMode:1,// site up
      loaded:false,
      nameEditIndex:-1,
      nameEdit:"funk",
//       zones:[
//       {site}
//       ],
    }
    this.subscribe_keyUpEvent=globs.events.subscribe("keyUp",this.keyUp)
    this.loadInfo()
  }
  
  
  componentWillUnmount=()=>{
    this.subscribe_keyUpEvent.remove()
  }
  
  loadInfo=async()=>{
    await loadSitesInfo()
    await loadZonesInfo()
    await loadGatewaysInfo()
    let zones=[]
    globs.zonesInfo.info.forEach((z,i)=>{
      let ind=getSiteIndex(z.siteId)
      zones.push({
        siteName:globs.sitesInfo.info[getSiteIndex(z.siteId)].name,
        origIndex:i,
//         zoneName:z.zoneName,
        zone:z,
      })
    })
    this.setState({loaded:true,zones:this.doSortZones(1,zones)})
  }
  
  doSortZones=(sortMode,zones)=>{
    zones.sort((a,b)=>{
      let dir=(sortMode>0)?1:-1
      switch(Math.abs(sortMode)){
        case 1:// site
          if(a.siteName.toLowerCase()<b.siteName.toLowerCase()){return 0-dir}
          if(a.siteName.toLowerCase()>b.siteName.toLowerCase()){return dir}
        case 2:// site
          if(a.zone?.zoneName?.toLowerCase()<b.zone?.zoneName?.toLowerCase()){return 0-dir}
          if(a.zone?.zoneName?.toLowerCase()>b.zone?.zoneName?.toLowerCase()){return dir}
        case 1:// site
          if(a.tier<b.tier){return 0-dir}
          if(a.tier>b.tier){return dir}
          return 0
      }
    }).slice(0)
//     cl(zones)
    return zones
  }
  
  sortZones=(col)=>{
    var sortMode
    if(Math.abs(col)==Math.abs(this.state.sortMode)){
      sortMode=0-this.state.sortMode
    }else{
      sortMode=col
    }
    this.setState({sortMode:sortMode,zones:this.doSortZones(sortMode,this.state.zones.slice(0))})
//     cl(col)
  }
  
  keyUp=(e)=>{
    let key=e.key
    let st=this.state
    let vals={nameEditIndex:-1}
    switch(key){
      case "Enter":
      case "Tab":
        let zones=st.zones.slice(0)
//         zones[st.nameEditIndex].zoneName=st.nameEdit
        let ind=zones[st.nameEditIndex]?.origIndex
        this.props.parms.zoneNames[ind]=st.nameEdit
        Object.assign(vals,{zones:zones})
        globs.events.publish("savePageEnable",true)
      case "Escape":
        this.setState({nameEditIndex:-1})
        break
    }
    
  }
  
  onChange=(type,vals)=>{
//     cl(globs.zonesInfo.info)
//     cl(this.props.parms)
//     cl(type,vals)
//     cl(vals)
    switch(type){
      case "sort":
        this.sortZones(vals.column)
        break
      case "zone":
        if(this.props.parms.edit){this.props.parms.onChange(vals)}
//         cl(vals)
        break
      case "tier":
//         cl(vals)
//         cl(vals.zone)
//         cl(this.state.zones[vals.zone])
        let zoneSel=this.state.zones[vals.zone].zone.zoneId
        let zoneTier=vals.tier
        this.props.parms.onChange("zoneTier",{zoneSel:zoneSel,zoneTier:zoneTier})
//         let zones=this.state.zones.slice(0)
//         zones[vals.zone].tier=vals.tier
//         this.setState({zones:zones})
//           this.setState({update:(this.state.update||0)+1})
        break
      case "nameClick":
//         cl(vals)
//         let z=this.state.zones[vals.nameEditIndex].zone.zoneName
        let ind=this.state.zones[vals.nameEditIndex].origIndex
        let z=this.props.parms.zoneNames[ind]
//         cl(z)
        Object.assign(vals,{nameEdit:z})
        this.setState(vals)
        break
      case "nameEdit":
        this.setState(vals)
        break
      default:
        break
    }
  }
  
  makeTiers=()=>{
    let tiers={}
    let pa=this.props.parms
    globs.zonesInfo.info.forEach(z=>{tiers[z.zoneId]=z.zoneTier||"locked"})
    if(pa.newConfig){
      pa.newConfig.forEach(nc=>{tiers[nc.zoneId]=nc.zoneTier||"locked"})
    }
    return tiers
  }
  
  getZoneSubInfo=(tiers)=>{
//     cl(globs.subscriptionInfo.info)
    let subUnlocked=globs.subscriptionInfo.info.add_ons.zone_qty.zone.unlocked
    let oldZonesUnlocked=globs.zonesInfo.info.filter(z=>{return z.zoneTier=="unlocked"}).length
    let newZonesUnlocked=Object.keys(tiers).filter(k=>{return tiers[k]=="unlocked"}).length
//     cl(oldZonesUnlocked)
//     cl(subUnlocked)
//     cl(newZonesUnlocked)
    return [subUnlocked,oldZonesUnlocked,newZonesUnlocked]
  }
  
  showZones=()=>{
    let pa=this.props.parms
    let icons=[]
    let sortMode=this.state.sortMode
    let edit=pa.edit
    let tiers=this.makeTiers()
    let [subUnlocked,oldZonesUnlocked,newZonesUnlocked]=this.getZoneSubInfo(tiers)
    let zoneCnt=globs.zonesInfo.info.length
    let totalLicenses = Math.max(pa.totalLicenses || newZonesUnlocked)
    let unlockInfo=`Using ${newZonesUnlocked} of ${totalLicenses} licenses, for ${zoneCnt} zones`
    if(totalLicenses>oldZonesUnlocked){unlockInfo+=" - New Charges Apply!"}
    for(let i=0;i<3;i++){
      var icon
      if((Math.abs(sortMode)==(i+1))){
        icon=(sortMode>0)?"keyboard_arrow_down":"keyboard_arrow_up"
      }else{
        icon=""
      }
      icons.push(icon)
    }
    let cursor=(edit)?"pointer":""
    let disabled = pa.disabled ? "disable":""
//               <th>
//                 <button type="button" aria-label="sort-sub" 
//                   onClick={()=>this.onChange("sort",{column:3})}>
//                   Subscription <span className="material-icons-outlined">{icons[2]}</span>
//                 </button>            
//               </th>
//                   <td>{tier}</td>
//                   onClick={e=>this.onChange("zone",{zoneId:z.zone.zoneId})}
// 3 zones of 9 unlocked - new Charges Apply!
    return(
      <div>
        <span>Using {newZonesUnlocked} of </span>
        <input 
          type="number" 
          id="zone-unlocked" 
          value={totalLicenses}
          onChange={(e) => this.props.parms.onChange("zone_qty", ["zone", zoneTypes["unlocked"], e.currentTarget.value])}
          min={0}
          max={999}
          inputMode="decimal"
          display="inline"
          disabled={disabled}
          className="license-counter"
         />
         <span>licenses, for {zoneCnt} zones</span>
        <div className="table-container">
          <table className="list"><tbody>
            <tr>
              <th>
                <button type="button" aria-label="sort-site" 
                  onClick={()=>this.onChange("sort",{column:1})}>
                  <h2>Site <span className="material-icons-outlined">{icons[0]}</span></h2>
                </button>            
              </th>
              <th>
                <button type="button" aria-label="sort-zone" 
                  onClick={()=>this.onChange("sort",{column:2})}>
                  <h2>Zone <span className="material-icons-outlined">{icons[1]}</span></h2>
                </button>            
              </th>
              <th style={{width:75}}><h2>Status</h2></th>
            </tr>
            {this.state.zones.map((z,i)=>{
              var zoneName
              // let pearlType = z.
              if(i==this.state.nameEditIndex){
                zoneName=(
                  <input 
                    type="text"
                    value={this.state.nameEdit}
                    onChange={e=>this.onChange("nameEdit",{nameEdit:e.target.value})}
                    />
                )
              }else{
                zoneName=this.props.parms.zoneNames[z.origIndex]
              }
//               cl(z.zone.zoneTier)
//               let bgColor=((pa.value==z.zone.zoneId)&&edit)?"#CCCCCC":"#FFFFFF"
//               let tier={locked:"Locked",unlocked:"Unlocked",disabled:"Disabled"}[z.tier]
              let type = getPearlType(getGatewayInfo(z.zone.gatewayId))
              // cl(getGatewayInfo(z.zone.gatewayId))
              // cl(type)
              return(
                <tr key={i}>
                  <td>{z.siteName}</td>
                  {
                    this.props.parms.edit ?
                    <td style={{cursor:"pointer",textDecoration:"underline"}}
                      onClick={e=>this.onChange("nameClick",{nameEditIndex:i})}>{zoneName}</td>
                    :
                    <td style={{textDecoration:"underline"}}>{zoneName} {type === "Protect" && `(${type})`}</td>
                  }
                  <td>
                    <div className="slide-switch">
                    <table className="slide-table"><tbody>
                    <tr>
                    <td>
                    {(tiers[z.zone.zoneId]==zoneTypes["locked"]) &&
                      <label className="switch-label">Locked</label>
                    }
                    </td>
                    <td>
                    <label className="switch">
                      <C18Input00 type="checkbox"
                        checked={tiers[z.zone.zoneId]==zoneTypes["unlocked"]}
                        disabled={pa.disabled}
                        onChange={e=>this.onChange("tier",{zone:i, 
                          tier: (tiers[z.zone.zoneId]==zoneTypes["locked"]) ? zoneTypes["unlocked"] :zoneTypes["locked"]})}
                      />
                      <span className="slider round"></span>
                    </label>
                    </td>
                    <td>
                    { (tiers[z.zone.zoneId]==zoneTypes["unlocked"]) &&
                    <label className="switch-label">Unlocked</label>
                    }
                    </td>
                    </tr>
                    </tbody></table>
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody></table>
        </div>
        <div className="clearfloat"></div><br/>
      </div>
    )
  }
  
  showSiteZoneSub=()=>{
    return(
      <div>
      {this.showZones()}
      </div>
    )
  }
  
  render(){
//     cl(this.state)
    if(this.state.loaded){
      return this.showSiteZoneSub()
    }else{
      return <div id="content-area">loading. . .</div>
    }
  }
}

export default C18SiteZoneSub00;
