import React from 'react';
import C18Button00 from './C18Button00'
import C18Input00 from './C18Input00'
import C18Select00 from './C18Select00'
import C18InputError00 from './C18InputError00'
import UsaIcon from './UsaIcon';
//  import { Router, Route, Switch } from "react-router-dom";// , Link
import {wsTrans, inputField, doGetPostBasic, checkLoggedIn, logout,getUId} from '../utils/utils';

import {cl, globs, constant, allCountries, allStates, allTimezones, allThemes, getTime,
  getRandomString, validAppVersions,copyToClipboard
} from '../../components/utils/utils';
import {addToAdminLog, loadUsersInfo, getUserIndex, loadDevicesInfo, updateDeviceInfo, rnRest, rnCl} from './C18utils';
import IconButton from '@material-ui/core/IconButton';
import Camera from '@material-ui/icons/Camera';
import Check from '@material-ui/icons/Check';
import Clear from '@material-ui/icons/Clear';
//  import InputAdornment from '@material-ui/core/InputAdornment';
//  import Input from "@material-ui/core/Input";
//  import InputLabel from '@material-ui/core/InputLabel';
//  import Register from './Register';
//  import Login from './Login';
import MainBar from '../../components/MainBar';
import history from "../../history"
import C18Confirm00 from './C18Confirm00'
 
 
class UserProfile extends React.Component{
  constructor(props) {
    super(props);
    document.title="User Profile";
    this.subscribe_savePageEvent=globs.events.subscribe("savePageEvent", this.onSubmit)
    this.props.parms.onChange({cmd:"savePage", data:{savePage:true}})
    this.state={
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      street: "",
      city: "",
      state: "",
      zipcode: "",
      country: "US",
      timezone: "",
      avatar: "",
      // theme: "",
      loaded: false,
      showHidePassword: "password",
      showHideConfirm: "password",
      passwordVisible: false,
      confirmPasswordVisible: false,
      uiSettingsTableBanding: false,
      uiInfo:{},
      popup:{
        opacity:0,
        text:"Are you sure?",
        buttons:["Close"],
        resolve:null,
      },
    }
//     cl(props);
//     cl(globs.userData);
    this.getMyUserInfo()
    this.getMyDevicesInfo()
    // this.deleteDeviceInfo()
    
    if(this.props.parms){
      this.props.parms.onChange(
        (globs.userData.session.developer)?
          {
            cmd: "breadcrumbs",
            data:
              {breadcrumbs: [
                {t:"Home", url:"/usa/c18/home"},
                {t:"User Profile", url:`/usa/c18/admin/userProfile`},
              ]},
          }
        :
          {
            cmd: "breadcrumbs",
            data:
              {breadcrumbs: [
                {t:"Sites", url:"/usa/c18/sites"},
                {t:"Admin", url:`/usa/c18/admin`},
                {t:"User Profile", url:`/usa/c18/admin/userProfile`},
              ]},
          }
      )
    }
  }

  componentWillUnmount=()=>{
    this.subscribe_savePageEvent.remove()
  }

  // device specific checks, o is initial device loaded here - n is after save
  saveToAdminLogDevice=(adds, o, n)=>{
//     cl(o)
//     cl(n)
    let addObj={
      userId:globs.userData.session.userId,
      time:Math.floor(getTime()),
      deviceName: n?.deviceName
    }
    if((o?.deviceName)&&(o?.deviceName!=n?.deviceName)){
      adds.push(
        Object.assign({},addObj,
        {
        action:"deviceName",
        oldVal:o?.deviceName,
        newVal:n?.deviceName,
      }))
    }
    if((o.deviceToken)&&(o.deviceToken!=n.deviceToken)){
      adds.push(
        Object.assign({},addObj,
        {
        action:"deviceToken",
        oldVal:o.deviceToken,
        newVal:n.deviceToken,
      }))
    }
    if((o.deviceTheme)&&(o.deviceTheme!=n.deviceTheme)){
      adds.push(
        Object.assign({},addObj,
        {
        action:"deviceTheme",
        oldVal:o.deviceTheme,
        newVal:n.deviceTheme,
      }))
    }
    if((o.bioEnabled)&&(o.bioEnabled!=n.bioEnabled)){
      adds.push(
        Object.assign({},addObj,
        {
        action:"deviceBioLogin",
        oldVal:o.bioEnabled,
        newVal:n.bioEnabled,
      }))
    }
  }
  
  saveToAdminLog=(adds,o,n)=>{
//     cl(o)
//     cl(n)
    let addObj={
      userId:globs.userData.session.userId,
      time:Math.floor(getTime())
    }
    if((o.name)&&(o.name!=n.name)){
      adds.push(
        Object.assign({},addObj,
        {
        action:"profileName",
        oldVal:o.name,
        newVal:n.name,
      }))
    }
    if(o.email!=n.email){
      adds.push(
        Object.assign({},addObj,
        {
        action:"profileEmail",
        oldVal:o.email,
        newVal:n.email,
      }))
    }
    if(o.street!=n.street){
      adds.push(
        Object.assign({},addObj,
        {
        action:"profileStreet",
        oldVal:o.street,
        newVal:n.street,
      }))
    }
    if(o.city!=n.city){
      adds.push(
        Object.assign({},addObj,
        {
        action:"profileCity",
        oldVal:o.city,
        newVal:n.city,
      }))
    }
    if(o.state!=n.state){
      adds.push(
        Object.assign({},addObj,
        {
        action:"profileState",
        oldVal:o.state,
        newVal:n.state,
      }))
    }
    if(o.zipcode!=n.zipcode){
      adds.push(
        Object.assign({},addObj,
        {
        action:"profileZipcode",
        oldVal:o.zipcode,
        newVal:n.zipcode,
      }))
    }
    if(o.country!=n.country){
      adds.push(
        Object.assign({},addObj,
        {
        action:"profileCountry",
        oldVal:o.country,
        newVal:n.country,
      }))
    }
    if(o.timezone!=n.timezone){
      adds.push(
        Object.assign({},addObj,
        {
        action:"profileTimezone",
        oldVal:o.timezone,
        newVal:n.timezone,
      }))
    }
    if(o.avatar!=n.avatar){
      adds.push(
        Object.assign({},addObj,
        {
        action:"profileAvatar",
        oldVal:o.avatar,
        newVal:n.avatar,
      }))
    }
    if(o.theme!=n.theme){
      adds.push(
        Object.assign({},addObj,
        {
        action:"profileTheme",
        oldVal:o.theme,
        newVal:n.theme,
      }))
    }
    if(n.password){
      adds.push(
        Object.assign({},addObj,
        {
        action:"profilePassword",
        oldVal:"",
        newVal:"",
      }))
    }
    if(o.noteInfo.enableEmail != n.noteInfo.enableEmail){
      adds.push(
        Object.assign({},addObj,
        {
        action:"enableEmail",
        oldVal:o.noteInfo.enableEmail,
        newVal:n.noteInfo.enableEmail,
      }))
    }
    if(o.noteInfo.enablePush != n.noteInfo.enablePush){
      adds.push(
        Object.assign({},addObj,
        {
        action:"enablePush",
        oldVal:o.noteInfo.enablePush,
        newVal:n.noteInfo.enablePush,
      }))
    }
    if(o.uiInfo.tableBanding != n.uiInfo.tableBanding){
      adds.push(
        Object.assign({},addObj,
        {
        action:"tableBanding",
        oldVal:o.uiInfo.tableBanding,
        newVal:n.uiInfo.tableBanding,
      }))
    }
//     cl(o.noteInfo)
//     cl(n.noteInfo)
//     cl(adds)
  }
  
  getMyUserInfo=async()=>{// need to login first
    
//     cl(globs.userData);
    let logged=await checkLoggedIn()
    if(!logged)history.push("/usa/login")
//     cl(logged)
    if(!globs?.userData?.session?.userId) return;
//     cl(globs)
    await loadUsersInfo()
//     cl(globs.userData.session.userId)
    // cl(globs.usersInfo)
    let idx = getUserIndex(globs.userData.session.userId)
    // cl(idx)
    if (idx||(idx==0)) {
      let u = globs.usersInfo.info[idx]
//       cl(u)
      if (u) {        
//       cl(u.apiKey)
        if(!u.apiKey){
          cl("new one")
          u.apiKey=getRandomString(32)
          let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/users", method: "update", 
            sessionId: globs.userData.session.sessionId, body: {userId:u.userId,apiKey:u.apiKey}})
        }
//         cl(u)
        if (!u.noteInfo) {
          u.noteInfo = {enableEmail: true, enablePush: true, enableBanding:true}
        }
        let old = {
          avatar: (u.avatar) ? u.avatar : "",
          email: u.email,
          name: u.name,
          firstName: (u.name||"").substring(0, (u.name||"").indexOf(" ")),
          lastName: (u.name||"").substring((u.name||"").indexOf(" ")),
          street: (u.street) ? u.street : "",
          city: (u.city) ? u.city : "",
          zipcode: (u.zipcode) ? u.zipcode : "",
          state: (u.state) ? u.state : "",
          country: (u.country) ? u.country : "",
          timezone: (u.timezone) ? u.timezone : "",
          apiKey: u.apiKey,
          noteInfo: u.noteInfo,
          uiInfo:u.uiInfo,
          groupInfo:u.groupInfo,
          // theme: (u.theme) ? u.theme : "originalLight",
        }
        // cl(old)
        this.setState({
          avatar: u.avatar,
          email: u.email,
          name: u.name,
          firstName: (u.name||"").substring(0, (u.name||"").indexOf(" ")),
          lastName: (u.name||"").substring((u.name||"").indexOf(" ") + 1),
          street: (u.street) ? u.street : "",
          city: (u.city) ? u.city : "",
          zipcode: (u.zipcode) ? u.zipcode : "",
          country: (u.country) ? u.country : "US",
          state: (u.state) ? u.state : "AL",
          timezone: (u.timezone) ? u.timezone : Intl.DateTimeFormat().resolvedOptions().timeZone,
          apiKey: u.apiKey,
          noteInfo: Object.assign({},u.noteInfo),
          uiInfo:u.uiInfo,
          groupInfo:u.groupInfo,
          // theme: (u.theme) ? u.theme : "originalLight",
          loaded:true,
          old:old,
        })
        if (old.firstName === '' && old.lastName !== '') {
          this.setState({
            firstName: old.lastName,
            lastName: old.firstName
          })
        }
      }
    }else{
      this.setState({loaded: true})
    }
//     cl(this.state.noteInfo)
  }

  getMyDevicesInfo=async()=>{
    // if device info is not found, request and populate
//     cl(globs.device)
    if (window.ReactNativeWebView) {
      // request device info
      let deviceId = getUId()
      let device = await rnRest("/device", "retrieve", {deviceId: deviceId, session: globs.userData.session, appVersion: validAppVersions})
      // create device (if not already made)
      await loadDevicesInfo()
      // update device on table with info
      await updateDeviceInfo(device)
    } else {
      await loadDevicesInfo()
    }
//     cl("still device")
    // make deep copy for oldDevice
//     cl(globs.device)
    if (globs.device) this.setState({oldDevice: JSON.parse(JSON.stringify(globs.device)), device: globs.device, deviceLoaded: true})
  }

  putMyDeviceInfo=async(adds)=>{
//     cl(this.state.device)
    // update app and db
    let body
    let deviceId = getUId()
    if (window.ReactNativeWebView) {
      // put device info
      let data = this.state.device||{}
      let device = await rnRest("/device", "update", data)
      body = {
        deviceId: device?.deviceId || deviceId,
        deviceName: device?.deviceName || globs.device.deviceName,
        deviceToken: device?.deviceToken,
        deviceInfo: device?.deviceInfo,
        bioEnabled: device?.bioEnabled,
        deviceTheme: device?.deviceTheme || globs.device.deviceTheme,
      }
    } else {
      body = {
        deviceId: deviceId,
        deviceName: this.state?.device?.deviceName || globs.device.deviceName,
        deviceToken: this.state?.device?.deviceToken,
        deviceInfo: this.state?.device?.deviceInfo,
        bioEnabled: this.state?.device?.bioEnabled,
        deviceTheme: this.state?.device?.deviceTheme || globs.device.deviceTheme,
      }
    }
//     cl([this.state?.device?.deviceTheme, globs.device.deviceTheme])
//     cl(body)
    await updateDeviceInfo(body)
    this.saveToAdminLogDevice(adds, this.state.oldDevice||{}, body)
    addToAdminLog(adds)
    if(body.deviceTheme!=this.state.oldDevice.deviceTheme){
      setTimeout(()=>{window.location.reload()},1000)
    }
//     cl(globs.device)
    this.setState({oldDevice: JSON.parse(JSON.stringify(globs.device)), device: globs.device, deviceLoaded: true})
  }

  putMyUserInfo=async(adds)=>{
    
    // let body={userId: globs.userData.session.userId, name: this.state.name, email: this.state.email, avatar: this.state.avatar};
    let body = {
      userId: globs.userData.session.userId, 
      name: `${this.state.firstName} ${this.state.lastName}`, 
      street: this.state.street,
      city: this.state.city,
      state: (this.state.country == "US") ? this.state.state : "",
      zipcode: this.state.zipcode,
      country: this.state.country,
      timezone: this.state.timezone,
      avatar: this.state.avatar,
      noteInfo: this.state.noteInfo,
      uiInfo: this.state.uiInfo,
      groupInfo: this.state.groupInfo,
      // theme: this.state.theme,
    }

    // validation
    let msg = ""
    if (this.state.password || this.state.confirmPassword) {
      msg = this.checkPasswords()
      cl(msg)
      this.setState({passwordError: msg, confirmPasswordError: msg})
    }
    if (this.state.email) {
//       cl(msg)
      msg = this.checkEmail()
      this.setState({emailError: msg})
    }
    if (msg) return

    if (this.state.email) body.email = this.state.email
    if (this.state.password) body.password = this.state.password

    let putUser={cmd: "cRest", uri: "/s/users", method: "update", 
      sessionId: globs.userData.session.sessionId,
      body: body};
//     cl(body)
    await wsTrans("usa", putUser)
    globs.user=Object.assign({},body)
    globs.usersInfo={}
//     cl("zero users info")
    await loadUsersInfo()
//     cl(globs.usersInfo)
    this.saveToAdminLog(adds, this.state.old, body)
    // redefine what old is
    body.firstName = this.state.firstName
    body.lastName = this.state.lastName
    this.setState({old: JSON.parse(JSON.stringify(body))})

    let idx = getUserIndex(globs.userData.session.userId)
    if (idx||(idx==0)) {
      globs.usersInfo.info[idx].timezone = this.state.timezone
    }

//     cl(adds)
//     globs.events.publish("savePageEnable", false)
    
  }

  onSubmit=async(cmd)=>{
    if (cmd == "save") {
      let adminAdds = []
//       cl("saving")
      await this.putMyUserInfo(adminAdds);
      await this.putMyDeviceInfo(adminAdds);
      globs.events.publish("saveOK",true)
    } else {
      
    }
  }
  
  checkFields=async(state)=>{
//     cl(state)
    let res=await wsTrans("usa", {cmd: "cRest", uri: "/o/users/unique", method: "retrieve", 
      body: {companyName: null, email: state.email, password: state.password}})
//     cl(res)
    if(state.email==this.state.old.email){res.ue=true}
    if(!res.ue){
      globs.events.publish("savePageEnable", false)
    }
    this.setState({emailError:(!res.ue)?"That email address is in use":""})
    
  }

  onChange = async (type, val)=> {
    let st=this.state
//     cl([type, val])
  let updatedDevice,success
  globs.events.publish("savePageEnable", true)
  switch (type) {
    case 'firstName':
      this.setState({ firstName: val[type] });
      break;
    case 'lastName':
      this.setState({ lastName: val[type] });
      break;
    case 'email':
      let vals = { email: val[type] };
      let state = Object.assign({}, this.state, vals);
      this.checkFields(state);
      this.setState({ email: val[type] });
      break;
    case 'password':
      this.setState({ password: val[type] });
      break;
    case 'confirmPassword':
      this.setState({ confirmPassword: val[type] });
      break;
    case 'street':
      this.setState({ street: val[type] });
      break;
    case 'city':
      this.setState({ city: val[type] });
      break;
    case 'state':
      this.setState({ state: val });
      break;
    case 'zipcode':
      this.setState({ zipcode: val[type] });
      break;
    case 'country':
      this.setState({ country: val });
      break;
    case 'timezone':
      this.setState({ timezone: val });
      break;
    case 'theme':
      // this.setState({theme: val})
      break;
    case 'showHidePassword':
      this.setState({ passwordVisible: !this.state.passwordVisible });
      break;
    case 'showHideConfirmPassword':
      this.setState({
        confirmPasswordVisible: !this.state.confirmPasswordVisible,
      });
      break;
    case 'tableBanding':
      let uiInfo = Object.assign({}, this.state.uiInfo);
      Object.assign(uiInfo, val);
      cl(uiInfo);
      this.setState({ uiInfo: uiInfo });
      break;

    case 'avatar':
      this.setState(val);
      break;
    case 'deviceName':
      updatedDevice = this.state.device || {};
      updatedDevice.deviceName = val[type];
      this.setState({ device: updatedDevice });
      break;
    case 'bioEnabled':
      updatedDevice = this.state.device;
      updatedDevice.bioEnabled = val;
      this.setState({ device: updatedDevice });
      break;
    case 'deviceTheme':
      updatedDevice = this.state.device;
      updatedDevice.deviceTheme = val;
      this.setState({ device: updatedDevice });
      break;
    case 'enableEmail':
      this.state.noteInfo.enableEmail = val;
      this.setState({ noteInfo: this.state.noteInfo });
      break;
    case 'enablePush':
      this.state.noteInfo.enablePush = val;
      this.setState({ noteInfo: this.state.noteInfo });
      break;
    case 'groupInfo':
      let groupInfo = Object.assign({}, st.groupInfo, val);
      //       cl(groupInfo)
      this.setState({ groupInfo: groupInfo });
      break;
    case 'copyValue':
      globs.events.publish("savePageEnable", false)
       success = await copyToClipboard(val);
      // show "success" if copied correctly to clipboard
      setTimeout(() => {
        this.setState({ copyText: '' });
      }, 5000);
      if (success) {
        this.setState({ copyText: 'Copied to clipboard!' });
      } else {
        this.setState({ copyText: 'Error copying text.' });
      }
      break;
    // case 'pushEnabled':
    //   updatedDevice = this.state.device
    //   updatedDevice.pushEnabled = val
    //   this.setState({device: updatedDevice})
    //   break
  }
  }
  
  ok=()=>{
    cl("save");
//     cl(this.state.name);
  }
  
  cancel=()=>{
    cl("cancel");
  }
  
  showInfo=()=>{
//     cl(globs.userData)
    let clients={
      "Ck1W1c98ldyObUxV": "31373730353851040023004a",
      "-RoMkMjyQORLauk1": "31373730353851040023004c",
      "0WJBVFV0B0NYF0PR": "31373730353851040023004a",
//       "0WJBVFV0B0NYF0PR": "393632353438511200300018",
      "F5M1AABTX131XFTC": "393632353438511200330018",
      "015JX0V4UUCGD55V": "393632353438511200330018",
      "A73M5XD1PD0CH570": "39363235343851120023002e",
    }
//     cl(globs.userData.session.siteId)
    let clientId=clients[globs.userData.session.siteId]
    if(!clientId)clientId="Unknown"
    return(
      <div>
      accountId: {globs.userData.session.accountId}<br/>
      expire: {globs.userData.session.expire}<br/>
      sessionId: {globs.userData.session.sessionId}<br/>
      siteId: {globs.userData.session.siteId}<br/>
      clientId: {clientId}<br/>
      userId: {globs.userData.session.userId}<br/>
      info
      </div>
    )
  }
  
  result=(type)=>{
    cl(type)
  }

  resolvePopup=(e)=>{
    //     cl(e)
        let popup=this.state.popup
        popup.opacity=0
        this.setState({popup:popup})
        // if(resolve){resolve(e)}
  }
  
  markImage=async(e)=>{
    
    let files=e.target.files[0]
    if (!files) return
    if ((files.size > (2097152) ) || (!files.type.startsWith("image/"))) {
      // files are too powerful message
      let popup={
        opacity:1,
        text:"File must be an image and smaller than 2MB!",
        buttons:["Close"],
        resolve:e=>this.resolvePopup(e),
      }
      this.setState({popup:popup})
      return
    } 
    
    let data = new FormData()
    data.append("type", "avatar")
    data.append("sessionId", globs.userData.session.sessionId)
    data.append('file', files)
    let url=`${constant.expressUrl}/usa/images`
    let method="POST"
    let type="multipart/form-data"
    // cl("post image")
    // cl(url,method,data,type)
    let ret=await doGetPostBasic(url, method, data, type)
    let ret2 = await ret.json()
    // cl(ret2)
    this.onChange("avatar",{avatar: ret2.avatar})
//     this.setState({avatar: ret2.avatar})
  }
    
  logOut=()=>{
//     cl("logout")
//     cl(this.state)
//     cl(this.props)
    globs.events.publish("savePageEnable", false)
    // admin log with user info
    let adds = [{
      userId:globs.userData.session.userId,
      time:Math.floor(getTime()),
      action:"logout",
      deviceName: globs.device?.deviceName,


      oldVal:"",
      newVal:"",
    }]
    addToAdminLog(adds)
    history.push("/usa/c18/logout")
    logout()
  }

  checkPasswords=()=>{
    let msg = ""
    // check password length
    // check password strength
    if (this.state.password != this.state.confirmPassword) {
      msg = "Passwords do not match"
    }
    return msg
  }

  checkEmail=()=>{
    let msg = ""
    // check email format
    // check email exists?
    if (this.state.email == "bad") {
      msg = "Bad format for email"
    }
    return msg
  }

  showCountry=()=> {
    return(
    <span className="custom-select floatleft">

      <div className="custom-select">
        <label htmlFor="country-select">Country</label>
        <C18Select00 
          id="profile-country"
          onChange={(e)=>this.onChange("country", e.currentTarget.value)}
          value={this.state.country}
        >
          {allCountries.map((t,i)=>{
            return(
              <option key={i} value={t.cca2}>{t.country}</option>
            )
          })}
        </C18Select00>
        <span className="material-icons down-arrow">
          keyboard_arrow_down
        </span>
      </div>
    </span>
    )
  }

  showState=()=> {
    return(
    <span className="custom-select floatleft">

      <div className="custom-select">
        <label htmlFor="state-select">State</label>
        <C18Select00 
          id="profile-state"
          onChange={(e)=>this.onChange("state", e.currentTarget.value)}
          value={this.state.state}
        >
          {allStates.map((t,i)=>{
            return(
              <option key={i} value={t}>{t}</option>
            )
          })}
        </C18Select00>
        <span className="material-icons down-arrow">
          keyboard_arrow_down
        </span>
      </div>
    </span>
    )
  }

  showTimezone=()=>{
    return(
    <span className="custom-select floatleft">

      <div className="custom-select">
        <label htmlFor="timezone-select">Timezone</label>
        <C18Select00 
          id="profile-timezone"
          onChange={(e)=>this.onChange("timezone", e.currentTarget.value)}
          value={(this.state.timezone) ? this.state.timezone : "default"}
        >
          <option key={0} disabled hidden value="default"> -- select an option -- </option>
          {allTimezones.map((t,i)=>{
            return(
              <option key={i} value={t}>{t}</option>
            )
          })}
        </C18Select00>
        <span className="material-icons down-arrow">
          keyboard_arrow_down
        </span>
      </div>
    </span>
    )
  }

  showTheme=()=>{
//     cl(this.state.device?.deviceTheme)
//     cl(this.state)
    return(
    <div className="clearfloat" >
      <span className="custom-select floatleft">

        <div className="custom-select">
          <label htmlFor="theme-select">Theme</label>
          <C18Select00 
            id="profile-theme"
            onChange={(e)=>this.onChange("deviceTheme", e.currentTarget.value)}
            value={this.state.device?.deviceTheme||"originalLight"}
          >
            {allThemes.map((t,i)=>{
              return(
                <option key={i} value={t[1]}>{t[0]}</option>
              )
            })}
          </C18Select00>
          <span className="material-icons down-arrow">
            keyboard_arrow_down
          </span>
        </div>
      </span>
    </div>
    )
  }

  showAvatarSection=()=> {
    let av=this.state.avatar
//     cl(av)
    let path
    if(av){
      path=`${constant.expressUrl}/usa/images/avatars/uploads/${av[0]}/${av[1]}/${av[2]}/${av.substr(3)}.jpeg`
    }else{
      path=`${constant.expressUrl}/usa/images/avatars/stockAvatar.jpg`
    }
//         <IconButton
//           variant="contained"
//           component="label"
//           title="Upload Image"
//         >
//           <Camera/>
//           <input hidden multiple type="file" onChange={this.markImage}/>
//         </IconButton>
    return (
      <>
        <div className="title">Avatar</div>
        <img src={path}/>
          <form className="file-upload-form">
          <C18Input00 type="file"  onChange={this.markImage} style={{
            position:"absolute", 
            width:103, 
            height:103, 
            marginTop:0, 
            marginLeft:0,
            zIndex:10,                   
            opacity:0,
            cursor: "pointer",
          }}/>
          </form>
        <button onClick={()=>this.onChange("avatar", {avatar: ""})}type="button"><span className="alarm-on material-icons-outlined">
        delete
        </span></button>
        <p>File must be an image and smaller than 2MB.</p>
      </>
    )
  }

  showAppSettings=()=>{
      return (
      <div className="clearfloat">
        <div className="title">Biometric Login</div>
          <input
            name="Biometric Login"
            type="checkbox"
            checked={this.state.device.bioEnabled == 2}
            onChange={()=>this.onChange("bioEnabled", (this.state.device.bioEnabled == 2) ? 1 : 2)}
          />

      </div>
      )
  }

          // <div className="title">Push Notifications</div>
          // <input
          //   name="Push Notifications"
          //   type="checkbox"
          //   checked={this.state.device.pushEnabled == 2}
          //   onChange={()=>this.onChange("pushEnabled", (this.state.device.pushEnabled == 2) ? 1 : 2)}
          // />

  showDeviceSettings=()=> {
//     cl(this.state.device)
      return (
      <div className="clearfloat" >
        <C18InputError00 parms={{
          type:'text',
          title:"Device Name",
          id:"device-name",
          required: false,
          value: this.state.device?.deviceName||"",
          valueId: "deviceName",
          onChange: this.onChange,
          message: "",
          input: "deviceName",
          messageType: "error"
        }}/>

        <br/>

        {this.showTheme()}

        <br/>
        
        {(window.ReactNativeWebView) ? this.showAppSettings() : ""}

        <br/>
        <br/>

      </div>
      )
  }

  showGroupSettings=()=>{
    return(
      <div className="clearfloat">
        <div className="title">Allow Select Zone</div>
          <input
            type="checkbox"
            checked={this.state.groupInfo?.allowSelectZone||false}
            onChange={e=>this.onChange("groupInfo", {allowSelectZone:e.currentTarget.checked})}
          />
        <div className="clearfloat"/><br/>
        <div className="title">Show All Zones</div>
          <input
            type="checkbox"
            checked={!(this.state.groupInfo?.notShowAllZones||false)}
            onChange={e=>this.onChange("groupInfo", {notShowAllZones:!e.currentTarget.checked})}
          />
        <div className="clearfloat"/><br/>
        <div className="title">Unique Groups</div>
          <input
            type="checkbox"
            checked={!(this.state.groupInfo?.notUniqueGroups||false)}
            onChange={e=>this.onChange("groupInfo", {notUniqueGroups:!e.currentTarget.checked})}
          />
        <div className="clearfloat"/><br/>
        <div className="title">Group on Menu</div>
          <input
            type="checkbox"
            checked={(this.state.groupInfo?.groupOnMenu||false)}
            onChange={e=>this.onChange("groupInfo", {groupOnMenu:e.currentTarget.checked})}
          />
      </div>
    )
  }

  showNoteSettings=()=>{// and other UI stuff
    let banding=this.state.uiInfo?.tableBanding
    if(banding==undefined){banding=true}// default if not set
    return (
    <div className="clearfloat">
      <div className="title">Enable Emails</div>
        <input
          name="Enable Emails"
          type="checkbox"
          checked={this.state.noteInfo?.enableEmail}
          onChange={()=>this.onChange("enableEmail", !this.state.noteInfo?.enableEmail)}
        />
      <div className="clearfloat" /><br/>
        <div className="title">Enable Push Notifications</div>
        <input
          name="Enable Push"
          type="checkbox"
          checked={this.state.noteInfo?.enablePush}
          onChange={()=>this.onChange("enablePush", !this.state.noteInfo?.enablePush)}
        />
      <div className="clearfloat" /><br/>
        <div className="title">Enable Table Banding</div>
        <input
          type="checkbox"
          checked={banding}
          onChange={e=>this.onChange("tableBanding",
            {tableBanding:e.currentTarget.checked})}
        />
    </div>
    )
  }
  
  showApiKey=()=>{
    return (
      <div>
        <br />
        <hr className="clearfloat" />
        <h3>
          API Key (
          <a
            className="colored-link-text"
            target="_blank"
            rel="noopener noreferrer"
            href="https://api01.link4cloud.com/"
          >
            How to Use?
          </a>
          )
        </h3>
        <span>{this.state.apiKey}</span>{' '}
        <button
          type="button"
          className="material-icons-outlined copy-button"
          title="Copy"
          onClick={() => this.onChange('copyValue', this.state.apiKey)}
        >
          content_copy
        </button>
      </div>
    );
  }

  showUserId=()=>{
//     cl(this.state)
    return(
      <div>
        <br /><hr className="clearfloat" />
        <h3>User ID</h3>
        <span>{globs.userData?.session?.userId}</span>
        <button
          type="button"
          className="material-icons-outlined copy-button"
          title="Copy"
          onClick={() => this.onChange('copyValue', globs.userData?.session?.userId)}
        >
          content_copy
        </button>
      </div>
    )
  }

  showUserInfo=()=> {
    return (
    <div id="login-register">

      <C18InputError00 parms={{
        type:'text',
        title:"First Name",
        id:"first-name",
        required: true,
        value: this.state.firstName,
        valueId: "firstName",
        onChange: this.onChange,
        message: "",
        input: "firstName",
        messageType: "error"
      }}/>

      <C18InputError00 parms={{
        type:'text',
        title:"Last Name",
        id:"last-name",
        required: true,
        value: this.state.lastName,
        valueId: "lastName",
        onChange: this.onChange,
        message: "",
        input: "lastName",
        messageType: "error"
      }}/>

      <div className="clearfloat"></div>

      <C18InputError00 parms={{
        type:'email',
        title:"Email",
        id:"if-email1",
        required: true,
        value: this.state.email,
        valueId: "email",
        onChange: this.onChange,
        message: this.state.emailError,
        input: "email",
        messageType: "error"
      }}/>

      <C18InputError00 parms={{
        type: this.state.showHidePassword,
        visible:this.state.passwordVisible,
        visibility:"showHidePassword",      
        title:"Password",
        id:"your-password",
        required: true,
        value: this.state.password,
        valueId: "password",
        onChange: this.onChange,
        message: this.state.passwordError,
        input: "password",
        messageType: "error"
      }}/>

      <C18InputError00 parms={{
        type: this.state.showHideConfirm,
        visible:this.state.confirmPasswordVisible,
        visibility:"showHideConfirmPassword",      
        title:"Confirm Password",
        id:"confirm-password",
        required: true,
        value: this.state.confirmPassword,
        valueId: "confirmPassword",
        onChange: this.onChange,
        message: this.state.confirmPasswordError,
        input: "confirmPassword",
        messageType: "error"
      }}/>

    <div className="clearfloat"></div>
    <br /><hr /><br />

      <C18InputError00 parms={{
        type:'text',
        title:"Street",
        id:"profile-street",
        required: true,
        value: this.state.street,
        valueId: "street",
        onChange: this.onChange,
        message: "",
        input: "street",
        messageType: "error"
      }}/>

      <C18InputError00 parms={{
        type:'text',
        title:"City",
        id:"profile-city",
        required: true,
        value: this.state.city,
        valueId: "city",
        onChange: this.onChange,
        message: "",
        input: "city",
        messageType: "error"
      }}/>

      <div className="clearfloat"></div>

      <C18InputError00 parms={{
        type:'text',
        title:"Zip Code",
        id:"profile-zip",
        required: true,
        value: this.state.zipcode,
        valueId: "zipcode",
        onChange: this.onChange,
        message: "",
        input: "zipcode",
        messageType: "error"
      }}/>

      {this.showCountry()}

      {(this.state.country ==  "US") ? this.showState() : ""}

      <div className="clearfloat"></div>

      {this.showTimezone()}
      {this.showApiKey()}
      {this.showUserId()}

      <div className="clearfloat"></div>
      <br /><hr /><br />

      {(this.state.deviceLoaded) ? this.showDeviceSettings() : ""}

      <br />
      
      <br /><hr className="clearfloat" /><br />

      <br />

      {this.showNoteSettings()}

      <br /><hr className="clearfloat" /><br />

      <br />

      {this.showGroupSettings()}<br/><br/>

      <button type="button" onClick={()=>this.logOut()} className="outlined floatright clearfloat">Log Out</button>
      <div className="clearfloat"></div>
      <br />
    </div>
    )
  }
  
  render(){
//     cl(IconButton);
    if(this.state.loaded){
      return(
        <div>
            <div id="profile-page">
            <C18Confirm00 parms={this.state.popup}/>
              {this.showAvatarSection()}
              <br /><hr /><br />
              {this.showUserInfo()}
            </div> 

        </div>
      );
    } else{
      return <div>loading...</div>
    }
  } 
}

// attribute for avatar icons
// <a href="https://www.flaticon.com/packs/gardening-9" title="icons">Avatar icons created by Freepik - Flaticon</a>
  
export default UserProfile ;
 
