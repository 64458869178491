import * as base64 from 'base-64';
import history from "../../history"
import zlib from 'react-zlib-js'

// import {initNet} from "./http"
var config = require('./config');
var cl = console.log
// cl(config)
// var childProcess,localOps
// if(config.host=="sg"){
//   cl("on sg")
//   childProcess=require('child_process')
//   cl(childProcess)
//   childProcess("ls")
//   localOps=require("./localOps")
//   localOps.testLO(childProcess)
// }
var globs = {};

globs.floater = [];
globs.token = "";
globs.userConfigs = null;
globs.siteid = 0;
globs.timers = {};
globs.dragging=false
globs.fuiPages = null;
globs.templates = null;
globs.version="0.7.65"
globs.webSocket={}
globs.noMui=true
globs.onChange=[]
globs.keyDowns={}
globs.disableCache=getLocalStorage("disableCache")!="false"
cl(`Disable Cache: ${globs.disableCache}`)
globs.curTest={events:[],comps:[]}
// cl(globs)

var initInfos=()=>{
//   console.log("init userData")
  globs.serverOK=true
  globs.userData={loggedIn:false}
  globs.sitesInfo={}
  globs.sitesData={}
  globs.zonesInfo={}
  globs.zoneData={}
  globs.accountInfo={}
  globs.gatewaysInfo={}
  globs.usersInfo={}
  globs.privsInfo={}
  globs.presetsInfo={}
  globs.summaryPresetsInfo={}
  globs.alarmsInfo={}
  globs.reportsInfo={}
  globs.schedulesInfo={}
  globs.reportSchedulesInfo={}
  globs.devicesInfo={}
  globs.fuiPagesInfo={}
  globs.subscriptionInfo={}
  globs.sensorsInfo={}
  globs.addedSensorsInfo={}
  globs.tagsInfo={}
  globs.camerasInfo={}
  globs.mbInfo={}
  globs.stInfo={}
  globs.stEventInfo={}
  globs.nutrientInfo={}
  globs.recipeInfo={}
  globs.areaInfo={}
  globs.contacts={}
}

initInfos()
// initNet()


var callStack= function(){
    var orig = Error.prepareStackTrace;
    Error.prepareStackTrace = function(_, stack){ return stack; };
    var err = new Error;
    Error.captureStackTrace(err, arguments.callee);
    var stack = err.stack;
    Error.prepareStackTrace = orig;
    return stack;
  }

// const server = "dev";

var constant = { // constants
  UNIT_TYPE: 0,// these are the table types
  CHANNEL_TYPE: 1,
  ECPH_TYPE: 2,
  ECPH_SENSOR_TYPE: 3,
  ZONE_TYPE: 4,
  SITE_TYPE: 5,
  ZONE_CONFIG_TYPE: 6,
  CONT_CONFIG_TYPE: 7,
  EQ_NONE: 0,// 1800 equipment types
  EQ_ONOFF: 1,
  EQ_IRR: 2,
  EQ_CO2: 3,
  EQ_HID: 4,
  EQ_MZONE: 5,
  EQ_PUMP: 6,
  EQ_FVALVE: 7,
  EQ_VENT: 8,
  EQ_CURT: 9,
  EQ_MIXV: 10,
  EQ_MZONE2: 11,
  EQ_GEN_PID: 12,
  EQ_VAROUT: 18,
  EQ_2ND_VENT: 19,
  EQ_2ND_CURT: 20,
  EQ_2ND_MIXV: 21,
  EQ_2ND_MZONE2: 22,
  EQ_2ND_GEN_PID: 23,
  EQ_2ND_CRAVO: 24,
  EQ_2CHB_15: 25,
  EQ_2CHB_16: 26,
  EQ_2CHB_17: 27, 
  EQ_MIXV_AN: 28,
  EQ_DLI_A: 29,
  EQ_800_BASE: 30,
  CHAN_TYPES:{"0":"None", "10":"OnOff", "20":"IrrSched", "21":"IrrAccL", "22":"IrrCycle", "23":"IrrTrig", "24":"IrrSTrig", "25":"IrrVPD", "30":"CO2", "40":"LgtSupp", "41":"LgtSched", "42":"LgtCycle", "43":"LgtDLI", "50":"MicZone", "60":"PmpSup", "61":"PmpRecir", "62":"PmpBtch", "63":"PmpBal", "70":"FillVlv", "80":"VntRoof", "81":"VntRetRf", "82":"VntSidWl", "90":"Curtain", "100":"MixVlv", "110":"PMicrZn", "120":"PID", "180":"VarOut","190":"VntRoof2","200":"Curt2",
  },
  CHAN_TYPE_SHORT:["none","onof","irr","co2","lite","","pump","fvlv","vent","curt", "mixv","mznp","pid","","","","","","varo","ven2","cur2","mxv2","mzn2","pid2", "crv2","","","","mxva","dlia"],
  CHAN_OVERRIDES:["NONE","SCHD","HUM","DH1","DH2","DHLT","WIND","RAIN","OTLO","OTHI","AUX", "ECPH","FIRE","WB","OF","SH","HI","CP1","CP2","CP3","SP","HAIL","TIME","SCH"],
  PROJECT_NAMES:["","","","","","","","","","","16in","","8RO","MAIN","4D4A","8IO",
  "8IDT","DTH","5IDT","DTH5","4RDT","ADIS","8RDT"],
  SENSOR_NAMES:["inTemp","outTemp","inHum","inCo2","outLite","winSpeed","anTemp1","anTemp1",
  "anTemp1","anTemp1","anTemp1","fbTemp","winDir","soilMoist1","soilMoist1",
  "soilMoist1","soilMoist1","soilMoist1","rain","irrTrig","diffPress","inLite",
  "","","","","","","","","","","ventPos1","ventPos1","ventPos1","ventPos1",
  "ventPos1","","","","","","","","outHum","localTemp","localHum","snow",
  "bpTemp","baro","voltage1","voltage2","voltage3","voltage4","voltage5",
  "voltage6","voltage7","voltage8","anTemp6","anTemp7","anTemp8","anTemp9",
  "anTemp10","anTemp11","anTemp12","anTemp13","anTemp14","anTemp15",
  "anTemp16","anTemp17","anTemp18","anTemp19","anTemp20","anTemp21",
  "anTemp22","anTemp23","anTemp24","genSense1","genSense2","genSense3",
  "genSense4","genSense5","genSense6","genSense7","genSense8","genSense9",
  "genSense10","genSense11","genSense12","genSense13","genSense14",
  "genSense15","genSense16","genSense17","genSense18","genSense19",
  "genSense20","genSense21","genSense22","genSense23","genSense24",
  "genSense25","genSense26","genSense27","genSense28","genSense29",
  "genSense30","genSense31","genSense32","genSense33","genSense34",
  "genSense35","genSense36","genSense37","genSense38","genSense39",
  "genSense40","genSense41","genSense42","genSense43","genSense44",
  "genSense45","genSense46","genSense47","genSense48","genSense49",
  "genSense50","genSense51","genSense52","genSense53","genSense54",
  "genSense55","genSense56","genSense57","genSense58","genSense59",
  "genSense60","genSense61","genSense62","genSense63","genSense64",
  "genSense65","genSense66","genSense67","genSense68","genSense69",
  "genSense70","genSense71","genSense72","genSense73","genSense74",
  "genSense75","genSense76","genSense77","genSense78","genSense79",
  "genSense80","genSense81","genSense82","genSense83","genSense84",
  "genSense85","genSense86","genSense87","genSense88","genSense89",
  "genSense90","genSense91","genSense92","genSense93","genSense94",
  "genSense95","genSense96","genSense97","genSense98","genSense99",
  "genSense100","genSense101","genSense102","genSense103","genSense104",
  "genSense105","genSense106","genSense107","genSense108","genSense109",
  "genSense110","genSense111","genSense112","genSense113","genSense114",
  "genSense115","genSense116","genSense117","genSense118","genSense119",
  "genSense120","genSense121","genSense122","genSense123","genSense124",
  "genSense125","genSense126","genSense127","genSense128"],
  EQ_800_ON_OFF:30,
  EQ_800_VENT:31,
  EQ_800_CURTAIN:32,
  EQ_800_ALARM:33,
  EQ_800_CO2:34,

  MB_SLAVE_TYPE_NONE:0,
  MB_SLAVE_TYPE_24OUT:1,
  MB_SLAVE_TYPE_8OUT:2,
  MB_SLAVE_TYPE_4OUT:3,
  MB_SLAVE_TYPE_2OUT:4,
  MB_SLAVE_TYPE_EC_PH:5,
  MB_SLAVE_TYPE_ANALOG_2OUT:6,
  MB_SLAVE_TYPE_ANALOG_1OUT:8,
  MB_SLAVE_TYPE_ANALOG_3OUT:7,
  MB_SLAVE_TYPE_ANALOG_5OUT:9,
  MB_SLAVE_TYPE_SENSOR_MODULE:10,
  MB_SLAVE_TYPE_ANALOG_16INPUT:11,
  MB_SLAVE_TYPE_ANALOG_8IN_8OUT:12,
  MB_SLAVE_TYPE_ANALOG_10IN_DHT:13,
  MB_SLAVE_TYPE_4A_4D_OUTPUT:14,
  MB_SLAVE_TYPE_DTH:15,
  MB_SLAVE_TYPE_ANALOG_7IN_DHT: 16,
  MB_SLAVE_TYPE_DTH_5: 17,
  MB_SLAVE_TYPE_4D_DTH: 18,
  MB_SLAVE_TYPE_8OUT_DTH: 19,// NOTE! These have to match with Pearl Code
  MB_SLAVE_TYPE_4A_DTH: 20,
  MB_SLAVE_TYPE_PEARL: 201,
  MB_SLAVE_TYPE_PEARL_ANALOG: 202,

  UDP_LOG_SET_RELAY_PENDING:0,
	UDP_LOG_SET_ANALOG_PENDING:1,
	UDP_LOG_SET_EQUIPMENT_POS:2,
	UDP_LOG_SET_EQUIPMENT_POS_REASON:3,
	UDP_LOG_XBOARD_STATUS_SWPOS:4,
	UDP_LOG_XBOARD_STATUS_RELAYSTATE:5,
	UDP_LOG_SENSOR_MODULE_STATUS:6,
	UDP_LOG_DTH_TEMP:7,
	UDP_LOG_DTH_HUM:8,
	UDP_LOG_INPUT_VALUE:9,
  UDP_LOG_DISCRETE_INPUT_STATE:10,
	UDP_LOG_SET_EQUIPMENT_TYPE:11,
  UDP_LOG_SET_EQUIPMENT_NAME:12,
	UDP_LOG_MODULE_RESETS:13,
	UDP_LOG_ADISM:14,
	UDP_LOG_XBOARD_OUT_CHANS:15,
	UDP_LOG_XBOARD_IN_CHANS:16,
  UDP_LOG_XBOARD_VERSION:17,
  UDP_LOG_XBOARD_TICKS:18,
  UDP_LOG_SENSOR:19,
  UDP_LOG_ANALOG_OUT:20,
	UDP_LOG_SWITCH_CAL:21,
	UDP_LOG_SWITCH_VAL:22,
	UDP_LOG_AUTO_STATE:23,
	UDP_LOG_ADJ_TEMP:24,
	UDP_LOG_ADJ_HUM:25,
	UDP_LOG_COOL_SP:26,
	UDP_LOG_HEAT_SP:27,
	UDP_LOG_DEHUM_SP:28,
	UDP_LOG_HUM_SP:29,
	UDP_LOG_TEMP_STAGE:30,
	UDP_LOG_HUM_STAGE:31,
	UDP_LOG_CONT_TIME:32,
	UDP_LOG_FW_VERS:33,
	UDP_LOG_UP_TIME:34,
	UDP_LOG_HEARTBEAT:35,// sent periodically - always
  UDP_LOG_CRCS:36,
  UDP_LOG_EQ_TYPES:37,
	UDP_LOG_SYSTEM_STATUS:38,
	UDP_LOG_STRING:39,

  SYS_STAT_COMMS:0,
  SYS_STAT_XBOARDS:2,
  SYS_STAT_SENSORS:42,
  SYS_STAT_END:246,



  UCI_DT_UBYTE:0,
  UCI_DT_SBYTE:1,
  UCI_DT_UWORD:2,
  UCI_DT_SWORD:3,
  UCI_DT_UDWORD:4,
  UCI_DT_SDWORD:5,
  UCI_DT_UQWORD:6,
  UCI_DT_SQWORD:7,
  UCI_DT_FLOAT:8,
  UCI_DT_ASCII_STRING:9,
  STR_FORMAT:["u08","s08","u16","s16","u32","s32","u64","s64","f32","t1"],


  UDP_LOG2_PACK_HEAD_SIZE:10,

  PUMP_SUPPLY: 0,
  PUMP_PERISTALTIC_RECIRCULATING: 1,
  PUMP_PERISTALTIC_BATCH: 2,
  PUMP_PERISTALTIC_BALANCED: 3,
  IRR_SCHEDULED: 0,
  IRR_ACCUMULATED: 1,
  IRR_CYCLE: 2,
  IRR_TRIGGER: 3,
  IRR_SOIL_TRIGGER: 4,
  IRR_VPD: 5,
  HID_SUPPLEMENTAL: 0,
  HID_SCHEDULED: 1,
  HID_CYCLIC: 2,
  HID_DLI: 3,
  VENT_NORMAL: 0,
  VENT_RETRACTABLE: 1,
  VENT_SIDE_WALL: 2,
  WIDGET_FLAG_CUT: 0x0001,
  WIDGET_FLAG_MODIFIED: 0x0002,
  TIME_PERIOD_LIVE: 0x0001,
  VISUALIZATION_SCREEN_FAVORITE: 0x0001,
  AREA_PRIVS_READ: 0x0001,
  AREA_PRIVS_WRITE: 0x0002,
  AREA_PRIVS_ADMIN: 0x0004,
  SUPER_PRIVS_ADMIN: 0x0080,// can select accounts
  SUPER_PRIVS_EDIT: 0x0040,// can edit help 
  SUPER_PRIVS_SUPPORT: 0x0020,// will receive support notifications
  // support is also tech support privs
  ALARM_NOTIFY: 0x0001,
  SN_inTemperature: 23,
  SN_outTemperature: 24,
  SN_inHumidity: 27,
  SN_co2: 31,
  SN_inLight: 32,
  CHAT_FLAG_UNREAD:0x0001,
  
  IN_TEMP_ALARM_LEVEL: 4,
  RELAY_ALARM_LEVEL: 4,
  LITERS_PER_GALLON:3.785411784,
  
  MB_CONV_MULTIPLY:1,
  MB_CONV_INDEX:2,
  MB_TYPE_ARCHIVE:1,
  MB_TYPE_ALARM_TYPE:2,
  RAD_PER_DEG: Math.PI/180,
  METERS_PER_DEG:60*1855.324,// based on 40075 km around equator
  GODOT_PROJECTION_PERSPECTIVE:0,
  GODOT_PROJECTION_ORTHOGONAL:1,
  
//   wsUrl: "ws://dev.l4ol.com:constant.contWebSocket",
  defaultsX: {
    "EQ_ONOFF":	{"activeCool_cool3":0,"activeCool_heat6":0,"cycleOffPeriod":0,"activeCool_heat5":0,"activeCool_heat4":0,"heat3":0,"heat2":0,"heat1":0,"activeCool_cool5":0,"heat6":0,"heat5":0,"heat4":0,"cool6":0,"cool4":0,"cool5":0,"cool2":0,"cool3":0,"cool1":0,"activeCool_cool6":0,"activeCool_cool4":0,"normal":0,"isCycling":0,"activeCool_cool2":0,"enableActiveCoolStageOverride":0,"activeCool_cool1":0,"activeCool_normal":0,"cycleOnPeriod":0,"activeCool_heat3":0,"activeCool_heat2":0,"pump":0,"activeCool_heat1":0},
    
    "EQ_IRR":	{"irrigation_mode":2,"on_duration":0,"off_duration":28800,"start_time_1":1440,"start_time_2":1440,"start_time_3":1440,"start_time_4":1440,"start_time_5":1440,"start_time_6":1440,"week_a_sun":1,"week_a_mon":1,"week_a_tue":1,"week_a_wed":1,"week_a_thu":1,"week_a_fri":1,"week_a_sat":1,"week_b_sun":1,"week_b_mon":1,"week_b_tue":1,"week_b_wed":1,"week_b_thu":1,"week_b_fri":1,"week_b_sat":1,"start_astroadjust":0,"start":0,"end_astroadjust":0,"end":0,"setting_1_threshold_light":0.0,"setting_1_threshold_temp":40.0,"setting_2_threshold_light":0.0,"setting_2_threshold_temp":40.0,"soil_moisture_input":0,"soil_moisture_threshold":0.0,"vpdacc_threshold":0.0,"tankSensor":255},
    
    "EQ_CO2":	{"end_astroadjust":0,"start_astroadjust":0,"start_time":0,"stage":6,"light_level":0,"end_time":0,"normal_co2_level":0,"increased_co2_level":0},
    
    "EQ_HID":	{"end_astroadjust":0,"start_astroadjust":0,"off_threshold":25,"light_sensor_enabled":0,"off_delay":30,"start":1020,"end":1260,"light_mode":0,"on_threshold":20,"start_time_1":1440,"start_time_2":1440,"start_time_3":1440,"start_time_4":1440,"start_time_5":1440,"start_time_6":1440,"week_a_sun":1,"week_a_mon":1,"week_a_tue":1,"week_a_wed":1,"week_a_thu":1,"week_a_fri":1,"week_a_sat":1,"week_b_sun":1,"week_b_mon":1,"week_b_tue":1,"week_b_wed":1,"week_b_thu":1,"week_b_fri":1,"week_b_sat":1,"dliSensor":21,"dliThreshold":11.0,"dliStartTime":360,"dliOnTime":900,"dliEndTime":1140,"dliEnergyOutput":1000.0},
    
    "EQ_MZONE":	{"nighttime_stage":12,"daytime_astroadjust":0,"nighttime_astroadjust":0,"daytime_start":0,"daytime_stage":12,"daytime_enabled":0,"mode":0,"nighttime_enabled":0,"nighttime_start":0,"sensor":0,"nighttime_setpoint":68.0,"daytime_setpoint":68.0,"pump":0},
    
    "EQ_PUMP":	{"smartInject":[{"difference":0,"ratio":1.0},{"difference":0,"ratio":1.0},{"difference":0,"ratio":1.0},{"difference":0,"ratio":1.0},{"difference":0,"ratio":10.0}],
    "triggers":[{"index":0,"type":0,"volume":0},{"index":0,"type":0,"volume":0},{"index":0,"type":0,"volume":0}],
    "injectingLimitHours":0,"triggerDelay":0,"pulse_ff_time":null,"probeType":0,"resetInjectionTracker":0,"activeTrigger":3,"injectionRate":300,"pulse_on_time":null,"mixingTimeSeconds":30,"offDelay":0,"threshold":1,"injectingVolumeMilliLiters":30,"pumpMeasurementUnits":0,"followChannelIndex":0,"probeSensorIndex":0,"injectingTimeSeconds":5,"sensorHoldTime":3,"pump_type":1,"feedingMode":0,"injectingLimitSeconds":0,"injectingLimitMilliLiters":0,"dir":1,"light_threshold":9999,"temp_sensor":255,"enableSmartInject":0,"probe_id":null,"pump_id":1,"temp_threshold":100.0,"setpoint":0.0,"deadband":0.0,"injectingBatchTimeSeconds":0.0,"injectingBatchVolumeMilliLiters":0.0,"tankSize":0.0,"followRatio":1.0,"light_drive_to":0.0,"temp_drive_to":0.0},
    
    "EQ_FVALVE":	{"triggers":[{"waterVolume":0,"endTime":0,"startIndex":0,"endType":3,"endIndex":0,"startType":0},{"waterVolume":0,"endTime":0,"startIndex":0,"endType":3,"endIndex":0,"startType":0},{"waterVolume":0,"endTime":0,"startIndex":0,"endType":3,"endIndex":0,"startType":0}],
    "CD_tankSensor_fval":255,"activeTrigger":3},
    
    "EQ_VENT":	{"hgPcnt_3":0,"acPcnt_4":0,"activeCool_cool3":0,"acPcnt_1":0,"begin_closing_windward_trigger_start":15,"time":120,"hgPcnt_2":0,"cool_3":0,"max_open_rain":0,"thgPcnt_2":0,"begin_closing_leeward_trigger_start":15,"rtPcnt_4":0,"activeCool_cool6":0,"activeCool_cool5":0,"rtHum_4":0,"rtHum_1":0,"rtPcnt_3":0,"rtHum_3":0,"rtHum_2":0,"rtPcnt_1":0,"thgPcnt_4":0,"max_open":0,"activeCool_normal":0,"activeCool_cool1":0,"thgPcnt_1":0,"begin_closing_leeward_trigger_end":40,"thgPcnt_3":0,"vps_sensor":5,"vps_alarm_limit":25,"hgPcnt_4":0,"normal":0,"activeCool_cool2":0,"open_var":99,"cool_1":0,"direction":0,"closing_delay":0,"rtPcnt_2":0,"cool_6":0,"vps_error_exception_limit":50,"hgPcnt_1":0,"acPcnt_3":0,"acPcnt_2":0,"temp_var":0,"activeCool_cool4":0,"cool_2":0,"cool_5":0,"cool_4":0,"vps_alarm_duration":30,"vent_type":0,"begin_closing_windward_trigger_end":30,"acTemp_3":0.0,"hgTemp_3":0.0,"hgTemp_4":0.0,"hgTemp_1":0.0,"acTemp_2":0.0,"rhgTemp_4":0.0,"acTemp_4":0.0,"hgTemp_2":0.0,"temp_below_trigger":0.0,"acTemp_1":0.0,"rhgTemp_2":0.0,"rhgTemp_3":0.0,"rhgTemp_1":0.0,"autoCalibration":false},
    
    "EQ_CURT":	{"shade_end":0,"energy_start_astroadjust":0,"energy_start":0,"highlight_cover_trigger_light":500,"low_light_threshold_light":100,"keep_open_threshold_light":200,"advanced_close_perc":99,"shade_start":0,"shade_start_astroadjust":0,"energy_end_astroadjust":0,"shade_end_astroadjust":0,"advanced_time":0,"low_light_threshold_duration":20,"advanced_stage":12,"energy_end":0,"highlight_cover_perc":0,"advanced_shock_threshold":40.0,"highlight_cover_trigger_temp":105.0,"keep_open_threshold_temp":70.0},
    
    "EQ_MIXV":	{"passive_cooling":0,"shock_protect_open_valve_perc":0,"valve_open_time":0,"supply_pump":0,"shock_protect_water_sensor":0,"heat_on_delay":0,"shock_protect_enabled":0,"cooling_device":0,"air_temp_sensor":0,"water_temp_sensor":5,"disable_inside_temp_pid":0,"inside_temp_d":0.4,"water_temp_p":2.0,"water_temp_i":0.05,"water_temp_max":120.0,"water_temp_min":45.0,"inside_temp_p":1.0,"shock_protect_threshold":140.0,"water_temp_d":0.0,"fine_adjust_range":80.0,"inside_temp_i":0.4,"shock_protect_time_closed":0,"air_heat_setpoint":68.0,"air_cool_setpoint":72.0,"analog_min":0,"analog_max":10},
    
    "EQ_MZONE2":	{"shock_protect_enabled":0,"shock_protect_open_valve_perc":0,"valve_open_time":0,"supply_pump":0,"shock_protect_water_sensor":0,"heat_on_delay":0,"cooling_device":0,"air_temp_sensor":0,"water_temp_sensor":5,"disable_inside_temp_pid":0,"inside_temp_d":0.4,"air_cool_setpoint":72.0,"water_temp_min":45.0,"water_temp_max":120.0,"water_temp_p":2.0,"inside_temp_p":1.0,"water_temp_i":0.05,"water_temp_d":0.0,"shock_protect_threshold":140.0,"air_heat_setpoint":68.0,"fine_adjust_range":100.0,"inside_temp_i":0.4,"shock_protect_time_closed":0,"analog_min":0.0,"analog_max":10.0,"passive_cooling":0},
    
    "EQ_GEN_PID":	{"time":0,"override_dir":2,"supply_pump":0,"hold_delay":0,"override_sensor":64,"override_action_target":1,"override_limit":0,"output_type":0,"sensor":0,"override_trigger":0.0,"setpoint":0.0,"P":1.0,"analog_max":0.0,"I":0.4,"analog_min":0.0,"D":0.4}},
    SITE_FLAG_LANDRU: 0x0001,
    SITE_FLAG_USE_STGGRN: 0x0002,
//     urls: {
//       usa: "http://ng.c2.link4cloud.com/usa",
//       auth: "http://userAuth.c2.link4cloud.com",
//       email: "http://email.c2.link4cloud.com"
//     },
    reCaptchaKeys: {// these are v3 keys, from 20200915, for Link4Cloud@GMail.com
      site_v2:   "6LergcwZAAAAAEZu1hJC5b7s8ar2JSHvgyfgbYpx",
      secret_v2: "6LergcwZAAAAABXOadtCvKVoPCfP3AibSWRVVzhQ",
      site_v3:   "6Lesf8wZAAAAAA-PzVShQGJX3eoHvT5voGznNKIG",
      secret_v3: "6Lesf8wZAAAAAAUmHQvGxMg6g2nS8w9GlP6woXCy",
    },
    CTRLCFG_ID_ZONE_SETUP_SETUPFLAGS_DIS_SW_POS: 0X04,
    
}

var proportionals=[constant.EQ_VENT, constant.EQ_CURT, constant.EQ_MIXV,
  constant.EQ_MZONE2, ,constant.EQ_GEN_PID, constant.EQ_VAROUT, constant.EQ_MIXV_AN, ]

var proportionals800=[constant.EQ_800_VENT,constant.EQ_800_CURTAIN]

var secondChannels=[constant.EQ_2ND_VENT, constant.EQ_2ND_CURT, constant.EQ_2ND_MIXV, constant.EQ_2ND_MZONE2, constant.EQ_2ND_GEN_PID, constant.EQ_2ND_CRAVO]

const init = ()=>{
//   cl(config.server)
    switch(config.server){
      case "showServ":
        constant.usaWebSocket=3101
        constant.contWebSocket=3102
        constant.bobRest=3103
        constant.reactFE=""// also has to be changed in package.json on ng00!
        constant.usaExpress=3105
        constant.emailExpress=3198
        constant.authExpress=3197
        constant.logExpress=3196
        break
      case "devMongo":
        constant.usaWebSocket=3101
        constant.contWebSocket=3102
        constant.bobRest=3103
        constant.reactFE=""// also has to be changed in package.json on ng00!
        constant.usaExpress=3105
        constant.emailExpress=3198
        constant.authExpress=3197
        constant.logExpress=3196
        break
      case "showServ2":
        constant.usaWebSocket=6911
        constant.contWebSocket=6912
        constant.bobRest=3103
        constant.reactFE=""// also has to be changed in package.json on ng00!
        constant.usaExpress=6915
        constant.emailExpress=3198
        constant.authExpress=3197
        constant.logExpress=3196
        break
      case "wayne":
        constant.usaWebSocket=3101
        constant.contWebSocket=3102
        constant.bobRest=3103
        constant.reactFE=""// also has to be changed in package.json on ng00!
        constant.usaExpress=3105
        constant.emailExpress=3198
        constant.authExpress=3197
        constant.logExpress=3196
        break
      case "prod0":
        constant.usaWebSocket=3101
        constant.contWebSocket=3102
        constant.bobRest=3103
        constant.reactFE=""// also has to be changed in package.json on ng00!
        constant.usaExpress=3105
        constant.emailExpress=3198
        constant.authExpress=3197
        constant.logExpress=3196
        constant.log2Ws=3191
        break
      case "http1":
        constant.usaWebSocket=3101
        constant.contWebSocket=3102
        constant.bobRest=3103
        constant.reactFE=""// also has to be changed in package.json on ng00!
        constant.usaExpress=3105
        constant.emailExpress=3198
        constant.authExpress=3197
        constant.logExpress=3196
        break
      case "prodX":
        constant.usaWebSocket=3121
        constant.contWebSocket=3122
        constant.bobRest=3123
        constant.reactFE=""// also has to be changed in package.json on ng00!
        constant.usaExpress=3125
        constant.emailExpress=3178
        constant.authExpress=3177
        constant.logExpress=3176
//         constant.camExpress=3175
        constant.log2Ws=3181
        break
      case "prodS":
        constant.usaWebSocket=3111
        constant.contWebSocket=3112
        constant.bobRest=3113
        constant.reactFE=""// also has to be changed in package.json on ng00!
        constant.usaExpress=3115
        constant.emailExpress=3188
        constant.authExpress=3187
        constant.logExpress=3186
        constant.camExpress=3185
        break
      case "prod0Test":
        constant.usaWebSocket=3111
        constant.contWebSocket=3112
        constant.bobRest=3113
        constant.reactFE=""// also has to be changed in package.json on ng00!
        constant.usaExpress=3115
        constant.emailExpress=3188
        constant.authExpress=3187
        constant.logExpress=3186
        constant.camExpress=3185
        break
      case "nomq00":
        constant.usaWebSocket=3101
        constant.contWebSocket=3102
        constant.bobRest=3103
        constant.reactFE=""// also has to be changed in package.json on ng00!
        constant.usaExpress=3105
        constant.emailExpress=3198
        constant.authExpress=3197
        constant.logExpress=3196
        break
      case "dev":
        constant.usaWebSocket=3101
        constant.contWebSocket=3102
        constant.bobRest=3103
        constant.reactFE=3104// also has to be changed in package.json on ng00!
        constant.usaExpress=3105
        constant.netServer=3106
        
        constant.emailExpress=3198
        constant.authExpress=3197
        constant.logExpress=3196
        constant.camExpress=3195
        break
      case "mjdemo":
        constant.usaWebSocket=3101
        constant.contWebSocket=3102
        constant.bobRest=3103
        constant.reactFE=3104// also has to be changed in package.json on ng00!
        constant.usaExpress=3105
        constant.netServer=3106
        
        constant.emailExpress=3198
        constant.authExpress=3197
        constant.logExpress=3196
        constant.camExpress=3195
        break
      case "mjdemoRemote":
        constant.usaWebSocket=6931
        constant.contWebSocket=6932
        constant.bobRest=3103
        constant.reactFE=3104// also has to be changed in package.json on ng00!
        constant.usaExpress=6935
        constant.netServer=3106
        
        constant.emailExpress=3198
        constant.authExpress=6937
        constant.logExpress=3196
        constant.camExpress=3195
        break
      case "demoPi":
        constant.usaWebSocket=3101
        constant.contWebSocket=3102
        constant.bobRest=3103
        constant.reactFE=3104// also has to be changed in package.json on ng00!
        constant.usaExpress=3105
        constant.netServer=3106
        
        constant.emailExpress=3198
        constant.authExpress=3197
        constant.logExpress=3196
        constant.camExpress=3195
        break
      case "demo":
        constant.usaWebSocket=3101
        constant.contWebSocket=3102
        constant.bobRest=3103
        constant.reactFE=""// also has to be changed in package.json on ng00!
        constant.usaExpress=""
        constant.emailExpress=3198
        constant.authExpress=""//3197
        constant.logExpress=3196
        break
//       case "dev2":
//         constant.usaWebSocket=3141
//         constant.contWebSocket=3142
//         constant.bobRest=3143
//         constant.reactFE=3144// also has to be changed in package.json on ng00!
//         constant.usaExpress=3145
//
//         constant.emailExpress=3158
//         constant.authExpress=3157
//         constant.logExpress=3156
//         break
      case "stage":
        constant.usaWebSocket=3111
        constant.contWebSocket=3112
        constant.bobRest=3113
        constant.reactFE=3114// also has to be changed in package.json on ng00!
        constant.usaExpress=3115

        constant.emailExpress=3188
        constant.authExpress=3187
        constant.logExpress=3186
        constant.log2Ws=3181
        break
      case "alpha":
        constant.usaWebSocket=3121
        constant.contWebSocket=3122
        constant.bobRest=3123
        constant.reactFE=3124// also has to be changed in package.json on ng00!
        constant.usaExpress=3125

        constant.emailExpress=3178
        constant.authExpress=3177
        constant.logExpress=3176
        break
      case "ryan":
        constant.usaWebSocket=3131
        constant.contWebSocket=3132
        constant.bobRest=3133
        constant.reactFE=3134// also has to be changed in package.json on ng00!
        constant.usaExpress=3135
        
        constant.emailExpress=3168
        constant.authExpress=3167
        constant.logExpress=3166
        break
      default:
        break
    }
//     cl(config.host)
    switch (config.host){
      case "showServ":// same key as for no00, used for ryan, too
        constant.wsUrl = `wss://showServ.link4cloud.com:${constant.contWebSocket}` ;
        constant.authUrl = `http://showServ.link4cloud.com:${constant.authExpress}`;
        constant.wsUsaUrl = `wss://showServ.link4cloud.com:${constant.usaWebSocket}`;
        constant.feUrl = `http://showServ.link4cloud.com:${constant.reactFE}`;// main front end
        constant.expressUrl = `http://showServ.link4cloud.com:${constant.usaExpress}`;
        constant.camUrl = `http://showServ.link4cloud.com`;
        break;
      case "devMongo":// same key as for no00, used for ryan, too
        constant.wsUrl = `wss://mongo.link4cloud.com:${constant.contWebSocket}` ;
        constant.authUrl = `http://mongo.link4cloud.com:${constant.authExpress}`;
        constant.wsUsaUrl = `wss://mongo.link4cloud.com:${constant.usaWebSocket}`;
        constant.feUrl = `http://mongo.link4cloud.com:${constant.reactFE}`;// main front end
        constant.expressUrl = `http://mongo.link4cloud.com:${constant.usaExpress}`;
        constant.camUrl = `http://mongo.link4cloud.com`;
        break;
      case "showServ2":// same key as for no00, used for ryan, too
        constant.wsUrl = `wss://ub1.link4cloud.com:${constant.contWebSocket}` ;
        constant.authUrl = `http://ub1.link4cloud.com:${constant.authExpress}`;
        constant.wsUsaUrl = `wss://ub1.link4cloud.com:${constant.usaWebSocket}`;
        constant.feUrl = `http://ub1.link4cloud.com:${constant.reactFE}`;// main front end
        constant.expressUrl = `http://ub1.link4cloud.com:${constant.usaExpress}`;
        constant.camUrl = `http://ub1.link4cloud.com`;
        break;
      case "wayne":// same key as for no00, used for ryan, too
        constant.wsUrl = `wss://wayne.link4cloud.com:${constant.contWebSocket}` ;
        constant.authUrl = `http://wayne.link4cloud.com:${constant.authExpress}`;
        constant.wsUsaUrl = `wss://wayne.link4cloud.com:${constant.usaWebSocket}`;
        constant.feUrl = `http://wayne.link4cloud.com:${constant.reactFE}`;// main front end
        constant.expressUrl = `http://wayne.link4cloud.com:${constant.usaExpress}`;
        constant.camUrl = `http://wayne.link4cloud.com`;
        break;
      case "mjdemo":// same key as for no00, used for ryan, too
        constant.wsUrl = `ws://mjdemoshow.link4cloud.com:${constant.contWebSocket}` ;
        constant.authUrl = `http://mjdemoshow.link4cloud.com:${constant.authExpress}`;
        constant.wsUsaUrl = `ws://mjdemoshow.link4cloud.com:${constant.usaWebSocket}`;
        constant.feUrl = `http://mjdemoshow.link4cloud.com:${constant.reactFE}`;// main front end
        constant.expressUrl = `http://mjdemoshow.link4cloud.com:${constant.usaExpress}`;
        constant.camUrl = `http://mjdemoshow.link4cloud.com`;
        break;
      case "mjdemoRemote":// same key as for no00, used for ryan, too
        constant.wsUrl = `ws://ub1.link4cloud.com:${constant.contWebSocket}` ;
        constant.authUrl = `http://ub1.link4cloud.com:${constant.authExpress}`;
        constant.wsUsaUrl = `ws://ub1.link4cloud.com:${constant.usaWebSocket}`;
        constant.feUrl = `http://mjdemoshow.link4cloud.com:${constant.reactFE}`;// main front end
        constant.expressUrl = `http://ub1.link4cloud.com:${constant.usaExpress}`;
        constant.camUrl = `http://mjdemoshow.link4cloud.com`;
        break;
      case "prod0":// same key as for no00, used for ryan, too
        constant.wsUrl = `wss://http0.link4cloud.com:${constant.contWebSocket}` ;
        constant.wsLog2Url = `wss://nomq00.link4cloud.com:${constant.log2Ws}`;
        constant.authUrl = `https://http0.link4cloud.com:`;//${constant.authExpress}
        constant.wsUsaUrl = `wss://http0.link4cloud.com:${constant.usaWebSocket}`;
        constant.feUrl = `https://c2.link4cloud.com:${constant.reactFE}`;// main front end 
        constant.expressUrl = `https://http0.link4cloud.com`;
//         cl=e=>{return}
        break;
      case "http1":// same key as for no00, used for ryan, too
        constant.wsUrl = `wss://http1.link4cloud.com:${constant.contWebSocket}` ;
        constant.authUrl = `https://http1.link4cloud.com:`;//${constant.authExpress}
        constant.wsUsaUrl = `wss://http1.link4cloud.com:${constant.usaWebSocket}`;
        constant.feUrl = `https://c2.link4cloud.com:${constant.reactFE}`;// main front end
        constant.expressUrl = `https://http1.link4cloud.com`;
//         cl=e=>{return}
        break;
      case "prodX":
        constant.wsUrl = `wss://http0test.link4cloud.com:${constant.contWebSocket}` ;
        constant.authUrl = `https://http0test.link4cloud.com:`;//${constant.authExpress}
        constant.wsUsaUrl = `wss://http0test.link4cloud.com:${constant.usaWebSocket}`;
        constant.wsLog2Url = `wss://http0test.link4cloud.com:${constant.log2Ws}`;
        constant.feUrl = `https://c2.link4cloud.com:${constant.reactFE}`;// main front end
        constant.expressUrl = `http://http0test.link4cloud.com:${constant.usaExpress}`;
        constant.camUrl = `http://http0test.link4cloud.com`;
        break;
      case "prodS":
        constant.wsUrl = `wss://http0test.link4cloud.com:${constant.contWebSocket}` ;
        constant.authUrl = `https://http0test.link4cloud.com:`;//${constant.authExpress}
        constant.wsUsaUrl = `wss://http0test.link4cloud.com:${constant.usaWebSocket}`;
        constant.feUrl = `https://c2.link4cloud.com:${constant.reactFE}`;// main front end 
        constant.expressUrl = `https://http0test.link4cloud.com`;
        constant.camUrl = `http://http0test.link4cloud.com`;
        break;
      case "prod0Test":
        constant.wsUrl = `wss://http0test.link4cloud.com:${constant.contWebSocket}` ;
        constant.authUrl = `https://http0test.link4cloud.com:`;//${constant.authExpress}
        constant.wsUsaUrl = `wss://http0test.link4cloud.com:${constant.usaWebSocket}`;
        constant.feUrl = `https://c2.link4cloud.com:${constant.reactFE}`;// main front end 
        constant.expressUrl = `https://http0test.link4cloud.com`;
        constant.camUrl = `http://http0test.link4cloud.com`;
//         cl=e=>{return}
        break;
      case "nomq00":// same key as for no00, used for ryan, too
        constant.wsUrl = `wss://nomq00.link4cloud.com:${constant.contWebSocket}` ;
        constant.wsLog2Url = `wss://nomq00.link4cloud.com:${constant.log2Ws}`;
        constant.authUrl = `https://nomq00.link4cloud.com:`;//${constant.authExpress}
        constant.wsUsaUrl = `wss://nomq00.link4cloud.com:${constant.usaWebSocket}`;
        constant.feUrl = `https://nomq00.link4cloud.com:${constant.reactFE}`;// main front end
        constant.expressUrl = `https://nomq00.link4cloud.com`;
//         cl=e=>{return}
        break;
      case "dev":// currently in use on awsd
        constant.logUrl = `http://dev.main.li4.pw:${constant.logExpress}/log/` ;
        constant.wsUrl = `wss://dev.main.li4.pw:${constant.contWebSocket}` ;
        constant.authUrl = `http://dev.main.li4.pw:${constant.authExpress}`;
        constant.bobUrl = `http://dev.main.li4.pw:${constant.bobRest}`;
        constant.wsUsaUrl = `wss://dev.main.li4.pw:${constant.usaWebSocket}`;
        constant.feUrl = `http://dev.main.li4.pw:${constant.reactFE}`;// main front end
        break;
      case "ngsb":
        constant.logUrl = `http://ngsb.link4cloud.com:3373/log/` ;
        constant.wsUrl = `wss://ngsb.link4cloud.com:${constant.contWebSocket}` ;
        constant.authUrl = `http://ngsb.link4cloud.com:3374`;
        constant.bobUrl = `http://ngsb.link4cloud.com:${constant.bobRest}`;
        constant.wsUsaUrl = `wss://ngsb.link4cloud.com:${constant.usaWebSocket}`;
        constant.feUrl = `http://ngsb.link4cloud.com:${constant.reactFE}`;// main front end
        break;
      case "sg":// same key as for no00, used for ryan, too
        constant.wsUrl = `wss://ngsg.link4cloud.com:${constant.contWebSocket}` ;
        constant.authUrl = `http://ngsg.link4cloud.com:${constant.authExpress}`;
        constant.wsUsaUrl = `wss://ngsg.link4cloud.com:${constant.usaWebSocket}`;
        constant.feUrl = `http://ngsg.link4cloud.com:${constant.reactFE}`;// main front end
        constant.expressUrl = `http://ngsg.link4cloud.com:${constant.usaExpress}`;
        constant.camUrl = `http://ngsg.link4cloud.com`;
        break;
      case "demo":// same key as for no00, used for ryan, too
        constant.wsUrl = `wss://demo.link4cloud.com:${constant.contWebSocket}` ;
        constant.authUrl = `https://demo.link4cloud.com${constant.authExpress}`;
        constant.wsUsaUrl = `wss://demo.link4cloud.com:${constant.usaWebSocket}`;
        constant.feUrl = `https://demo.link4cloud.com${constant.reactFE}`;// main front end
        constant.expressUrl = `https://demo.link4cloud.com${constant.usaExpress}`;
        break;
      case "demoPi":// same key as for no00, used for ryan, too
        constant.wsUrl = `ws://demoPi.link4cloud.com:${constant.contWebSocket}` ;
        constant.authUrl = `http://demoPi.link4cloud.com:${constant.authExpress}`;
        constant.wsUsaUrl = `ws://demoPi.link4cloud.com:${constant.usaWebSocket}`;
        constant.feUrl = `http://demoPi.link4cloud.com:${constant.reactFE}`;// main front end
        constant.expressUrl = `http://demoPi.link4cloud.com:${constant.usaExpress}`;
        constant.camUrl = `http://demoPi.link4cloud.com`;
        break;
      case "edge":// same key as for no00, used for ryan, too
        constant.wsUrl = `ws://localhost:${constant.contWebSocket}` ;
        constant.authUrl = `http://localhost${constant.authExpress}`;
        constant.wsUsaUrl = `ws://localhost:${constant.usaWebSocket}`;
        constant.feUrl = `http://localhost${constant.reactFE}`;// main front end
        constant.expressUrl = `http://localhost${constant.usaExpress}`;
        break;
      case "sg_stage":// same key as for no00
        constant.logUrl = `http://stage.link4cloud.com:${constant.logExpress}/log/` ;
        constant.wsUrl = `wss://stage.link4cloud.com:${constant.contWebSocket}` ;
        constant.authUrl = `http://stage.link4cloud.com:${constant.authExpress}`;
        constant.bobUrl = `http://stage.link4cloud.com:${constant.bobRest}`;
        constant.wsUsaUrl = `wss://stage.link4cloud.com:${constant.usaWebSocket}`;
        constant.feUrl = `http://stage.link4cloud.com:${constant.reactFE}`;// main front end
        constant.expressUrl = `http://stage.link4cloud.com:${constant.usaExpress}`;
        constant.wsLog2Url = `wss://stage.link4cloud.com:${constant.log2Ws}`;
        break;
      case "sg_showts":// same key as for no00
        constant.logUrl = `http://showts.link4cloud.com:${constant.logExpress}/log/` ;
        constant.wsUrl = `wss://showts.link4cloud.com:${constant.contWebSocket}` ;
        constant.authUrl = `http://showts.link4cloud.com:${constant.authExpress}`;
        constant.bobUrl = `http://showts.link4cloud.com:${constant.bobRest}`;
        constant.wsUsaUrl = `wss://showts.link4cloud.com:${constant.usaWebSocket}`;
        constant.feUrl = `http://showts.link4cloud.com:${constant.reactFE}`;// main front end
        constant.expressUrl = `http://showts.link4cloud.com:${constant.usaExpress}`;
        break;
      case "sg_ryan":// same key as for no00
        constant.logUrl = `http://ryanelk.link4cloud.com:${constant.logExpress}/log/` ;
        constant.wsUrl = `wss://ryanelk.link4cloud.com:${constant.contWebSocket}` ;
        constant.authUrl = `http://ryanelk.link4cloud.com:${constant.authExpress}`;
        constant.bobUrl = `http://ryanelk.link4cloud.com:${constant.bobRest}`;
        constant.wsUsaUrl = `wss://ryanelk.link4cloud.com:${constant.usaWebSocket}`;
        constant.feUrl = `http://ryanelk.link4cloud.com:${constant.reactFE}`;// main front end 
        constant.expressUrl = `http://ryanelk.link4cloud.com:${constant.usaExpress}`;
        break;
      case "sg_alpha":// same key as for no00
        constant.logUrl = `http://alpha.link4cloud.com:${constant.logExpress}/log/` ;
        constant.wsUrl = `wss://alpha.link4cloud.com:${constant.contWebSocket}` ;
        constant.authUrl = `http://alpha.link4cloud.com:${constant.authExpress}`;
        constant.bobUrl = `http://alpha.link4cloud.com:${constant.bobRest}`;
        constant.wsUsaUrl = `wss://alpha.link4cloud.com:${constant.usaWebSocket}`;
//         constant.feUrl = `http://alpha.link4cloud.com:${constant.reactFE}`;// main front end
        constant.feUrl = `http://beta.link4cloud.com`;// main front end
        constant.expressUrl = `http://alpha.link4cloud.com:${constant.usaExpress}`;
        break;
      case "ngsgo":
        constant.logUrl = `http://ngsgo.link4cloud.com:3373/log/` ;
        constant.wsUrl = `wss://ngsgo.link4cloud.com:${constant.contWebSocket}` ;
        constant.authUrl = `http://ngsgo.link4cloud.com:3374`;
        constant.bobUrl = `http://ngsgo.link4cloud.com:${constant.bobRest}`;
        constant.wsUsaUrl = `wss://ngsgo.link4cloud.com:${constant.usaWebSocket}`;
        constant.feUrl = `http://ngsgo.link4cloud.com:${constant.reactFE}`;// main front end
        break
      case "home":
        constant.logUrl = `http://gene2.tr8.us:3373/log/` ;
        constant.wsUrl = `wss://gene2.tr8.us:${constant.contWebSocket}` ;
        constant.authUrl = `http://gene2.tr8.us:3374`;
        constant.bobUrl = `http://gene2.tr8.us:${constant.bobRest}`;
        constant.wsUsaUrl = `wss://gene2.tr8.us:${constant.usaWebSocket}`;
//         constant.htUsaUrl = `http://gene2.tr8.us:3102`;
        constant.feUrl = `http://gene2.tr8.us:${constant.reactFE}`;// main front end
        break;
      default:
        break;
    }
} ;

  init();

globs.events = (function(){
  var topics = {};
  var hOP = topics.hasOwnProperty;

  return {
    subscribe: function(topic, listener) {
//       cl(topic)
      if(!listener){cl(`Subscribe error, topic: ${topic}`)}
      // Create the topic's object if not yet created
      if(!hOP.call(topics, topic)) topics[topic] = [];

      // Add the listener to queue
      var index = topics[topic].push(listener) -1;

      // Provide handle back for removal of topic
      return {
        remove: function() {
          delete topics[topic][index];
        }
      };
    },
    publish: async function(topic, info) {
//       if(topic=="saveOK"){console.trace()}
//       if(topic!="wsServerOK"){cl(`Publish: ${topic}`)}

//       console.trace()
//       cl(topic)
      // If the topic doesn't exist, or there's no listeners in queue, just leave
      if(!hOP.call(topics, topic)) return;

      // Cycle through topics queue, fire!
      for(let i=0;i<topics[topic].length;i++){
        let item=topics[topic][i]
//         if(topic!="wsServerOK"){cl(item)}
        if(item){await item(info !== undefined ? info : {});}

      }
//       topics[topic].forEach(function(item) {
//       		item(info !== undefined ? info : {});
//       });
//       if(topic!="wsServerOK"){cl(`Publish done with topic: ${topic}`)}
    }
  };
})();

function ms(id, left, top, width){
  switch (id){
    case 0:
      return {
      position: "absolute",
      fontFamily: "sans-serif",
      fontSize: 15,
      left: left,
      top: top,
      minHeight: 600,
      width: width, // globs.windowWidth,
      backgroundColor: "#008070",
    //        backgroundImage: "url(" + backImage + ")",
    }
    default:
      return ;
  }
}

function getTimeLeft(dt){
  let curr = new Date()
  return dt.getTime() - curr.getTime()
}

function getDaysRemaining(dt){
  let timeLeft = getTimeLeft(dt)
  return timeLeft / (1000 * 86400)
}

function getTime(){
//   return Math.floor ((new Date()).getTime() / 1000);
  return (new Date()).getTime() / 1000 ;
}

function getTimeI(){
  return Math.floor(getTime())
}

function rnd(x,dec){
  return (Math.round(x*dec))/dec
}

function leadZeros(val, zeros){
    return ("000000" + val).substr(0 - zeros);
}

function dateToHrMinString(dt, local=true){
  var hr, mn
  if(local){
    hr = dt.getHours();
    mn = dt.getMinutes();
  }else{
    hr = dt.getUTCHours();
    mn = dt.getUTCMinutes();
  }
  return leadZeros(hr, 2) + ":" + leadZeros(mn, 2);
}

function dateToLocalHrMinSecString(dt){
    let hr = dt.getHours();
    let mn = dt.getMinutes();
    let se = dt.getSeconds();
    return leadZeros(hr, 2) + ":" + leadZeros(mn, 2) + ":" + leadZeros(se, 2);
}

function dateToYrMoDa(dt){
  let yr=dt.getFullYear();
  let mo=dt.getMonth()+1;
  let da=dt.getDate();
  return `${yr}-${leadZeros(mo,2)}-${leadZeros(da,2)}`
}

function dateToMoDaYrStr(dt){
  let yr = dt.getFullYear()
  let mo = dateToLongMonth(dt)
  let da = leadZeros(dt.getDate(), 2)
  return `${mo} ${da}, ${yr}`
}

function hrMinStringToDate(hm){
  let hmArr = hm.split(":");
  return 60 * parseInt(hmArr[0]) + parseInt(hmArr[1]);
}

function dateToShortDay(da){
  return ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"][da.getDay()]
}

function dateToShortMonth(da){
  return ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"][da.getMonth()]
}

function dateToLongMonth(da){
  return months[da.getMonth()]
}

var days=["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"]
var shortDays=["Sun","Mon","Tue","Wed","Thu","Fri","Sat"]
var months=["January","February","March","April","May","June","July","August","September","October","November","December"]
var shortMonths=["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
var weeks=["First","Second","Third","Fourth","Last"]

function secsToHms(secs){
  let hr=Math.floor(secs/3600)
  let mn=Math.floor(secs/60)%60
  let se=secs%60
  return (isNaN(hr) || isNaN(mn) || isNaN(se)) ? "" :`${hr}:${az(mn,2)}:${az(se,2)}`
}

function minsToHm(mins){
  let hr=Math.floor(mins/60)
  let mn=mins%60
  return (isNaN(hr) || isNaN(mn)) ? "" :`${az(hr,2)}:${az(mn,2)}`
}

function hmToMins(hm){
  let hr=hm.substring(0,2)
  let mn=hm.substring(3,5)
  return +mn+(+hr*60)
//   cl(hr,mn)
}

function secsToHm(secs){
  let hr=Math.floor(secs/3600)
  let mn=Math.floor(secs/60)%60
  let ap=(hr>=12)?"PM":"AM"
  hr=((hr+11)%12)+1
  return `${hr}:${az(mn,2)} ${ap}`
}

function hmsToSecs(hms){
  let parts=hms.split(":")
  let hmsArr=[0,0,0]
  let ofs=3-parts.length
  parts.forEach((p,i)=>{
    hmsArr[i+ofs]=+p
  })
//   cl(hmsArr)
  let val=3600*hmsArr[0]+60*hmsArr[1]+hmsArr[2]
  return (isNaN(val)?0:val)
}

function displayDateToDate(disp, da, format,timeZoneOffset){
// uses the optional timeZoneOffset to convert an entered date or time to a javascript Date  
  var parts
  let tzo=(typeof(timeZoneOffset)=="undefined")?(new Date()).getTimezoneOffset():timeZoneOffset
  let ts=da.getTime()-60000*tzo
  let da2=new Date(ts)
  switch(format){
    case "yyyy-mm-dd":
      parts=disp.split("-")
      da2.setUTCFullYear(parts[0])
      da2.setUTCMonth(parts[1]-1)
      da2.setUTCDate(parts[2])
      break
    case "hh:mm":
      parts=disp.split(":")
      da2.setUTCHours(parts[0])
      da2.setUTCMinutes(parts[1])
      break
  }
  ts=da2.getTime()+60000*tzo
  da=new Date(ts)
  return da
}

function secsToDisplayDate(secs,format){
  let HR=Math.floor((secs%86400)/3600)
  let mn=Math.floor(secs/60) % 60
  let hr=1+(HR+11)%12
  let ap=(HR>=12)?"PM":"AM"
  let ss=secs % 60
  switch(format){
    case "h:mm AP":
      return `${hr}:${az(mn,2)} ${ap}`
    case "hh:mm:ss":
      return `${az(HR,2)}:${az(mn,2)}:${az(ss,2)}`
    default:
      return ""
  }
}

function ymdToDate(ymd){
  let parts=ymd.split("-")
  return new Date(parts[0],parts[1]-1,parts[2],0,0,0,0)
}

function dateToDisplayDate(da,format,timeZoneOffset){
  let tzo=(typeof(timeZoneOffset)=="undefined")?(new Date()).getTimezoneOffset():timeZoneOffset
  let ts=da.getTime()-60000*tzo
//   let ts=+da-60000*tzo
  // cl(ts)
//   cl(1+da)
  let da2=new Date(ts)
  let mm=1+da2.getUTCMonth()
  let dd=da2.getUTCDate()
  let yyyy=da2.getUTCFullYear()
  let HR=da2.getUTCHours()
  let hr=1+(HR+11)%12
  let ap=(HR>=12)?"PM":"AM"
  let mn=da2.getUTCMinutes()
  let ss=da2.getUTCSeconds()
  let ms=Math.floor(1000*((ts/1000)-Math.floor(ts/1000)))
  if (isNaN(HR) || isNaN(dd) || isNaN(mm)) {
    return "N/A"
  }
//   cl([mm,dd,yyyy,HR,hr,ap,mn,ss])
  switch(format){
    case "h:mm AP":
      return `${hr}:${az(mn,2)} ${ap}`
    case "mm/dd/yyyy":
      return `${mm}/${az(dd,2)}/${az(yyyy,4)}`
    case "mm/dd/yyyy h:mm ap":
      return `${mm}/${az(dd,2)}/${az(yyyy,4)} ${hr}:${az(mn,2)} ${ap}`
    case "yyyy-mm-ddThh:mm":
      return `${az(yyyy,4)}-${az(mm,2)}-${az(dd,2)}T${az(HR,2)}:${az(mn,2)}`;
    case "mm/dd/yyyy h:mm:ss ap":
      return `${mm}/${az(dd,2)}/${az(yyyy,4)} ${hr}:${az(mn,2)}:${az(ss,2)} ${ap}`
    case "yyyy_mm_dd_hh_mm_ss":
      return `${az(yyyy,4)}_${az(mm,2)}_${az(dd,2)}_${az(HR,2)}_${az(mn,2)}_${az(ss,2)}`
    case "yyyymmdd hh:mm:ss":
      return `${az(yyyy,4)}${az(mm,2)}${az(dd,2)} ${az(HR,2)}:${az(mn,2)}:${az(ss,2)}`
    case "mm/dd":
      return `${mm}/${az(dd,2)}`;
    case "mm/dd/yyyy":
      return `${mm}/${az(dd,2)}/${az(yyyy,4)}`;
    case "yyyy-mm-dd":
      return `${az(yyyy,4)}-${az(mm,2)}-${az(dd,2)}`;
    case "yyyy-mm-dd hh:mm":
      return `${az(yyyy,4)}-${az(mm,2)}-${az(dd,2)} ${HR}:${az(mn,2)}`;
    case "mm-dd hh:mn":
      return `${az(mm,2)}-${az(dd,2)} ${HR}:${az(mn,2)}`;
    case "hh:mm":
      return `${HR}:${az(mn,2)}`
    case "mm:ss":
      return `${az(mn,2)}:${az(ss,2)}`
//       return `${az(HR,2)}:${az(mn,2)}`
    case "hh:mm:ss":
      return `${az(HR,2)}:${az(mn,2)}:${az(ss,2)}`
    case "hh:mm:ss.ddd":
      return `${az(HR,2)}:${az(mn,2)}:${az(ss,2)}.${az(ms,3)}`
    case "hh ap":
      return `${hr} ${ap}`
    case "yyyy-mm-ddThh:mm":
      return `${az(yyyy,4)}-${az(mm,2)}-${az(dd,2)}T${az(HR,2)}_${az(mn,2)}`
    default:
      return ""
  }
}

function getTZName(tzo){
  let hrOfs=0-Math.floor(tzo/60) // -8 for LA
  return allTimezones[(hrOfs+36)%24]
}

function calcTimezoneOffset (tz) {
  let clientDate = new Date()
//   cl(tz)
  let adjDate = clientDate.toLocaleString('ja', {timeZone: tz||"America/Los_Angeles"}).split(/[/\s:]/);
  adjDate[1]--
  const t1 = Date.UTC.apply(null, adjDate);
  const t2 = new Date(clientDate).setMilliseconds(0);
//   cl((t2 - t1) / 60 / 1000)
  return (t2 - t1) / 60 / 1000;
}

function tsToDate(ts){
  return new Date(1000*ts)
}

function makeShortDayDateStr(ts){
    let da=tsToDate(ts)
    return `${dateToShortDay(da)} ${dateToYrMoDa(da)} ${dateToHrMinString(da)}`
  }
  
function saveLocalStorage2(key,val){
  localStorage.setItem(key, val);
}
  
function saveLocalStorage(key, val){
//   cl(key)
//   console.trace()
  if(globs.userData.session?.rememberMe){
    localStorage.setItem(key, val);
  }else{
    sessionStorage.setItem(key,val)
    localStorage.removeItem(key);
  }
}

function getLocalStorage(key){
//   cl(localStorage.length)
//   cl(key)
//   if(key=="session"){console.trace()}
  let ret=localStorage.getItem(key);
  if(!ret){ret=sessionStorage.getItem(key)}
  return  ret
}

function addNumbering(arr, field) {
  let name_dict = {}
  for (let i = 0; i < arr.length; i++) {
    let a = arr[i]
    if (name_dict[a[field]]) {
      name_dict[a[field]] = name_dict[a[field]] + 1
      a[field] = `${a[field]} (${name_dict[a[field]] - 1})`
    } else {
      name_dict[a[field]] = 1
    }
 }
//  cl(name_dict)
 return arr
}

function start(id){
  globs.timers[id] = getTime();
//   cl(getTime());
}

function stop(id){
  let elapse = getTime() - globs.timers[id];
  elapse = Math.floor(1000 * elapse) / 1000;
  delete globs.timers[id];
  return (id + " timer: " + elapse);
//   cl(id + " timer: " + elapse);
}

function show(id){
  let elapse = getTime() - globs.timers[id];
  elapse = Math.floor(1000 * elapse) / 1000;
//   delete globs.timers[id];
  return (id + " timer: " + elapse);
}

function minToTime(min){
  let h = Math.floor(min / 60);
  let m = min % 60;
  return leadZeros(h, 2) + ":" + leadZeros(m, 2);
}

function getRandomInt(){
  let array= new Uint8Array(4);
  crypto.getRandomValues(array);
  return (new DataView(array.buffer)).getUint32()
}

var fiveBits="0123456789ACDEFGHJKLMNPQRTUVWXYZ"//BIOS removed

function intToFiveBit(val){
  let chars=[]
  for(let i=5;i>=0;i--){
//     cl((val>>(5*i))&0x1F)
    chars.push(fiveBits[(val>>(5*i))&0x1F])
  }
  return chars.join("")
}

function fiveBitsToInt(str){
  let res=0
  for (let i=0;i<6;i++){
    let pos=fiveBits.indexOf(str[5-i])
//     cl(pos)
    res|=(pos<<(5*i))
  }
//   cl(res)
  return res
}

function getRandomString(len){
  let bLen=Math.ceil(6*len/8)
  let array= new Uint8Array(bLen);
  crypto.getRandomValues(array);
  let str=""
  array.forEach(a=>{
    str+=String.fromCharCode(a)
  })
  return btoa(str).substr(0, len)// substitutes $,@ for /,+
    .replace(/\//g, "+")// tried ! and # - no dice!, no '_', '@', 
    .replace(/\+/g, "*")// was '@'
//   str2+="abc/def"
//   let str3=str2.replace(/\//g, "!")
//   cl(str3)
// // illegal: NUL, \, /, :, *, ", <, >, |   
//   return str3
}

function getPWString(len){
  return getRandomString(len)
    .toUpperCase()
    .replace(/O/g, "0")
    .replace(/Q/g, "0")
    .replace(/I/g, "1")
    .replace(/L/g, "1")
    .replace(/S/g, "5")
    .replace(/Z/g, "2")
    .replace(/\*/g, "8")
    .replace(/_/g, "9")
}

var az=(val,ze)=>{
  let str="000000"+val
  return str.substr(str.length-ze)
}

var versions=[]

var showVersion=(obj)=>{
  var i
  for(i=0 ; i<versions.length; i++){
    if(obj==versions[i]){
//       return cl(`Object version: ${i}`)
    }
  }
  versions.push(obj);
//   return cl(`Object version: ${i}`)
}

var userSpecificHome=()=>{
  let homes={
    "oimKAELLXcyTZBsV": "/usa/dash/yen_vz4sci_m-iHC9nQZ",
    "w8lgwlsDFyt4L4Yz": "/usa/dash/u1_vz4sci_m-iHC9nQZ",
    "k2jTXK2ejpzxqxBV": "/usa/dash/u2_vz4sci_m-iHC9nQZ",
    "z8K_qcij1Bt8tAT8": "/usa/dash/u3_vz4sci_m-iHC9nQZ",
    "3hRZJgBx2_rNEIhd":  "/usa/dash/u3_vz4sci_m-iHC9nQZ",
    "A1pjnxXqEOhfpPcy":  "/usa/dash/u3_vz4sci_m-iHC9nQZ",
    "-QCIsBzi6-dV1GO7":  "/usa/dash/u3_vz4sci_m-iHC9nQZ",

    
    "xRYyQVrJEVxVtU3B": "/usa/dash/u4_vz4sci_m-iHC9nQZ",
    "7r22cX-CBioQjq5y": "/usa/dash/vz4sci_m-iHC9nQZ",
  }
//   cl(globs.userData.session.userId)
  let url=homes[globs.userData.session.userId]
  history.push(url)
  return url
}

var timedOut=(type)=>{
  console.trace()
  alert(`Unable to load ${type} data`)
}

var setRestTimeout=(type, prom)=>{
//   cl(`Request ${type}`)
  let to=setTimeout(r=>{timedOut(type)}, 10000)
  prom.then(r=>{
//     cl(`Got ${type} data`)
    clearTimeout(to)
  })
}

var deepCopy=(obj)=>{
  if((obj===null)||(typeof(obj)!=='object')){return obj}
  let ret=(obj instanceof Date)? new obj.constructor() : obj.constructor()
  for (let k in obj){
    if(obj.hasOwnProperty(k)){
//       cl(k,obj[k])
      ret[k]=deepCopy(obj[k])
    }
  }
//   cl(obj)
//   cl(ret)
  return ret
}

var deepCompare=(obj, cmp)=>{
  if((obj===null)||(typeof(obj)!=='object')){
//     if(obj!==cmp){cl([obj,cmp])}
    return obj===cmp
  }
  for (let k in obj){
    if(obj.hasOwnProperty(k)){
      if(!deepCompare(obj[k], cmp[k])){
//         cl(obj[k])
//         cl(cmp[k])
        return false
      }
    }
  }
  return true
}

var arrToFlags=(arr)=>{
    let flags=0
    arr.forEach(a=>{flags=(a)?2*flags+1:2*flags})
    return flags
  }
  
var flagsToArr=(flags)=>{
    let arr=[]
    while(flags){arr.unshift((flags&0x01)?1:0);flags=flags>>1}
    return arr
  }

var copyToClipboard=async(text)=>{
  let res = await navigator.clipboard?.writeText(text).then(function() {
    cl('Async: Copying to clipboard was successful!');
    return true
  }, function(err) {
    cl('Async: Could not copy text: ', err);
    return false
  })
  return res
}

var allCountries = 
[
  {
    "country": "United States",
    "cca2": "US",
    "cca3": "USA",
    "ccn3": 840
  },
  {
    "country": "Afghanistan",
    "cca2": "AF",
    "cca3": "AFG",
    "ccn3": 4
  },
  {
    "country": "Albania",
    "cca2": "AL",
    "cca3": "ALB",
    "ccn3": 8
  },
  {
    "country": "Algeria",
    "cca2": "DZ",
    "cca3": "DZA",
    "ccn3": 12
  },
  {
    "country": "American Samoa",
    "cca2": "AS",
    "cca3": "ASM",
    "ccn3": 16
  },
  {
    "country": "Andorra",
    "cca2": "AD",
    "cca3": "AND",
    "ccn3": 20
  },
  {
    "country": "Angola",
    "cca2": "AO",
    "cca3": "AGO",
    "ccn3": 24
  },
  {
    "country": "Anguilla",
    "cca2": "AI",
    "cca3": "AIA",
    "ccn3": 660
  },
  {
    "country": "Antigua and Barbuda",
    "cca2": "AG",
    "cca3": "ATG",
    "ccn3": 28
  },
  {
    "country": "Argentina",
    "cca2": "AR",
    "cca3": "ARG",
    "ccn3": 32
  },
  {
    "country": "Armenia",
    "cca2": "AM",
    "cca3": "ARM",
    "ccn3": 51
  },
  {
    "country": "Aruba",
    "cca2": "AW",
    "cca3": "ABW",
    "ccn3": 533
  },
  {
    "country": "Australia",
    "cca2": "AU",
    "cca3": "AUS",
    "ccn3": 36
  },
  {
    "country": "Austria",
    "cca2": "AT",
    "cca3": "AUT",
    "ccn3": 40
  },
  {
    "country": "Azerbaijan",
    "cca2": "AZ",
    "cca3": "AZE",
    "ccn3": 31
  },
  {
    "country": "Bahamas",
    "cca2": "BS",
    "cca3": "BHS",
    "ccn3": 44
  },
  {
    "country": "Bahrain",
    "cca2": "BH",
    "cca3": "BHR",
    "ccn3": 48
  },
  {
    "country": "Bangladesh",
    "cca2": "BD",
    "cca3": "BGD",
    "ccn3": 50
  },
  {
    "country": "Barbados",
    "cca2": "BB",
    "cca3": "BRB",
    "ccn3": 52
  },
  {
    "country": "Belarus",
    "cca2": "BY",
    "cca3": "BLR",
    "ccn3": 112
  },
  {
    "country": "Belgium",
    "cca2": "BE",
    "cca3": "BEL",
    "ccn3": 56
  },
  {
    "country": "Belize",
    "cca2": "BZ",
    "cca3": "BLZ",
    "ccn3": 84
  },
  {
    "country": "Benin",
    "cca2": "BJ",
    "cca3": "BEN",
    "ccn3": 204
  },
  {
    "country": "Bermuda",
    "cca2": "BM",
    "cca3": "BMU",
    "ccn3": 60
  },
  {
    "country": "Bhutan",
    "cca2": "BT",
    "cca3": "BTN",
    "ccn3": 64
  },
  {
    "country": "Bolivia",
    "cca2": "BO",
    "cca3": "BOL",
    "ccn3": 68
  },
  {
    "country": "Bosnia and Herzegovina",
    "cca2": "BA",
    "cca3": "BIH",
    "ccn3": 70
  },
  {
    "country": "Botswana",
    "cca2": "BW",
    "cca3": "BWA",
    "ccn3": 72
  },
  {
    "country": "Brazil",
    "cca2": "BR",
    "cca3": "BRA",
    "ccn3": 76
  },
  {
    "country": "British Virgin Islands",
    "cca2": "VG",
    "cca3": "VGB",
    "ccn3": 92
  },
  {
    "country": "Brunei",
    "cca2": "BN",
    "cca3": "BRN",
    "ccn3": 96
  },
  {
    "country": "Bulgaria",
    "cca2": "BG",
    "cca3": "BGR",
    "ccn3": 100
  },
  {
    "country": "Burkina Faso",
    "cca2": "BF",
    "cca3": "BFA",
    "ccn3": 854
  },
  {
    "country": "Burundi",
    "cca2": "BI",
    "cca3": "BDI",
    "ccn3": 108
  },
  {
    "country": "Cambodia",
    "cca2": "KH",
    "cca3": "KHM",
    "ccn3": 116
  },
  {
    "country": "Cameroon",
    "cca2": "CM",
    "cca3": "CMR",
    "ccn3": 120
  },
  {
    "country": "Canada",
    "cca2": "CA",
    "cca3": "CAN",
    "ccn3": 124
  },
  {
    "country": "Cape Verde",
    "cca2": "CV",
    "cca3": "CPV",
    "ccn3": 132
  },
  {
    "country": "Cayman Islands",
    "cca2": "KY",
    "cca3": "CYM",
    "ccn3": 136
  },
  {
    "country": "Central African Republic",
    "cca2": "CF",
    "cca3": "CAF",
    "ccn3": 140
  },
  {
    "country": "Chad",
    "cca2": "TD",
    "cca3": "TCD",
    "ccn3": 148
  },
  {
    "country": "Chile",
    "cca2": "CL",
    "cca3": "CHL",
    "ccn3": 152
  },
  {
    "country": "China",
    "cca2": "CN",
    "cca3": "CHN",
    "ccn3": 156
  },
  {
    "country": "Colombia",
    "cca2": "CO",
    "cca3": "COL",
    "ccn3": 170
  },
  {
    "country": "Comoros",
    "cca2": "KM",
    "cca3": "COM",
    "ccn3": 174
  },
  {
    "country": "Cook Islands",
    "cca2": "CK",
    "cca3": "COK",
    "ccn3": 184
  },
  {
    "country": "Costa Rica",
    "cca2": "CR",
    "cca3": "CRI",
    "ccn3": 188
  },
  {
    "country": "Croatia",
    "cca2": "HR",
    "cca3": "HRV",
    "ccn3": 191
  },
  {
    "country": "Cuba",
    "cca2": "CU",
    "cca3": "CUB",
    "ccn3": 192
  },
  {
    "country": "Curacao",
    "cca2": "CW",
    "cca3": "CUW",
    "ccn3": 531
  },
  {
    "country": "Cyprus",
    "cca2": "CY",
    "cca3": "CYP",
    "ccn3": 196
  },
  {
    "country": "Czech Republic",
    "cca2": "CZ",
    "cca3": "CZE",
    "ccn3": 203
  },
  {
    "country": "Denmark",
    "cca2": "DK",
    "cca3": "DNK",
    "ccn3": 208
  },
  {
    "country": "Djibouti",
    "cca2": "DJ",
    "cca3": "DJI",
    "ccn3": 262
  },
  {
    "country": "Dominica",
    "cca2": "DM",
    "cca3": "DMA",
    "ccn3": 212
  },
  {
    "country": "Dominican Republic",
    "cca2": "DO",
    "cca3": "DOM",
    "ccn3": 214
  },
  {
    "country": "DR Congo",
    "cca2": "CD",
    "cca3": "COD",
    "ccn3": 180
  },
  {
    "country": "Ecuador",
    "cca2": "EC",
    "cca3": "ECU",
    "ccn3": 218
  },
  {
    "country": "Egypt",
    "cca2": "EG",
    "cca3": "EGY",
    "ccn3": 818
  },
  {
    "country": "El Salvador",
    "cca2": "SV",
    "cca3": "SLV",
    "ccn3": 222
  },
  {
    "country": "Equatorial Guinea",
    "cca2": "GQ",
    "cca3": "GNQ",
    "ccn3": 226
  },
  {
    "country": "Eritrea",
    "cca2": "ER",
    "cca3": "ERI",
    "ccn3": 232
  },
  {
    "country": "Estonia",
    "cca2": "EE",
    "cca3": "EST",
    "ccn3": 233
  },
  {
    "country": "Eswatini",
    "cca2": "SZ",
    "cca3": "SWZ",
    "ccn3": 748
  },
  {
    "country": "Ethiopia",
    "cca2": "ET",
    "cca3": "ETH",
    "ccn3": 231
  },
  {
    "country": "Falkland Islands",
    "cca2": "FK",
    "cca3": "FLK",
    "ccn3": 238
  },
  {
    "country": "Faroe Islands",
    "cca2": "FO",
    "cca3": "FRO",
    "ccn3": 234
  },
  {
    "country": "Fiji",
    "cca2": "FJ",
    "cca3": "FJI",
    "ccn3": 242
  },
  {
    "country": "Finland",
    "cca2": "FI",
    "cca3": "FIN",
    "ccn3": 246
  },
  {
    "country": "France",
    "cca2": "FR",
    "cca3": "FRA",
    "ccn3": 250
  },
  {
    "country": "French Guiana",
    "cca2": "GF",
    "cca3": "GUF",
    "ccn3": 254
  },
  {
    "country": "French Polynesia",
    "cca2": "PF",
    "cca3": "PYF",
    "ccn3": 258
  },
  {
    "country": "Gabon",
    "cca2": "GA",
    "cca3": "GAB",
    "ccn3": 266
  },
  {
    "country": "Gambia",
    "cca2": "GM",
    "cca3": "GMB",
    "ccn3": 270
  },
  {
    "country": "Georgia",
    "cca2": "GE",
    "cca3": "GEO",
    "ccn3": 268
  },
  {
    "country": "Germany",
    "cca2": "DE",
    "cca3": "DEU",
    "ccn3": 276
  },
  {
    "country": "Ghana",
    "cca2": "GH",
    "cca3": "GHA",
    "ccn3": 288
  },
  {
    "country": "Gibraltar",
    "cca2": "GI",
    "cca3": "GIB",
    "ccn3": 292
  },
  {
    "country": "Greece",
    "cca2": "GR",
    "cca3": "GRC",
    "ccn3": 300
  },
  {
    "country": "Greenland",
    "cca2": "GL",
    "cca3": "GRL",
    "ccn3": 304
  },
  {
    "country": "Grenada",
    "cca2": "GD",
    "cca3": "GRD",
    "ccn3": 308
  },
  {
    "country": "Guadeloupe",
    "cca2": "GP",
    "cca3": "GLP",
    "ccn3": 312
  },
  {
    "country": "Guam",
    "cca2": "GU",
    "cca3": "GUM",
    "ccn3": 316
  },
  {
    "country": "Guatemala",
    "cca2": "GT",
    "cca3": "GTM",
    "ccn3": 320
  },
  {
    "country": "Guinea",
    "cca2": "GN",
    "cca3": "GIN",
    "ccn3": 324
  },
  {
    "country": "Guinea-Bissau",
    "cca2": "GW",
    "cca3": "GNB",
    "ccn3": 624
  },
  {
    "country": "Guyana",
    "cca2": "GY",
    "cca3": "GUY",
    "ccn3": 328
  },
  {
    "country": "Haiti",
    "cca2": "HT",
    "cca3": "HTI",
    "ccn3": 332
  },
  {
    "country": "Honduras",
    "cca2": "HN",
    "cca3": "HND",
    "ccn3": 340
  },
  {
    "country": "Hong Kong",
    "cca2": "HK",
    "cca3": "HKG",
    "ccn3": 344
  },
  {
    "country": "Hungary",
    "cca2": "HU",
    "cca3": "HUN",
    "ccn3": 348
  },
  {
    "country": "Iceland",
    "cca2": "IS",
    "cca3": "ISL",
    "ccn3": 352
  },
  {
    "country": "India",
    "cca2": "IN",
    "cca3": "IND",
    "ccn3": 356
  },
  {
    "country": "Indonesia",
    "cca2": "ID",
    "cca3": "IDN",
    "ccn3": 360
  },
  {
    "country": "Iran",
    "cca2": "IR",
    "cca3": "IRN",
    "ccn3": 364
  },
  {
    "country": "Iraq",
    "cca2": "IQ",
    "cca3": "IRQ",
    "ccn3": 368
  },
  {
    "country": "Ireland",
    "cca2": "IE",
    "cca3": "IRL",
    "ccn3": 372
  },
  {
    "country": "Isle of Man",
    "cca2": "IM",
    "cca3": "IMN",
    "ccn3": 833
  },
  {
    "country": "Israel",
    "cca2": "IL",
    "cca3": "ISR",
    "ccn3": 376
  },
  {
    "country": "Italy",
    "cca2": "IT",
    "cca3": "ITA",
    "ccn3": 380
  },
  {
    "country": "Ivory Coast",
    "cca2": "CI",
    "cca3": "CIV",
    "ccn3": 384
  },
  {
    "country": "Jamaica",
    "cca2": "JM",
    "cca3": "JAM",
    "ccn3": 388
  },
  {
    "country": "Japan",
    "cca2": "JP",
    "cca3": "JPN",
    "ccn3": 392
  },
  {
    "country": "Jordan",
    "cca2": "JO",
    "cca3": "JOR",
    "ccn3": 400
  },
  {
    "country": "Kazakhstan",
    "cca2": "KZ",
    "cca3": "KAZ",
    "ccn3": 398
  },
  {
    "country": "Kenya",
    "cca2": "KE",
    "cca3": "KEN",
    "ccn3": 404
  },
  {
    "country": "Kiribati",
    "cca2": "KI",
    "cca3": "KIR",
    "ccn3": 296
  },
  {
    "country": "Kuwait",
    "cca2": "KW",
    "cca3": "KWT",
    "ccn3": 414
  },
  {
    "country": "Kyrgyzstan",
    "cca2": "KG",
    "cca3": "KGZ",
    "ccn3": 417
  },
  {
    "country": "Laos",
    "cca2": "LA",
    "cca3": "LAO",
    "ccn3": 418
  },
  {
    "country": "Latvia",
    "cca2": "LV",
    "cca3": "LVA",
    "ccn3": 428
  },
  {
    "country": "Lebanon",
    "cca2": "LB",
    "cca3": "LBN",
    "ccn3": 422
  },
  {
    "country": "Lesotho",
    "cca2": "LS",
    "cca3": "LSO",
    "ccn3": 426
  },
  {
    "country": "Liberia",
    "cca2": "LR",
    "cca3": "LBR",
    "ccn3": 430
  },
  {
    "country": "Libya",
    "cca2": "LY",
    "cca3": "LBY",
    "ccn3": 434
  },
  {
    "country": "Liechtenstein",
    "cca2": "LI",
    "cca3": "LIE",
    "ccn3": 438
  },
  {
    "country": "Lithuania",
    "cca2": "LT",
    "cca3": "LTU",
    "ccn3": 440
  },
  {
    "country": "Luxembourg",
    "cca2": "LU",
    "cca3": "LUX",
    "ccn3": 442
  },
  {
    "country": "Macau",
    "cca2": "MO",
    "cca3": "MAC",
    "ccn3": 446
  },
  {
    "country": "Madagascar",
    "cca2": "MG",
    "cca3": "MDG",
    "ccn3": 450
  },
  {
    "country": "Malawi",
    "cca2": "MW",
    "cca3": "MWI",
    "ccn3": 454
  },
  {
    "country": "Malaysia",
    "cca2": "MY",
    "cca3": "MYS",
    "ccn3": 458
  },
  {
    "country": "Maldives",
    "cca2": "MV",
    "cca3": "MDV",
    "ccn3": 462
  },
  {
    "country": "Mali",
    "cca2": "ML",
    "cca3": "MLI",
    "ccn3": 466
  },
  {
    "country": "Malta",
    "cca2": "MT",
    "cca3": "MLT",
    "ccn3": 470
  },
  {
    "country": "Marshall Islands",
    "cca2": "MH",
    "cca3": "MHL",
    "ccn3": 584
  },
  {
    "country": "Martinique",
    "cca2": "MQ",
    "cca3": "MTQ",
    "ccn3": 474
  },
  {
    "country": "Mauritania",
    "cca2": "MR",
    "cca3": "MRT",
    "ccn3": 478
  },
  {
    "country": "Mauritius",
    "cca2": "MU",
    "cca3": "MUS",
    "ccn3": 480
  },
  {
    "country": "Mayotte",
    "cca2": "YT",
    "cca3": "MYT",
    "ccn3": 175
  },
  {
    "country": "Mexico",
    "cca2": "MX",
    "cca3": "MEX",
    "ccn3": 484
  },
  {
    "country": "Micronesia",
    "cca2": "FM",
    "cca3": "FSM",
    "ccn3": 583
  },
  {
    "country": "Moldova",
    "cca2": "MD",
    "cca3": "MDA",
    "ccn3": 498
  },
  {
    "country": "Monaco",
    "cca2": "MC",
    "cca3": "MCO",
    "ccn3": 492
  },
  {
    "country": "Mongolia",
    "cca2": "MN",
    "cca3": "MNG",
    "ccn3": 496
  },
  {
    "country": "Montenegro",
    "cca2": "ME",
    "cca3": "MNE",
    "ccn3": 499
  },
  {
    "country": "Montserrat",
    "cca2": "MS",
    "cca3": "MSR",
    "ccn3": 500
  },
  {
    "country": "Morocco",
    "cca2": "MA",
    "cca3": "MAR",
    "ccn3": 504
  },
  {
    "country": "Mozambique",
    "cca2": "MZ",
    "cca3": "MOZ",
    "ccn3": 508
  },
  {
    "country": "Myanmar",
    "cca2": "MM",
    "cca3": "MMR",
    "ccn3": 104
  },
  {
    "country": "Namibia",
    "cca2": "NA",
    "cca3": "NAM",
    "ccn3": 516
  },
  {
    "country": "Nauru",
    "cca2": "NR",
    "cca3": "NRU",
    "ccn3": 520
  },
  {
    "country": "Nepal",
    "cca2": "NP",
    "cca3": "NPL",
    "ccn3": 524
  },
  {
    "country": "Netherlands",
    "cca2": "NL",
    "cca3": "NLD",
    "ccn3": 528
  },
  {
    "country": "New Caledonia",
    "cca2": "NC",
    "cca3": "NCL",
    "ccn3": 540
  },
  {
    "country": "New Zealand",
    "cca2": "NZ",
    "cca3": "NZL",
    "ccn3": 554
  },
  {
    "country": "Nicaragua",
    "cca2": "NI",
    "cca3": "NIC",
    "ccn3": 558
  },
  {
    "country": "Niger",
    "cca2": "NE",
    "cca3": "NER",
    "ccn3": 562
  },
  {
    "country": "Nigeria",
    "cca2": "NG",
    "cca3": "NGA",
    "ccn3": 566
  },
  {
    "country": "Niue",
    "cca2": "NU",
    "cca3": "NIU",
    "ccn3": 570
  },
  {
    "country": "North Korea",
    "cca2": "KP",
    "cca3": "PRK",
    "ccn3": 408
  },
  {
    "country": "North Macedonia",
    "cca2": "MK",
    "cca3": "MKD",
    "ccn3": 807
  },
  {
    "country": "Northern Mariana Islands",
    "cca2": "MP",
    "cca3": "MNP",
    "ccn3": 580
  },
  {
    "country": "Norway",
    "cca2": "NO",
    "cca3": "NOR",
    "ccn3": 578
  },
  {
    "country": "Oman",
    "cca2": "OM",
    "cca3": "OMN",
    "ccn3": 512
  },
  {
    "country": "Pakistan",
    "cca2": "PK",
    "cca3": "PAK",
    "ccn3": 586
  },
  {
    "country": "Palau",
    "cca2": "PW",
    "cca3": "PLW",
    "ccn3": 585
  },
  {
    "country": "Palestine",
    "cca2": "PS",
    "cca3": "PSE",
    "ccn3": 275
  },
  {
    "country": "Panama",
    "cca2": "PA",
    "cca3": "PAN",
    "ccn3": 591
  },
  {
    "country": "Papua New Guinea",
    "cca2": "PG",
    "cca3": "PNG",
    "ccn3": 598
  },
  {
    "country": "Paraguay",
    "cca2": "PY",
    "cca3": "PRY",
    "ccn3": 600
  },
  {
    "country": "Peru",
    "cca2": "PE",
    "cca3": "PER",
    "ccn3": 604
  },
  {
    "country": "Philippines",
    "cca2": "PH",
    "cca3": "PHL",
    "ccn3": 608
  },
  {
    "country": "Poland",
    "cca2": "PL",
    "cca3": "POL",
    "ccn3": 616
  },
  {
    "country": "Portugal",
    "cca2": "PT",
    "cca3": "PRT",
    "ccn3": 620
  },
  {
    "country": "Puerto Rico",
    "cca2": "PR",
    "cca3": "PRI",
    "ccn3": 630
  },
  {
    "country": "Qatar",
    "cca2": "QA",
    "cca3": "QAT",
    "ccn3": 634
  },
  {
    "country": "Republic of the Congo",
    "cca2": "CG",
    "cca3": "COG",
    "ccn3": 178
  },
  {
    "country": "Reunion",
    "cca2": "RE",
    "cca3": "REU",
    "ccn3": 638
  },
  {
    "country": "Romania",
    "cca2": "RO",
    "cca3": "ROU",
    "ccn3": 642
  },
  {
    "country": "Russia",
    "cca2": "RU",
    "cca3": "RUS",
    "ccn3": 643
  },
  {
    "country": "Rwanda",
    "cca2": "RW",
    "cca3": "RWA",
    "ccn3": 646
  },
  {
    "country": "Saint Barthelemy",
    "cca2": "BL",
    "cca3": "BLM",
    "ccn3": 652
  },
  {
    "country": "Saint Kitts and Nevis",
    "cca2": "KN",
    "cca3": "KNA",
    "ccn3": 659
  },
  {
    "country": "Saint Lucia",
    "cca2": "LC",
    "cca3": "LCA",
    "ccn3": 662
  },
  {
    "country": "Saint Martin",
    "cca2": "MF",
    "cca3": "MAF",
    "ccn3": 663
  },
  {
    "country": "Saint Pierre and Miquelon",
    "cca2": "PM",
    "cca3": "SPM",
    "ccn3": 666
  },
  {
    "country": "Saint Vincent and the Grenadines",
    "cca2": "VC",
    "cca3": "VCT",
    "ccn3": 670
  },
  {
    "country": "Samoa",
    "cca2": "WS",
    "cca3": "WSM",
    "ccn3": 882
  },
  {
    "country": "San Marino",
    "cca2": "SM",
    "cca3": "SMR",
    "ccn3": 674
  },
  {
    "country": "Sao Tome and Principe",
    "cca2": "ST",
    "cca3": "STP",
    "ccn3": 678
  },
  {
    "country": "Saudi Arabia",
    "cca2": "SA",
    "cca3": "SAU",
    "ccn3": 682
  },
  {
    "country": "Senegal",
    "cca2": "SN",
    "cca3": "SEN",
    "ccn3": 686
  },
  {
    "country": "Serbia",
    "cca2": "RS",
    "cca3": "SRB",
    "ccn3": 688
  },
  {
    "country": "Seychelles",
    "cca2": "SC",
    "cca3": "SYC",
    "ccn3": 690
  },
  {
    "country": "Sierra Leone",
    "cca2": "SL",
    "cca3": "SLE",
    "ccn3": 694
  },
  {
    "country": "Singapore",
    "cca2": "SG",
    "cca3": "SGP",
    "ccn3": 702
  },
  {
    "country": "Sint Maarten",
    "cca2": "SX",
    "cca3": "SXM",
    "ccn3": 534
  },
  {
    "country": "Slovakia",
    "cca2": "SK",
    "cca3": "SVK",
    "ccn3": 703
  },
  {
    "country": "Slovenia",
    "cca2": "SI",
    "cca3": "SVN",
    "ccn3": 705
  },
  {
    "country": "Solomon Islands",
    "cca2": "SB",
    "cca3": "SLB",
    "ccn3": 90
  },
  {
    "country": "Somalia",
    "cca2": "SO",
    "cca3": "SOM",
    "ccn3": 706
  },
  {
    "country": "South Africa",
    "cca2": "ZA",
    "cca3": "ZAF",
    "ccn3": 710
  },
  {
    "country": "South Korea",
    "cca2": "KR",
    "cca3": "KOR",
    "ccn3": 410
  },
  {
    "country": "South Sudan",
    "cca2": "SS",
    "cca3": "SSD",
    "ccn3": 728
  },
  {
    "country": "Spain",
    "cca2": "ES",
    "cca3": "ESP",
    "ccn3": 724
  },
  {
    "country": "Sri Lanka",
    "cca2": "LK",
    "cca3": "LKA",
    "ccn3": 144
  },
  {
    "country": "Sudan",
    "cca2": "SD",
    "cca3": "SDN",
    "ccn3": 729
  },
  {
    "country": "Suriname",
    "cca2": "SR",
    "cca3": "SUR",
    "ccn3": 740
  },
  {
    "country": "Sweden",
    "cca2": "SE",
    "cca3": "SWE",
    "ccn3": 752
  },
  {
    "country": "Switzerland",
    "cca2": "CH",
    "cca3": "CHE",
    "ccn3": 756
  },
  {
    "country": "Syria",
    "cca2": "SY",
    "cca3": "SYR",
    "ccn3": 760
  },
  {
    "country": "Taiwan",
    "cca2": "TW",
    "cca3": "TWN",
    "ccn3": 158
  },
  {
    "country": "Tajikistan",
    "cca2": "TJ",
    "cca3": "TJK",
    "ccn3": 762
  },
  {
    "country": "Tanzania",
    "cca2": "TZ",
    "cca3": "TZA",
    "ccn3": 834
  },
  {
    "country": "Thailand",
    "cca2": "TH",
    "cca3": "THA",
    "ccn3": 764
  },
  {
    "country": "Timor-Leste",
    "cca2": "TL",
    "cca3": "TLS",
    "ccn3": 626
  },
  {
    "country": "Togo",
    "cca2": "TG",
    "cca3": "TGO",
    "ccn3": 768
  },
  {
    "country": "Tokelau",
    "cca2": "TK",
    "cca3": "TKL",
    "ccn3": 772
  },
  {
    "country": "Tonga",
    "cca2": "TO",
    "cca3": "TON",
    "ccn3": 776
  },
  {
    "country": "Trinidad and Tobago",
    "cca2": "TT",
    "cca3": "TTO",
    "ccn3": 780
  },
  {
    "country": "Tunisia",
    "cca2": "TN",
    "cca3": "TUN",
    "ccn3": 788
  },
  {
    "country": "Turkey",
    "cca2": "TR",
    "cca3": "TUR",
    "ccn3": 792
  },
  {
    "country": "Turkmenistan",
    "cca2": "TM",
    "cca3": "TKM",
    "ccn3": 795
  },
  {
    "country": "Turks and Caicos Islands",
    "cca2": "TC",
    "cca3": "TCA",
    "ccn3": 796
  },
  {
    "country": "Tuvalu",
    "cca2": "TV",
    "cca3": "TUV",
    "ccn3": 798
  },
  {
    "country": "Uganda",
    "cca2": "UG",
    "cca3": "UGA",
    "ccn3": 800
  },
  {
    "country": "Ukraine",
    "cca2": "UA",
    "cca3": "UKR",
    "ccn3": 804
  },
  {
    "country": "United Arab Emirates",
    "cca2": "AE",
    "cca3": "ARE",
    "ccn3": 784
  },
  {
    "country": "United Kingdom",
    "cca2": "GB",
    "cca3": "GBR",
    "ccn3": 826
  },
  {
    "country": "United States Virgin Islands",
    "cca2": "VI",
    "cca3": "VIR",
    "ccn3": 850
  },
  {
    "country": "Uruguay",
    "cca2": "UY",
    "cca3": "URY",
    "ccn3": 858
  },
  {
    "country": "Uzbekistan",
    "cca2": "UZ",
    "cca3": "UZB",
    "ccn3": 860
  },
  {
    "country": "Vanuatu",
    "cca2": "VU",
    "cca3": "VUT",
    "ccn3": 548
  },
  {
    "country": "Vatican City",
    "cca2": "VA",
    "cca3": "VAT",
    "ccn3": 336
  },
  {
    "country": "Venezuela",
    "cca2": "VE",
    "cca3": "VEN",
    "ccn3": 862
  },
  {
    "country": "Vietnam",
    "cca2": "VN",
    "cca3": "VNM",
    "ccn3": 704
  },
  {
    "country": "Wallis and Futuna",
    "cca2": "WF",
    "cca3": "WLF",
    "ccn3": 876
  },
  {
    "country": "Western Sahara",
    "cca2": "EH",
    "cca3": "ESH",
    "ccn3": 732
  },
  {
    "country": "Yemen",
    "cca2": "YE",
    "cca3": "YEM",
    "ccn3": 887
  },
  {
    "country": "Zambia",
    "cca2": "ZM",
    "cca3": "ZMB",
    "ccn3": 894
  },
  {
    "country": "Zimbabwe",
    "cca2": "ZW",
    "cca3": "ZWE",
    "ccn3": 716
  }
]

var allStates = [
 'AL','AK','AS','AZ','AR','CA','CO','CT','DE','DC','FM','FL','GA',
 'GU','HI','ID','IL','IN','IA','KS','KY','LA','ME','MH','MD','MA',
 'MI','MN','MS','MO','MT','NE','NV','NH','NJ','NM','NY','NC','ND',
 'MP','OH','OK','OR','PW','PA','PR','RI','SC','SD','TN','TX','UT',
 'VT','VI','VA','WA','WV','WI','WY'
]

var allTimezones = 
[
  "Pacific/Auckland",//+12*60
  "Pacific/Midway",
  "Pacific/Honolulu",
  "America/Anchorage",
  "America/Los_Angeles",//+8*60
  "America/Denver",
  "America/Chicago",
  "America/New_York",
  "America/Antigua",
  "America/Buenos_Aires",
  "Atlantic/Stanley",
  "Atlantic/Azores",
  "Europe/London",//+0
  "Europe/Berlin",//-1*60
  "Europe/Athens",
  "Europe/Moscow",
  "Europe/Volgograd",
  "Asia/Karachi",
  "Asia/Yekaterinburg",
  "Asia/Bangkok",
  "Asia/Hong_Kong",
  "Asia/Tokyo",
  "Australia/Brisbane",
  "Asia/Vladivostok",//-11*60
  "Pacific/Auckland"
]

let allThemes = [
      ["Original Light", "originalLight"],
      ["Original Dark", "originalDark"]
]

// let allZones = {
//   "zone": {"free": 0, "basic": 34.95, "professional": 9.99, "irrigation": 3.99}
// }

let zoneTypes = {
  "locked": "locked",
  "unlocked": "unlocked",
  "disabled": "disabled"
}

let allZones = {
  // inactive is now locked
  // basic is now unlocked
  // "zone": {"basic": 34.95, "inactive": 0, "disabled": 0, }
  "zone": {"unlocked": 32.95, "unlocked_protect": 13.45, "locked": 0, "disabled": 0,},
}

let zoneQtyDiscount = {
  "standard": [7, 13],
  "protect": [2.2, 4.5]
}

let allAddons = {
  "API Access" : 11.99,
}

let allModuleTypes=[
  {type:constant.MB_SLAVE_TYPE_SENSOR_MODULE,
    name:"ADISM (Pearl)",
    id:"dismp",},
  {type:constant.MB_SLAVE_TYPE_SENSOR_MODULE,
    name:"ADISM-RS (iGrow)",
    id:"dismprs",},
//   {type:constant.MB_SLAVE_TYPE_ANALOG_8IN_8OUT,
//     name:"8 Input/8 Output",
//     id:"8i8o",},
  {type:constant.MB_SLAVE_TYPE_DTH_5,
    name:"DTH5 (G0)",
    id:"dth5G0",},
  // {type:constant.MB_SLAVE_TYPE_DTH_5,
  //   name:"DTH5 (L0)",
  //   id:"dth5L0",},
  {type:constant.MB_SLAVE_TYPE_ANALOG_7IN_DHT,
    name:"WS 5 Input",
    id:"ws5in",},
  // {type:constant.MB_SLAVE_TYPE_ANALOG_7IN_DHT,
  //   name:"WS 5 Input Test",
  //   id:"ws5int",},
  {type:constant.MB_SLAVE_TYPE_8OUT_DTH,
    name:"8 Output/I2C",
    id:"8outI2C-DS",},
  {type:constant.MB_SLAVE_TYPE_ANALOG_7IN_DHT,
    name:"1U 5 Input",
    id:"1uin",},
  {type:constant.MB_SLAVE_TYPE_4A_4D_OUTPUT,
    name:"4 Analog/4 Digital",
    id:"4a4d",},
  {type:constant.MB_SLAVE_TYPE_4D_DTH,
    name:"1U 4 Output",
    id:"1u4out",},
  {type:constant.MB_SLAVE_TYPE_4A_DTH,
    name:"1U 4 Analog Output",
    id:"1u4aout",},
  {type:constant.MB_SLAVE_TYPE_PEARL,
    name:"Pearl Digital",
    id:"prl",},
  {type:constant.MB_SLAVE_TYPE_PEARL_ANALOG,
    name:"Pearl Analog",
    id:"prla",},
  {type:constant.MB_SLAVE_TYPE_PEARL_ANALOG,
    name:"Pearl Protect",
    id:"prlp",},
  {type:constant.MB_SLAVE_TYPE_PEARL_ANALOG,
    name:"Pearl Bootloader",
    id:"prlboot",},

]



// let allModuleTypes = [
//   ["8 Output/I2C", "8outI2C"],
//   ["4 Analog/4 Digital", "4a4d"],
//   ["1U 4 Output", "1u4out"],
//   ["1U 5 Input", "1uin"],
//   ["DTH5 (L0)", "dth5L0"],
//   ["DTH5 (G0)", "dth5G0"],
//   ["ADISM", "dismp"],
//   ["ADISM-RS", "dismprs"],
//   ["8 Output", "8out"],
//   ["8 Input/8 Output", "8i8o"],
//   ["1U 5 Input/WS", "1uiWS"],
//   ["1U 4 Analog Out", "1u4ao"],
//   ["1U Diff Pressure", "1udfp"]
// ]

let countNumbers=["One","Two","Three","Four","Five","Six","Seven","Eight","Nine","Ten",
  "Eleven","Twelve","Thirteen","Fourteen","Fifteen","Sixteen","Seventeen",
  "Eightteen","Nineteen","Twenty",]

let capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

let round = (num) => {
  let formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
  });
  return formatter.format(num);
}

let lerp = (a, b, t) => {
  return Math.round(a + (b - a) * t)
}

let lerpf = (a, b, t) => {
  return a + (b - a) * t
}

let logerp = (a, b, t) => {
  return Math.round(a * Math.pow(b / a, t))
}

let logerpf = (a, b, t) => {
  return a * Math.pow(b / a, t)
}

let normalize = (min, max, t) => {
  return (t - min) / (max - min)
}

let interpolateColor = (a, b, t, curve="linear") => {
  let col = []
  for (let i = 0; i < 4; i++) {
    if (i == 3) {
      if (curve == "linear") {
        col[i] = lerpf(a[i], b[i], t) 
      } else if (curve == "log") {
//         cl("getting logf")
        col[i] = logerpf(a[i], b[i], t)
      }
    } else {
      if (curve == "linear") {
        col[i] = lerp(a[i], b[i], t) 
      } else if (curve == "log") {
        cl("getting log")
        col[i] = logerp(a[i], b[i], t) 
      }
    }
  }
  return `rgba(${col[0]}, ${col[1]}, ${col[2]}, ${col[3]})`
}

let recurlySubdomain = "link4cloud"

let getRecurlySuccessUrl = () => {
  let url
  switch(config.server){
    case "prod0":
      url = `https://igrow1800.link4cloud.com/usa/c18/admin/manageSubscription`
    case "dev":
      url = "http://ngsg.link4cloud.com:3104/usa/c18/admin/manageSubscription"
      break
    case "stage":
      url = "http://stage.link4cloud.com:3114/usa/c18/admin/manageSubscription"
      break
    case "alpha":
      url = "http://beta.link4cloud.com/usa/c18/admin/manageSubscription"
      break
    case "ryan":
      url = "http://ryanelk.link4cloud.com:3134/usa/c18/admin/manageSubscription"
      break
    default:
      url = "http://beta.link4cloud.com/usa/c18/admin/manageSubscription"
      break
  }
  return url
}

var colors={
  blue00:"#CCFFFF",
  green00:"#CCFFCC",
  yellow00:"#FFFFCC",
  red00:"#FFCCCC",
}

let validAppVersions = ["0.992", "0.993", "0.994", "0.995", "0.996", "0.997", "0.998", "0.999"]

var maxLen=(str,len)=>{
  if(str.length>len){str=str.substring(0,len-3)+"..."}
  return str
}

let avatarCount = 20

var makeNumNum=(x)=>{
  cl(x)
  cl(+x)
    return (isNaN(x))?x:+x
}


/********************* On Change Testing Functions ***********************************/

var ocKey=(keys)=>{
  if(!keys)return ""
//   cl(keys)
//   cl(keys.join("-"))
  return keys.join("-")
}

var saveOnChange=(keys,type,vals)=>{
  globs.curTest.events.push([ocKey(keys),type,vals])
}

var octRecord=()=>{
  cl("Preparing to record Test Sequence")
  globs.curTest.events=[]
  // window.location.reload()
}

var checkOnChangeKey=(key)=>{
//   cl(globs.keyDowns)
  if(!key){return}
  if(key=="Escape"){
    return {cmd:"showHideTestControl",val:false}
  }
  if(globs.keyDowns.Alt){
    switch(key.toUpperCase()){
      case "P":
        cl(globs.curTest)
        break
      case "R":
        octRecord()
        break
      case "T":
        return {cmd:"showHideTestControl"}
        break
      default:
        break
    }
  }
  if(globs.keyDowns.Alt&&(key=="P")){
    cl(globs.onChange)
  }
}

var delay=(ms)=>{
  return new Promise((r,e)=>{
    setTimeout(x=>{r()},ms)
  })
}

cl.oc=(that,type,vals)=>{
//   console.trace()
//   cl(that.ocKeys)
  saveOnChange(that.ocKeys,type,vals)

}

cl.oci=(that,keys,ocFunc)=>{// initialize
  that.ocKeys=keys
//   cl(keys)
  globs.curTest.comps.push({key: ocKey(keys),func:ocFunc})

}

cl.octSaveUrl=(url)=>{
  globs.curTest.url=url
}


/********************* End On Change Testing Functions ***********************************/

/************************ User File System (ufs) *****************************************/

var ufs={
  db:null,
  te:new TextEncoder(),
  td:new TextDecoder(),
  dbName:"/ufs",
  osName:"file_store",
  
  idbRequest:(obj,method,args)=>{
// used internally to convert "onsuccess", etc. to a Promise
    return new Promise((r,e)=>{
//       cl(obj)
      let res=obj[method](...(args||[]))
//       cl(res)
      res.onsuccess=(re)=>{/*cl("success");*/r(res.result)}
      res.onerror=(e)=>{cl("error",e)}
      res.onupgradeneeded=ufs.initDB// fires when database needs to be created
    })
  },
  
  getStore:(rw)=>{
    try{
      let trans=ufs.db.transaction([ufs.osName],rw)
      return trans.objectStore(ufs.osName)
    }catch{
      return
    }
  },

  get:async(path)=>{// return string
    let val=await ufs.getb(path)
//     cl(val)
    return ufs.td.decode(val)
  },

  getb:(path)=>{// return Uint8Array
    return new Promise(async(r,e)=>{
//       cl(ufs.db)
      if(!ufs.db){await ufs.openDB()}
      let store=ufs.getStore()
      if(!store){return}
//       cl(path)
      let val=await ufs.idbRequest(store,"get",[path])
      let len=val?.contents?.byteLength
      let mode=val?.mode
//       cl(`mode: ${mode}, len: ${len}`)
      r(val?.contents)
    })
  },

  set:(path,data)=>{// data must be either string or Uint8Array
    let mode={'/userfs/packs':16893, 
//       '/userfs/assets':16893, 
//       '/userfs/scenes':16893, 
//       '/userfs/scripts':16893, 
      '/userfs/packs/electric_fan.pck':33206}[path]||13
    if(typeof data=="string"){ data=ufs.te.encode(data) }
    return new Promise(async(r,e)=>{
      if(!ufs.db){await ufs.openDB()}
      let store=ufs.getStore("readwrite")
      if(!store){return}
      if((data)||(data=="")){
        let obj={timestamp:new Date(),mode:mode,contents:data}
        await ufs.idbRequest(store,"put",[obj,path])
      }else{
        cl("deleting")
        let res=await ufs.idbRequest(store,"delete",[path])
        cl(res)
      }
      r(true)
    })
  },
  
  list:(path)=>{
    return new Promise(async(r,e)=>{
      if(!ufs.db){await ufs.openDB()}
      let store=ufs.getStore()
      if(!store){r([]);return}
      let query=(path)?window.IDBKeyRange.bound(`${path}!`,`${path}~`):undefined
      let keys=await ufs.idbRequest(store,"getAllKeys",[query])
      r(keys)
    })
  },
  
  close:()=>{
    if(!ufs.db){return}
    ufs.db.close()
  },
  
  initDB:(e)=>{// create object store when db created
// for some reason, Godot needs to create the ObjectStore, *not* here
//     ufs.notCreated=true
    cl("db needs to be created!")
    // these lines were commented out, to allow Godot to create the Object Store
    cl("initting db")
//     e.target.result.createObjectStore(ufs.osName,{keyPath:"timestamp"})        
    e.target.result.createObjectStore(ufs.osName)        
  },
  
  openDB:async()=>{
//     console.trace()
    if(!ufs.db){ 
//       cl("try")
      ufs.db=await ufs.idbRequest(window.indexedDB,"open",[ufs.dbName])
//       cl("got it")
//       cl("indexedDB is open")
//       cl(ufs)
//       cl(ufs.db)

      // these lines were to allow Godot to create the db:
//       if(ufs.notCreated || (ufs.db.version<21)){
//         cl("closing db")
//         ufs.db.close()
//         ufs.db=null
//       }
      
    }
  },
}

var compress=(inp)=>{
  return new Promise((r,e)=>{
    var input=new Buffer.from(inp)// (inp, 'utf8');
    zlib.deflate(input, (er,buf)=>{
      if(er){
        e(er)
      }else{
        r(buf)
      }
      cl(er)
    })
  })
}

var uncompress=(inp)=>{// inp is a blob, returns an object
  return new Promise(async (r,e)=>{
    let inp2=await inp.arrayBuffer()//Buffer.from(msg.data)
    let inp3=Buffer(inp2)

    zlib.inflate(inp3, (er,buf)=>{
      if(er){
        e(er)
      }else{
        r(JSON.parse(buf.toString()))
      }
    })
  })
}



/************************ End User File System (ufs) *****************************************/

var groupColors=[/*"#FFAAAA",*/"#AAFFAA","#AAFFFF","#FFFFAA",
  "#FFAAFF","#FFCCAA","#AAAAFF","#CCFFAA","#AACCFF","#AAFFCC",
  "#CCAAFF",/*"#FFAACC",*/
  "#FFBBAA","#BBFFAA","#AAFFBB","#AABBFF","#BBAAFF",/*"#FFAABB",*/
  "#FFDDAA","#DDFFAA","#AAFFDD","#AADDFF","#DDAAFF","#FFAADD",
  "#FFEEAA","#EEFFAA","#AAFFEE","#AAEEFF","#EEAAFF","#FFAAEE",
]

var sendLog2Command=(cmd,clientId,ws,parms)=>{
      let obj={
        cmd:cmd,
        val:clientId,
        parms:parms,
      }
      ws.send(JSON.stringify(obj))
  }

export {constant, globs, ms, cl, getTime, getTimeI, dateToHrMinString, hrMinStringToDate, dateToLocalHrMinSecString, 
  saveLocalStorage,saveLocalStorage2,
  getLocalStorage, start, show, stop, minToTime, leadZeros, rnd, getRandomString, 
  getPWString, az, dateToYrMoDa,
  showVersion, callStack, tsToDate, dateToShortDay, dateToShortMonth, dateToDisplayDate, secsToDisplayDate, displayDateToDate,
  secsToHm,minsToHm,
  makeShortDayDateStr, secsToHms, hmsToSecs, hmToMins, userSpecificHome, setRestTimeout,
  timedOut, deepCopy, deepCompare, arrToFlags, flagsToArr,days,shortDays,months,shortMonths,weeks,
  calcTimezoneOffset, getTZName, allCountries, allStates, allTimezones, allThemes, initInfos, allZones, allAddons, colors, capitalize,
  recurlySubdomain, round, zoneTypes, getRecurlySuccessUrl, dateToMoDaYrStr, getDaysRemaining,ymdToDate, validAppVersions,ufs, lerp, interpolateColor,maxLen, normalize,
  checkOnChangeKey, avatarCount,makeNumNum,proportionals,proportionals800,secondChannels,
  uncompress,allModuleTypes,getRandomInt,
  intToFiveBit,fiveBitsToInt,groupColors,countNumbers,addNumbering,copyToClipboard,
  delay,sendLog2Command,zoneQtyDiscount
}
