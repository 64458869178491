import React, { Component } from "react";

class InfoPopover extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPopover: false,
    };
  }

  togglePopover = () => {
    this.setState((prevState) => ({
      showPopover: !prevState.showPopover,
    }));
  };

  render() {
    return (
      <span style={{ position: "relative", cursor: "pointer" }} className="infoicon-hide">
        <i
          className="material-icons-outlined info-popover"
          title="Show Full Date"
          onClick={this.togglePopover}
          tabIndex={0}
          onBlur={()=>this.setState({showPopover: false})}
        >
          info
        </i>

        {this.state.showPopover && (
          <div
            style={{
                position: "absolute",
                bottom: "25px", 
                left: "50%",
                transform: "translateX(-50%)",
                background: "#9ed1f7",
                boxShadow: "0px 0px 5px rgba(0,0,0,0.2)",
                padding: "5px 10px",
                borderRadius: "5px",
                whiteSpace: "nowrap", 
                zIndex: 100,
                fontSize: "12px",
              
            }}
          >
            {this.props.fullDate}
          </div>
        )}
      </span>
    );
  }
}

export default InfoPopover;
