import React from 'react';
// import TextField from '@material-ui/core/TextField';// import UsaIcon from './UsaIcon';
import C18MuiTextField from '../../usa/components/C18MuiTextField'
import C18CustomSelect00 from '../../usa/components/C18CustomSelect00'
import UsaIcon from '../../usa/components/UsaIcon';
import {cl, globs,az,leadZeros, deepCopy, deepCompare,constant,
  proportionals
} from '../../components/utils/utils';

const OPERAND_TYPE_1_FIELD=0
const OPERATOR_1_FIELD=1
const OPERAND_TYPE_2_FIELD=2
const OPERATOR_2_FIELD=3
const OPERAND_TYPE_3_FIELD=4
const CONDITION_SECONDS_FIELD=5
const ACTION_FIELD=6
const TARGET_TYPE_FIELD=7
const ACTION_PARAMETER_FIELD=8
const ACTION_HOLD_FIELD=9
const USER_COMMENT_FIELD=10

const OPERAND_NONE=0
const OPERAND_SENSOR=1
const OPERAND_DIGITAL=2
const OPERAND_OUTPUTS=3
const OPERAND_ALARM=4// hi, low
const OPERAND_HOUR_MINUTE_TIME=5
const OPERAND_EQUIPMENTS=6
const OPERAND_SETPOINT=7
const OPERAND_TEMPERATURE_STAGE=8
const OPERAND_VARIABLES=9
const OPERAND_INTEGER=10
const OPERAND_FLOAT=11
const OPERAND_SPECIAL_STATES=12
const OPERAND_SETPOINT_START_TIME=13
const OPERAND_MIXING_TANK_EC=14
const OPERAND_MIXING_TANK_PH=15
const OPERAND_MIXING_TANK_TEMPERATURE=16
const OPERAND_PVARIABLES=17
const OPERAND_AUX_ALARMS=18
const OPERAND_STAGE_OVERRIDE_ACTIVE=19

const INPUTIDX_INTEMP=0
const INPUTIDX_OUTTEMP=1
const INPUTIDX_HUM=2
const INPUTIDX_CO2=3
const INPUTIDX_LITE=4
const INPUTIDX_WINSPD=5
const INPUTIDX_BHEAT1=6
const INPUTIDX_BHEAT2=7
const INPUTIDX_BHEAT3=8
const INPUTIDX_BHEAT4=9
const INPUTIDX_BHEAT5=10
const INPUTIDX_FBINTEMP=11
const INPUTIDX_WINDIR=12
const INPUTIDX_SOIL1=13
const INPUTIDX_SOIL2=14
const INPUTIDX_SOIL3=15
const INPUTIDX_SOIL4=16
const INPUTIDX_SOIL5=17
const INPUTIDX_RAIN=18
const INPUTIDX_IRR_TRIG=19
const INPUTIDX_DIFFP=20
const INPUTIDX_INLIGHT=21
const INPUTIDX_VENTPOS1=32
const INPUTIDX_VENTPOS2=33
const INPUTIDX_VENTPOS3=34
const INPUTIDX_VENTPOS4=35
const INPUTIDX_VENTPOS5=36
// const INPUTIDX_ECPH1=37
// const INPUTIDX_ECPH2=38
// const INPUTIDX_ECPH3=39
// const INPUTIDX_ECPH4=40
// const INPUTIDX_ECPH5=41
const INPUTIDX_OUTHUM=44
const INPUTIDX_LOCAL_INTEMP=45
const INPUTIDX_LOCAL_INHUM=46
const INPUTIDX_SNOW=47
const INPUTIDX_OUTTEMP2=48
const INPUTIDX_BARO=49
const INPUTIDX_VOLTAGE1=50
const INPUTIDX_VOLTAGE2=51
const INPUTIDX_VOLTAGE3=52
const INPUTIDX_VOLTAGE4=53
const INPUTIDX_VOLTAGE5=54
const INPUTIDX_VOLTAGE6=55
const INPUTIDX_VOLTAGE7=56
const INPUTIDX_VOLTAGE8=57
const INPUTIDX_DISABLED=64
const INPUTIDX_ACCUM_DISABLE=75
const INPUTIDX_ACCUM=75
const INPUTIDX_ACCUM2=76
const INPUTIDX_GENERIC1=77
const INPUTIDX_GENERIC2=78
const INPUTIDX_GENERIC3=79
const INPUTIDX_GENERIC4=80
const INPUTIDX_GENERIC5=81
/*
INPUTNAME_NONE = 0
INPUTNAME_ANALOG_SS1 = 1
INPUTNAME_ANALOG_SS2 = 2
INPUTNAME_ANALOG_1 = 3
INPUTNAME_ANALOG_2 = 4
INPUTNAME_ANALOG_3 = 5
INPUTNAME_ANALOG_4 = 6
INPUTNAME_ANALOG_5 = 7
INPUTNAME_ANALOG_6 = 8
INPUTNAME_ANALOG_7 = 9
INPUTNAME_ANALOG_8 = 10
INPUTNAME_FDIN_1 = 11
INPUTNAME_FDIN_2 = 12
INPUTNAME_FDIN_3 = 13
INPUTNAME_DIGITAL_1 = 14
INPUTNAME_DIGITAL_2 = 15
INPUTNAME_DIGITAL_3 = 16
INPUTNAME_DIGITAL_4 = 17
INPUTNAME_DIGITAL_5 = 18
INPUTNAME_DIGITAL_6 = 19
INPUTNAME_DIGITAL_7 = 20
INPUTNAME_DIGITAL_8 = 21
INPUTNAME_SOFTWARE = 22
INPUTNAME_REMOTE = 23
INPUTNAME_EXB_ANALOG_1 = 24
INPUTNAME_EXB_ANALOG_2 = 25
INPUTNAME_RS232_1 = 26
INPUTNAME_SENSMOD_BKUP = 27
 
 */

const SP_TYPE_PERIOD=-1
const SP_TYPE_HEAT=0
const SP_TYPE_COOL=1
const SP_TYPE_HUMIDIFY=2
const SP_TYPE_DEHUMIDIFY=3

const S_ST_ACTIVE_COOL=0
const S_ST_PASSIVE_COOL_OVERRIDE=1
const S_ST_BAROMETRIC_PRESSURE=2

const STAGE_OVERRIDE_ANY=-1
const STAGE_OVERRIDE_1=0
const STAGE_OVERRIDE_2=1
const STAGE_OVERRIDE_3=2
const STAGE_OVERRIDE_4=3
      
const OPERATOR_NONE=0
const OPERATOR_ADD=1
const OPERATOR_SUB=2
const OPERATOR_AND=3
const OPERATOR_OR=4
const OPERATOR_EQUALS=5
const OPERATOR_NOT_EQUALS=6
const OPERATOR_LESS_THAN=7
const OPERATOR_GREATER_THAN=8
const OPERATOR_LESS_EQUALS=9
const OPERATOR_GREATER_EQUALS=10

const INPUTIDX_STRING_MAP={
  0:"Inside Temperature",
  1:"Outside Temperature",
  2:"Relative Humidity",
  3:"CO2",
  4:"Outside Light",
  5:"Wind Speed",
  6:"Analog Temperature 1",
  7:"Analog Temperature 2",
  8:"Analog Temperature 3",
  9:"Analog Temperature 4",
  10:"Analog Temperature 5",
  11:"Fallback Temperature",
  12:"Wind Direction",
  13:"Soil Moisture 1",
  14:"Soil Moisture 2",
  15:"Soil Moisture 3",
  16:"Soil Moisture 4",
  17:"Soil Moisture 5",
  18:"Rain",
  19:"Irrigation Trigger",
  20:"Differential Pressure",
  21:"Inside Light",
  32:"Vent Position Sensor 1",
  33:"Vent Position Sensor 2",
  34:"Vent Position Sensor 3",
  35:"Vent Position Sensor 4",
  36:"Vent Position Sensor 5",
  // 37:"EC/pH 1",
  // 38:"EC/pH 2",
  // 39:"EC/pH 3",
  // 40:"EC/pH 4",
  // 41:"EC/pH 5",
  42:"Generic Sensor 1",
  43:"Generic Sensor 2",
  44:"Outside Humidity",
  45:"Local Temperature",
  46:"Local Humidity",
  47:"Snow",
  48:"Black Plate Temp.",
  49:"Barometric Pressure",
  50:"Voltage 1",
  51:"Voltage 2",
  52:"Voltage 3",
  53:"Voltage 4",
  54:"Voltage 5",
  64:"None",
  75:"Accumulator 1",
  76:"Accumulator 2",
  77:"Generic 1",
  78:"Generic 2",
  79:"Generic 3",
  80:"Generic 4",
  81:"Generic 5",
}

const AUX_SP_TYPE_MAP={
  0: "Heat Setpoint",
  1: "Cool Setpoint",
  2: "Humidify Setpoint",
  3: "Dehumidify Setpoint",
  "-1": "Setpoint Period",
}

const AUX_S_ST_TYPE_MAP={
  0: "Active Cool",
  1: "Passive Cool Override",
  2: "Change in Pressure",
}

const STAGE_OVERRIDE_TYPE_MAP={
  "-1": "Any Stage Override",
  0: "Stage Override 1",
  1: "Stage Override 2",
  2: "Stage Override 3",
  3: "Stage Override 4",
}

const STAGE_TYPE_MAP={
  0: "Heat 6",
  1: "Heat 5",
  2: "Heat 4",
  3: "Heat 3",
  4: "Heat 2",
  5: "Heat 1",
  6: "Normal",
  7: "Cool 1",
  8: "Cool 2",
  9: "Cool 3",
  10: "Cool 4",
  11: "Cool 5",
  12: "Cool 6",
}

const EQUIPMENT_TYPE_MAP={
  0: "Off",
  1: "On",
  2: "Cycle",
}

var EQUIPMENT_PROP_MAP

const OPERATOR_MAP={
  0: "N/A",
  1: "+",
  2: "-",
  3: "AND",
  4: "OR",
  5: "=",
  6: "≠",
  7: "<",
  8: ">",
  9: "≤",
  10: "≥",
}

class AuxControl extends React.Component{
  constructor(props) {
    super(props);
    cl(props)
//     cl(props.parms.auxControl.auxIndex)
//     cl(props.current)
    this.initVars()// including state
//     cl(`new props add ${props.parms.index}`)
    this.props.parms.newProps(this.newProps)// register newProps function
//     this.subscribe_savePageEvent=globs.events.subscribe("savePageEvent",this.saveCancel)
//     this.testFloat()
//     this.props.getValue(props.ind, {type: "array", count: 14})
    this.subscribe_auxControlSelect=globs.events.subscribe("auxControlSelect",this.auxControlSelect)
    this.proportionals=proportionals
    this.updateStringMap()
    
//     cl(this.state.operators)
  }
  
  updateStringMap=()=>{
//     cl(this.props.parms.sensors)
    let sensors=this.props.parms.sensors
    let sm=INPUTIDX_STRING_MAP
    Object.keys(sm).forEach(k=>{
      if(sensors[k]){sm[k]=sensors[k]}
    })
  }
  
  componentWillUnmount=()=>{
//     cl("unMount")
    this.subscribe_auxControlSelect.remove()
  }
  
  auxControlSelect=(val)=>{
//     cl(val,this.props.parms.index)
    if(val!=this.props.parms.index){this.setState({editField:-1})}
 }
  
  newProps=(props)=>{
//     cl(props.parms.auxControl.operandTypes)
//     cl(props.parms.auxControl.userComment)
//     cl(props.parms.auxControl)
    this.updateStringMap()
    this.setState(props.parms.auxControl)
  }
  
  testFloat=()=>{
    cl("test float")
    var bytes=new Uint8Array([1,2,3,4])
    var intar=new Uint32Array([1085381018])
    var buf=intar.buffer
    var floatval=new Float32Array(buf)
    cl(floatval)
    cl(buf)
    cl(bytes)
  }
  
//   deepCopy=(obj)=>{
//     if((obj===null)||(typeof(obj)!=='object')){return obj}
//     let ret=(obj instanceof Date)? new obj.constructor() : obj.constructor()
//     for (let k in obj){
//       if(obj.hasOwnProperty(k)){
//         ret[k]=this.deepCopy(obj[k])
//       }
//     }
//     return ret
//   }
  

  initVars=()=>{
    this.operandTypeFields=[
      OPERAND_TYPE_1_FIELD,
      OPERAND_TYPE_2_FIELD,
      OPERAND_TYPE_3_FIELD,
      TARGET_TYPE_FIELD,
    ]
    this.operatorFields=[
      OPERATOR_1_FIELD,
      OPERATOR_2_FIELD,
    ]
    this.auxControl=deepCopy(this.props.parms.auxControl)
//     cl(this.auxControl)
//     this.parmAuxControl=this.props.parms.auxControl
    
//     cl(this.auxControl)
//     let ah=this.secsToHMS("ah", this.auxControl.actionHoldTime)
//     ah=ah.substr(0,ah.length-3)
    this.state=Object.assign({
      editField: -1,
      modified: false,
      conditionSecondsText: this.secsToHMS("cs", this.auxControl.conditionSeconds),
      conditionSecondsFocus: false,
      actionHoldText: this.secsToHMS("ah", this.auxControl.actionHoldTime),
      actionHoldFocus: false,
      timeOfDay: "12:00",
    },this.auxControl)
    EQUIPMENT_PROP_MAP={}
    for(let i=0;i<100;i++){EQUIPMENT_PROP_MAP[i]=i}
//     cl(EQUIPMENT_PROP_MAP)
//     cl(this.auxControl)
//     cl(this.state.operators)
    
    
//     cl(this.state)
//     cl(this.auxControl)
//     this.state={
//       operandTypes: [0,0,0,0],// ind 3 is the target type/value
//       operandValues: [0,0,0,0],
//       operators: [0,0],
//       conditionSeconds: 0,
//       conditionSecondsText: "00:00",
//       conditionSecondsFocus: false,
//       durationDiv: 1,
//       action: 0,
//       targetType: 0,
//       targetValue: 0,
//       actionParameter: 0,
//       actionHoldTime: 0,
//       actionHoldText: "00:00",
//       actionHoldFocus: false,
//     }
    this.operandTypes=[
      {v:OPERAND_NONE, t:"None"},
      {v:OPERAND_INTEGER, t:"Integer"},
      {v:OPERAND_FLOAT, t:"Float"},
      {v:OPERAND_VARIABLES, t:"Variables"},
      {v:OPERAND_PVARIABLES, t:"Persistent Variables"},
      {v:OPERAND_AUX_ALARMS, t:"Aux Control Alarms"},
      
      {v:OPERAND_MIXING_TANK_EC, t:"Mixing Tank - EC"},
      {v:OPERAND_MIXING_TANK_PH, t:"Mixing Tank - pH"},
      {v:OPERAND_MIXING_TANK_TEMPERATURE, t:"Mixing Tank - Temperature"},
      
      {v:OPERAND_SENSOR, t:"Sensor"},
      {v:OPERAND_DIGITAL, t:"Digital Input"},
      {v:OPERAND_EQUIPMENTS, t:"Equipments"},
      {v:OPERAND_OUTPUTS, t:"Relay Output"},
      {v:OPERAND_ALARM, t:"Alarm"},
      {v:OPERAND_SETPOINT, t:"Setpoint"},
      {v:OPERAND_HOUR_MINUTE_TIME, t:"Time of Day"},
      {v:OPERAND_SETPOINT_START_TIME, t:"Setpoint Period Start Time"},
      {v:OPERAND_TEMPERATURE_STAGE, t:"Temperature Stage"},
      {v:OPERAND_STAGE_OVERRIDE_ACTIVE, t:"Stage Override Active"},
      {v:OPERAND_SPECIAL_STATES, t:"Special State"},
//       {v:14, t:"Mixing Tank EC"},
//       {v:15, t:"Mixing Tank pH"},
//       {v:16, t:"Mixing Tank Temperature"},
//       {v:1, t:"Number / Value"},
    ]
    this.targetTypes=[
      {v:OPERAND_NONE, t:"None"},
      {v:OPERAND_VARIABLES, t:"Variables"},
      {v:OPERAND_PVARIABLES, t:"Persistent Variables"},
      {v:OPERAND_AUX_ALARMS, t:"Aux Control Alarms"},
      {v:OPERAND_EQUIPMENTS, t:"Equipments"},
      {v:OPERAND_OUTPUTS, t:"Relay Output"},
      {v:OPERAND_ALARM, t:"Alarm"},
      {v:OPERAND_SETPOINT, t:"Setpoint"},
      {v:OPERAND_TEMPERATURE_STAGE, t:"Temperature Stage"},
      {v:OPERAND_SPECIAL_STATES, t:"Special State"},
    ]
    this.operators=[
      {v:OPERATOR_NONE, t:"N/A"},
      {v:OPERATOR_ADD, t:"+"},
      {v:OPERATOR_SUB, t:"-"},
      {v:OPERATOR_AND, t:"AND"},
      {v:OPERATOR_OR, t:"OR"},
      {v:OPERATOR_EQUALS, t:"="},
      {v:OPERATOR_NOT_EQUALS, t:"≠"},
      {v:OPERATOR_LESS_THAN, t:"<"},
      {v:OPERATOR_GREATER_THAN, t:">"},
      {v:OPERATOR_LESS_EQUALS, t:"≤"},
      {v:OPERATOR_GREATER_EQUALS, t:"≥"},
      ]
//     cl(this.props)
    let p=this.props.parms
//     cl(p)
    this.variables=p.variables//[]; for(let i=1; i<=64; i++){this.variables.push(`Variable ${i}`)}
    this.pVariables=p.pVariables//[]; for(let i=1; i<=64; i++){this.pVariables.push(`Persistent Variable ${i}`)}
    this.auxAlarms=p.auxAlarms//[]; for(let i=1; i<=64; i++){this.auxAlarms.push(`Alarm ${i}`)}
//     this.digital=[]; for(let i=1; i<=8; i++){this.digital.push(`Digital Input ${i}`)}
//     this.equipments=p.equipments//[]; for(let i=1; i<=12; i++){this.equipments.push(`Equipment ${i}`)}
    this.tanks=p.tanks
    this.underline="underline"
    this.cursor="pointer"
    if(this.props.parms.hideUnderline){
      this.underline=""
      this.cursor=""
    }
//     this.underline=(this.props.parms.hideUnderline)?"":"underline"
  }

  onChange=(ind)=>{
    cl("onChange")
    let stageStatus=this.props.value
    stageStatus[ind]=(+stageStatus[ind])?0:1
    this.setState({stageStatus: stageStatus})
//     cl(stageStatus)
    this.props.onChange(this.props.ind, {value: stageStatus})
  }
  
  setOperandValueOpts=(ind, type)=>{
    switch(type){
      
    }
  }
  
  restoreOriginal=()=>{
    this.auxControl=deepCopy(this.props.parms.auxControl)
    this.setState(this.auxControl)
//     let ac=this.auxControl
//     this.setState({
//       operandTypes: ac.operandTypes,
//       operandValues: ac.operandValues,
//       operators: ac.operators,
//       conditionSeconds: ac.conditionSeconds,
//       conditionSecondsText: ac.conditionSecondsText,
//       conditionSecondsFocus: ac.conditionSecondsFocus,
//       action: ac.action,
//       actionParameter: ac.actionParameter,
//       actionHoldTime: ac.actionHoldTime,
//       actionHoldText: ac.actionHoldText,
//       actionHoldFocus: ac.actionHoldFocus,
//     })
    
  }
  
  result=(type)=>{
//     cl(type)
    switch(type){
      case "Aux-OK":
        this.props.parms.onChange(this.auxControl)
//         cl(this.auxControl)
        this.setState({editField:-1, modified: false})
        break
      case "Aux-Cancel":
        this.restoreOriginal()
        this.setState({editField:-1, modified: false})
        break
      default:
        break
    }
  }
  
  onChange2=(type,ind,e)=>{
    cl([type,ind,e.currentTarget.value])
//     return
//     cl(type,ind,e)
//     cl([type,ind,e.currentTarget.value])
//     cl(type)
//     cl(ind)
//     cl(e)
    let val=e.currentTarget.value
//     cl(val)
    let st={}
    if(type=="operandValue"){val=this.handleFromFloat(val,ind)}
//     cl(val)
//     globs.events.publish("savePageEnable",true)
//     this.props.parms.onChange(this.auxControl)// this is before it's updated!
    switch(type){
      case "operand":
        this.setOperandValueOpts(ind, val)
        this.auxControl.operandTypes[ind]=+val
        if((ind==0)&&(val==OPERAND_HOUR_MINUTE_TIME || val==OPERAND_TEMPERATURE_STAGE)){
          this.auxControl.operandTypes[1]=OPERAND_INTEGER
        }
//         if((ind==0)&&(val==OPERAND_HOUR_MINUTE_TIME)){
//           this.auxControl.operandTypes[1]=OPERAND_HOUR_MINUTE_TIME
//           this.auxControl.operandValues[1]=0
//         }
        st=/*this.setState(*/{operandTypes: this.auxControl.operandTypes.slice(0),
          operandValues: this.auxControl.operandValues}/*)*/
        break
      case "operandValue":
//         let operandValue=e.currentTarget.value
//         cl(this.auxControl)
        if(ind==4){
          this.auxControl.actionParameter=+val
        }else{
          this.auxControl.operandValues[ind]=+val
        }
//         let vals=this.state.operandValues.slice(0)
//         vals[ind]=val
        st=/*this.setState(*/{operandValues: this.auxControl.operandValues.slice(0), actionParameter: this.auxControl.actionParameter}/*)*/
        break
      case "operator":
//         let operator=e.currentTarget.value
//         this.setOperandValueOpts(ind, operandType)
//         cl(this.auxControl.operators)
//         cl([ind,val])
        this.auxControl.operators[ind]=+val
//         let operators=this.state.operators.slice(0)
//         operators[ind]=val
//         cl(this.auxControl.operators)
        st=/*this.setState(*/{operators: this.auxControl.operators.slice(0)/*operators*/}/*)*/
//         cl(st)
        break
      case "conditionSecondsText":
        this.auxControl.conditionSecondsText=val
        let secs=this.parseTimeString("cs",val)
        this.auxControl.conditionSeconds=secs
        st=/*this.setState(*/{conditionSecondsText: val}/*)*/
        break
      case "actionHoldText":
        this.auxControl.actionHoldText=val
        cl(val)
        let mins=this.parseTimeString("ah",val)
        this.auxControl.actionHoldTime=mins
        st=/*this.setState(*/{actionHoldText:val}/*)*/
        break
      case "action":
        this.auxControl.action=+val
        st=/*this.setState(*/{action: +val}/*)*/
        break
      case "actionParameter":
        this.auxControl.actionParameter=+val
        st=/*this.setState(*/{actionParameter: +val}/*)*/
        break
      case "timeOfDay":
//         cl([val,this.muiTimeToMins(val)])
        this.auxControl.operandValues[ind]=this.muiTimeToMins(val)
        let ot=this.auxControl.operandTypes
        if((ind==2)&&(ot[1]==OPERAND_HOUR_MINUTE_TIME)){
          ot[2]=OPERAND_INTEGER
          cl("set int")
        }
        cl(this.auxControl.operandTypes)
        st=/*this.setState(*/{operandValues: this.auxControl.operandValues.slice(0)}/*)*/
        break
      case "userComment":
//         this.auxControl.operandValues[ind]=this.muiTimeToMins(val)
        this.auxControl.userComment=e.currentTarget.value
        st=/*this.setState(*/{userComment: this.auxControl.userComment}/*)*/
        break
      default:
        break
    }
    let mod=!deepCompare(this.auxControl,this.props.parms.auxControl)
    if(mod!=this.state.modified){
      st.modified=mod
    }
//     cl(this.auxControl.operators)
    this.props.parms.onChange({t:"auxControl",v:this.auxControl})// this is before it's updated!
    this.setState(st)
  }
  
  makeOptions=(opts)=>{
//     cl(opts)
    return opts.map((o,i)=>{
      let text=(typeof(o.t)=="object")?o.t.n:o.t// new style of variables, etc.
      return (
        <option key={i} value={o.v}>{text}</option>
      )
    })
  }
  
  editFieldSelect=(id)=>{
//     cl(id)

    if(this.props.parms.saveOK){
      this.setState({editField: id})
      this.props.parms.onChange({t:"select",v:id})
      
    }
//     this.setState({editField: id})
  }
  
  dispOV=(ind,str)=>{
    let editId=this.operandTypeFields[ind]
    return <div style={{display: "inline", marginLeft: "0.3em", cursor: this.cursor, textDecoration:this.underline}}
      onClick={()=>this.editFieldSelect(editId)}
    >{str}</div>
  }

  dispOPMap=(ind,val)=>{
//     return this.dispOV(ind,`${mapTable[val]}`)
    let editId=this.operatorFields[ind]
//     cl(editId)
    let txt=((ind==0)&&(val==OPERATOR_NONE))?"is non-zero":OPERATOR_MAP[val]
//     cl(OPERATOR_MAP[val])
//     cl(txt)
    if(txt=="N/A"){txt="(_)"}
    return <div style={{display: "inline", marginLeft: "0.3em", cursor: this.cursor, textDecoration:this.underline}}
      onClick={()=>this.editFieldSelect(editId)}
    >{`${txt}`}</div>
  }

  dispStandardOVOpts=(ind,val,name)=>{
//     let val=1+(+this.state.operandValues[ind])
    return this.dispOV(ind,`${name} ${val}`)
  }
  
  dispOVMap=(ind,val,mapTable, opts)=>{
//     let val=+this.state.operandValues[ind]
//     cl(ind,val,mapTable)
//     cl(mapTable)
    let suffix=""
//     cl(val)
    val=mapTable[val]
//     cl(val)
    if(opts){
      suffix=opts.suffix||""
      if(opts.varType){val=val.n}
    }
    return this.dispOV(ind,`${val}${suffix}`)
  }
  
  dispOVStageMap=(ind,val,mapTable, suffix="")=>{
//     cl([ind,val])
    if((ind==0 || ind==3)||
      ((ind==1)&&(this.state.operandTypes[1]==OPERAND_TEMPERATURE_STAGE))){
      return this.showOVTimeOfDay(ind,"Temperature Stage")
    }else{
      return this.dispOV(ind,`${mapTable[val]}`)
    }
  }
  
//   dispNamedOVOpts=(ind,tab)=>{
//     
//   }
  
  dispOVDefault=(ind,name)=>{
    let editId=this.operandTypeFields[ind]
    return <div style={{display: "inline", marginLeft: "0.3em", cursor: this.cursor, textDecoration:this.underline}}
      onClick={()=>this.editFieldSelect(editId)}
    >{name}</div>
  }
  
  dispIntegerFloat=(ind,val)=>{
    return this.dispOV(ind,`${val}`)
  }
  
//   dispTargetType=()=>{
// //     let editId=this.operandTypeFields[ind]
// //     return <div style={{display: "inline", marginLeft: "0.3em", cursor: "pointer"}}
// //       onClick={()=>this.editFieldSelect(editId)}
// //     >operand {ind}</div>
// 
//     let val=+this.state.targetValue
//     let ind=3
//     let showOperandValueTypes=[
//     ()=>this.dispOVDefault(ind,"None"),
//     ()=>this.dispOVMap(ind,val,INPUTIDX_STRING_MAP),
//     ()=>this.dispStandardOVOpts(ind,val,"Digital Input"),
//     ()=>this.dispStandardOVOpts(ind,val,"Output"),
//     ()=>this.dispOVMap(ind,val,["Low Alarm", "High Alarm"]),
//     this.showOVNone,
//     ()=>this.dispOVMap(ind,val,this.equipments),
//     ()=>this.dispOVMap(ind,val,AUX_SP_TYPE_MAP),
//     this.showOVNone,
//     ()=>this.dispOVMap(ind,val,this.variables),
//     ()=>this.dispIntegerFloat(ind,val),
//     ()=>this.dispIntegerFloat(ind,val),
//     ()=>this.dispIntegerFloat(ind,val,AUX_S_ST_TYPE_MAP),
//     ()=>this.dispStandardOVOpts(ind,val,"Setpoint Period"),
//     this.showOVNone,
//     this.showOVNone,
//     this.showOVNone,
//     ()=>this.dispOVMap(ind,val,this.pVariables),
//     ()=>this.dispOVMap(ind,val,this.auxAlarms),
//     ()=>this.dispIntegerFloat(ind,val,STAGE_OVERRIDE_TYPE_MAP),
//     ]
//     let targetType=this.state.targetType
//     cl(targetType)
//     return showOperandValueTypes[targetType](ind)
//   }

  handleToFloat=(val,ind)=>{// val is a float formatted as an int
    let operandType=this.state.operandTypes[ind]
    if ((+operandType)==OPERAND_FLOAT){
//       cl("handle float")
      let buf=new ArrayBuffer(4)
      let intView=new Int32Array(buf)
      intView[0]=val
      let flView=new Float32Array(buf)
      return 1*(flView[0].toPrecision(4))
    }
    return val
  }
  
  handleFromFloat=(val,ind)=>{
    let operandType=this.state.operandTypes[ind]
    if ((+operandType)==OPERAND_FLOAT){
      let buf=new ArrayBuffer(4)
      let intView=new Int32Array(buf)
      let flView=new Float32Array(buf)
      cl(val)
      if(val==""){val=0}
      if(!isNaN(+val)){val=(+val).toPrecision(4)}
      flView[0]=val//(val||0).toPrecision(4)
      return intView[0]
    }
    return val
  }
    
  dispOperandType=(ind)=>{
//     let editId=this.operandTypeFields[ind]
//     return <div style={{display: "inline", marginLeft: "0.3em", cursor: "pointer"}}
//       onClick={()=>this.editFieldSelect(editId)}
//     >operand {ind}</div>
//     cl(this.props.parms.equipments[0])

    let val=+this.state.operandValues[ind]
    val=this.handleToFloat(val,ind)
//     cl(this.variables)
    let showOperandValueTypes=[
    ()=>this.dispOVDefault(ind,"None"),
    ()=>this.dispOVMap(ind,val,INPUTIDX_STRING_MAP),
    ()=>this.dispStandardOVOpts(ind,1+val,"Digital Input"),
    ()=>this.dispStandardOVOpts(ind,val,"Output"),
    ()=>this.dispOVMap(ind,val,["Low Alarm", "High Alarm"]),
    ()=>this.showOVTimeOfDay(ind,"Time of Day"),// force time of day for next operand
    ()=>this.dispOVMap(ind,val,this.props.parms.equipments),
    ()=>this.dispOVMap(ind,val,AUX_SP_TYPE_MAP),
    ()=>this.dispOVStageMap(ind,val,STAGE_TYPE_MAP),
//     ()=>this.showOVTimeOfDay(ind,"Temperature Stage"),// force time of day for next operand
//     ()=>this.dispOVMap(ind,val,STAGE_TYPE_MAP),
//     this.showOVNone,// force heat6, etc. stage
    ()=>this.dispOVMap(ind,val,this.variables,{varType:true}),
    ()=>this.dispIntegerFloat(ind,val),
    ()=>this.dispIntegerFloat(ind,val),
    ()=>this.dispIntegerFloat(ind,val,AUX_S_ST_TYPE_MAP),
    ()=>this.dispStandardOVOpts(ind,val,"Setpoint Period"),
    ()=>this.dispOVMap(ind,val,this.tanks, {suffix:" EC"}),
    ()=>this.dispOVMap(ind,val,this.tanks, {suffix:" pH"}),
    ()=>this.dispOVMap(ind,val,this.tanks, {suffix:" Temperature"}),
//     this.showOVNone,
//     this.showOVNone,
//     this.showOVNone,
    ()=>this.dispOVMap(ind,val,this.pVariables,{varType:true}),
    ()=>this.dispOVMap(ind,val,this.auxAlarms,{varType:true}),
    ()=>this.dispIntegerFloat(ind,val,STAGE_OVERRIDE_TYPE_MAP),
    ]
    let operandType=this.state.operandTypes[ind]
    // cl(this.state)
//     if(ind==0){
//       let opType2=this.state.operandTypes[1]
//       if(opType2==OPERAND_HOUR_MINUTE_TIME){operandType=OPERAND_HOUR_MINUTE_TIME}
//     }
//     if([0,2].includes(ind)){
    let operators=this.state.operators
    if(((ind==0)&&(![3,4].includes(operators[0])))
      ||(ind==2)&&(![3,4].includes(operators[1]))
    ){
      let opType2=this.state.operandTypes[1]
      if(opType2==OPERAND_HOUR_MINUTE_TIME){operandType=OPERAND_HOUR_MINUTE_TIME}
      if(opType2==OPERAND_TEMPERATURE_STAGE){operandType=OPERAND_TEMPERATURE_STAGE}
    }
    if(ind==1){
      let opType1=this.state.operandTypes[0]
      if(opType1==OPERAND_HOUR_MINUTE_TIME){operandType=OPERAND_HOUR_MINUTE_TIME}
      if(opType1==OPERAND_TEMPERATURE_STAGE){operandType=OPERAND_TEMPERATURE_STAGE}
    }
    // cl(operandType)
    return showOperandValueTypes[operandType||0](ind)
    
    
//     let editId=this.operandTypeFields[ind]
//     return <div style={{display: "inline", marginLeft: "0.3em", cursor: "pointer"}}
//       onClick={()=>this.editFieldSelect(editId)}
//     >operand {ind}</div>
  }
  
  dispOperator=(ind)=>{
//     cl(this.state.operators)
    let val=this.state.operators[ind]
//     cl(this.state.operators)
    if((this.auxControl.auxIndex==1)&&(ind==0)){
//         cl(val)
    }
//     cl(val)
//     let val=((ind==0)&&(this.state.operators[ind]==OPERATOR_NONE))?"is non-zero":this.state.operators[ind]
//     cl(ind,val,OPERATOR_MAP)
    return this.dispOPMap(ind,val,OPERATOR_MAP)
//   dispOVMap=(ind,val,mapTable)=>{
    
//     let editId=this.operatorFields[ind]
//     return <div style={{display: "inline", marginLeft: "0.3em", cursor: "pointer"}}
//       onClick={()=>this.editFieldSelect(editId)}
//     >operator {ind}</div>
  }
  
  showOVNone=(ind,name)=>{
    return <span>{name}</span>
  }
  
  showOVTimeOfDay=(ind,name)=>{
//     cl(`Show ${ind}, ${name}`)
//     return <span style={{marginLeft: "0.3em",}}>{name}</span>
    var time
    if(
      ((this.state.operandTypes[1]==OPERAND_HOUR_MINUTE_TIME)||
      (this.state.operandTypes[1]==OPERAND_TEMPERATURE_STAGE))
      &&(ind<3)){
      if((ind==0)||(ind==2)){
        let val=this.state.operandValues[ind]
        if(this.state.operandTypes[1]==OPERAND_HOUR_MINUTE_TIME){
          time=this.minsToDispTime(val)
        }else{
          time=this.dispOV(ind,`${STAGE_TYPE_MAP[val]}`)          
        }
      }else{
        time=name
      }
    }else{
      if(name=="Temperature Stage"){
        time=name
      }else{
        time=(ind==0)?name:this.minsToDispTime(this.state.operandValues[ind])
      }
    }
    let editId=this.operandTypeFields[ind]
//     cl(editId)
    return <div style={{display: "inline", marginLeft: "0.3em", cursor: this.curosr, textDecoration:this.underline}}
      onClick={()=>this.editFieldSelect(editId)}
    >{time}</div>
  }
  
  showOVTimeOfDayValue=(ind,name)=>{
    cl([ind,name])
//     if(ind==0){
//       return null
//     }else{
      if(name=="Time of Day"){
        cl(`returning for ${ind}`)
        return(
          <input type="time"
            value={this.minsToMuiTime(this.state.operandValues[ind])}
            onChange={e=>this.onChange2("timeOfDay",ind,e)}
          />
        )
      }else{
        if(name=="Temperature Stage" && ind==3){
          return null
        }else{
          return this.showOVMapType(ind,STAGE_TYPE_MAP)
        }
      }
//       return <span style={{marginLeft: "0.3em",}}>{name}</span>
//     }
  }
  
  showOVSensor=(ind)=>{
    // cl(INPUTIDX_STRING_MAP)
    let sensorOpts=[
      INPUTIDX_INTEMP,INPUTIDX_FBINTEMP,INPUTIDX_OUTTEMP,INPUTIDX_OUTTEMP2,
      INPUTIDX_HUM,INPUTIDX_OUTHUM,INPUTIDX_CO2,INPUTIDX_INLIGHT,INPUTIDX_LITE,
      INPUTIDX_WINSPD,INPUTIDX_WINDIR,INPUTIDX_RAIN,INPUTIDX_SNOW,INPUTIDX_BARO,
      INPUTIDX_IRR_TRIG,INPUTIDX_DIFFP,
      INPUTIDX_BHEAT1,INPUTIDX_BHEAT2,INPUTIDX_BHEAT3,INPUTIDX_BHEAT4,
      INPUTIDX_BHEAT5,INPUTIDX_SOIL1,
      INPUTIDX_SOIL2,INPUTIDX_SOIL3,INPUTIDX_SOIL4,INPUTIDX_SOIL5,
      INPUTIDX_VENTPOS1,
      INPUTIDX_VENTPOS2,INPUTIDX_VENTPOS3,INPUTIDX_VENTPOS4,INPUTIDX_VENTPOS5,
      INPUTIDX_VOLTAGE1,INPUTIDX_VOLTAGE2,INPUTIDX_VOLTAGE3,INPUTIDX_VOLTAGE4,
      INPUTIDX_VOLTAGE5,
      // INPUTIDX_ECPH1,INPUTIDX_ECPH2,INPUTIDX_ECPH3,INPUTIDX_ECPH4,INPUTIDX_ECPH5,
      INPUTIDX_GENERIC1,INPUTIDX_GENERIC2,INPUTIDX_GENERIC3,INPUTIDX_GENERIC4,INPUTIDX_GENERIC5,
      INPUTIDX_LOCAL_INTEMP,
      INPUTIDX_LOCAL_INHUM,
      // INPUTIDX_DISABLED,INPUTIDX_ACCUM,INPUTIDX_ACCUM2,
//       INPUTIDX_INTEMP,
//       INPUTIDX_OUTTEMP,
//       INPUTIDX_HUM,
//       INPUTIDX_CO2,
//       INPUTIDX_LITE,
//       INPUTIDX_WINSPD,
//       INPUTIDX_BHEAT1,
//       INPUTIDX_FBINTEMP,
//       INPUTIDX_WINDIR,
//       INPUTIDX_RAIN,
//       INPUTIDX_IRR_TRIG,
//       INPUTIDX_INLIGHT,
//       INPUTIDX_OUTHUM,
    ]
    let opts=[]
    // cl(sensorOpts)
    sensorOpts.forEach(s=>{opts.push({v:s,t:INPUTIDX_STRING_MAP[s]})})
    // cl(opts)
    return(
      <C18CustomSelect00 style={{marginLeft: "0.3em",}}
        value={this.state.operandValues[ind]} 
        onChange={e=>this.onChange2("operandValue",ind,e)}
      >
      {this.makeOptions(opts)}
      </C18CustomSelect00>
    )
  }
  
  showSelect=(val,valId,ind,opts)=>{
    return(
      <C18CustomSelect00 style={{marginLeft: "0.3em",}}
        value={val} 
        onChange={e=>this.onChange2(valId,ind,e)}
      >
      {this.makeOptions(opts)}
      </C18CustomSelect00>
    )
  }
  
  showOVSelect=(ind,opts)=>{
//     cl([ind,opts])
//     cl(this.state)
    let val=(ind==4)?+this.state.actionParameter:this.state.operandValues[ind]
    return this.showSelect(val, "operandValue",ind,opts)
//     return(
//       <C18CustomSelect00 style={{marginLeft: "0.3em",}}
//         value={this.state.operandValues[ind]} 
//         onChange={e=>this.onChange2("operandValue",ind,e)}
//       >
//       {this.makeOptions(opts)}
//       </C18CustomSelect00>
//     )
  }
  
  showStandardOVOpts=(ind,name, count)=>{
    let opts=[]
    for(let i=0; i<count; i++){opts.push({v:i, t:`${name} ${i+1}`})}
    return this.showOVSelect(ind,opts)
  }
  
  showNamedOVOpts=(ind,names)=>{
    let opts=[]
    names.forEach((n,i)=>{opts.push({v:i, t:n})})
    return this.showOVSelect(ind,opts)
  }
  
  showOVSetpoint=(ind)=>{
    let opts=[]
    Object.keys(AUX_SP_TYPE_MAP).forEach(k=>{
      opts.push({v:k,t:AUX_SP_TYPE_MAP[k]})
    })
    return this.showOVSelect(ind,opts)
  }
  
  showOVMapType=(ind,mapSource)=>{
    let opts=[]
    // cl(mapSource)
    Object.keys(mapSource).forEach(k=>{opts.push({v:k,t:mapSource[k]})})
    return this.showOVSelect(ind,opts)
  }
  
  showOVSetpoint=(ind)=>{
    return this.showOVMapType(ind,AUX_SP_TYPE_MAP)
  }
  
  showOVDigital=(ind)=>{
    return this.showStandardOVOpts(ind,"Digital Input",8)
  }
  
  showNumeric=(val,valId,ind)=>{
    val=this.handleToFloat(val,ind)
    return(
      <input 
        type="number"
        inputMode="decimal"
        style={{marginLeft: "0.3em", width: 75}}
        value={val}
        onChange={e=>this.onChange2(valId,ind,e)}
      />
    )
  }
  
  showIntegerFloat=(ind)=>{
    cl("show int")
    return this.showNumeric(this.state.operandValues[ind],"operandValue",ind)
//     return(
//       <input 
//         type="number" 
//         style={{marginLeft: "0.3em", width: 50}}
//         value={this.state.operandValues[ind]}
//         onChange={e=>this.onChange2("operandValue",ind,e)}
//       />
//     )
  }
  
//   showTargetEditValue=(ind)=>{
// //     cl("show")
//     let showOperandValueTypes=[
// //     ()=>this.showOVNone(ind,"None"),
//     this.showOVNone,
//     ()=>this.showOVSensor(ind),
//     ()=>this.showStandardOVOpts(ind,"Digital Input",8),
//     ()=>this.showStandardOVOpts(ind,"Output",12),
//     ()=>this.showNamedOVOpts(ind,["Low Alarm", "High Alarm"]),
//     this.showOVNone,
//     ()=>this.showNamedOVOpts(ind,this.equipments),
//     ()=>this.showOVMapType(ind,AUX_SP_TYPE_MAP),
//     this.showOVNone,
//     ()=>this.showNamedOVOpts(ind,this.variables),
//     ()=>this.showIntegerFloat(ind),
//     ()=>this.showIntegerFloat(ind),
//     ()=>this.showOVMapType(ind,AUX_S_ST_TYPE_MAP),
//     ()=>this.showStandardOVOpts(ind,"Setpoint Period",8),
//     this.showOVNone,
//     this.showOVNone,
//     this.showOVNone,
//     ()=>this.showNamedOVOpts(ind,this.pVariables),
//     ()=>this.showNamedOVOpts(ind,this.auxAlarms),
//     ()=>this.showOVMapType(ind,STAGE_OVERRIDE_TYPE_MAP),
//     ]
//     let operandType=this.state.operandTypes[ind]
//     return showOperandValueTypes[operandType](ind)
//   }
//   
  showOperandEditValue=(ind)=>{
//     cl("show")
//     if((ind==1)&&(this.state.operandTypes[0]==OPERAND_HOUR_MINUTE_TIME)){
//       return this.showOVTimeOfDayValue(ind, "this is it")
//     }
    let showOperandValueTypes=[
//     ()=>this.showOVNone(ind,"None"),
    this.showOVNone,
    ()=>this.showOVSensor(ind),
    ()=>this.showStandardOVOpts(ind,"Digital Input",8),
    ()=>this.showStandardOVOpts(ind,"Output",12),
    ()=>this.showNamedOVOpts(ind,["Low Alarm", "High Alarm"]),
    ()=>this.showOVTimeOfDayValue(ind, "Time of Day"),
//     ()=>this.showNamedOVOpts(ind,this.equipments),
    ()=>this.showOVMapType(ind,this.props.parms.equipments),
    ()=>this.showOVMapType(ind,AUX_SP_TYPE_MAP),
    ()=>this.showOVTimeOfDayValue(ind, "Temperature Stage"),
//     ()=>this.showOVTimeOfDayValue(ind, "this is it"),
    ()=>this.showNamedOVOpts(ind,this.variables),
    ()=>this.showIntegerFloat(ind),
    ()=>this.showIntegerFloat(ind),
    ()=>this.showOVMapType(ind,AUX_S_ST_TYPE_MAP),
    ()=>this.showStandardOVOpts(ind,"Setpoint Period",8),
    ()=>this.showNamedOVOpts(ind,this.tanks),
    ()=>this.showNamedOVOpts(ind,this.tanks),
    ()=>this.showNamedOVOpts(ind,this.tanks),
//     this.showOVNone,
//     this.showOVNone,
//     this.showOVNone,
    ()=>this.showNamedOVOpts(ind,this.pVariables),
    ()=>this.showNamedOVOpts(ind,this.auxAlarms),
    ()=>this.showOVMapType(ind,STAGE_OVERRIDE_TYPE_MAP),
    ]
//     cl(this.state.operandTypes)
    let operandType=this.state.operandTypes[ind]
//     cl(operandType)
//     if((ind==0)||(ind==2)){
    let operators=this.state.operators
    if(((ind==0)&&(![3,4].includes(operators[0])))
      ||(ind==2)&&(![3,4].includes(operators[1]))
    ){
      let opType2=this.state.operandTypes[1]
      if(opType2==OPERAND_HOUR_MINUTE_TIME){operandType=OPERAND_HOUR_MINUTE_TIME}
      if(opType2==OPERAND_TEMPERATURE_STAGE){operandType=OPERAND_TEMPERATURE_STAGE}
    }
    // cl([ind,operandType])
    if(ind==1){
      let opType1=this.state.operandTypes[0]
      if(opType1==OPERAND_HOUR_MINUTE_TIME){operandType=OPERAND_HOUR_MINUTE_TIME}
      if(opType1==OPERAND_TEMPERATURE_STAGE){operandType=OPERAND_TEMPERATURE_STAGE}
    }
//     cl(operandType)
    return showOperandValueTypes[operandType](ind)
//     return(
//       <select style={{marginLeft: "0.3em",}}
//         value={this.state.operandTypes[ind]} 
//         onChange={e=>this.onChange2("operand",ind,e)}
//       >
//       {this.makeOptions(this.operandTypes)}
//       </select>
//       
//     )
    
  }
  
  showOperator=(ind)=>{
//     return <div style={{display: "inline", marginLeft: "0.3em"}}>=</div>
//     cl(ind)
//     cl(this.state.operators[ind])
//     let val=((ind==0)&&(this.state.operators[ind]==OPERATOR_NONE))?"is non-zero":this.state.operators[ind]
//     cl(val)
//     cl(this.state.operators)
//     if((this.auxControl.auxIndex==1)&&(ind==0)){
//         cl("got")
//         cl(this.auxControl)
//         cl(this.state.operators)
//     }
    if(this.operatorFields[ind]==this.state.editField){
//     if(this.operatorFields.includes(this.state.editField)){
      return(
        <div style={{display: "inline", marginLeft: "0.3em", }}>
        <C18CustomSelect00 
          value={this.state.operators[ind]}
          onChange={e=>this.onChange2("operator",ind,e)}
        >
        {this.makeOptions(this.operators)}
        </C18CustomSelect00>
        </div>
      )
    }else{
      return this.dispOperator(ind)
    }
  }
  
  showOperandType=(ind)=>{
//     cl(this.targetTypes)
//     cl(this.operandTypes)
//     if(this.operandTypeFields.includes(this.state.editField)){
    if(this.operandTypeFields[ind]==this.state.editField){
      if((ind==1)&&(this.state.operandTypes[0]==OPERAND_HOUR_MINUTE_TIME)){return this.showOperandEditValue(ind)}
      if((ind==1)&&(this.state.operandTypes[0]==OPERAND_TEMPERATURE_STAGE)){return this.showOperandEditValue(ind)}
      
//       if(([0,2].includes(ind))&&
      let operators=this.state.operators
      if((((ind==0)&&(![3,4].includes(operators[0])))
        ||(ind==2)&&(![3,4].includes(operators[1]))
      )&&
        [OPERAND_HOUR_MINUTE_TIME,OPERAND_TEMPERATURE_STAGE].includes(this.state.operandTypes[1])
//         ((this.state.operandTypes[1]==OPERAND_HOUR_MINUTE_TIME)||
//         (this.state.operandTypes[1]==OPERAND_TEMPERATURE_STAGE))
      ){return this.showOperandEditValue(ind)}
      // cl("still showing")
      return(
        <div style={{display: "inline", marginLeft: "0.3em", }}>
        <C18CustomSelect00 
          value={this.state.operandTypes[ind]} 
          onChange={e=>this.onChange2("operand",ind,e)}
        >
        {this.makeOptions((ind>2)?this.targetTypes:this.operandTypes)}
        </C18CustomSelect00>
        {this.showOperandEditValue(ind)}
        </div>
      )
    }else{
      return this.dispOperandType(ind)
    }
  }
  
//     showTargetType=(ind)=>{
// //     if(this.operandTypeFields.includes(this.state.editField)){
//     if(TARGET_TYPE_FIELD==this.state.editField){
//       return(
//         <div style={{display: "inline", marginLeft: "0.3em", }}>
//         <select 
//           value={this.state.targetType} 
//           onChange={e=>this.onChange2("targetType",ind,e)}
//         >
//         {this.makeOptions(this.targetTypes)}
//         </select>
//         {this.showTargetEditValue(ind)}
//         </div>
//       )
//     }else{
//       return this.dispTargetType(ind)
//     }
//   }
  
isNumber=(x)=>{
    let y = x*1;
    return(y >= 0) && (y < 60);
  }

  checkParts=(type,parts)=>{
    let isNum = true;
    let tot = 0;
    for (let i = 0 ; i < parts.length ; i++){
      let p = parts[i]
      if (this.isNumber(p)){
        tot = tot * 60 + p*1;
      }else{
        return -1;
      }
    }
    if(type!="cs"){tot *= 60}
    return tot;
  }

  tryColons=(type,str)=>{
    let parts=str.split(":");
    return this.checkParts(type,parts);
  }
  
  muiTimeToMins=(txt)=>{
    let p=txt.split(":")
    return p[0]*60+(+p[1])
  }
  
  minsToDispTime=(mins)=>{
//     cl(mins)
    let hr=Math.floor(mins/60)
    let mn=mins%60
    if(mn<10)mn="0"+mn
    let ap=(hr>=12)?"PM":"AM"
    hr=1+(hr+11)%12// 0->12, 1->1, 11->11, 12->12, 13->1
    return `${hr}:${mn} ${ap}`
  }
  
  minsToMuiTime=(mins)=>{
    let hr=Math.floor(mins/60)
    let mn=mins%60
    let ret=`${az(hr,2)}:${az(mn,2)}`
//     cl(ret)
//     ret="00:00"
//     cl(ret)
    return ret
  }

  hmsToSecs=(hms)=>{
    // cl(hms)
    let parts = hms.split(":")
    let mults = {h: 3600, m: 60, s: 1}
    let tot = 0;
    let isNum = true;
    // cl(parts)
    parts.forEach(p=>{
      if (p!==""){
        let len = p.length
        let num = p.substr(0, len -1)
        isNum &= this.isNumber(num)
        if (!isNum) return -1;
        let typ = p.substr(len-1, 1)
        tot += num * mults[typ]
      }
    })
    return tot;
  }

  tryHMS=(str)=>{
    let strh = str.replace("h", "h:")
        .replace("m", "m:")
        .replace("s", "s:");
    let secs = this.hmsToSecs(strh)
    return secs;
  }

  parseTimeString=(type,str)=>{
    let secs = this.tryColons(type,str)
    if (secs < 0) secs = this.tryHMS(str)
    if(type!="cs"){secs = Math.floor(secs / 60)}
    return secs;
  }

  secsToHMS=(type,secs)=>{
    // cl(this.props.custom.mode)
    if(type!="cs"){secs = secs * 60}// input is actually in minutes for hold time
    let h = Math.floor(secs / 3600);
    let m = Math.floor(secs / 60) % 60;
    let s = secs % 60;
    let ret = h + ":" + leadZeros(m, 2);
//     if (this.props.custom.mode !== "min"){
    if(type!="ah"){
      ret += ":" + leadZeros(s, 2);
    }
//     }
    return ret ; // h + ":" + leadZeros(m, 2) +        ":" + leadZeros(s, 2);

  }

  durFocus=(type)=>{
    this.setState((type=="cs")?{conditionSecondsFocus: true}:{actionHoldFocus: true})
  }
  
  durBlur=(type)=>{
    cl("durblur")
    let secs=this.parseTimeString(type,(type=="cs")?this.state.conditionSecondsText:this.state.actionHoldText)
//     if(type!="cs"){secs=Math.floor(secs/60)}
    let str=this.secsToHMS(type,secs)
    if(type=="cs"){
      this.auxControl.conditionSeconds=secs
      this.setState({conditionSecondsFocus: false, conditionSeconds: secs, conditionSecondsText: str})
    }else{
      cl(secs)
//       let mins=Math.floor(secs/60)
      this.auxControl.actionHoldTime=secs//Math.floor(secs/60)
      this.setState({actionHoldFocus: false, actionHoldTime: secs, actionHoldText: str})
    }
  }
  
  showDuration=(type)=>{
    if(((type=="cs")?CONDITION_SECONDS_FIELD:ACTION_HOLD_FIELD)==this.state.editField){
//           style={{marginLeft: "0.3em", width: 75}}
      return(
        <>
        <input type="text" style={{display:"inline-block"}}
          onFocus={()=>this.durFocus(type)}
          onBlur={()=>this.durBlur(type)}
          value={(type=="cs")?this.state.conditionSecondsText:this.state.actionHoldText}
          onChange={e=>this.onChange2((type=="cs")?"conditionSecondsText":"actionHoldText",null,e)}
        />
        </>
      )
    }else{
//       cl(this.state)
      return(
        <span style={{marginLeft:"0.3em", cursor: this.cursor, textDecoration:this.underline}}
        onClick={()=>this.editFieldSelect((type=="cs")?CONDITION_SECONDS_FIELD:ACTION_HOLD_FIELD)}>
          {(type=="cs")?this.state.conditionSecondsText:this.state.actionHoldText}</span>
      )
    }
  }
  
  showComment=()=>{
//     cl(this.state.editField)
    cl(this.props)
    
    if(this.state.editField==USER_COMMENT_FIELD){
      cl("comment")
      return(
        <input type="text"
          value={this.state.userComment}
          onChange={e=>this.onChange2("userComment",this.state.editField,e)}
        />
      )
    }else{
      cl("not comment")
      return(
        <span style={{cursor: "pointer"}} onClick={()=>this.editFieldSelect(USER_COMMENT_FIELD)}>
          {this.state.userComment}
        </span>
      )
    }
  }
  
  showActionHold=()=>{
    
  }

  showAction=()=>{
    if(ACTION_FIELD==this.state.editField){
      return this.showSelect(this.state.action,"action",null,[
        {v:0,t:"set"},
        {v:1,t:"offset"}
      ])
//       return(
//         
//         <input 
//           style={{marginLeft: "0.3em", width: 75}}
//           onFocus={this.durFocus}
//           onBlur={this.durBlur}
//           value={this.state.conditionSecondsText}
//           onChange={e=>this.onChange2("conditionSecondsText",null,e)}
//         />
//       )
    }else{
//         cl(this.state)
      return(
        <span style={{marginLeft:"0.3em", cursor: this.cursor, textDecoration:this.underline,}}
        onClick={()=>this.editFieldSelect(ACTION_FIELD)}>{(+this.state.action)?"offset":"set"}</span>
      )
    }
  }
  
  showActionParameterB=(proportional)=>{
    let val=+this.state.actionParameter
    // cl(val)
    if(this.state.operandTypes[3]==OPERAND_TEMPERATURE_STAGE){ 
      if(val < 0) {val=0}
      if(val>12){val=12}
      return this.dispOVStageMap(4,val,STAGE_TYPE_MAP) 
    }
    if(this.state.operandTypes[3]==OPERAND_EQUIPMENTS){
      if(!proportional){
        if(val < 0) {val=0}
        if(val>2){val=1}
      }
      let map=(proportional)?EQUIPMENT_PROP_MAP:EQUIPMENT_TYPE_MAP
      cl(val,map)
      return this.dispOVStageMap(4,val,map) 
    }
    return this.state.actionParameter      
  }
  
  showActionParameter=()=>{
//     cl(this.state)
//     cl(this.props)
    var proportional
    if(this.state.operandTypes[3]==OPERAND_EQUIPMENTS){ 
      let chan=+this.state.operandValues[3]
      let eqType=+this.props.parms.equipmentTypes[chan]
      proportional=this.proportionals.includes(eqType)
    }
    if(ACTION_PARAMETER_FIELD==this.state.editField){
      if(this.state.operandTypes[3]==OPERAND_TEMPERATURE_STAGE){ return this.showOVMapType(4,STAGE_TYPE_MAP)}
      cl(this.state.operandTypes[3])
      if(this.state.operandTypes[3]==OPERAND_EQUIPMENTS){ 
        let map=(proportional)?EQUIPMENT_PROP_MAP:EQUIPMENT_TYPE_MAP
        return this.showOVMapType(4,map)
      }
      return this.showNumeric(this.state.actionParameter, "actionParameter", null)
    }else{
      return(
        <span style={{marginLeft:"0.3em", cursor: this.cursor, textDecoration:this.underline,}}
        onClick={()=>this.editFieldSelect(ACTION_PARAMETER_FIELD)}>
        {this.showActionParameterB(proportional)}</span>
      )
    }
  }
  
  showAuxControl=()=>{
//             <div className="custom body"><span>1</span>. When <button>Time of Day</button> <button>=</button> <button>12:00 AM</button> <button>N/A</button> <button>for</button> <button>0:00:00</button> then <button>set</button> <button>FV1</button> to <button>On</button> and hold <button>0:00:00</button></div>
    let aeClass=(this.props.parms.hideBox)?"":"aux-element"
    let pa=this.props.parms
//     cl(this.state)
//     cl(this.props)
//     cl(pa)
//     cl(pa.auxControl)
//     cl(pa.auxControl.pauseAuxId)
    var bgColor
    if(pa.auxControl.pauseAuxId){bgColor="#CCCCCC"}
    // aux-controls
    return(
      <div className="custom">

        <div className="clearfloat"></div>

        <div className={aeClass} style={{display:"flex",backgroundColor:bgColor}}>
          <div className="left">
              <div style={{width:24,fontSize:24,textAlign:"center"}}>{this.props.parms.index+1}</div>
          {(!this.props.parms.hideButtons)&&
            <>
              <button type="button" className="material-icons up-arrow"
              onClick={()=>this.props.parms.onChange({t:"button",v:{t:"up"}})}
              title='Move Up'
              >
                arrow_upward
              </button>
              <button type="button" className="material-icons down-arrow"
              onClick={()=>this.props.parms.onChange({t:"button",v:{t:"down"}})}
              title='Move Down'
              >
                arrow_downward
              </button>
              {!pa.auxControl.pauseAuxId &&
              <button type="button" className="material-icons" aria-label="pause aux control" style={{ 
              left: "0",
              bottom: "0",
              marginLeft: "0",
              color: "#1F8DED"}}
              onClick={()=>this.props.parms.onChange({t:"button",v:{t:"pause"}})}
              title="Pause"
              >
                pause
              </button>
              }
              {pa.auxControl.pauseAuxId &&
              <button type="button" className="material-icons" aria-label="play aux control" style={{ 
              left: "0",
              bottom: "0",
              marginLeft: "0",
              color: "#1F8DED"}}
              onClick={()=>this.props.parms.onChange({t:"button",v:{t:"pause"}})}
              title='Enable'
              >
                play_arrow
              </button>
              }
              <button type="button" className="material-icons" aria-label="delete aux control" style={{
                left: "0",
                bottom: "0",
                marginLeft: "0",
                color: "red"
              }}
              onClick={()=>this.props.parms.onChange({t:"button",v:{t:"delete"}})}
              title='Delete'
              >
                delete_outline
                </button>
            </>
          }
          </div>
          <div className="right">
            {this.showAuxControlText()}
            {(!this.props.parms.hideComment)&&
              <div className="bottom" style={{
                marginTop: "48px"
              }}>
                <label>Comment</label>
                <textarea 
                value={this.state.userComment}
                onChange={e=>this.onChange2("userComment",null,e)}
                ></textarea>
              </div>
            }
          </div>
        </div>
      </div>
    )
  }
  
  showAuxControlText=()=>{
    let en2=this.state.operators[0]!=0
    let en3=en2&&(this.state.operators[1]!=0)
    let style={}
//     cl(this.auxControl)
    if(this.props.parms.inline){style.display="inline"}
//       <div style={style}>
//       {this.showAuxControl()}
//       </div>
//     cl(this.props.parms.equipments[0])

    return(
      <div>
        <div style={{marginTop:11,marginBottom:28,display:"inline-block"}}>When </div>
        {this.showOperandType(0)}
        {this.showOperator(0)}
        {en2&&this.showOperandType(1)}
        {en2&&this.showOperator(1)}
        {en3&&this.showOperandType(2)}
        <span style={{marginLeft: "0.3em"}}>for</span>
        {this.showDuration("cs")}
        <span style={{marginLeft: "0.3em"}}>then</span>
        {this.showAction()}
        {this.showOperandType(3)}
        <span style={{marginLeft: "0.3em"}}>{+this.state.action?"by":"to"}</span>
        {this.showActionParameter()}
        <span style={{marginLeft: "0.3em"}}>and hold</span>
        {this.showDuration("ah")} minutes
      </div>
    );
    
  }

  render(){
//     cl(this.state)
//     cl(this.auxControl.operators)
//     cl(this.state.operandTypes)
//     cl(this.props.parms.auxControl.operandTypes[0])
    let style={}
    if(this.props.parms.inline){style.display="inline"}
    return(
      <div style={style}>
        {this.showAuxControl()}
      </div>
      
    )
  }

}

export default AuxControl ;
